import {
  Box,
  Button,
  Card,
  Dialog,
  // DialogActions,
  DialogTitle,
  IconButton,
  InputAdornment,
  InputBase,
  Typography,
  Grid,
  CircularProgress
} from "@mui/material";
import React, { useEffect, useState } from "react";
import JobHeader from "../common/JobHeader";
// import JobTime from "../common/JobTime";
// import JobDetails from "../common/JobDetails";
import priorityHighSymbol from "../../assets/images/priorityHighSymbol.svg";
// import priorityLowSymbol from "../../assets/images/priorityLowSymbol.svg";
// import priorityMediumSymbol from "../../assets/images/priorityMediumSymbol.svg";
import timerImage from "../../assets/images/timer.svg";
import openTextImage from "../../assets/images/openText.svg";
import naukriImage from "../../assets/images/naukri-round.svg";
import indeedImage from "../../assets/images/indeed-round.svg";
import linkedInImage from "../../assets/images/linkedIn-round.svg";
import { useNavigate } from "react-router-dom";
import { GridCloseIcon } from "@mui/x-data-grid";
import CopyUrlBox from "../common/CopyUrlBox";
import { getJobPostAPIData } from "../../Crud/card";
import SearchIcon from '@mui/icons-material/Search';
import NoDataAvail from "../../assets/images/NoDataAvail.svg";
import { useAppSelector } from "../../redux/hooks";

interface JobPostCreationCardProps {
  drawerOpen: boolean;
  setLowCount: Function;
  setMediumCount: Function
  setHighCount: Function
}

const JobPostCard: React.FC<JobPostCreationCardProps> = ({ drawerOpen,setLowCount, setHighCount, setMediumCount  }) => {

  const [open, setOpen] = useState(false);
  const [items, setItems] = useState<any>("")
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  console.log('data---->',data)
  const [loading, setLoading] = useState<boolean>(false);
  const userId = localStorage.getItem("userId") ?? "";
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
  useEffect(() => {
    if (searchQuery.trim()) {
      setSearchLoading(true);
    } else {
      setSearchLoading(false);
    }

    const timeoutId = setTimeout(() => {
      setLoading(true);
      getJobPostAPIData(userId, searchQuery,teamHeadFilter)
        .then((res) => {
          setData(res?.data?.listOfRth);   
          setLowCount(res.data.count.JobPostPriorityCount.LowCount); 
          setHighCount(res.data.count.JobPostPriorityCount.HighCount); 
          setMediumCount(res.data.count.JobPostPriorityCount.MediumCount);
        })
        .catch((error) => {
          setData([]);
        })
        .finally(() => {
          setLoading(false);
          setSearchLoading(false);
        });
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [userId, searchQuery,teamHeadFilter,setMediumCount,setLowCount,setHighCount]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  }  
  return (
    <>

      {/* Search */}
      <Grid item sx={{ marginTop: data.length > 0 ? "-60px" : "-58px" }} position='absolute'>
        <InputBase
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search MRF..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
            </InputAdornment>
          }
          sx={{
            border: '1px solid #ccc',
            borderRadius: 1,
            padding: 0,
            backgroundColor: 'white',
            minHeight: '3px',
            fontSize: 14,
            '.MuiInputBase-input': {
              padding: '5px 5px',
            }
          }}
        />
        {searchLoading && (
          <CircularProgress
            size={20}
            sx={{
              position: 'absolute',
              right: '10px',
              top: '25%',
              transform: 'translateY(-50%)',
              color: '#2F54EB',
            }}
          />
        )}
      </Grid>
      <Box>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '300px',
            }}
          >
            <CircularProgress size={100} sx={{ color: '#2F54EB' }} />
          </Box>
        ) : data?.length > 0 ? (
          <Box sx={{ display: "grid", gridTemplateColumns: drawerOpen ? "repeat(4, 1fr)" : "repeat(5, 1fr)", gap: 2, alignItems: "flex-start" }}>
            {data?.map((item: any) => (
              <Card
                key={item?.seqId}
                sx={{
                  height: "auto",
                  minHeight: "120px",
                  minWidth: "fit-content",
                  boxShadow: "none",
                  border: "1px solid #D9D9D9",
                  borderRadius: "8px",
                  cursor: 'pointer'
                }}
                onClick={() => {
                  navigate(`/request-hire`, {
                    state: { id: item?.rthId, status: "jobPost", queryParam: "job-post" },
                  })
                  setItems(item)
                }
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    margin: 1,
                    position: "relative",
                  }}
                >
                  <Box sx={{ paddingInline: "0.5rem", cursor: "pointer" }}>
                    <JobHeader department={item?.positionTitle} priority={item?.priority} business={item?.business} seqId={item?.seqId} />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "5px",
                        margin: "0 5px",
                        paddingBottom: "5px",
                      }}
                      gap={1}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: "1px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "10px",
                            color: "#A3A1A1",
                          }}
                        >
                          #{item?.seqId}
                        </Typography>
                      </Box>
                      <Typography
                        gap={1}
                        sx={{
                          fontWeight: "bold",
                          fontSize: "10px",
                          color:
                            item?.priority === "High"
                              ? "#F04E4E"
                              : item?.priority === "Medium"
                                ? "yellow"
                                : "green",
                        }}
                      >
                        {item?.priority === "High" ? (
                          <img src={priorityHighSymbol} alt="" />
                        ) : item?.priority === "Medium" ? (
                          <img src={item?.priorityMediumSymbol} alt="" />
                        ) : (
                          <img src={item?.priorityLowSymbol} alt="" />
                        )}
                        {" " + item?.priority}
                      </Typography>
                    </Box>
                    {/* <JobDetails typeOfRth={item?.typeOfRth} modeOfRth={item?.modeOfRth} rthProcess={item?.rthProcess} status={item?.status} modesOfRth={item?.modesofRth} /> */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "2px 3px",
                      }}
                    >
                      <Box sx={{ display: "flex", gap: "5px" }}>
                        <Button
                          size="small"
                          sx={{
                            mr: 1,
                            borderRadius: "8px",
                            backgroundColor: "#F0F0F0",
                            border: "1px solid lightgray",
                            fontSize: "7px",
                            padding: "1px",
                            margin: "1px",
                            color: "#8C8C8C",
                            minWidth: "50px",
                          }}
                        >
                          {item?.typeOfRth}
                        </Button>
                        <Button
                          size="small"
                          sx={{
                            mr: 1,
                            borderRadius: "8px",
                            backgroundColor: "#F0F0F0",
                            border: "1px solid lightgray",
                            fontSize: "7px",
                            padding: "1px",
                            margin: "1px",
                            color: "#8C8C8C",
                            minWidth: "50px",
                          }}
                        >
                          {item?.modeOfRth}
                        </Button>
                        <Button
                          size="small"
                          sx={{
                            mr: 1,
                            borderRadius: "8px",
                            backgroundColor: "#427bc53f",
                            border: "1px solid #427BC5",
                            fontSize: "7px",
                            padding: "1px",
                            margin: "1px",
                            color: "#427BC5",
                            minWidth: "60px",
                          }}
                        >
                          JD Created
                        </Button>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", gap: "4px", marginTop: "10px" }}>
                      <img src={timerImage} alt="timer" />
                      <Typography sx={{ fontSize: "10px", color: "#8C8C8C" }}> {item?.jobPosted ? "Posted in" : "Not Posted Yet"} </Typography>
                      <Box
                        sx={{
                          position: "relative",
                        }}
                      >
                        {item?.postedIn?.naukri && (
                          <Box
                            component="img"
                            src={naukriImage}
                            alt="Naukri"
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "16px",
                              height: "16px",
                              objectFit: "cover",
                              zIndex: 1,
                            }}
                          />
                        )}
                        {item?.postedIn?.indeed && (
                          <Box
                            component="img"
                            src={indeedImage}
                            alt="Indeed"
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 5,
                              width: "16px",
                              height: "16px",
                              objectFit: "cover",
                              zIndex: 2,
                            }}
                          />
                        )}
                        {item?.postedIn?.linkedIn && (
                          <Box
                            component="img"
                            src={linkedInImage}
                            alt="LinkedIn"
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 15,
                              width: "16px",
                              height: "16px",
                              objectFit: "cover",
                              zIndex: 3,
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Box sx={{ width: "100%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 1,
                            marginTop: "2px",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ fontSize: "10px", lineHeight: 1.2 }}
                          >
                            {item?.name}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{ fontSize: "8px", lineHeight: 1.2, color: "gray" }}
                          >
                            {item?.reqName}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginTop: "5px",
                      marginLeft: "8px",
                    }}
                  >
                    {item?.jobPosted ? (
                      <Button
                        sx={{
                          textTransform: "none",
                          minWidth: "80px",
                          padding: "4px 8px",
                          fontSize: "10px",
                          background: "#1677ff29",
                          border: "1px solid #1677FF",
                          color: "#1677FF",
                          "&:hover": {
                            background: "#1677ff29",
                            color: "#1677FF",
                            boxShadow: "none",
                          },
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpen(true);
                          setItems(item)
                        }}
                      >
                        Posted in
                      </Button>
                    ) : (
                      <Button
                        sx={{
                          textTransform: "none",
                          minWidth: "80px",
                          padding: "4px 8px",
                          fontSize: "10px",
                          background: "#1677FF",
                          color: "#FFFFFF",
                          "&:hover": {
                            background: "#1677FF",
                            color: "#FFFFFF",
                            boxShadow: "none",
                          },
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpen(true);
                          setItems(item)
                        }}
                      >
                        Post Job
                      </Button>
                    )}
                  </Box>
                  <Box sx={{ position: "absolute", right: 10, bottom: 1 }}>
                    <img src={openTextImage} alt="open-image" width={20} />
                  </Box>
                </Box>
              </Card>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "300px",
            }}
          >
            <img src={NoDataAvail} alt="No Data Available" style={{ maxWidth: "200px" }} />
          </Box>
        )}
        <Dialog open={open} onClick={(e) => { e.stopPropagation(); setOpen(false) }}>
          <DialogTitle>
            <Typography sx={{ color: "#8C8C8C", fontSize: "14px" }}> Share </Typography>
            <IconButton edge="end" onClick={() => setOpen(false)} aria-label="close" size="small"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#8C8C8C",
              }}
            >
              <GridCloseIcon />
            </IconButton>
          </DialogTitle>
          <Box mx={2}> <CopyUrlBox id={items?.rthId} /> </Box>
          <Box mx={2} my={2}>
            <Typography sx={{ fontSize: "10px", color: "#8C8C8C" }}>
              Post here
            </Typography>
            {!items?.postedIn?.naukri && (
              <Box component="img" src={naukriImage} alt="Naukri" sx={{ width: "20px", height: "20px" }} />
            )}
            {!items?.postedIn?.indeed && (
              <Box component="img" src={indeedImage} alt="Indeed" sx={{ width: "20px", height: "20px" }} />
            )}
            {!items?.postedIn?.linkedIn && (
              <Box component="img" src={linkedInImage} alt="linkedInImages" sx={{ width: "20px", height: "20px" }} />
            )}
          </Box>
          <>
            {items?.jobPosted && (
              <Box mx={2} my={2}>
                <Typography sx={{ fontSize: "10px", color: "#8C8C8C" }}> Posted here </Typography>
                {items?.postedIn?.naukri && (
                  <Box component="img" src={naukriImage} alt="Naukri" sx={{ width: "20px", height: "20px" }} />
                )}
                {items?.postedIn?.indeed && (
                  <Box component="img" src={indeedImage} alt="Indeed" sx={{ width: "20px", height: "20px" }} />
                )}
                {items?.postedIn?.linkedIn && (
                  <Box component="img" src={linkedInImage} alt="linkedInImages" sx={{ width: "20px", height: "20px" }} />
                )}
              </Box>
            )}
          </>
        </Dialog>
      </Box>
    </>
  );
}

export default JobPostCard;
