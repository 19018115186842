import {
  Box,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import CandidateCard from "../../../common/CandidateCard";
import pdfImage from "../../../../assets/images/pdf 1.svg";
import {
  deleteCandidateById,
  getCandidateByRthIdandRecruiterId,
} from "../../../../redux/features/candidate";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import { StyledTableCell } from "./RthClosedTable";
import { GridDeleteIcon } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import SearchIcon from "@mui/icons-material/Search";
import { calculateDaysForCandidateCard } from "../../../../helpers/calculateDays";
// import RecruiterDropDown from "../../commoncomponent/RecruiterDropDown";
import { editApproveview, getMrfByRthId, getRthById } from "../../../../Crud/rth";
import CustomRecruiterDropDown, { optionType } from "../../commoncomponent/CustomRecruiterDropDown";

export const CandidateDetails = ({ viewType, drawerOpen,searchQuery,setSearchQuery }: any) => {
  const [candidate, setCandidate] = useState<any>([]);

  console.log("cadi", candidate);
  const location=useLocation();
  const resumeRepoPath=location.pathname.includes("resumeRepo");
  const { id, jobTitle } = useParams();
  console.log("mrfID",id);
  // const [loading, setLoading] = useState<boolean>(false);
  // const [candidateIdd, setCandidateId] = useState<string>("");
  // const [searchQuery, setSearchQuery] = useState<string>('');
  const[page,setpage]=useState(0);
  const filterDate=useAppSelector((state)=>state.teamHeadFilter);
  const filterData=useAppSelector((state)=>state.resumeInfo);
  const{candidateExpectedCtc,candidateInterviewStage,candidateNoticePeriod,candidateSource,selectedStatuses}=filterData;
  const{fromDate,toDate}=filterDate;
  const candidateDetails=useAppSelector((state)=>state.resume.allCandidates);
  console.log("candidateDetailsss",candidateDetails);
  const dispatch=useAppDispatch();
  const recruiterId=localStorage.getItem('userId');
  const scrollPosition = useRef(0);    
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const hasMore = true;
  const [searchState, setSearchState] = useState(false);
  const navigate = useNavigate();
  const [recruiterData,setRecruiterData]=useState<optionType[]>();
  const [load,setLoad]=useState<number>(0);
console.log("load", load)
  const [selectedRecruiter,setSelectedRecruiter]=useState<optionType>();
  const[isAccess,setIsAccess]=useState<boolean>(false);

  console.log("selected--Recruiter",selectedRecruiter);
  const defaultPermissions = {
    assignRecruiterLead: false,
    assignRecruiter: false,
    jj: false,
    assignAndEditRecruiterLead:false,
    assignAndEditRecruiter:false
  };
  
  const userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") || "{}")
    : {
        permissionsList: defaultPermissions,
        roleName: "",
      };

  const permissionsList = userData.permissionsList || defaultPermissions

const getValue=(recruiterId:string)=>{
  console.log("record it",recruiterId);
  if(recruiterId)
  {
    return recruiterId ? recruiterData?.map((data)=>({
      value:data.value,
      label:data.label,
      imgUrl:data.imgUrl,
      designation:data.designation
    })).find((option)=>option.value === recruiterId):null
  }
  return null;
}

  useEffect(() => {
    const checkAccess = async () => {
      const userID = localStorage.getItem("userId"); // Your target userId
      console.log("uid", userID);
      let assignedPosition:string|number = 0;

      try {
        const mrfData = await getMrfByRthId(id); // Fetch data

        console.log("john is gone",mrfData);

        if (mrfData && mrfData.length > 0) {
          const mrf = mrfData[0];

          const isRecruiter = mrf.recruiter?.userId === userID;

          console.log(isRecruiter);

          if (isRecruiter) {
            assignedPosition = mrf.openPosition;
          }
        }
        if (assignedPosition === 0) {
          setIsAccess(false);
        } else {  
          setIsAccess(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        return false;
      }
    };
    checkAccess();
  }, [id]);

  useEffect(()=>{
    getRthById(id).then((res)=>{      
      setRecruiterData(
        res.data.assignedRecruiter.map((data:any)=>({
        label:data.userName,
        value:data.userId,
        imgUrl:data.imageUrl,
        designation:data.designation
      })));
    })
  },[id])

    useEffect(()=>{
      const fetch=async()=>{
        if (selectedRecruiter?.candidateId && selectedRecruiter?.value) {
          await editApproveview(selectedRecruiter.candidateId, {
            recruiterId: selectedRecruiter.value
          });
        }
        setLoad((prev:number)=>prev+1);
      }
      fetch();
    },[selectedRecruiter])

  console.log("reason",recruiterData);



  const extractValues = (
    items: { label: string; value: string }[] | null | undefined
  ) => {
    if (!items || items.length === 0) {
      return null;
    }
    return items.map((item) => item.value);
  };

  const normalizePayloadValue = (value: any) => {
    if (Array.isArray(value)) {
      return value.length > 0 ? value : null;
    }
    return value || null;
  };

  const normalizeSalaryValue = (value: any) => {
    // If the value is a string and can be converted to a number, convert it to an array with that number

    if (value == null || value === "") {
      return null;
    }

    if (typeof value === "string" && !isNaN(Number(value))) {
      return [Number(value)];
    }
    // Otherwise, return the value as is (or null if the value is not valid)
    return value || null;
  };

  const Md = useMediaQuery("(max-width: 1504px)");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setpage(0);
    setSearchQuery(event.target.value);
  };

  const handleScroll = useCallback(() => {
    if (!scrollContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } =
      scrollContainerRef.current;

    // Trigger the API call only if scrolling downward and near the bottom
    if (scrollTop > scrollPosition.current) {
      if (
        scrollTop + clientHeight >= scrollHeight - 100 &&
        hasMore &&
        page >= 0
      ) {
        setpage((prevPage) => prevPage + 1);
      }
    }

    // Update the previous scroll position
    scrollPosition.current = scrollTop;
  }, [hasMore, page, scrollPosition, scrollContainerRef]);

  const handleDeleteButton = async (candidateId: string) => {
    // await setCandidateId(candidateId);

    await deleteCandidateById(candidateId);

    // setCandidateId("");
    toast.success("Deleted Sucessfully");
    const filterPayload = {
      resumesPostedInFrom: normalizePayloadValue(fromDate) || null,
      resumesPostedInTo: normalizePayloadValue(toDate) || null,
      interviewStages: normalizePayloadValue(candidateInterviewStage) || null,
      source: normalizePayloadValue(candidateSource) || null,
      expectedCtc: normalizeSalaryValue(candidateExpectedCtc) || null,
      noticePeriod: normalizePayloadValue(candidateNoticePeriod) || null,
      status: extractValues(selectedStatuses) || null,
      page: page,
      size: 20,
    };
    dispatch(
      getCandidateByRthIdandRecruiterId({
        rthId: id,
        recId: recruiterId,
        data: filterPayload,
        searchData: searchQuery,
      })
    );
  };

  const tableHead = [
    "SI.No",
    "Profile Details",
    "Email ID",
    "Mobile Number",
    "Recruiter",
    "Resume",
    "Arrived Status",
    "Interview Status",
    ...(permissionsList.assignAndEditRecruiterLead || permissionsList.assignAndEditRecruiter) && !resumeRepoPath 
        ? ["Action"] 
        : []
];


  const getFileName = (url: string): string => {
    const fileNameWithParams = url?.split("/").pop() || ""; // Get the last part of the URL
    const fileName = fileNameWithParams?.split("?")[0]; // Remove query params

    // Truncate if the name exceeds 32 characters
    return fileName?.length > 32 ? `${fileName?.slice(0, 29)}...` : fileName;
  };

  const handleRowClick = (candidateId: any, firstName: any, lastName: any) => {
    const fullName = lastName ? `${firstName} ${lastName}` : firstName;
    // console.log("elakkia or sughanthan pannidunga")
    navigate(`/newRequestToHire/${jobTitle}/${id}/candidate/${fullName}/${candidateId}`)
  }
  
  const fetchCandidates = useCallback(() => {
    if (!load) return;

    const filterPayload = {     
      "resumesPostedInFrom": null,      
      "resumesPostedInTo": null,      
      "interviewStages": null,      
      "source": null,      
      "expectedCtc": null,      
      "noticePeriod": null,      
      "status": null,
      "page": 0,
      "size": 20
    };
  
    dispatch(getCandidateByRthIdandRecruiterId({ 
      rthId: id, 
      recId: recruiterId, 
      data: filterPayload, 
      searchData: '' 
    }));
    
    setCandidate([]);
  },[recruiterId,id,dispatch,load]);
  
  useEffect(()=>{
    if(searchQuery?.length>0||fromDate||toDate||candidateExpectedCtc.length>0||
      candidateInterviewStage.length>0||candidateSource.length>0||candidateNoticePeriod.length>0||
      selectedStatuses.length>0
    ){
      setSearchState(true);
    } else {
      setSearchState(false);
    }
  }, [
    searchQuery,
    fromDate,
    toDate,
    candidateInterviewStage,
    candidateSource,
    candidateExpectedCtc,
    candidateNoticePeriod,
    selectedStatuses,
  ]);

  useEffect(() => {
    // setLoading(true);
    const filterPayload = {
      resumesPostedInFrom: normalizePayloadValue(fromDate) || null,
      resumesPostedInTo: normalizePayloadValue(toDate) || null,
      interviewStages: normalizePayloadValue(candidateInterviewStage) || null,
      source: normalizePayloadValue(candidateSource) || null,
      expectedCtc: normalizeSalaryValue(candidateExpectedCtc) || null,
      noticePeriod: normalizePayloadValue(candidateNoticePeriod) || null,
      status: extractValues(selectedStatuses) || null,
      page: page,
      size: 20,
    };
    dispatch(
      getCandidateByRthIdandRecruiterId({
        rthId: id,
        recId: recruiterId,
        data: filterPayload,
        searchData: searchQuery,
      })
    );
  }, [
    fromDate,
    toDate,
    candidateInterviewStage,
    candidateSource,
    candidateExpectedCtc,
    candidateNoticePeriod,
    selectedStatuses,
    page,
    searchQuery,
    dispatch,
    id,
    recruiterId,
  ]);

  useEffect(() => {
    if (candidateDetails?.length > 0) {
      if (searchState) {
        setCandidate(candidateDetails);
      } else {
        setCandidate((prevData: any) => [
          ...prevData,
          ...candidateDetails.filter(
            (item: any) =>
              !prevData.some(
                (prevItem: any) => prevItem.candidateId === item.candidateId
              )
          ),
        ]);
      }
    }
    if (searchState) {
      setCandidate(candidateDetails);
    }
  }, [candidateDetails, searchState]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMore, handleScroll]);

  useEffect(() => {
    setSearchQuery("");
  }, [viewType,setSearchQuery]);

  useEffect(()=>{
  //   const filterPayload={     
  //     "resumesPostedInFrom":null,      
  //     "resumesPostedInTo":null,      
  //     "interviewStages":null,      
  //     "source":null,      
  //     "expectedCtc":null,      
  //     "noticePeriod":null,      
  //     "status":null,
  //     "page":0,
  //     "size":20
  //   }
  //   // setLoading(true);
  // dispatch(getCandidateByRthIdandRecruiterId({rthId:id,recId:recruiterId,data:filterPayload,searchData:''}))
  // setCandidate([]);
  // setLoading(false);
  fetchCandidates();
  },[dispatch,id,recruiterId,fetchCandidates])
 
  return (
    <>
         <Box sx={{ml:'50px',mt:'-55px' }}>
          <Grid item>
            <InputBase
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search Profile..."
              startAdornment={<InputAdornment position="start">
                <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
              </InputAdornment>}
              sx={{
                border: '1px solid #ccc',
                borderRadius: 1,
                padding: 0,
                backgroundColor: 'white',
                minHeight: '3px',
                fontSize: 14,
                '.MuiInputBase-input': {
                  padding: '5px 5px',
                },
              }} />
          </Grid>
        </Box>
      {viewType === "grid" ? (
        <>
           
        <Box
          ref={scrollContainerRef}
          sx={{
            ml: "20px",
            mt: "10px",
            mb: 1,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "0px",
              height: "0px",
            },
          }}
        >
            <>
              {candidate?.length === 0 ? (

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }}>
                    No Candidate Available
                  </Typography>
                </Box>
              ) : (
                <>
                          <Grid container sx={{ height: Md ? "290px" : "440px " }}>
                    {candidate.map((candidate: any) => (
                      <Grid item key={candidate.id}>
                        <CandidateCard
                        // isAccess={isAccess}
                          name={candidate?.firstName}
                          lastName={candidate?.lastName}
                          status={calculateDaysForCandidateCard(candidate?.createdAt)}
                          daysAgo={candidate?.daysAgo}
                          phone={candidate?.phoneNumber}
                          email={candidate?.emailAddress}
                          pdfLink={candidate?.resumeUrl || ""}
                          schedule={candidate?.interviewStatus}
                          candidate={candidate}
                          drawerOpen={drawerOpen}
                          fetchCandidates={fetchCandidates} />
                      </Grid>
                    ))}
                  </Grid></>
              )}
            </>
          </Box></>
      ) : (
        <>
        {/* <Box sx={{ ml: '50px' ,mt:'-55px'}}>
            <Grid item >
              <InputBase
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search Profile..."
                startAdornment={<InputAdornment position="start">
                  <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
                </InputAdornment>}
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: 1,
                  padding: 0,
                  backgroundColor: 'white',
                  minHeight: '3px',
                  fontSize: 14,
                  '.MuiInputBase-input': {
                    padding: '5px 5px',
                  },
                }} />
            </Grid>
          </Box> */}
          <Box sx={{ width: "auto", backgroundColor: "#f0f4ff" }} p={1}>
              {candidate.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50%",
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }}>
                    No Candidate Available
                  </Typography>
                </Box>
              ) : (
                <>
                {/* <Box sx={{ ml: '50px', mt: '-55px' }}>
                    <Grid item sx={{ ...(viewType === 'grid' && { position: 'absolute' }) }}>
                      <InputBase
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search Profile..."
                        startAdornment={<InputAdornment position="start">
                          <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
                        </InputAdornment>}
                        sx={{
                          border: '1px solid #ccc',
                          borderRadius: 1,
                          padding: 0,
                          backgroundColor: 'white',
                          minHeight: '3px',
                          fontSize: 14,
                          '.MuiInputBase-input': {
                            padding: '5px 5px',
                          },
                        }} />
                    </Grid>
                  </Box> */}
                  <Box sx={{ width: "100%", overflowX: "auto", mt:'20px' }}>
                      <TableContainer component={Paper} sx={{ height: Md? '270px' : '415px', mx:1, '&::-webkit-scrollbar': { width: '0px', height: '0px' }}}>
                        <Table sx={{ minWidth: 650 }} aria-label="MRF table">
                          <TableHead sx={{ backgroundColor: "#F9F9F9" , position:'sticky', top:'0', zIndex:2}}>
                            <TableRow
                              sx={{
                                "& th": {
                                  padding: "4px 8px",
                                  fontSize: "14px", 
                                },
                              }}
                            >
                              {tableHead.map((data, index) => (
                                <StyledTableCell key={index}>{data}</StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody sx={{
                            "& td, & th": {
                              padding: "4px 8px", // Adjust padding here
                            }
                          }}>
                            {candidate?.map((data: any, index: number) =>{
                              const candidateStatus = calculateDaysForCandidateCard(data?.createdAt);
                              return (
                              <TableRow
                                onClick={() =>{
                                  if(isAccess)
                                  {
                                    handleRowClick(data?.candidateId, data?.firstName, data?.lastName)
                                  }
                                  else{
                                    toast.warning("You don't have access")
                                  }
                                } }
                                key={index}
                                sx={{
                                  cursor: "pointer",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Example color
                                  },
                                  backgroundColor:isAccess ? "white":"#e5e8e8"
                                }}
                              >
                                <StyledTableCell>{index + 1}</StyledTableCell>
                                <StyledTableCell>{data?.firstName}</StyledTableCell>
                                <StyledTableCell>{data?.emailAddress}</StyledTableCell>
                                <StyledTableCell>
                                  {data.phoneNumber}
                                </StyledTableCell>
                                <StyledTableCell onClick={(e)=>e.stopPropagation()} style={{minWidth:"200px"}}>
                                  <CustomRecruiterDropDown isAccess={isAccess} options={recruiterData} candidateId={data.candidateId} selectedOption={setSelectedRecruiter} value={getValue(data.recruiterId)}/>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <img src={pdfImage} alt="" />
                                  {getFileName(data?.resumeUrl)}
                                </StyledTableCell>
                                <StyledTableCell
                          sx={{
                            color: candidateStatus === 'Newly Arrived' ? '#57DB57' : '#7F7F7F',
                            }}
                        >
                          {candidateStatus}
                        </StyledTableCell>
                                <StyledTableCell>
                                  <Chip
                                    label={data?.interviewStatus}
                                    size="small"
                                    sx={{
                                      fontSize: "12px",
                                      height: "px",
                                      borderRadius: "1px",
                                      backgroundColor: "#1677FF1A",
                                      color: "#1677FF",
                                    }} />
                                </StyledTableCell>
                                {(permissionsList.assignAndEditRecruiterLead ||
                               permissionsList.assignAndEditRecruiter)&&(!resumeRepoPath)&&
                                <StyledTableCell>
                                  <IconButton
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteButton(data?.candidateId);
                                    } }
                                  >
                                    <GridDeleteIcon sx={{ color: "grey" }} />
                                  </IconButton>
                                </StyledTableCell>
                            }
                              </TableRow>
                            )})}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box></>
              )}
            </Box></>
      )}
    </>
  );
};
