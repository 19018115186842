import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { setSelectedRole } from "../../../redux/features/reportRoleSlice";

const RoleReportDropdown: React.FC = () => {
  const userString = localStorage.getItem("user");
  const userDataa = userString ? JSON.parse(userString) : null;
  const roleName = userDataa?.roleName || null;
  const dispatch=useDispatch<AppDispatch>();
  const selectedRole=useSelector((state:RootState)=>state.reportRole.selectedRole);
  const [roleData, setRoleData] = useState<string[]>([]);
  

  const userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") || "{}")
    : {
        permissionsList: {},
        roleName: "",
      };
  const permissionsList = userData.permissionsList || {};

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    dispatch(setSelectedRole(event.target.value));
    console.log(event.target.value);
  };

  useEffect(() => {
    const roles: string[] = [];
    // dispatch(setSelectedRole(roleName));
    if (permissionsList.mrfUsers) {
      roles.push("TEAMHEAD");
    }
    if (permissionsList.approveDeclineMrf) {
      roles.push("APPROVERHEAD");
    }
    if (permissionsList.assignAndEditRecruiter) {
      roles.push("RECRUITERLEAD");
    }
    if (permissionsList.assignAndEditRecruiterLead) {
      roles.push("TALENTACQUISITIONHEAD");
    }
    if (permissionsList.recruiterAccess) {
      roles.push("RECRUITER");
    }
    setRoleData(roles);
  }, [permissionsList.approveDeclineMrf,permissionsList.assignAndEditRecruiter,permissionsList.assignAndEditRecruiterLead,permissionsList.mrfUsers,permissionsList.recruiterAccess,]);

  return (
    <Box>
      <Select
        onChange={handleSelectChange}
        value={selectedRole}
        size="small"
        style={{
          marginLeft: "1rem",
          color: "#000",
          backgroundColor: "white",
          borderRadius: "4px",
          padding: "0.25rem",
          height: "2rem",
          fontSize: "12px",
        }}
      >
        {roleData.map((data, index) => (
          <MenuItem sx={{ fontSize: "12px" }} key={index} value={data || roleName}>
            {data}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default RoleReportDropdown;
