import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  CircularProgress,
} from "@mui/material";
import { getReportData } from "./getReportData";
import NoDataAvail from "../../assets/images/NoDataAvail.svg";
import { toast } from "react-toastify";
 
interface Prop {
  currentReportData: any[];
  selectedTab: string;
  roleName: string;
  setRthId: Function;
  setStatus: Function;
  setOpenn: Function;
  isLoading:boolean;
  setExportRows:Function;
  setExportHeaderTitle:Function;
}
 
const ReportTableView: React.FC<Prop> = ({
  currentReportData,
  selectedTab,
  roleName,
  setRthId,
  setStatus,
  setOpenn,
  isLoading,
  setExportHeaderTitle,
  setExportRows
}) => {
  const [headerTitle, setHeaderTitle] = useState<string[]>([]);
  const [rows, setRows] = useState<
    { [key: string]: string | number | null | undefined }[]
  >([]);
 
  useEffect(() => {
    const { headerNames, rows } = getReportData(
      selectedTab,
      currentReportData,
      roleName
    );
    setHeaderTitle(headerNames);
    setExportHeaderTitle(headerNames);
    setRows(rows);
    setExportRows(rows);    
    setExportRows(rows);    
  }, [currentReportData, selectedTab, roleName,setExportRows,setExportHeaderTitle]);  
 
  const handleBackgroundColor = (title: string) => {
    if (title === "Screening") return "#FFF6E7";
    if (title === "L1 Interview") return "#F7F1FF";
    if (title === "L2 Interview") return "#E8FFF1";
    if (title === "HR Round") return "#E1FAFF";
    if (title === "Other Status") return "#FFF1ED";
  };
 
  const handleCursor = (cellIndex: number) => {
    if (
      [6, 7, 8, 9, 10].includes(cellIndex) &&
      selectedTab === "Candidate Interview Status"
    )
      return "pointer";
  };
 
  const handleOnClick = (
    cellIndex: number,
    mrfId: string | number | null | undefined,
    value:any
  ) => {    
    if(value === 0)
    {
      toast.warn("No Data Available");
    }
    else{
    if (
      [6, 7, 8, 9, 10].includes(cellIndex) &&
      selectedTab === "Candidate Interview Status"
    ) {
      setOpenn(true);
      setRthId(mrfId);
      if (cellIndex === 6) setStatus("Screening");
      if (cellIndex === 7) setStatus("L1Interview");
      if (cellIndex === 8) setStatus("L2Interview");
      if (cellIndex === 9) setStatus("HRRound");
      if (cellIndex === 10) setStatus("Others");
      console.log(cellIndex, mrfId);
    }
  }
  };
 
  return (
    <>
    {
      !isLoading ? (
 
        <Box sx={{ overflowX: "scroll" }}>
        {rows.length > 0 ? (
          <TableContainer component={Paper}>
            <Table aria-label="MRF table">
              <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
                <TableRow
                  sx={{
                    "& th": {
                      padding: "4px 8px",
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                    },
                  }}
                >
                  {headerTitle.map((title, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        backgroundColor: handleBackgroundColor(title),
                      }}
                    >
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
 
              <TableBody
                sx={{
                  "& td, & th": {
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                  },
                }}
              >
                {rows?.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {Object.values(row).map((value, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        sx={{
                          cursor: handleCursor(cellIndex),
                          "&:hover": {
                            backgroundColor: (theme) =>
                              [6, 7, 8, 9, 10].includes(cellIndex) &&
                              selectedTab === "Candidate Interview Status"
                                ? theme.palette.action.selected // Color on hover
                                : "transparent",
                          },
                        }}
                        onClick={() => handleOnClick(cellIndex, row.mrfId,value)}
                      >
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "300px",
            }}
          >
            <img
              src={NoDataAvail}
              alt="No Data Available"
              style={{ maxWidth: "200px" }}
            />
          </Box>
        )}
      </Box>
 
      ) : (
        <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "300px",
        }}
      >
        <CircularProgress size={100} sx={{ color: "#2F54EB" }} />
      </Box>
      )
    }    
    </>
  );
};
export default ReportTableView;