import React, { useEffect, useState } from "react";
import {
  FormControl,
  Checkbox,
  Typography,
  Button,
  Box,
  Divider,
  Collapse,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import NavigateNext from "@mui/icons-material/NavigateNext";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import { styled } from "@mui/system";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useDispatch } from "react-redux";
import {
  resetFilterState,
  setFilterState,
} from "../../../redux/features/teamHeadFilterSlice";
import { AppDispatch } from "../../../redux/store";
import { useAppSelector } from "../../../redux/hooks";
import CommonMultiSelect from "../../common/CommonMultiSelect";
import {
  noticePeriod,
  sourceOptions,
  YearsInExp,
  InterviewMode,
  interviewNameOptions,
} from "../../../constants/dummyConst";
import {
  getInterviewStatus,
  getQualification,
  getSkill,
} from "../../../redux/features/resume";
import {
  setNoticePeriods,
  setResumeCurrentSalary,
  setResumeEducation,
  setResumeExpectedCtc,
  setResumeExpectedSalary,
  setResumeExperience,
  setResumeInterviewStages,
  setResumeInterviewStatus,
  setResumePositionTitle,
  setResumeSkillSets,
  setResumeSource,
} from "../../../redux/features/filterSlice";
import { getPosition } from "../../../redux/features/interviewSchedule";
import CommonTextField from "../../common/CommonTextField";
import {
  setInterviewName,
  setInterviewStages,
  setSource,
  setInterviewMode,
  setPositions,
  setInterviewStatus,
} from "../../../redux/features/interviewFilterSlice";
import {
  setCandidateExpectedCtc,
  setCandidateInterviewStage,
  setCandidateNoticePeriod,
  setCandidateSource,
  setInterviewDetExpectedCtc,
  setInterviewDetNoticePeriod,
  setInterviewSource,
  setInterviewStage,
  toggleStatus,
} from "../../../redux/features/resumeInfoTabFilter";
import DropDownsOfRandRl from "./DropDownsOfRandRl";
import CustomDropdown from "./RthStagesDropDown";
import PositionTitleDropDown from "./PositionTitleDropDown";
import BusinessDropDown from "./BusinessDropDown";
import LocationDropDown from "./LocationDropDown";
import CommonSelect from "../../common/CommomSelect";
import PostedInDropDown from "./PostedInDropDown";

const SmallCheckbox = styled(Checkbox)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    fontSize: 12,
  },
}));

const CustomSmallFormControlLabel = (props: {
  label: string;
  number?: string;
  isChecked?: boolean;
  onChange?: (e: any) => void;
}) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    }}
  >
    <SmallCheckbox
      checked={props.isChecked}
      sx={{ paddingLeft: "15px", borderColor: "#D9D9D9" }}
      onChange={props.onChange}
    />
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        marginLeft: "8px", // Optional spacing between checkbox and label
      }}
    >
      <Box sx={{ flexBasis: "70%" }}>
        <Typography sx={{ fontSize: "12px", paddingRight: "10px" }}>
          {props.label}
        </Typography>
      </Box>
      <Box sx={{ flexBasis: "30%" }}>
        <Typography
          sx={{
            fontSize: "12px",
            textAlign: "right",
            color: "#8C8C8C",
          }}
        >
          {props.number}
        </Typography>
      </Box>
    </Box>
  </Box>
);

const FilterSidebar = ({
  drawerOpen,
  onChangeFilter,
  tabName,
  lowCount,
  mediumCount,
  highCount,
  l1StatusPending,
  l1StatusDeclined,
  l2StatusPending,
  l2StatusApproved,
}: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
  console.log(teamHeadFilter);
  const toggleDrawer = () => onChangeFilter(!drawerOpen);  
  const [skillOptions, setSkillOptions] = useState<any[]>([]);
  const [positionOptions, setPostionOptions] = useState<any[]>([]);
  const [interviewStatusOption, setInterviewStatusOption] = useState<any[]>([]);
  const [qualificationOptions, setQualificationOptions] = useState<any[]>([]);
  const [errors, setErrors] = useState({
    resumeExpectedSalary: "",
    resumeCurrentSalary: "",
    resumeExpectedCtc: "",
    interviewDetExpectedCtc: "",
    candidateExpectedCtc: "",
  });
  const skills = useAppSelector((state) => state.resume.skills);
  const qualificationsData = useAppSelector(
    (state) => state.resume.qualifications
  );
  const resumeData = useAppSelector((state) => state.resume.interviewStatus);
  const position = useAppSelector((state) => state.interviewSchedule.position);
  const filterValues = useAppSelector((state) => state.filter);
  const {
    resumeInterviewStages,
    resumeSource,
    resumeExpectedCtc,
    resumeNoticePeriod,
    resumeskillSets,
    resumeEducation,
    resumeInterviewStatus,
    resumeExperience,
    resumeCurrentSalary,
    resumeExpectedSalary,
    resumePositionTitle,
  } = filterValues;
  const filterData = useAppSelector((state) => state.interviewFilter);
  const interviewStatusData = useAppSelector(
    (state) => state.resume.interviewStatus
  );
  const positionsData = useAppSelector(
    (state) => state.interviewSchedule.position
  );
  const {
    interviewMode,
    interviewName,
    interviewStages,
    interviewStatus,
    source,
    positions,
  } = filterData;
  const interviewDetData = useAppSelector((state) => state.resumeInfo);
  const {
    interviewDetExpectedCtc,
    interviewDetInterviewStage,
    interviewDetNoticePeriod,
    interviewDetSource,
    selectedStatuses,
    candidateExpectedCtc,
    candidateInterviewStage,
    candidateNoticePeriod,
    candidateSource,
  } = interviewDetData;

  const statuses = [
    { label: "Newly Arrived", value: "NewlyArrived" },
    { label: "Uploaded", value: "uploaded" },
    { label: "Applied", value: "applied" },
  ];

  const handleCheckboxChange =
    (status: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(toggleStatus(status));
    };

    const handleDateChange = (value: [Date, Date]) => {
      const adjustToLocal = (date: Date, isStartOfDay: boolean) => {
        const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        if (isStartOfDay) {
          adjustedDate.setHours(0, 0, 0, 0); // Start of the day
        } else {
          adjustedDate.setHours(23, 59, 59, 999); // End of the day
        }
        return adjustedDate.toISOString();
      };
    
      const fromDate = adjustToLocal(value[0], true);  // Start of the day
      const toDate = adjustToLocal(value[1], false);  // End of the day
    
      console.log("fromDate", fromDate);
      console.log("toDate", toDate);
    
      // Dispatch the action to update Redux store with the selected date range
      dispatch(
        setFilterState({
          fromDate: fromDate,
          toDate: toDate,
        })
      );
    };
    
    
    

  const handleDateClean = () => {
    dispatch(
      setFilterState({
        fromDate: null,
        toDate: null,
      })
    );
  };

  const handleresumeInterviewStages = (event: any) => {
    dispatch(setResumeInterviewStages(event.target.value));
  };

  const handleResumeSource = (event: any) => {
    dispatch(setResumeSource(event.target.value));
  };

  const handleResumeSkillSetChange = (event: any) => {
    dispatch(setResumeSkillSets(event.target.value));
  };

  const handleResumeEducationChange = (event: any) => {
    dispatch(setResumeEducation(event.target.value));
  };

  const handleInterviewNameChange = (event: any) => {
    dispatch(setInterviewName(event.target.value)); // Assuming event.target.value is an array of selected values
  };

  // Handle changes for Interview Status
  const handleInterviewStatusChange = (event: any) => {
    dispatch(setInterviewStatus(event.target.value)); // Assuming event.target.value is an array of selected values
  };

  const handleInterviewStagesChange = (event: any) => {
    dispatch(setInterviewStages(event.target.value));
  };

  const HandleSourceChange = (event: any) => {
    dispatch(setSource(event.target.value));
  };

  const handleInterviewModeChange = (event: any) => {
    dispatch(setInterviewMode(event.target.value));
  };

  const handlePositionChange = (event: any) => {
    dispatch(setPositions(event.target.value));
  };

  const handleInterviewDetExpectedCtc = (event: any) => {
    const inputValue = event.target.value;
    if (/^\d{0,10}$/.test(inputValue)) {
      setErrors((prev) => ({ ...prev, interviewDetExpectedCtc: "" }));
      dispatch(setInterviewDetExpectedCtc(event.target.value));
    } else {
      setErrors((prev) => ({
        ...prev,
        interviewDetExpectedCtc: "Only numbers upto 10 digits are allowed",
      }));
    }
  };

  const handleInterviewDetNoticePeriod = (event: any) => {
    dispatch(setInterviewDetNoticePeriod(event.target.value));
  };
  const handleInterviewDetInterviewSource = (event: any) => {
    dispatch(setInterviewSource(event.target.value));
  };
  const handleInterviewDetInterviewStage = (event: any) => {
    dispatch(setInterviewStage(event.target.value));
  };
  const handleCandidateInterviewStage = (event: any) => {
    dispatch(setCandidateInterviewStage(event.target.value));
  };
  const handleCandidateSource = (event: any) => {
    dispatch(setCandidateSource(event.target.value));
  };
  const handleCandidateExpectedCtc = (event: any) => {
    const inputValue = event.target.value;
    if (/^\d{0,10}$/.test(inputValue)) {
      setErrors((prev) => ({ ...prev, candidateExpectedCtc: "" }));
      dispatch(setCandidateExpectedCtc(event.target.value));
    } else {
      setErrors((prev) => ({
        ...prev,
        candidateExpectedCtc: "Only numbers upto 10 digits are allowed",
      }));
    }
  };
  const handleCandidateNoticePeriod = (event: any) => {
    dispatch(setCandidateNoticePeriod(event.target.value));
  };

  const transformCityData = (data: any[]) => {
    return data.map((data) => ({
      label: data?.skillName || data?.statusName || data, // Use cityName as the label
      value: data?.skillName || data?.statusName || data, // Use cityId as value, fallback to cityName if cityId is null
    }));
  };

  const jobKind: string[] = [
    "Internal-Onrole",
    "Internal-Contract",
    "External-One Time",
    "External-Contract",
  ];

  const handleExperienceLevelChange = (event: any) => {
    dispatch(setResumeExperience(event.target.value));
  };

  const handleExpectedCtc = (event: any) => {
    const inputValue = event.target.value;

    if (/^\d{0,10}$/.test(inputValue)) {
      // Valid input (only digits and up to 10 characters)
      setErrors((prev) => ({ ...prev, resumeExpectedCtc: "" }));
      dispatch(setResumeExpectedCtc(inputValue));
    } else {
      // Invalid input
      setErrors((prev) => ({
        ...prev,
        resumeExpectedCtc: "Only numbers up to 10 digits are allowed",
      }));
    }
  };

  const handleNoticePeriod = (event: any) => {
    dispatch(setNoticePeriods(event.target.value));
  };

  const handleResumeInterviewStatusChange = (event: any) => {
    dispatch(setResumeInterviewStatus(event.target.value));
  };

  const handleResumeExpectedSalaryChange = (event: any) => {
    const inputValue = event.target.value;

    if (/^\d*$/.test(inputValue)) {
      // Valid input
      setErrors((prev) => ({ ...prev, resumeExpectedSalary: "" }));
      dispatch(setResumeExpectedSalary(inputValue));
    } else {
      // Invalid input
      setErrors((prev) => ({
        ...prev,
        resumeExpectedSalary: "Only numbers are allowed",
      }));
    }
  };

  // Event handler for Current Salary
  const handleResumeCurrentSalaryChange = (event: any) => {
    const inputValue = event.target.value;

    if (/^\d*$/.test(inputValue)) {
      // Valid input
      setErrors((prev) => ({ ...prev, resumeCurrentSalary: "" }));
      dispatch(setResumeCurrentSalary(inputValue));
    } else {
      // Invalid input
      setErrors((prev) => ({
        ...prev,
        resumeCurrentSalary: "Only numbers are allowed",
      }));
    }
  };

  const handleResumePositionChange = (event: any) => {
    dispatch(setResumePositionTitle(event.target.value));
  };
  const transformData = (data: any[]) => {
    return data.map((data) => ({
      label: data?.skillName || data?.statusName || data?.degree || data, // Use cityName as the label
      value: data?.skillName || data?.statusName || data?.degree || data, // Use cityId as value, fallback to cityName if cityId is null
    }));
  };

  useEffect(() => {
    if (tabName === "gridResumeRepo" || tabName === "listResumeRepo") {
      dispatch(getSkill({ search: "", page: 0, size: 10 }));
    }
  }, [tabName, dispatch]);

  useEffect(() => {
    if (skills) {
      const skillData = transformData(skills);
      setSkillOptions(skillData);
    }
  }, [skills]);

  useEffect(() => {
    if (
      tabName === "gridResumeRepo" ||
      tabName === "listResumeRepo" ||
      tabName === "candidate" ||
      tabName === "interviewDetails" ||
      tabName === "jobDetails"
    ) {
      dispatch(getInterviewStatus());
    }
  }, [tabName, dispatch]);

  useEffect(() => {
    if (tabName === "gridResumeRepo" || tabName === "listResumeRepo") {
      dispatch(getPosition());
      dispatch(getQualification());
    }
  }, [tabName, dispatch]);

  useEffect(() => {
    if (position) {
      const positionData = transformData(position);
      setPostionOptions(positionData);
    }
  }, [position]);

  useEffect(() => {
    if (resumeData) {
      const statusData = transformData(resumeData);
      setInterviewStatusOption(statusData);
    }
  }, [resumeData]);

  useEffect(() => {
    if (qualificationsData) {
      const qualifyData = transformData(qualificationsData);
      setQualificationOptions(qualifyData);
    }
  }, [qualificationsData]);

  useEffect(() => {
    if (
      tabName === "interviewModuleCalendar" ||
      tabName === "interviewModuleTable"
    ) {
      dispatch(getPosition());
      dispatch(getInterviewStatus());
    }
  }, [tabName, dispatch]);

  useEffect(() => {
    if (positionsData) {
      const posData = transformCityData(positionsData);
      setPostionOptions(posData);
    }
  }, [positionsData]);

  useEffect(() => {
    if (interviewStatusData) {
      const interviewData = transformCityData(interviewStatusData);
      setInterviewStatusOption(interviewData);
    }
  }, [interviewStatusData]);

  useEffect(() => {
    setInterviewDetNoticePeriod([]);
    setInterviewSource([]);
    setInterviewStage([]);
    setInterviewDetExpectedCtc("");
    dispatch(resetFilterState());
  }, [tabName, dispatch]);

  return (
    <Box
      bgcolor={"white"}
      sx={{
        margin: "20px",
        borderRadius: "10px",
        marginRight: "0px",
        width: drawerOpen ? "216px" : "50px",
        height: drawerOpen ? "85vh" : "0px",
        position: drawerOpen ? "relative" : "absolute",
        zIndex: 1000,
        top: "-10px",
        overflow:drawerOpen ? "auto":"none",
        '&::-webkit-scrollbar': { width: '0px', height: '0px' }
      }}
    >
      <Box
        sx={{
          display: "flex",
          bgcolor: "white",
          padding: "10px",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
          margin: "2px",
          borderRadius: "10px",
        }}
      >
        <TuneIcon sx={{ fontSize: 20 }} />

        {drawerOpen && (
          <Typography variant="h6" sx={{ fontSize: 14 }}>
            Filter
          </Typography>
        )}

        <Box
          sx={{ cursor: "pointer", padding: "0px", backgroundColor: "#F6F6F6" }}
        >
          {drawerOpen ? (
            <NavigateBefore onClick={toggleDrawer} sx={{ fontSize: 14 }} />
          ) : (
            <NavigateNext onClick={toggleDrawer} sx={{ fontSize: 14 }} />
          )}
        </Box>
      </Box>
      {drawerOpen && <Divider />}

      <Collapse
        in={drawerOpen}
        timeout="auto"
        unmountOnExit
        orientation="horizontal"
      >
        <Box
          width={200}
          padding={1}
          bgcolor={"white"}
          sx={{
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",            
            overflowX: "auto",
          }}
        >
          {(tabName === "My Requests" || tabName === "Overall MRF") && (
            <Box
              marginTop={2}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                L1 Status
              </Typography>
              <FormControl component="fieldset">
                <CustomSmallFormControlLabel
                  label="Pending"
                  number={l1StatusPending}
                  isChecked={teamHeadFilter.l1StatusPending}
                  onChange={() =>
                    dispatch(
                      setFilterState({
                        l1StatusPending: !teamHeadFilter.l1StatusPending,
                      })
                    )
                  }
                />
                <CustomSmallFormControlLabel
                  label="Declined"
                  number={l1StatusDeclined}
                  isChecked={teamHeadFilter.l1StatusDeclined}
                  onChange={() =>
                    dispatch(
                      setFilterState({
                        l1StatusDeclined: !teamHeadFilter.l1StatusDeclined,
                      })
                    )
                  }
                />
              </FormControl>
            </Box>
          )}

          {(tabName === "My Requests" || tabName === "Overall MRF") && (
            <Box
              marginTop={2}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                L2 Status
              </Typography>
              <FormControl component="fieldset">
                <CustomSmallFormControlLabel
                  label="Pending"
                  number={l2StatusPending}
                  isChecked={teamHeadFilter.l2StatusPending}
                  onChange={() =>
                    dispatch(
                      setFilterState({
                        l2StatusPending: !teamHeadFilter.l2StatusPending,
                      })
                    )
                  }
                />
                <CustomSmallFormControlLabel
                  label="Approved"
                  number={l2StatusApproved}
                  isChecked={teamHeadFilter.l2StatusApproved}
                  onChange={() =>
                    dispatch(
                      setFilterState({
                        l2StatusApproved: !teamHeadFilter.l2StatusApproved,
                      })
                    )
                  }
                />
              </FormControl>
            </Box>
          )}

          {tabName === "Job Post" && (
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                  marginBottom: "5px", // Reduced margin for better compactness
                  textAlign: "left", // Align the label to the left
                }}
              >
                Posted in
              </Typography>
              <PostedInDropDown />
            </Box>
          )}

          {(tabName === "In Progress" || tabName === "Assigned MRF") && (
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                  marginBottom: "5px", // Reduced margin for better compactness
                  textAlign: "left", // Align the label to the left
                }}
              >
                MRF Stages
              </Typography>
              <CustomDropdown />
            </Box>
          )}

          <DropDownsOfRandRl tabName={tabName} />

          {(tabName === "My Requests" ||
            tabName === "In Progress" ||
            tabName === "Closed" ||
            tabName === "Overall MRF" ||
            tabName === "Job Post" ||
            tabName === "Waiting to Move to Job" ||
            tabName === "Ready for JD Creation" ||
            tabName === "Resume" ||
            tabName === "To Be Assigned" ||
            tabName === "Assigned MRF" ||
            tabName === "MRF Hub") && (
            <Box marginTop={2}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                Job Kind
              </Typography>
              {jobKind.map((jobs) => (
                <Box
                  display="flex"
                  alignItems="center"
                  mt={1}
                  sx={{ paddingLeft: "15px", gap: "8px" }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      width: "auto",
                      fontSize: "8px",
                      height: "20px",
                      color:
                        (jobs === "Internal-Onrole" &&
                          teamHeadFilter.internalOnRole) ||
                        (jobs === "Internal-Contract" &&
                          teamHeadFilter.internalContract) ||
                        (jobs === "External-One Time" &&
                          teamHeadFilter.externalOneTime) ||
                        (jobs === "External-Contract" &&
                          teamHeadFilter.externalContract)
                          ? "blue"
                          : "grey",
                      border:
                        (jobs === "Internal-Onrole" &&
                          teamHeadFilter.internalOnRole) ||
                        (jobs === "Internal-Contract" &&
                          teamHeadFilter.internalContract) ||
                        (jobs === "External-One Time" &&
                          teamHeadFilter.externalOneTime) ||
                        (jobs === "External-Contract" &&
                          teamHeadFilter.externalContract)
                          ? "1px solid blue"
                          : "1px solid grey",
                      padding: "4px 8px",
                    }}
                    data-value={jobs}
                    onClick={(e) => {
                      const target = e.currentTarget as HTMLButtonElement;

                      if (target.dataset.value === "Internal-Onrole") {
                        dispatch(
                          setFilterState({
                            internalOnRole: !teamHeadFilter.internalOnRole,
                          })
                        );
                      }
                      if (target.dataset.value === "Internal-Contract") {
                        dispatch(
                          setFilterState({
                            internalContract: !teamHeadFilter.internalContract,
                          })
                        );
                      }
                      if (target.dataset.value === "External-One Time") {
                        dispatch(
                          setFilterState({
                            externalOneTime: !teamHeadFilter.externalOneTime,
                          })
                        );
                      }
                      if (target.dataset.value === "External-Contract") {
                        dispatch(
                          setFilterState({
                            externalContract: !teamHeadFilter.externalContract,
                          })
                        );
                      }
                    }}
                  >
                    {jobs}
                  </Button>
                </Box>
              ))}
            </Box>
          )}

          {(tabName === "My Requests" ||
            tabName === "In Progress" ||
            tabName === "Closed" ||
            tabName === "Overall MRF" ||
            tabName === "Job Post" ||
            tabName === "Waiting to Move to Job" ||
            tabName === "Ready for JD Creation" ||
            tabName === "Resume" ||
            tabName === "To Be Assigned" ||
            tabName === "Assigned MRF" ||
            tabName === "MRF Hub") && (
            <Box marginTop={2}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                Priority
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Typography sx={{ color: "#73D13D", fontSize: "10px" }}>
                  <CustomSmallFormControlLabel
                    label="Low"
                    isChecked={teamHeadFilter.priorityLow}
                    number={lowCount}
                    onChange={() =>
                      dispatch(
                        setFilterState({
                          priorityLow: !teamHeadFilter.priorityLow,
                        })
                      )
                    }
                  />
                </Typography>
                <Typography sx={{ color: "#FFA940", fontSize: "10px" }}>
                  <CustomSmallFormControlLabel
                    label="Medium"
                    number={mediumCount}
                    isChecked={teamHeadFilter.priorityMedium}
                    onChange={() =>
                      dispatch(
                        setFilterState({
                          priorityMedium: !teamHeadFilter.priorityMedium,
                        })
                      )
                    }
                  />
                </Typography>
                <Typography sx={{ color: "#FF4D4F", fontSize: "10px" }}>
                  <CustomSmallFormControlLabel
                    label="High"
                    isChecked={teamHeadFilter.priorityHigh}
                    number={highCount}
                    onChange={() =>
                      dispatch(
                        setFilterState({
                          priorityHigh: !teamHeadFilter.priorityHigh,
                        })
                      )
                    }
                  />
                </Typography>
              </Box>
            </Box>
          )}

          {(tabName === "My Requests" ||
            tabName === "In Progress" ||
            tabName === "Closed" ||
            tabName === "Overall MRF" ||
            tabName === "Job Post" ||
            tabName === "Waiting to Move to Job" ||
            tabName === "Ready for JD Creation" ||
            tabName === "Resume" ||
            tabName === "To Be Assigned" ||
            tabName === "Assigned MRF") && (
            <Box marginTop={2} alignItems="center">
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginRight: "8px",
                  paddingLeft: "10px",
                }}
              >
                MRF Posted Date
              </Typography>
              <Box>
                <DateRangePicker
                  showOneCalendar
                  placement="rightEnd"
                  onOk={handleDateChange}
                  onClean={handleDateClean}
                  style={{ width: "100%", left: "10px", maxWidth: "300px" }}
                  menuStyle={{ transform: "translateX(14px)" }}
                  disabledDate={(date) => {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    return date > today;
                  }}
                />
              </Box>
            </Box>
          )}

          {(tabName === "Job Post" ||
            tabName === "Waiting to Move to Job" ||
            tabName === "Ready for JD Creation" ||
            tabName === "Resume" ||
            tabName === "Assigned MRF" ||
            tabName === "MRF Hub") && (
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                Position Title
              </Typography>
              <PositionTitleDropDown />
            </Box>
          )}

          {(tabName === "Job Post" ||
            tabName === "Waiting to Move to Job" ||
            tabName === "Ready for JD Creation" ||
            tabName === "Resume" ||
            tabName === "Assigned MRF" ||
            tabName === "MRF Hub") && (
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                Business
              </Typography>
              <BusinessDropDown />
            </Box>
          )}

          {(tabName === "Job Post" ||
            tabName === "Waiting to Move to Job" ||
            tabName === "Ready for JD Creation" ||
            tabName === "Resume" ||
            tabName === "Assigned MRF" ||
            tabName === "MRF Hub") && (
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                Location
              </Typography>
              <LocationDropDown />
            </Box>
          )}

          {tabName === "listResumeRepo" && (
            <>
              <Box marginTop={2} alignItems="center">
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginRight: "8px",
                    paddingLeft: "10px",
                  }}
                >
                  MRF Posted Date
                </Typography>

                <Box>
                  <DateRangePicker
                    showOneCalendar
                    placement="autoHorizontalStart"
                    onOk={handleDateChange}
                    onClean={handleDateClean}
                    style={{ width: "100%" }}
                  />
                </Box>
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Interview Stages
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="resumeInterviewStages"
                  pageType="resumeRepo"
                  value={resumeInterviewStages || []}
                  onChange={handleresumeInterviewStages}
                  options={interviewStatusOption}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Source
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="resumeSource"
                  pageType="resumeRepo"
                  value={resumeSource || []}
                  onChange={handleResumeSource}
                  options={sourceOptions}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Expected CTC
                </Typography>
                <CommonTextField
                  label=""
                  name="resumeExpectedCtc"
                  value={resumeExpectedCtc || ""}
                  onChange={handleExpectedCtc}
                  error={!!errors.resumeExpectedCtc}
                  helperText={errors.resumeExpectedCtc}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Notice Period
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="resumeNoticePeriod"
                  pageType="resumeRepo"
                  value={resumeNoticePeriod || []}
                  onChange={handleNoticePeriod}
                  options={noticePeriod}
                />
              </Box>
            </>
          )}
          {tabName === "gridResumeRepo" && (
            <>
              <Box marginTop={2} alignItems="center">
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginRight: "8px",
                    paddingLeft: "10px",
                  }}
                >
                  MRF Posted Date
                </Typography>

                <Box>
                  <DateRangePicker
                    showOneCalendar
                    placement="autoHorizontalStart"
                    onOk={handleDateChange}
                    onClean={handleDateClean}
                    style={{ width: "100%" }}
                  />
                </Box>
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Experience
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="resumeExperience"
                  pageType="resumeRepo"
                  value={resumeExperience || []}
                  onChange={handleExperienceLevelChange}
                  options={YearsInExp}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Skill Sets
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  pageType="resumeRepo"
                  name="resumeskillSets"
                  value={resumeskillSets || []}
                  onChange={handleResumeSkillSetChange}
                  options={skillOptions}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Education
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="resumeEducation"
                  pageType="resumeRepo"
                  value={resumeEducation || []}
                  onChange={handleResumeEducationChange}
                  options={qualificationOptions}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Interview Status
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="resumeInterviewStatus"
                  pageType="resumeRepo"
                  value={resumeInterviewStatus || []}
                  onChange={handleResumeInterviewStatusChange}
                  options={interviewStatusOption}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Positions
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="resumePositionTitle"
                  pageType="resumeRepo"
                  value={resumePositionTitle || []}
                  onChange={handleResumePositionChange}
                  options={positionOptions}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Expected Salary
                </Typography>
                <CommonTextField
                  label=""
                  name="resumeExpectedSalary"
                  value={resumeExpectedSalary || ""}
                  onChange={handleResumeExpectedSalaryChange}
                  error={!!errors.resumeExpectedSalary}
                  helperText={errors.resumeExpectedSalary}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Current Salary
                </Typography>
                <CommonTextField
                  label=""
                  name="resumeCurrentSalary"
                  value={resumeCurrentSalary || ""}
                  onChange={handleResumeCurrentSalaryChange}
                  error={!!errors.resumeCurrentSalary}
                  helperText={errors.resumeCurrentSalary}
                />
              </Box>
            </>
          )}
          {(tabName === "interviewModuleCalendar" ||
            tabName === "interviewModuleTable") && (
            <>
              <Box marginTop={2} alignItems="center">
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginRight: "8px",
                    paddingLeft: "10px",
                  }}
                >
                  Custom Date Range
                </Typography>

                <Box>
                  <DateRangePicker
                    showOneCalendar
                    placement="autoHorizontalStart"
                    onOk={handleDateChange}
                    onClean={handleDateClean}
                    style={{ width: "100%" }}
                  />
                </Box>
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Positions
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="positions"
                  pageType="resumeRepo"
                  value={positions || []}
                  onChange={handlePositionChange}
                  options={positionOptions}
                />
              </Box>
            </>
          )}
          {tabName === "interviewModuleCalendar" && (
            <>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Interview Stages
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="interviewStages"
                  pageType="resumeRepo"
                  value={interviewStages || []}
                  onChange={handleInterviewStagesChange}
                  options={interviewStatusOption}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Source
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="source"
                  pageType="resumeRepo"
                  value={source || []}
                  onChange={HandleSourceChange}
                  options={sourceOptions}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Interview Mode
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="interviewMode"
                  pageType="resumeRepo"
                  value={interviewMode || []}
                  onChange={handleInterviewModeChange}
                  options={InterviewMode}
                />
              </Box>
            </>
          )}
          {tabName === "interviewModuleTable" && (
            <>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Interview Name
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="interviewName"
                  pageType="resumeRepo"
                  value={interviewName || []}
                  onChange={handleInterviewNameChange}
                  options={interviewNameOptions}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Interview Status
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="interviewStatus"
                  pageType="resumeRepo"
                  value={interviewStatus || []}
                  onChange={handleInterviewStatusChange}
                  options={interviewStatusOption}
                />
              </Box>
            </>
          )}
          {(tabName === "interviewDetails" || tabName === "jobDetails") && (
            <>
              <Box marginTop={2} alignItems="center">
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginRight: "8px",
                    paddingLeft: "10px",
                  }}
                >
                  Interview Date Range
                </Typography>

                <Box>
                  <DateRangePicker
                    showOneCalendar
                    placement="autoHorizontalStart"
                    onOk={handleDateChange}
                    onClean={handleDateClean}
                    style={{ width: "100%" }}
                  />
                </Box>
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Interview Stages
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="interviewDetInterviewStage"
                  pageType="resumeRepo"
                  value={interviewDetInterviewStage || []}
                  onChange={handleInterviewDetInterviewStage}
                  options={interviewStatusOption}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Source
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="interviewDetSource"
                  pageType="resumeRepo"
                  value={interviewDetSource || []}
                  onChange={handleInterviewDetInterviewSource}
                  options={sourceOptions}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Expected CTC
                </Typography>
                <CommonTextField
                  label=""
                  name="interviewDetExpectedCtc"
                  value={interviewDetExpectedCtc || ""}
                  onChange={handleInterviewDetExpectedCtc}
                  error={!!errors.interviewDetExpectedCtc}
                  helperText={errors.interviewDetExpectedCtc}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Notice Period
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="interviewDetNoticePeriod"
                  pageType="resumeRepo"
                  value={interviewDetNoticePeriod || []}
                  onChange={handleInterviewDetNoticePeriod}
                  options={noticePeriod}
                />
              </Box>
            </>
          )}
          {tabName === "candidate" && (
            <>
              <Box marginTop={2} alignItems="center">
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginRight: "8px",
                    paddingLeft: "10px",
                  }}
                >
                  MRF Posted Date
                </Typography>

                <Box>
                  <DateRangePicker
                    showOneCalendar
                    placement="autoHorizontalStart"
                    onOk={handleDateChange}
                    onClean={handleDateClean}
                    style={{ width: "100%" }}
                  />
                </Box>
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Interview Stages
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="candidateInterviewStage"
                  pageType="resumeRepo"
                  value={candidateInterviewStage || []}
                  onChange={handleCandidateInterviewStage}
                  options={interviewStatusOption}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Source
                </Typography>
                <CommonMultiSelect
                  labelId=""
                  label=""
                  name="candidateSource"
                  pageType="resumeRepo"
                  value={candidateSource || []}
                  onChange={handleCandidateSource}
                  options={sourceOptions}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Expected CTC
                </Typography>
                <CommonTextField
                  label=""
                  name="candidateExpectedCtc"
                  value={candidateExpectedCtc || ""}
                  onChange={handleCandidateExpectedCtc}
                  error={!!errors.candidateExpectedCtc}
                  helperText={errors.candidateExpectedCtc}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Notice Period
                </Typography>
                <CommonSelect
                  labelId=""
                  label=""
                  name="candidateNoticePeriod"
                  value={candidateNoticePeriod || ""}
                  onChange={handleCandidateNoticePeriod}
                  options={noticePeriod}
                />
              </Box>
              <Box marginTop={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                  }}
                >
                  Status
                </Typography>
                {statuses.map((status) => (
                  <CustomSmallFormControlLabel
                    key={status.value}
                    label={status.label}
                    isChecked={selectedStatuses.some(
                      (selected) => selected.value === status.value
                    )}
                    onChange={handleCheckboxChange(status)}
                  />
                ))}
              </Box>
            </>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default FilterSidebar;
