import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Divider, Typography, Autocomplete } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getPostApprovals, getApprovers } from "../../Crud/rth";
import styled from "@emotion/styled";
import { toast } from "react-toastify";

export interface Approver {
    userId: string;
    firstName: string;
    lastName: string;
}

interface ApproverDialogProps {
    open: boolean;
    onClose: () => void;
    onAddApprover: (approvers: { l1Approver: Approver | null; l2Approver: Approver | null; l3Approver?: Approver | null }) => void;
    candidateId: string | undefined;
}

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        height: '40px',
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
        borderRadius: '4px',
    },
    '& .MuiInputBase-input': {
        fontSize: '0.75rem',
        color: '#000000',
    },
    '& .Mui-disabled .MuiInputBase-input': {
        color: '#000000',
        WebkitTextFillColor: '#000000',
    },
    '& .MuiInputLabel-root': {
        fontSize: '0.875rem',
    },
    '& .MuiInputLabel-shrink': {
        fontSize: '0.875rem',
    },
});

const ApproverDialog: React.FC<ApproverDialogProps> = ({ open, onClose, onAddApprover, candidateId }) => {
    const [l1Approver, setL1Approver] = useState<Approver | null>(null);
    const [l2Approver, setL2Approver] = useState<Approver | null>(null);
    const [l3Approver, setL3Approver] = useState<Approver | null>(null);
    const [l1Options, setL1Options] = useState<Approver[]>([]);
    const [l2Options, setL2Options] = useState<Approver[]>([]);
    const [l3Options, setL3Options] = useState<Approver[]>([]);
    const [loadingL1, setLoadingL1] = useState<boolean>(false);
    const [loadingL2, setLoadingL2] = useState<boolean>(false);
    const [loadingL3, setLoadingL3] = useState<boolean>(false);
    const [l1Search, setL1Search] = useState<string>('');
    const [l2Search, setL2Search] = useState<string>('');
    const [l3Search, setL3Search] = useState<string>('');
    const [l1Page, setL1Page] = useState<number>(0);
    const [l2Page, setL2Page] = useState<number>(0);
    const [l3Page, setL3Page] = useState<number>(0);
    const [l1HasMore, setL1HasMore] = useState<boolean>(true);
    const [l2HasMore, setL2HasMore] = useState<boolean>(true);
    const [l3HasMore, setL3HasMore] = useState<boolean>(true);
    const [showL3, setShowL3] = useState<boolean>(false);

    const formatApproverData = (approver: any): Approver => ({
        userId: approver.userId,
        firstName: approver.firstName,
        lastName: approver.lastName,
    });

    const fetchApprovers = useCallback(
        async (level: 'L1' | 'L2' | 'L3', search: string = '', page: number = 0) => {
            const setLoading = level === 'L1' ? setLoadingL1 : level === 'L2' ? setLoadingL2 : setLoadingL3;
            const setOptions = level === 'L1' ? setL1Options : level === 'L2' ? setL2Options : setL3Options;
            const setHasMore = level === 'L1' ? setL1HasMore : level === 'L2' ? setL2HasMore : setL3HasMore;

            setLoading(true);
            try {
                const response = await getApprovers(search, page);
                const data = response.data || [];
                setOptions(prev => (page === 0 ? data.map(formatApproverData) : [...prev, ...data.map(formatApproverData)]));
                setHasMore(data.length > 0);
            } catch (error) {
                console.error(`Error fetching ${level} approvers:`, error);
            } finally {
                setLoading(false);
            }
        },
        []
    );

    useEffect(() => {
        if (open) {
            fetchApprovers('L1', l1Search, l1Page);
            fetchApprovers('L2', l2Search, l2Page);
            fetchApprovers('L3', l3Search, l3Page);
        }
    }, [open, l1Search, l2Search, l3Search, l1Page, l2Page, l3Page, fetchApprovers]);

    const handleSearchChange = (level: 'L1' | 'L2' | 'L3', event: React.SyntheticEvent, value: string) => {
        if (level === 'L1') {
            setL1Search(value);
            setL1Page(0);
            setL1Options([]); 
        } else if (level === 'L2') {
            setL2Search(value);
            setL2Page(0);
            setL2Options([]); 
        } else {
            setL3Search(value);
            setL3Page(0);
            setL3Options([]); 
        }
    };

    const handleScroll = (level: 'L1' | 'L2' | 'L3', event: React.UIEvent<HTMLElement>) => {
        const listboxNode = event.currentTarget;
        const hasMore = level === 'L1' ? l1HasMore : level === 'L2' ? l2HasMore : l3HasMore;
        const loading = level === 'L1' ? loadingL1 : level === 'L2' ? loadingL2 : loadingL3;

        if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight && hasMore && !loading) {
            if (level === 'L1') setL1Page(prev => prev + 1);
            else if (level === 'L2') setL2Page(prev => prev + 1);
            else setL3Page(prev => prev + 1);
        }
    };

    const handleAddApprover = async () => {
        if (!l1Approver || !l2Approver || !candidateId) {
            console.error("L1 or L2 approver is missing or candidateId is not available.");
            return;
        }
        try {
            const response = await getPostApprovals(candidateId, l1Approver.userId, l2Approver.userId, l3Approver?.userId);
            if (response.status === true) {
                toast.success("Approvers added successfully");
                onAddApprover({ l1Approver, l2Approver, l3Approver });
                setL1Approver(null);
                setL2Approver(null);
                setL3Approver(null);
                setShowL3(false);
                onClose();
            } else {
                toast.error("Failed to add approvers");
                console.error("Failed to add approvers:", response.message);
            }
        } catch (error) {
            console.error("Error adding approvers:", error);
        }
    };

    const filterApprovers = (level: 'L1' | 'L2' | 'L3') => {
        if (level === 'L1') {
            return l1Options.filter((approver) => {
                return (
                    (!l2Approver || approver.userId !== l2Approver.userId) &&
                    (!l3Approver || approver.userId !== l3Approver.userId)
                );
            });
        } else if (level === 'L2') {
            return l2Options.filter((approver) => {
                return (
                    (!l1Approver || approver.userId !== l1Approver.userId) &&
                    (!l3Approver || approver.userId !== l3Approver.userId)
                );
            });
        } else {
            return l3Options.filter((approver) => {
                return (
                    (!l1Approver || approver.userId !== l1Approver.userId) &&
                    (!l2Approver || approver.userId !== l2Approver.userId)
                );
            });
        }
    };

    return (
        <Dialog open={open} maxWidth={false} fullWidth sx={{ maxWidth: "500px", margin: "auto" }}
            onClose={(event, reason) => {
                if (reason === "backdropClick") {
                    return;
                }
                onClose();
            }}
        >
            <DialogTitle sx={{ p: 2, pt: 1, borderBottom: "1px solid #f0f0f0" }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: 500 }}>Confirm</Typography>
                    <IconButton aria-label="close" onClick={onClose} sx={{ position: "absolute", right: 8, top: 8, backgroundColor: "#ECECEC" }}>
                        <CloseIcon sx={{ fontSize: "12px" }} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Box sx={{ mt: 1 }}>
                    {(['L1', 'L2'] as ('L1' | 'L2')[]).map((level) => (
                        <Autocomplete
                            key={level}
                            options={filterApprovers(level)}
                            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                            value={level === 'L1' ? l1Approver : l2Approver}
                            onChange={(event, value) => {
                                if (level === 'L1') setL1Approver(value);
                                else setL2Approver(value);
                            }}
                            loading={level === 'L1' ? loadingL1 : loadingL2}
                            onInputChange={(event, value) => handleSearchChange(level, event, value)}
                            ListboxProps={{
                                onScroll: (event) => handleScroll(level, event),
                            }}
                            renderInput={(params) => (
                                <StyledTextField
                                    {...params}
                                    label={`Select ${level} Approver`}
                                    fullWidth
                                    size="small"
                                    sx={{ mb: 3 }}
                                />
                            )}
                        />
                    ))}
                </Box>
                {!showL3 ? (
                    <Button
                        variant="outlined"
                        onClick={() => setShowL3(true)}
                        sx={{ fontSize: "0.625rem", color: "#1677FF", backgroundColor: "#3185FC1A", fontWeight: "500" }}
                    >
                        +Add approver
                    </Button>
                ) : (
                    <Autocomplete
                        options={filterApprovers('L3')}
                        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                        value={l3Approver}
                        onChange={(event, value) => { setL3Approver(value) }}
                        loading={loadingL3}
                        onInputChange={(event, value) => handleSearchChange('L3', event, value)}
                        ListboxProps={{
                            onScroll: (event) => handleScroll('L3', event),
                        }}
                        renderInput={(params) => (
                            <StyledTextField {...params} label="Select L3 Approver" fullWidth size="small" />
                        )}
                        sx={{ mb: 3 }}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleAddApprover}
                    variant="contained"
                    sx={{ textTransform: "none", fontSize: "12px", borderRadius: "8px", backgroundColor: "#1677FF" }}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ApproverDialog;
