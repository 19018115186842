import { Box, Button, Card, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import tempCardIcon from '../../../../../assets/images/template_card_image.svg';
import DraftCardIcon from '../../../../../assets/images/draft_card_image.svg';
import DraftCardDisableIcon from '../../../../../assets/images/draft_card_disable_image.svg';
import tempCardDisableIcon from '../../../../../assets/images/template_card_disable_image.svg';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/system';
import { getAllTemplate, getAllDraftedJdTemplate, SaveAsDraftAndTemplate, Submit } from "../../../../../redux/features/jdTemplate";
import refreshIcon from '../../../../../assets/images/refeshIcon.svg';
import { toast } from "react-toastify";

export const TemplateComponent = ({pageType, goToTemplateTab, uploadContent, setUploadContent}: any) => {
  const [activeCardId, setActiveCardId] = useState<any>([]);
  const [showTemplate, setShowTemplate] = useState(false);
  const [buttonName, setButtonName] = useState("Submit");
  const [hideButton, setHideButton] = useState(true);
  const[JdName,setJdtName]=useState('');
  const[allTemplate,setAllTemplate]=useState<any>([]);
  const [value, setValue] = useState('');
  const [remainingLines, setRemainingLines] = useState("");
  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchText, setSearchText] = useState("");

  const navigate=useNavigate();
  const {id}=useParams();
  // const rthId='67168ac4794c4509a2fafec4';
  const displayIcon = pageType === 'Template' ? tempCardIcon : DraftCardIcon;
  const disableIcon = pageType === 'Template' ? tempCardDisableIcon : DraftCardDisableIcon;
  const displayText=pageType==='Template'?'Template':'Draft';

  const handleCardClick = (cid: number) => {
    setActiveCardId(cid);
    const selectedCard = allTemplate.find((card:any) => card.jdId === cid);
    navigate(`/JDCreation/${id}/${cid}`);
    if (selectedCard) {
      setValue(selectedCard.jdTemplateText);
    }
  };
 
  const handleSubmit=async ()=>{
    switch(buttonName){
      case 'Submit':
        const payload={
          jdText:value,
          jdStatus:'Submit'
        }
        await Submit(payload,id).then((response)=>{
          const{status,message}=response;
          console.log(status,'response1254');
          setValue('');
          if(pageType==="Generate")
          setUploadContent('');
          // navigate(-1)
          if(status===true)
            {
          toast.success("Submitted Successfully");
          navigate('/dashboard?tab=job-post');
          }
           else if(status===false)
            toast.error(message||'Unable to submit JD');
        })
        break;
      case 'Create New Template':
        handleTemplateSave();
        setValue("")
        setShowTemplate(true);
        break;
       
    }
  }

  const handleCancel = () => {      
    if(pageType==='Template' && !showTemplate){
      setShowTemplate(true);
      setHideButton(true)
      setButtonName('Submit');
    }
    else if(pageType==='Generate'){
      // navigate(`/newRequestToHire`);
    navigate(-1)

    }
    else{
      goToTemplateTab();
    }
  }

  const stripHtmlTags = (text:any) => {  
    return text?.replace(/<[^>]*>/g, '');
  };
 
  const truncateText = (text:any, length:any) => {
    const plainText = stripHtmlTags(text);
    return plainText?.length > length ? `${plainText?.slice(0, length)}...` : plainText;
  };
 
  const handleSaveAsDraft=async ()=>{
    const payload={
      "jdName":JdName,
      "jdTemplateText":remainingLines,
      "status":"Draft"
    }
    await SaveAsDraftAndTemplate(payload).then(async (response)=>{
      if(response){
        toast.success("Draft Saved Successfully");
        if(pageType==='Template'){
          await getAllTemplate('').then((response)=>{
            setAllTemplate(response.data)
          })
        }
     if(pageType==='Draft'){
      getAllDraftedJdTemplate().then(response=>{
        setAllTemplate(response.data);  
      })
     }
    }
    });
  }

  const handleNewTemplateButton = () => {
    setIsNewTemplate(true);
    setShowTemplate(false);
    setButtonName('Submit');
    setHideButton(true);
    if(showTemplate){
    setButtonName("Create New Template");
    setActiveCardId('');
    setValue('');
    setHideButton(false);
    }
    else{
      setButtonName('Submit');
      setHideButton(true);
    }
  };

const handleTemplateSave=async ()=>{
  const payload={
    "jdName":JdName,
    "jdTemplateText":remainingLines,
    "status":"Complete"
  }
  await SaveAsDraftAndTemplate(payload).then(async (response)=>{
       
    if(response){
      toast.success("Template Saved Successfully");
      if(pageType==='Template'){
     await getAllTemplate('').then((response)=>{
      setAllTemplate(response.data)
    })      
      }
      if(pageType==='Draft'){
        getAllDraftedJdTemplate().then(response=>{
          setAllTemplate(response.data);  
        })
       
      }
  }
  });
}

const reachQuillHeight = 
pageType === 'Template' && !showTemplate 
  ? isNewTemplate 
    ? '59.3vh' 
    : '60vh' 
  : '53vh';

  const RefreshButton = styled(Button)({
    background: 'linear-gradient(90deg, #2F54EB 0%, #36CFC9 100%)',
    borderRadius: '8px',
    height:"42px",
    color: '#fff',
    textTransform: 'none',
    padding: '10px 20px',
    fontWeight: 'bold',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    boxShadow: 'none',
    '&:hover': {
      background: 'linear-gradient(90deg, #004bbd 0%, #00a1c6 100%)',
      boxShadow: 'none',
    },
  });
   
  const modules = {
    toolbar: [
       [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ align: [] }],
    ["blockquote", "code-block"],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    ["link"],
    ["clean"],
    ],
  };

  const handleEditorChange = (value: any) => {
    // Regular expression to match emojis
    const emojiRegex = /[\u{1F600}-\u{1F6FF}|\u{1F300}-\u{1F5FF}|\u{1F900}-\u{1F9FF}|\u{1FA70}-\u{1FAFF}|\u{1F680}-\u{1F6FF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}|\u{1F1E0}-\u{1F1FF}]/gu;
  
    // Remove emojis from the value
    const filteredValue = value.replace(emojiRegex, '');
  
    // Update state based on pageType
    if (pageType === 'Generate') {
      setUploadContent(filteredValue);
    }
    
    // Update the value
    setValue(filteredValue);
  
    // Extract and handle lines from the filtered content
    const { firstLine, remainingLines } = extractLines(filteredValue);
    const truncatedText = firstLine.slice(0, 20); // Restrict to 20 characters
    setJdtName(truncatedText);
  
    // Store the remaining lines in a new state
    setRemainingLines(remainingLines);
  };
 
   
  const extractLines = (html: string) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
 
    let firstLine = "";
    const remainingLines: string[] = [];
    let isFirstLineExtracted = false;
 
    for (let child of tempElement.childNodes) {
      if (child.nodeType === Node.ELEMENT_NODE || child.nodeType === Node.TEXT_NODE) {
        if (!isFirstLineExtracted) {
          const content = child.textContent?.trim();
          if (content) {
            firstLine = content; // Store the first line
            isFirstLineExtracted = true;
          }
        } else {
          if (child.nodeType === Node.ELEMENT_NODE) {
            remainingLines.push((child as Element).outerHTML); // Use outerHTML for elements
          } else if (child.nodeType === Node.TEXT_NODE) {
            remainingLines.push(child.textContent || ""); // Append text for text nodes
          }
        }
      } else if (child.nodeType === Node.ELEMENT_NODE && (child as Element).tagName === "BR") {
        // Preserve <br> tags explicitly
        remainingLines.push("<br>");
      }
    }
 
    return {
      firstLine,
      remainingLines: remainingLines.join(""), // Combine remaining lines into a single HTML string
    };
  };


  const isContentEmpty = (content: string) => {
    // Create a temporary element to strip HTML tags
    const div = document.createElement("div");
    div.innerHTML = content;
   
    // Extract the text content, and trim any white spaces
    return !div.textContent?.trim();
  };    

  const handleRefresh=()=>{
    setUploadContent('');
    setValue('');
  }

  const formats = [ 'header', 'font', 'size', 'align', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link',  'color', 'background', 'script' ];

 
  const handleSearchClick = () => {
    setIsSearching(!isSearching);
  };
 
  const handleSearchTemplate=(search:any)=>{
       setSearchText(search);
    }
  
    useEffect(() => {
      
  
      const timeout = setTimeout(() => {
        getAllTemplate(searchText).then((response) => {
          setAllTemplate(response.data);
        });
      }, 500);
  
      return () => clearTimeout(timeout); // Cleanup previous timeout on new input
    }, [searchText]);

  useEffect(() => {
    // if (pageType === "Template" && !showTemplate) {
    //   setActiveCardId(id)
    //   setAllTemplate(NewTemplate);
    // }
    if (pageType === "Template"&& showTemplate) {
      setActiveCardId(id);
      setShowTemplate(true);
      getAllTemplate('').then(response=>{
        setAllTemplate(response.data);  
      })
    }
    else if(pageType==="Generate"){

    }
    else if(pageType==='Template'){
      getAllTemplate('').then(response=>{
        setAllTemplate(response.data);  
      })
    }
     else {
      setActiveCardId(id)
      getAllDraftedJdTemplate().then(response=>{
        setAllTemplate(response.data);  
      })
      setShowTemplate(false);
    }
    if(pageType==='Template'&& !showTemplate){
      setButtonName('Submit');
      setShowTemplate(true);
      setHideButton(true);
    }
  }, [pageType,id,showTemplate]);    

  return (
    <Box>    
      <Box sx={{ display: 'flex', flexDirection: "row" }}>
        {pageType !== "Generate" &&
        <Grid item  sx={{ width: '410px' }}>
          <Box sx={{ borderRight: '1px solid #DADADA' }}>
            {showTemplate && (
              <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', width: '380px', alignItems: 'center', m: 2 }}>
                <Button variant="contained" onClick={handleNewTemplateButton} sx={{ bgcolor: '#1677FF', width: '145px', height: '42px', textTransform: 'none', borderRadius: '8px', boxShadow: 'none', '&:hover': { border: 'none', backgroundColor: '#1677FF', boxShadow: 'none' } }}>
                  New Template
                </Button>
                {isSearching ? (
        <TextField
          variant="outlined"
          placeholder="Search..."
          size="small"
          autoFocus
          value={searchText}
          onChange={(e) => handleSearchTemplate(e.target.value)}
          sx={{ width: "150px" }} // Adjust width as needed
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearchClick}>
                  <SearchIcon sx={{ color: "#919191", fontSize: "22px" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <IconButton onClick={handleSearchClick}>
          <SearchIcon sx={{ color: "#919191", fontSize: "22px" }}/>
        </IconButton>
      )}

              </Box>
            )}

            <Box sx={{ height: (pageType==="Template" && showTemplate )?'50vh': '60.5vh', boxShadow: 'none', overflowY:'auto', '&::-webkit-scrollbar': { width: '0px', height: '0px' } }}>
            {allTemplate.length === 0 ? (
    <Box sx={{ fontSize: "14px", color: "#A9A9A9", mt: 2, display:'flex', justifyContent:'center', alignItems:'center' }}>
     {`No ${displayText} Available`}
    </Box>
  ) : (

              allTemplate.map((card:any) => (
                <Card
                  key={card.jdId}
                  onClick={() => handleCardClick(card?.jdId)}
                  sx={{
                    height: '100px',
                    borderRadius: '0',
                    borderBottom: '1px dashed #DADADA',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '400px',
                    gap: '10px',
                    boxShadow: 'none',
                    bgcolor: activeCardId === card.jdId ? '#F7FBF9' : 'white',
                    cursor: 'pointer',
                  }}
                >
                  <img
                    style={{ width: '30px', marginLeft: '22px', marginTop: '4px' }}
                    src={activeCardId === card.jdId ? displayIcon : disableIcon}
                    alt="Card Icon"
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 600, textAlign: 'left' }}>
                      {card.jdName}
                    </Typography>
                    <Typography sx={{ fontSize: '12px', color: '#A9A9A9', mr: '10px' }}>
                    {truncateText(card.jdTemplateText, 110)}
                    </Typography>
                  </Box>
                </Card>
              ))
            )}
            </Box>
          </Box>
        </Grid>
        }

        {/* Right section with textarea */}
        <Grid item sx={{  m: 2, overflow:'hidden' }}>
          {hideButton && (
            <Typography sx={{ color: '#27538D', fontWeight: 600, mb: '20px' }}>
              Edit Area
            </Typography>
          )}
              <ReactQuill
              style={{display:'flex',justifyContent:'flex-end', flexDirection:'column', height:reachQuillHeight, width: pageType==="Generate"? '88.2vw':'61.5vw', outline: 'none', border: 'none'}}
                value={pageType === "Generate"?uploadContent:value}
                onChange={handleEditorChange}
                modules={modules}
                formats={formats}
                placeholder="First Line is Taken as Template Name"
              />
              <style>{`
                .ql-toolbar.ql-snow {
                  background-color: white;
                }
              `}</style>
               
        </Grid>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: pageType==="Generate"? 'space-between': "flex-end", gap: '10px', mr: '15px', pb:'20px'}}>
        {pageType==="Generate" &&
        <Box sx={{ml:'15px'}} onClick={handleRefresh}>
        <RefreshButton>
        <img src={refreshIcon} alt="Refresh"/>
        Refresh
      </RefreshButton>
        </Box>
        }

        <Box sx={{ display: 'flex', gap:'10px'}}>
        <Button variant="contained" onClick={handleCancel} sx={{ border: 'none', bgcolor: 'transparent', color: '#848484', width: '116px', height: '42px', textTransform: 'none', borderRadius: '8px', boxShadow: 'none', '&:hover': { border: 'none', backgroundColor: 'transparent', boxShadow: 'none' } }}>
          Cancel
        </Button>
        {hideButton && (
          <>
            <Button variant="outlined" disabled={isContentEmpty(value)} onClick={handleTemplateSave} sx={{ border: '1px solid #1677FF', color: '#1677FF', width: '185px', height: '42px', textTransform: 'none', borderRadius: '8px', boxShadow: 'none', '&:hover': { backgroundColor: 'transparent', boxShadow: 'none' } }}>
              Save as Template
            </Button>
            { ( pageType==='Generate'||showTemplate ) && (
             <Button variant="outlined"onClick={handleSaveAsDraft} disabled={isContentEmpty(value)} sx={{ border: '1px solid #1677FF', color: '#1677FF', width: '145px', height: '42px', textTransform: 'none', borderRadius: '8px', boxShadow: 'none', '&:hover': { backgroundColor: 'transparent', boxShadow: 'none' } }}>
                Save as Draft
              </Button>
            )}
          </>
        )}
        <Button variant="contained" onClick={handleSubmit} disabled={isContentEmpty(value)} // Use trim to check for empty string
        sx={{ bgcolor: '#1677FF', width: hideButton?'116px':'217px', height: '42px', textTransform: 'none', borderRadius: '8px', boxShadow: 'none', '&:hover': { border: 'none', backgroundColor: '#1677FF', boxShadow: 'none' } }}>
          {buttonName}
        </Button>          
        </Box>
      </Box>
    </Box>
  );
};
