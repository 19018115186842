import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Button,
  Typography,
  CircularProgress,
  Grid,
  InputBase,
  InputAdornment,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { ViewCardProps } from "../../ViewCard";
import { useAppSelector } from "../../../../redux/hooks";
import {
  getInProgressAPIData,
  getOverAllRthAPIData,
} from "../../../../Crud/card";
import priorityHighSymbol from "../../../../assets/images/priorityHighSymbol.svg";
import priorityMediumSymbol from "../../../../assets/images/priorityMediumSymbol.svg";
import priorityLowSymbol from "../../../../assets/images/priorityLowSymbol.svg";
import NoDataAvail from "../../../../assets/images/NoDataAvail.svg";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { getStatusMessage } from "../../../reports/getReportData";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
  "&:last-child": {
    borderRight: "none",
  },
}));

const RTHInProgressTable: React.FC<ViewCardProps> = ({ data, tabName }) => {
  console.log('data',data)
  const userId = localStorage.getItem("userId") ?? "";

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  console.log("setSearchLoading",setSearchLoading)
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);    
  };

  const [tableData, setTableData] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  console.log(loading);
  const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);

  useEffect(() => {
    if (tabName === "In Progress") {
      setLoading(true);
      getInProgressAPIData(userId, teamHeadFilter, searchQuery)
        .then((res) => {
          // setLoading(false);
          setTableData(res?.data?.listOfRth);
          // console.log("why ?");
          // console.log(tableData);
        })
        .catch((error) => {
          console.error("Error fetching MRF data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (tabName === "Overall MRF") {
      setLoading(true);
      getOverAllRthAPIData(userId, teamHeadFilter, searchQuery)
        .then((res) => {
          // setLoading(false);
          setTableData(res?.data?.listOfRth);
          console.log("response",res);
        })
        .catch((error) => {
          console.error("Error fetching MRF data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
      // console.log(tableData);
    }
  }, [userId, teamHeadFilter, tabName, setTableData, searchQuery]);

  const navigate=useNavigate();

  const handleRowClick = (rthId: string) => {
    if (tabName === "In Progress") {
      navigate(`/request-hire`, {
        state: { id: rthId, queryParam: "in-progress" }
      });
    } else if (tabName === "Overall MRF") {
      navigate(`/request-hire`, {
        state: { id: rthId, queryParam: "overall-mrf", label: "overall MRF" }
      });
    }
  }
  // const datas = tableData?.map((job, index) => job?.listOfRth);

  return (
    <Box sx={{ width: "auto", backgroundColor: "#f0f4ff" }}>
      <Grid
        item
        sx={{ marginTop: tableData.length > 0 ? "-60px" : "-58px" }}
        position="absolute"
      >
        <InputBase
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search MRF..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
            </InputAdornment>
          }
          sx={{
            border: "1px solid #ccc",
            borderRadius: 1,
            padding: 0,
            backgroundColor: "white",
            minHeight: "3px",
            fontSize: 14,
            ".MuiInputBase-input": {
              padding: "5px 5px",
            },
          }}
        />
        {searchLoading && (
          <CircularProgress
            size={20}
            sx={{
              position: "absolute",
              right: "10px",
              top: "25%",
              transform: "translateY(-50%)",
              color: "#2F54EB",
            }}
          />
        )}
      </Grid>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <CircularProgress size={100} sx={{ color: "#2F54EB" }} />
        </Box>
      ) : tableData.length > 0 ? (
        <Box sx={{ width: "100%", overflowX: "auto" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="MRF table">
              <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
                <TableRow
                  sx={{
                    "& th": {
                      padding: "4px 8px", // Adjust padding for header cells here
                      fontSize: "14px", // Optional: Adjust font size
                    },
                  }}
                >
                  <StyledTableCell>SI.No.</StyledTableCell>

                  <StyledTableCell>MRF Name</StyledTableCell>

                  <StyledTableCell>Type of MRF</StyledTableCell>

                  <StyledTableCell>Business</StyledTableCell>

                  <StyledTableCell>Priority</StyledTableCell>

                  <StyledTableCell>Status</StyledTableCell>

                  <StyledTableCell>Stage</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "& td, & th": {
                    padding: "4px 8px", // Adjust padding here
                  },
                }}
              >
                {tableData?.map((row: any, index: number) => (
                  <TableRow
                    key={row.rthId}
                    onClick={() => handleRowClick(row.rthId)}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)", // Example color
                      },
                    }}
                  >
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{row.positionTitle}</StyledTableCell>
                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          size="small"
                          sx={{
                            backgroundColor: "#F0F0F0",
                            border: "1px solid lightgray",
                            fontSize: "8px",
                            padding: "2px",
                            color: "#8C8C8C",
                            height: "20px",
                            minWidth: "50px",
                          }}
                        >
                          {row.typeOfRth}
                        </Button>
                        <Button
                          size="small"
                          sx={{
                            backgroundColor: "#F0F0F0",
                            border: "1px solid lightgray",
                            fontSize: "8px",
                            padding: "2px 4px",
                            color: "#8C8C8C",
                            height: "20px",
                            minWidth: "50px",
                          }}
                        >
                          {row.modeOfRth}
                        </Button>
                      </Box>
                    </StyledTableCell>

                    <StyledTableCell>
                      <Typography
                        sx={{
                          width: "fit-content",
                          fontSize: "12px",
                          padding: "3%",
                          height: "px",
                          border:
                            "1px solid " +
                            (row.business.businessName === "HEPL"
                              ? "#D3ADF7"
                              : "#87E8DE"),
                          backgroundColor:
                            row.business.businessName === "HEPL"
                              ? "#EFDBFF"
                              : "#B5F5EC",
                          color:
                            row.business.businessName === "HEPL"
                              ? "#722ED1"
                              : "#08979C",
                        }}
                      >
                        {/* {row.business.businessName} */}
                        {row.business?.businessName &&
                        row.business.businessName.length > 10
                          ? `${row.business.businessName.substring(0, 10)}...`
                          : row.business?.businessName || "N/A"}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        gap={1}
                      >
                        {row.priority === "High" ? (
                          <Box>
                            <img src={priorityHighSymbol} alt="" />
                          </Box>
                        ) : row.priority === "Medium" ? (
                          <Box>
                            <img src={priorityMediumSymbol} alt="" />
                          </Box>
                        ) : (
                          <Box>
                            <img src={priorityLowSymbol} alt="" />
                          </Box>
                        )}
                        <Typography
                          sx={{
                            color:
                              row.priority === "High"
                                ? "#F04E4E"
                                : row.priority === "Medium"
                                ? "#FFA940"
                                : "#73D13D",
                            fontSize: "100%",
                          }}
                        >
                          {row.priority}
                        </Typography>
                      </Box>
                    </StyledTableCell>

                    <StyledTableCell>
                      <Chip
                        label={row.status}
                        size="small"
                        sx={{
                          fontSize: "12px",
                          height: "px",
                          borderRadius: "1px",
                          backgroundColor: row.status === "Declined" ? "#FFEBEE": "#389E0D1A",
                          color: row.status === "Declined" ? "#f44336": "#389E0D",
                        }}
                      />
                    </StyledTableCell>

                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Chip
                            label={getStatusMessage(row?.l1Status,row?.l2Status) || "-"}
                            size="small"
                            sx={{
                              fontSize: "12px",
                              padding: "3px 6px 0px 6px",
                              height: "20px",
                              borderRadius: "1px",
                              backgroundColor: "transparent",
                              color: "#8C8A8A",
                            }}
                          />
                        </Box>
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
          }}
        >
          <img
            src={NoDataAvail}
            alt="No Data Available"
            style={{ maxWidth: "200px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default RTHInProgressTable;
