import { Box, Typography, Grid, CircularProgress, MenuItem, FormControl, Select } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BaseUrl } from '../../../../../constants/Crudurl';
import { getPositionTitle } from '../../../../../Crud/rth';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';

const chartColors: { [key: string]: string } = {
  "L1 Pending": '#00A76F',
  "L2 Pending": '#FFAB00',
  HR: '#00B8D9',
  Screening: '#05EEFF',
  Others: '#FF5630'
};

function PieChartBoard1() {
  const data = useSelector((state: RootState) => state.profile.data);
  const userID = data?.data?.userId;
  const [statusReport, setStatusReport] = useState<{ label: string; value: number; }[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [positions, setPositions] = useState<{ id: string; title: string }[]>([]);
  const [selectedPosition, setSelectedPosition] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('Internal');
  // const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loadingPositions, setLoadingPositions] = useState(false);

  useEffect(() => {
    const fetchPositions = async () => {
      setLoadingPositions(true);
      try {
        const response = await getPositionTitle(page.toString(), 10);
        setPositions(prev => [...prev, ...response.map((data: string, index: number) => ({
          id: `${index}-${data}`,
          title: data
        }))]);
        setHasMore(response.length > 0);
      } catch (error) {
        console.error('Error fetching positions:', error);
      } finally {
        setLoadingPositions(false);
      }
    };
    fetchPositions();
  }, [page]);

  // const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchTerm(event.target.value);
  //   setPage(0);
  //   setPositions([]);
  // };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    if (target.scrollHeight - target.scrollTop === target.clientHeight && hasMore && !loadingPositions) {
      setPage(prev => prev + 1);
    }
  };

  useEffect(() => {
    const fetchStatusReport = async () => {
      try {
        setLoading(true);
        const response = await axios.post(`${BaseUrl}/dashboard/canIntStatusByTa/${userID}`, {
          mrf: [selectedPosition],
          category: [selectedCategory],
          userId: null
        });
        const responseData = response?.data?.data;
        const updatedStatusReport = Object.entries(responseData).map(([label, value]) => ({
          label,
          value: Number(value),
        }));
        setStatusReport(updatedStatusReport);
        setTotal(updatedStatusReport.reduce((acc, item) => acc + item.value, 0));
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (userID) {
      fetchStatusReport();
    }
  }, [userID, selectedPosition, selectedCategory]);

  return (
    <Grid container sx={{ borderRadius: '8px', height: '200px', backgroundColor: '#FFFFFF', padding: '8px', position: 'relative' }}>
      <Grid item xs={12} container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '30px' }}>
        <Typography sx={{ fontWeight: '500', fontSize: '12px' }}>Candidate Interview Status</Typography>
        <FormControl
          variant="standard"
          sx={{
            m: 1,
            minWidth: 100,
            '& .MuiInput-underline:before': { borderBottom: 'none' },
            '& .MuiInput-underline:after': { borderBottom: 'none' },
            '&:hover .MuiInput-underline:before': { borderBottom: 'none !important' }
          }}
        >
          <Select
            value={selectedPosition}
            onChange={(e) => setSelectedPosition(e.target.value)}
            size="small"
            IconComponent={(props) => <ArrowDropDownIcon {...props} sx={{ marginTop: '-4px' }} />}
            displayEmpty
            sx={{
              minWidth: 100,
              maxWidth: 120,
              fontSize: '12px',
              height: '30px',
              padding: '4px',
              '& .MuiSelect-select': { padding: 0 },
              '&:hover': { backgroundColor: 'transparent' },
            }}
            renderValue={(selected) =>
              selected ? (selected.length > 8 ? `${selected.substring(0, 8)}...` : selected) : "Position"
            }
            MenuProps={{
              PaperProps: {
                onScroll: handleScroll,
                style: {
                  maxHeight: 200,
                },
              },
            }}
          >
            {positions.map((pos) => (
              <MenuItem key={pos.id} value={pos.title}>{pos.title}</MenuItem>
            ))}
            {loadingPositions && (
              <MenuItem disabled>
                <CircularProgress size={20} sx={{ margin: 'auto' }} />
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl
          variant="standard"
          sx={{
            '& .MuiInput-underline:before': { borderBottom: 'none' },
            '& .MuiInput-underline:after': { borderBottom: 'none' },
            '&:hover .MuiInput-underline:before': { borderBottom: 'none !important' }
          }}
        >
          <Select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            size="small"
            IconComponent={(props) => <ArrowDropDownIcon {...props} sx={{ marginTop: '-4px' }} />}
            sx={{
              minWidth: 100,
              maxWidth: 120,
              fontSize: '12px',
              height: '30px',
              padding: '4px',
            }}
            renderValue={(selected) =>
              selected.length > 10 ? `${selected.substring(0, 10)}...` : selected
            }
          >
            <MenuItem value="Internal">Internal</MenuItem>
            <MenuItem value="External">External</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {loading ? (
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10 }}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <Box sx={{ position: 'relative', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', mt: -3.5 }}>
            <PieChart
              height={200}
              width={240}
              slotProps={{
                legend: { hidden: true },
              }}
              series={[
                {
                  data: statusReport.map((item) => ({
                    ...item,
                    color: chartColors[item.label],
                  })),
                  innerRadius: 60,
                  outerRadius: '65%',
                  arcLabel: () => '',
                  arcLabelMinAngle: 20,
                  // startAngle: -340,
                  // endAngle: 160,
                },
              ]}
            />
            <Grid item sx={{ position: 'absolute', top: '50%', left: '30%', transform: 'translate(-50%, -50%)', textAlign: 'center', pointerEvents: 'none' }}>
              <Typography component="span" sx={{ fontSize: '16px', fontWeight: 'bold', color: '#000' }}>
                {total}
              </Typography>
            </Grid>
          </Box>
        </Grid>
      )}
      <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mt: -2 }}>
        <Box>
          {statusReport.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
              <Box sx={{ width: 9, height: 9, borderRadius: '1px', backgroundColor: chartColors[item.label], marginRight: '8px' }}></Box>
              <Typography sx={{ color: '#6B7280', fontSize: '0.688rem', flex: 1, padding: '2px' }}>{item.label}</Typography>
              <Typography sx={{ color: '#6B7280', fontSize: '0.688rem', fontWeight: 'bold' }}>
                {item.value}
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
}

export default PieChartBoard1;
