import { Card, Typography, Avatar, Button, Box, Tooltip, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import pdfImage from '../../assets/images/pdf 1.svg';
import docImage from '../../assets/images/doc 1.svg';
import phonecall from '../../assets/images/phone-call.svg'
import emailopen from '../../assets/images/e-mail.svg'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { truncateText } from '../../helpers/truncateText';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { deleteCandidateById } from '../../redux/features/candidate';
import { toast } from 'react-toastify';
import {  useState } from 'react';
// import SelectData from './selectData';
// import { SelectDataOptions } from '../../constants/dummyConst';

const getFileIcon = (fileName: string) => {
  const extension = fileName.split('.').pop()?.toLowerCase();
  if (extension === 'pdf') {
    return <img src={pdfImage} alt="PDF Icon" style={{ width: '12px', height: '12px' }} />;
  }
  if(extension==='doc'||extension==='docx'){
    return <img src ={docImage} alt="DOC Icon" style={{width:'16px',height:'16px'}}/>;
  }
  return null;
};
const getFileNameAndType = (url: string) => {
  const parts = url.split('/');
  const fileNameWithParams = parts[parts.length - 1].split('?')[0]; // Extract filename before the query params
  const fileName = fileNameWithParams.includes('_') 
    ? fileNameWithParams.split('_').slice(1).join('_') 
    : fileNameWithParams; // Remove prefix if exists
  const extension = fileName.split('.').pop()?.toLowerCase(); // Get file extension
  return { fileName, extension };
};

const getInitials = (name: string, lastName: string) => {
  const firstNameInitial = name?.trim()[0] || ''; // Get first letter of first name
  const lastNameInitial = lastName?.trim()[0] || ''; // Get first letter of last name
  return firstNameInitial + lastNameInitial; // Combine both initials
};


const truncateFileName = (fileName: string, extension: string, maxLength = 3) => {
  if (fileName.length > maxLength) {
    return `${fileName.slice(0, maxLength)}..${extension}`;
  }
  return `${fileName}.${extension}`;
};

const getStatusStyles = (status: string) => {
  if (status === 'Newly Arrived') {
    return { color: '#57DB57', backgroundColor: '#FBFBFB' };
  }
  return { color: '#7F7F7F', backgroundColor: '#FBFBFB' }; // Default styles if no conditions are met
};
  
// function CircularProgressWithLabel(
//   props: CircularProgressProps & { value: number },
// ) {
//   return (
//     <Box sx={{ position: 'relative', display: 'flex', alignItems:'center' }}>
//      <CircularProgress variant="determinate" sx={{ color: '#00AB55' }} size={24} {...props} />
//       <Box
//         sx={{
//           top: 0,
//           left: 0,
//           bottom: 0,
//           right: 0,
//           position: 'absolute',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//         }}
//       >
//         <Typography
//           variant="caption"
//           component="div"
//           sx={{ pt:'2px', color: '#000000', fontSize:'7px', fontWeight:700 }}
//         >{`${Math.round(props.value)}%`}</Typography>
//       </Box>
//     </Box>
//   );
// }

const CandidateCard = ({
  name,
  status,
  daysAgo,
  phone,
  email,
  pdfLink,
  schedule,
  lastName,
  candidate,
  drawerOpen,
  isAccess,
  fetchCandidates
}: any) => {
  const statusStyles = getStatusStyles(status);
  const navigate=useNavigate();
  const { fileName, extension } = getFileNameAndType(pdfLink); 
  const displayedFileName = truncateFileName(fileName, extension!, 3); 
  const {jobTitle}=useParams();
  const location=useLocation();
  const fullName = lastName ? `${name} ${lastName}` : name;
  const resumeRepoPath=location.pathname.includes("resumeRepo");
  // const [progress, setProgress] = useState(10);
  // const [isHovered, setIsHovered] = useState(false);
  const[open,setOpen]=useState(false);
  const[deleteCandidateId,setDeleteCandidateId]=useState<any>([]);

  const defaultPermissions = {
    assignRecruiterLead: false,
    assignRecruiter: false,
    jj: false,
  };

  const userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") || "{}")
    : {
        permissionsList: defaultPermissions,
        roleName: "",
      };

  const permissionsList = userData.permissionsList || defaultPermissions;
   
  const handleNavigate=()=>{
    if(resumeRepoPath){
      navigate(`/resumeRepo/${candidate?.rthName}/${candidate?.candidateLogInList?.rthId}/candidate/${fullName}/${candidate.candidateLogInList?.candidateId}`)
    }
    else{
    navigate(`/newRequestToHire/${jobTitle}/${candidate.rthId}/candidate/${fullName}/${candidate.candidateId}`)
    }
  };

 
  const handleDeleteCandidate=async(candidateId:any)=>{
    setOpen(!open)
    setDeleteCandidateId(candidateId);    
  }
   const handleCandidateDelete=async()=>{
    await deleteCandidateById(deleteCandidateId);
    toast.success("Candidate Deleted Successfully");
    fetchCandidates();
   }
const Md = useMediaQuery("(max-width: 1504px)");

const isTruncated = schedule?.length > 18;
  const displayText = isTruncated ? `${schedule.substring(0, 18)}...` : schedule;

// console.log("inside the isAccess",isAccess);


// useEffect(() => {
//   const timer = setInterval(() => {
//     setProgress((prevProgress) => (prevProgress = 100));
//   }, 800);
//   return () => {
//     clearInterval(timer);
//   };
// }, []);

  return (
    <>
    <Card style={{margin: '5px', width: drawerOpen? (Md ? '298px' : '290px')  : (Md ? '376px' : '278px') , height: '160px',cursor:'pointer', backgroundColor: "white" }} onClick={(e) => {
      handleNavigate();
    }}>
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>      
        {/* <Box sx={{minWidth:'24px', display:'flex', justifyContent:'center'}} onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
        {isHovered ? (
        <Checkbox sx={{width:'10px', height:'10px'}}   onClick={(event) => {
          event.stopPropagation(); 
        }}
        />
        ) : (
          <CircularProgressWithLabel value={progress} />
        )}
        </Box> */}
        <Avatar sx={{ width: '28px', height: '28px', bgcolor: '#e8f2ff', color: '#000', fontSize: '12px', mt: '2px' }}>{getInitials(name, lastName)}</Avatar>
 
        <Tooltip
        title={name} 
        disableHoverListener={name?.length <= 15} 
        arrow >
        <Typography sx={{ fontSize: '16px' }}>{truncateText(name, 15)}
        </Typography>
        </Tooltip>
      </Box>

      <Box sx={{display:'flex', alignItems:'center'}}>
      <Typography
        variant="body2"
        sx={{
          color: statusStyles.color,
          backgroundColor: statusStyles.backgroundColor,
          padding: '4px 4px',
          borderRadius: '4px',
          display: 'inline-block',
          fontSize: '11px',
          mr:'10px'
        }}  
      >
        {daysAgo ? `${status} : ${daysAgo}` : status}
      </Typography>
      {(permissionsList.assignAndEditRecruiterLead ||
        permissionsList.assignAndEditRecruiter)&&(!resumeRepoPath)&&
        <Tooltip title='Delete'>
        <RemoveCircleOutlineIcon sx={{fontSize:'small',"&:hover": {
          color: "red"
        }}}
         onClick={(event) => {
          event.stopPropagation(); 
          handleDeleteCandidate(candidate?.candidateId);
        }}
         />
        </Tooltip>
      }
      </Box>     
      </Box>

      <Box sx={{ p: '4px 10px' }}>
        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center', fontSize: '13px', ml: '5px' }}>
          <img src={phonecall} alt={'phone'} style={{ marginRight: '10px', width: '12px' }} /> {phone}
        </Typography>
        <Tooltip
          title={email}
          disableHoverListener={email?.length <= 28}
          arrow>
          <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center', mt: '10px', fontSize: '13px', ml: '5px' }}>
            <img src={emailopen} alt={'emailOpen'} style={{ marginRight: '10px', width: '12px' }} /> {truncateText(email, 28)}

          </Typography>
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          // width: '100%',          
          padding: '10px',
          pb: 0,
          mt: 1
        }}
      >
        {pdfLink ?
          <Tooltip
            title={fileName}
            disableHoverListener={fileName?.length <= 3}
          >
            <Button
              variant="outlined"
              // href={pdfLink}
              // target="_blank"
              disabled
              startIcon={getFileIcon(fileName)}
              sx={{
                width: '100px',
                height: '24px',
                // mt: '12px',
                color: '#888888',
                fontSize: '11px',
                border: '1px solid #DEDEDE',
              }}
            >
              {displayedFileName}
            </Button>
          </Tooltip>
          :
          <Typography sx={{ fontSize: '12px' }}>Resume not uploaded</Typography>}
        <Box>
            <Tooltip title={isTruncated ? schedule : ''} arrow>
                <Button
                  sx={{
                    color: '#1677FF',
                    textTransform: 'none',
                    backgroundColor:  '#1677FF1A' ,
                    fontSize: '12px',
                    width: 'auto',
                    height: '24px',
                    '&:hover': {
                     backgroundColor: '#1677FF1A' , // Prevent hover effects
                    },
                  }}
                >
                  <Typography noWrap sx={{fontSize:'12px'}}>{displayText}</Typography>
                </Button>
              </Tooltip>
        </Box>
      </Box>
    </Card>
    <Dialog open={open}>
      <DialogTitle>Confirm Delete</DialogTitle>
       <DialogContent sx={{pb:'10px'}}>
            <Box>
             <Typography>Are you sure you want to delete this candidate?</Typography>
            </Box>
            </DialogContent>
            <DialogActions>
              <Box sx={{pb:'10px'}}>
                <Button sx={{ mr:2, textTransform:'none',color:'#848484'}} onClick={()=>setOpen(false)}>Cancel</Button>
                <Button variant='contained' sx={{mr:2,textTransform:'none',backgroundColor:'#1677FF',color:'white',borderRadius:'4px'}} onClick={handleCandidateDelete}>Delete</Button>
              </Box>
            </DialogActions>
      </Dialog>
    </>
  );
};

export default CandidateCard; 