import React, { useEffect, useState } from 'react';
import { Button, Grid, Box, Typography, IconButton } from '@mui/material';
import CommonTextField from '../../../common/CommonTextField';
import { useLocation } from 'react-router-dom';
import DeleteIcon from '../../../../assets/images/del_Icon.svg';

const predefinedLabels = [
  'Highest Education',
  'Postgraduate Degree',
  'Undergraduate Degree',
  'Diploma/Certification',
  'Secondary Education',
  'Other Education/Training',
];
 
const Education = ({ formValues, setFormValues,errors,setErrors }: any) => {
  // const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [educationLabelsUsed, setEducationLabelsUsed] = useState<number>(1);
  const location=useLocation();
  const path=location.pathname.includes("candidateForm");
 
  
  // Handle closing the dialog
 
  // Handle the input change for the current form values (including education entries)
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index?: number) => {
    const { name, value } = event.target;
    
    setErrors((prevErrors:any)=>({
      ...prevErrors,
      fieldOfStudy:'',
      degree:'',
      university:'',
      from:'',
      to:'',
    }))
    // Ensure index is defined
    if (index === undefined) return; // If index is undefined, exit early
  
    if (name === 'from' || name === 'to') {
      const currentDate = new Date();
      const inputDate = new Date(value);
  
      // 1) From cannot be today or a future date
      if (name === 'from') {
        if (inputDate > currentDate) {
          setErrors((prevErrors: any) => {
            const updatedErrors = [...(prevErrors.educations || [])];
            updatedErrors[index] = updatedErrors[index] || {};
            updatedErrors[index].from = 'From date cannot be today or a future date.';
            return { ...prevErrors, educations: updatedErrors };
          });
          return;
        }
      }
      const currentFromDate = name === 'from' ? inputDate : new Date(formValues.educations[index]?.from || '');
      const currentToDate = name === 'to' ? inputDate : new Date(formValues.educations[index]?.to || '');
  
      // 2) Check for duplicate date ranges across all educations
      for (let i = 0; i < formValues.educations.length; i++) {
        if (i !== index) {
          const otherFromDate = new Date(formValues.educations[i]?.from || '');
          const otherToDate = new Date(formValues.educations[i]?.to || '');
  
          // Check if both "from" and "to" dates match
          if (
            currentFromDate.getTime() === otherFromDate.getTime() ||
            currentToDate.getTime() === otherToDate.getTime()
          ) {
            setErrors((prevErrors: any) => {
              const updatedErrors = [...(prevErrors.educations || [])];
              updatedErrors[index] = updatedErrors[index] || {};
              updatedErrors[index][name] = 'This date range is already used for another education.';
              return { ...prevErrors, educations: updatedErrors };
            });
            return;
          }
  
          // Check if the current range overlaps with another range
          
          // if (
          //   (currentFromDate >= otherFromDate && currentFromDate <= otherToDate) ||
          //   (currentToDate >= otherFromDate && currentToDate <= otherToDate) ||
          //   (currentFromDate <= otherFromDate && currentToDate >= otherToDate)
          // ) {
          //   setErrors((prevErrors: any) => {
          //     const updatedErrors = [...(prevErrors.educations || [])];
          //     updatedErrors[index] = updatedErrors[index] || {};
          //     updatedErrors[index][name] = 'This date range overlaps with another education.';
          //     return { ...prevErrors, educations: updatedErrors };
          //   });
          //   return;
          // }
        }
      }
  
  

  
      const toDate = formValues.educations[index]?.to ? new Date(formValues.educations[index].to) : null;
      if (toDate && inputDate.getTime() === toDate.getTime()) {
        setErrors((prevErrors: any) => {
          const updatedErrors = [...(prevErrors.educations || [])];
          updatedErrors[index] = updatedErrors[index] || {};
          updatedErrors[index].from = 'From and To dates cannot be the same.';
          return { ...prevErrors, educations: updatedErrors };
        });
        return;
      }
    
      // 2) From and To cannot be the same date
      if (name === 'to' && formValues.educations[index]?.from === value) {
        setErrors((prevErrors: any) => {
          const updatedErrors = [...(prevErrors.educations || [])];
          updatedErrors[index] = updatedErrors[index] || {};
          updatedErrors[index].to = 'To date cannot be the same as From date.';
          return { ...prevErrors, educations: updatedErrors };
        });
        return;
      }
  
      // 3) To cannot be a past date
      if (name === 'to') {
        const fromDate = formValues.educations[index]?.from ? new Date(formValues.educations[index].from) : null;
      
        // Ensure `fromDate` exists before performing validation
        if (fromDate && inputDate <= fromDate) {
          setErrors((prevErrors: any) => {
            const updatedErrors = [...(prevErrors.educations || [])];
            updatedErrors[index] = updatedErrors[index] || {};
            updatedErrors[index].to = 
            inputDate.getTime() === fromDate.getTime()
            ? 'From and To dates cannot be the same.'
            : 'To date must be greater than From date.';
            return { ...prevErrors, educations: updatedErrors };
          });
          return;
        }
      }
      // If validation passes, reset the error and update form values
      setErrors((prevErrors: any) => {
        const updatedErrors = [...(prevErrors.educations || [])];
        updatedErrors[index] = updatedErrors[index] || {};
        updatedErrors[index][name] = ''; // Clear the error if input is valid
        return { ...prevErrors, educations: updatedErrors };
      });
    }
  
    // Ensure index is used when updating the formValues
    const updatedEducation = [...formValues.educations];
    updatedEducation[index][name] = value;
    setFormValues({
      ...formValues,
      educations: updatedEducation,
    });
  };
  
 
  // Add a new education entry with the label from the dialog
  const handleAddEducation = () => {
    if (educationLabelsUsed < predefinedLabels.length) {
      const newEducation = {
        university: '',
        degree: '',
        fieldOfStudy: '',
        from: '',
        to: '',
        eduLabel: predefinedLabels[educationLabelsUsed],
      };
      setFormValues({
        ...formValues,
        educations: [...(formValues.educations || []), newEducation],
      });
      setErrors((prevErrors: any) => {
        const updatedErrors = [...(prevErrors.educations || []), {}];
        return { ...prevErrors, educations: updatedErrors };
      });
      setEducationLabelsUsed((prev) => prev + 1);
    }
  };

  const handleDeleteEducation = (index: number) => {
    const updatedEducation = [...formValues.educations];
    updatedEducation.splice(index, 1);
    setFormValues({ ...formValues, educations: updatedEducation });
  };
 
  // Helper function to convert a number to Roman numerals
const toRoman = (num: number) => {
  const romanNumerals: [number, string][] = [
    [1000, 'M'], [900, 'CM'], [500, 'D'], [400, 'CD'],
    [100, 'C'], [90, 'XC'], [50, 'L'], [40, 'XL'],
    [10, 'X'], [9, 'IX'], [5, 'V'], [4, 'IV'],
    [1, 'I'],
  ];
 
  let result = '';
  for (let [value, symbol] of romanNumerals) {
    while (num >= value) {
      result += symbol;
      num -= value;
    }
  }
  return result;
};
 
  useEffect(()=>{
    if (!Array.isArray(formValues.educations) || formValues.educations.length === 0) {
      setFormValues((prevFormData:any) => ({
        ...prevFormData,
          educations: [{
          university: '',
          degree: '',
          fieldOfStudy: '',
          from: '',
          to: '',
          eduLabel: predefinedLabels[0],
        }]
      }));
    }
  },[formValues,setFormValues])
 
 
  return (
    <Box sx={{ pt: '4px',ml:path?'30px':'' }}>
      {/* <Paper elevation={0} sx={{ overflowY: 'scroll', pt: '10px', position: 'relative', height: '62vh' }}> */}
        <Typography sx={{ fontSize: '22px', fontWeight: '500', mb: '27px'}}>
        {path ? '6.Education' : 'Education'}
          </Typography>
 
        {/* Loop through education entries in formValues */}
        {(formValues.educations || []).map((entry: any, index: number) => (
          <Box key={index} sx={{ width: '50vw', mb: 2 }}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography sx={{ mt: '10px', fontSize: '16px', fontWeight: 'bold' }}>
            {index === 0
            ? `${toRoman(index + 1)}) Highest Education`
        : `${toRoman(index + 1)}) ${entry.eduLabel || `Education ${index + 1}`}`}
            </Typography>
            {index > 0 && (
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteEducation(index)}
                size="small"
                sx={{
                  ml: 1,
                  color: 'inherit',
                  '&:hover': {
                    color: 'red',
                  },
                }}
              >
                <img src={DeleteIcon} alt="Delete Icon" style={{ width: '16px', height: '16px' }} />
              </IconButton>
            )}
           </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '17px', mt: '10px' }}>University
                {path && <span style={{ color: 'red' }}> *</span>}
                </Typography>
                <CommonTextField
                  label=""
                  name="university"
                  value={entry.university}
                  onChange={(event) => handleInputChange(event, index)}
                  error={!!errors?.university||!!errors?.educations?.[index]?.university} // Check error for specific index
                  helperText={errors?.university||errors?.educations?.[index]?.university} // Display error for specific index
                />
              </Grid>
 
              <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '17px', mt: '10px' }}>Degree
                {path && <span style={{ color: 'red' }}> *</span>}
                </Typography>
                <CommonTextField
                  label=""
                  name="degree"
                  value={entry.degree}
                  onChange={(event) => handleInputChange(event, index)}
                  error={!!errors?.degree||!!errors?.educations?.[index]?.degree} // Check error for specific index
                  helperText={errors?.degree||errors?.educations?.[index]?.degree} // Display error for specific index
                />
              </Grid>
 
              <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '17px', mt: '10px' }}>Field of Study
                {path && <span style={{ color: 'red' }}> *</span>}
                </Typography>
                <CommonTextField
                  label=""
                  name="fieldOfStudy"
                  value={entry.fieldOfStudy}
                  onChange={(event) => handleInputChange(event, index)}
                  error={!!errors?.fieldOfStudy||!!errors?.educations?.[index]?.fieldOfStudy} // Check error for specific index
                  helperText={errors?.fieldOfStudy||errors?.educations?.[index]?.fieldOfStudy} // Display error for specific index
                />
              </Grid>
 
              <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '17px' }}>From
                {path && <span style={{ color: 'red' }}> *</span>}
                </Typography>
                <CommonTextField
                  label=""
                  type="date"
                  name="from"
                  value={entry.from}
                  onChange={(event) => handleInputChange(event, index)}
                  error={!!errors?.from||!!errors?.educations?.[index]?.from} // Check error for specific index
                  helperText={errors?.from||errors?.educations?.[index]?.from} // Display error for specific index
                />
              </Grid>
 
              <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '17px' }}>To
                {path && <span style={{ color: 'red' }}> *</span>}
                </Typography>
                <CommonTextField
                  label=""
                  type="date"
                  name="to"
                  value={entry.to}
                  onChange={(event) => handleInputChange(event, index)}
                  error={!!errors?.to||!!errors?.educations?.[index]?.to} // Check error for specific index
                  helperText={errors?.to||errors?.educations?.[index]?.to} // Display error for specific index
                />
              </Grid>
            </Grid>
          </Box>
        ))}
        {educationLabelsUsed < predefinedLabels.length && (
        <Box sx={{ mt: '8px' }}>
          <Button onClick={handleAddEducation}>+ Add New Education</Button>
        </Box>
        )}
      {/* </Paper> */}
 
      {/* Dialog for Adding Education */}
      
    </Box>
  );
};
 
export default Education;
 