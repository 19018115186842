import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, IconButton, Typography, Divider, List, ListItem, ListItemText, Drawer, Tooltip, Tabs, Tab, CircularProgress } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import notificationIcon from "../../../assets/images/notificationIcon.svg";
import markAllAsReadIcon from "../../../assets/images/markAllAsReadIcon.svg";
import notificationIconNew from "../../../assets/images/notificationIconNew.svg";
import { getUnreadNotification, getReadNotification, markAllAsReadNotification, getNotification } from "../../../Crud/rth";
// import useWebSocket, { ReadyState } from "react-use-websocket";

interface Notification {
    id: number;
    message: string;
    read: boolean;
    notifiedAt: string;
}

interface NotificationDrawerProps {
    onToggleDrawer: () => void;
    open: boolean;
}

const NotificationDrawer: React.FC<NotificationDrawerProps> = ({ onToggleDrawer, open }) => {
    const [newNotification, setNewNotification] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [page, setPage] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const observer = useRef<IntersectionObserver | null>(null);
    const userId = localStorage.getItem("userId") ?? "";

    //Websocket

    // const { sendMessage, lastMessage, readyState } = useWebSocket('wss://mytheron-dev-backend.citpl.info/ws', {
    //     onOpen: () => console.log('Connected to WebSocket'),
    //     onMessage: (message) => console.log('Message received:', message),
    //     onError: (error) => console.error('WebSocket error:', error),
    //     onClose: () => console.log('WebSocket closed'),
    //   });

    useEffect(() => {
        setNotifications([]);
        setPage(0);
        setHasMore(true);
    }, [selectedTab]);

    const fetchNotifications = useCallback(async () => {
        if (!hasMore || loading) return;

        try {
            const response = selectedTab === 0 ? await getUnreadNotification(userId, page) : await getReadNotification(userId, page);
            const notificationsArray = response.data?.data || [];

            if (notificationsArray.length === 0) {
                setHasMore(false);
            } else {
                setNotifications((prev) => [
                    ...prev,
                    ...notificationsArray.map((notif: any) => ({
                        id: notif.notificationId,
                        message: notif.content,
                        read: notif.status === "Read",
                        notifiedAt: new Date(notif.notifiedAt).toLocaleString("en-US", {
                            month: "short",
                            day: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                        }).replace(/, (\d{4})/, " $1 |").replace(" |,", " |"),
                    }))
                ]);
            }
        } catch (error) {
            console.error("Error fetching notifications:", error);
        } finally {
            setLoading(false);
        }
    }, [hasMore, loading, selectedTab, userId, page]);

    useEffect(() => {
        fetchNotifications();
    }, [fetchNotifications]);

    const handleMarkAllAsRead = async () => {
        try {
            await markAllAsReadNotification(userId);
            setNotifications((prev) => prev.map((notif) => ({ ...notif, read: true })));
            setSelectedTab(1);
            setNewNotification(false);
        } catch (error) {
            console.error("Error marking all notifications as read:", error);
        }
    };

    const handleNotificationClick = async (notificationId: number) => {
        try {
            await getNotification(notificationId.toString());
            setNotifications((prev) => {
                const updatedNotifications = prev.map((notif) =>
                    notif.id === notificationId ? { ...notif, read: true } : notif
                );

                return updatedNotifications.filter((notif) => !notif.read || selectedTab === 1);
            });
        } catch (error) {
            console.error("Error marking notification as read:", error);
        }
    };

    const lastNotificationRef = useCallback((node: HTMLLIElement | null) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                setPage((prev) => prev + 1);
            }
        });

        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    return (
        <>
            <IconButton onClick={onToggleDrawer} sx={{ marginLeft: "8px", position: "relative" }}>
                <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", height: 42 }}>
                    <img src={notificationIcon} alt="Notification Icon" style={{ height: "100%", width: "100%" }} />
                </Box>
                {newNotification && (
                    <Box sx={{ width: 8, height: 8, borderRadius: "50%", backgroundColor: "red", position: "absolute", top: 10, right: 10 }}></Box>
                )}
            </IconButton>
            <Drawer anchor="right" open={open} onClose={onToggleDrawer}>
                <div style={{ width: 300, padding: 16 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ fontSize: '1rem' }}>Notifications</Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title="Mark all as read" placement="left-start">
                                <IconButton onClick={handleMarkAllAsRead}>
                                    <img src={markAllAsReadIcon} alt="Mark All As Read" style={{ width: 18, height: 18 }} />
                                </IconButton>
                            </Tooltip>
                            <IconButton onClick={onToggleDrawer}>
                                <GridCloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)} variant="fullWidth">
                        <Tab label="Unread" style={{ fontSize: '0.875rem', textTransform: 'none' }} />
                        <Tab label="Read" style={{ fontSize: '0.875rem', textTransform: 'none' }} />
                    </Tabs>
                    <List>
                        {notifications.length === 0 ? (
                            <Typography sx={{ padding: 2, textAlign: "center", fontSize: "0.875rem", color: "gray" }}>
                                No Notifications
                            </Typography>
                        ) : (
                            notifications.map((notif, index) => (
                                <ListItem
                                    ref={index === notifications.length - 1 ? lastNotificationRef : null}
                                    key={notif.id}
                                    alignItems="flex-start"
                                    onClick={() => !notif.read && handleNotificationClick(notif.id)}
                                    sx={{ cursor: notif.read ? "default" : "pointer" }}
                                >
                                    <IconButton sx={{ padding: 0, marginRight: 1, marginTop: 0.5 }}>
                                        <img src={notificationIconNew} alt="New Notification" style={{ width: 40, height: 40 }} />
                                    </IconButton>
                                    <ListItemText primary={<Typography sx={{ fontSize: "0.75rem", fontWeight: notif.read ? "normal" : "bold" }}>{notif.message}</Typography>}
                                        secondary={
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Typography variant="caption" color="textSecondary">
                                                    {notif.notifiedAt}
                                                </Typography>
                                            </Box>
                                        } />
                                </ListItem>

                            ))
                        )}
                        {loading && <CircularProgress sx={{ display: "block", margin: "auto", mt: 2 }} />}
                    </List>
                </div>
            </Drawer>
        </>
    );
};

export default NotificationDrawer;
