import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Paper, Typography, Button, Rating, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip, CircularProgress, Checkbox } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import InterviewEvaluationFormLogo from '../../../../../assets/images/InterviewEvaluationFormLogo.svg';
import BackGroundImage from '../../../../../assets/images/bgImageOfInterviewEvaluationForm.svg';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { getCandidateDetails, interviewFeedBackL1, interviewFeedBackL2, interviewFeedbackL3 } from '../../../../../redux/features/interviewSchedule';
import { toast } from 'react-toastify';
import { truncateText } from '../../../../../helpers/truncateText';
import { fetchJobById } from "../../../../../redux/features/jobcardSlice";
import Autocomplete from "@mui/material/Autocomplete";
import {getRthSecondarySkillsData} from "../../../../../Crud/rth";

 
interface Skill {
  name: string;
  rating: number;
  experience: string; 
  technicalConcepts: string;
  developmentTools: string;
  logic: string;
  experienceEmoji: string;
  technicalConceptsEmoji: string;
  developmentToolsEmoji: string;
  logicEmoji: string;
  remarks:string;
}

interface SkillOption {
  label: string;
  value: string;
}

// interface JobData {
//   primarySkills?: string | string[] | null;
//   secondarySkills?: string | string[] | null;
// }


 
// interface nonTechSkill {
//   rating: string;
// }
 
interface Skills {
  "Communication Level": { rating: string };
  "Attention to Details": { rating: string };
  Integrity: { rating: string };
  Cooperation: { rating: string };
  "Stress Tolerance": { rating: string };
  "Learning Ability": { rating: string };
}
 
  export const InterviewEvaluationForm = () => {
  const [javaApiEvaluation, setJavaApiEvaluation] = useState('');
  // const [rating, setRating] = useState<any>(0);
  const [canChangeRating, setCanChangeRating] = useState<any>({}); // New state to control rating changes
  const [interviewScore, setInterviewScore] = useState<number | undefined>(undefined);
  const [recommendation, setRecommendation] = useState<string | undefined>(undefined);
  const [editedName, setEditedName] = useState<any>();
  const[nonTechnicalRemarks,setNonTechnicalRemarks]=useState<string>('');
  const[othersRemarks,setOthersRemarks]=useState<string>('');
  const [secondarySkillsData, setSecondarySkillsData] = useState([]);
  const [secondarySearch, setSecondarySearch] = useState("");
  const [secondaryPage, setSecondaryPage] = useState(0);
  const [loadingSecondary, setLoadingSecondary] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [sSkills, setSSkills] = useState<any>([]);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const PAGE_SIZE = 20;
  console.log('setSelectedSkills',setSelectedSkills)
  console.log('sSkills',sSkills)
  // const [finalRecommendationRating, setFinalRecommendationRating] = useState(0); // State for Final Recommendation
  // const [canChangeFinalRating, setCanChangeFinalRating] = useState(true); // State to control rating change
 
//  console.log('finalRecommendationRating', finalRecommendationRating)
  const Navigate = useNavigate();
 
  // New state for emojis
  const staticSkillNames = [
    "communicationLevel",
    "attentionToDetails",
    "integrity",
    "cooperation",
    "stressTolerance",
    "learningAbility",
    "remarks",
    "Remarks"
  ];  
  const [javaApiEmoji, setJavaApiEmoji] = useState('');
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [skillName, setSkillName] = React.useState('');
  const [techSkills,setTechSkills]=useState<Skill[]>([]);
  const [skills, setSkills] = useState({
    "Communication Level": { rating: "" },
    "Attention to Details": { rating: "" },
    "Integrity": { rating: "" },
    "Cooperation": { rating: "" },
    "Stress Tolerance": { rating: "" },
    "Learning Ability": { rating: "" }
  });
  const [ratings, setRatings] = useState<any>({});
  const [sectionType,setSectionType]=useState('');
  const [selectedButton, setSelectedButton] = useState(null);
  const [error, setError] = useState("");
  // const state=useAppSelector((state:any)=>state.resume.candidate);
  // const interview=useAppSelector((state:any)=>state.interviewSchedule.interviewDetailsByInterviewId);
  const{candidateId,interviewId,jobTitle, id}=useParams();
  const [loading, setLoading] = useState(true);
  console.log("loading",loading);
  const { jobData } = useAppSelector((state) => state.jobs);
  // const{candidateId,interviewId,jobTitle}=useParams();
  const { interviewRound } = useParams<{ interviewRound ?: string }>();
  const [selectedInterview, setSelectedInterview] = useState<any>(null);
  const dispatch=useAppDispatch();
  // const status=useAppSelector((state)=>state.interviewSchedule.status);
  // const navigate=useNavigate();
  const [editingIndex, setEditingIndex] = useState<number | null>(null); 
  const[editError,setEditError]=useState("");
  const[state,setState]=useState<any>([]);
  const[interview,setInterview]=useState<any>([]);
  const location=useLocation();
  const emailPath=location.pathname.includes('InterviewFeedBack');
  const candidateDetails=useAppSelector((state:any)=>state.interviewSchedule.candidateDetails);
   
  const getEmoji = (label: string) => {
    switch (label) {
      case 'Low':
        return '🙁'; // Sad face
      case 'Fair':
        return '☹️'; // Neutral face
      case 'Average':
        return '🙂'; // Relaxed face
      case 'Good':
        return '😀'; // Smiley face
      case 'Excellent':
        return '🤩'; // Heart eyes
      default:
        return '';
    }
  };


  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(fetchJobById(id)).finally(() => setLoading(false));
    }
  }, [dispatch, id]);

  console.log("newwwwww", jobData?.primarySkills
)

useEffect(() => {
  getRthSecondarySkillsData()
    .then((res) => {
      const data = res.data?.map((i: any) => {
        return { value: i.skillName, label: i.skillName };
      });
      setSecondarySkillsData(data);
    })
    .catch((err) => console.error(err));

}, []);

const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timer: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};



const fetchSecondarySkills = useCallback(
  async (search: string, page: number) => {
    if (loadingSecondary || (!hasMore && search !== "")) return;

  // setLoadingPrimary(true);
  try {
    const res = await getRthSecondarySkillsData(search, page, PAGE_SIZE);
    const newData = res.data?.map((i: any) => ({
      value: i.skillName,
      label: i.skillName
    }));

    if (newData?.length < PAGE_SIZE) {
      setHasMore(false);
    }

      setSecondarySkillsData((prevData) =>
        page === 0 ? newData : [...prevData, ...newData],
      );
    } catch (error) {
      console.error('Error fetching secondary skills:', error);
    } finally {
      setLoadingSecondary(false);
    }
  },
  [hasMore, loadingSecondary],
);

useEffect(() => {
  const resetAndFetch = () => {
    setSecondaryPage(0);
    setHasMore(true);

    if (secondarySearch === "") {
      setSecondarySkillsData([]); 
      fetchSecondarySkills("", 0); 
    } else {
      fetchSecondarySkills(secondarySearch, 0); 
    }
  };

  resetAndFetch();
}, [secondarySearch, fetchSecondarySkills]);



const handleSecondarySearchChange = debounce((value: string) => {
  setSecondarySearch(value.trim());
}, 300);


useEffect(() => {
  if (!jobData) return;

  // Helper function to handle both string and array inputs
  const normalizeSkills = (skills: string | string[] | null | undefined): string[] => {
    if (!skills) return [];
    if (Array.isArray(skills)) return skills;
    return skills.split(',').map(skill => skill.trim());
  };

  // Get skills arrays
  const primarySkillsArray = normalizeSkills(jobData.primarySkills);
  const secondarySkillsArray = normalizeSkills(jobData.secondarySkills);
  
  const combinedSkills = [...primarySkillsArray, ...secondarySkillsArray];
  
  if (combinedSkills.length > 0) {
    const formattedSkills: Skill[] = combinedSkills.map(skillName => ({
      name: skillName,
      rating: 0,
      experience: "",
      technicalConcepts: "",
      developmentTools: "",
      logic: "",
      experienceEmoji: "",
      technicalConceptsEmoji: "",
      developmentToolsEmoji: "",
      logicEmoji: "",
      remarks: ""
    }));

    setTechSkills(formattedSkills);
    
    const initialRatings: Record<string, number> = {};
    const initialCanChangeRating: Record<string, boolean> = {};
    combinedSkills.forEach(skill => {
      initialRatings[skill] = 0;
      initialCanChangeRating[skill] = true;
    });
    
    setRatings(initialRatings);
    setCanChangeRating(initialCanChangeRating);
  }
}, [jobData]);


const filteredSkillsData = useMemo(() => {
  if (!jobData) return secondarySkillsData;
  
  // Helper function to normalize skills
  const normalizeSkills = (skills: string | string[] | null | undefined): string[] => {
    if (!skills) return [];
    if (Array.isArray(skills)) return skills;
    return skills.split(',').map(skill => skill.trim());
  };

  // Get existing skills
  const existingPrimarySkills = normalizeSkills(jobData.primarySkills);
  const existingSecondarySkills = normalizeSkills(jobData.secondarySkills);
  const existingSkills = new Set([...existingPrimarySkills, ...existingSecondarySkills, ...selectedSkills]);

  // Filter out existing skills from options
  return secondarySkillsData.filter(
    (option: SkillOption) => !existingSkills.has(option.value)
  );
}, [jobData, secondarySkillsData, selectedSkills]);

 
  const handleNavigate = () =>{
    
    if(!emailPath){
    Navigate(-1);
    }
    if(emailPath){
      Navigate('/SubmittedFeedback');
    }
  }

 
  const handleButtonClickOthers = (buttonName:any, setEvaluation:any,  setEmoji: any) => {
    setEvaluation(buttonName);
    setEmoji(getEmoji(buttonName));
  };
 
 
  const handleResetSkill = (skill: any) => {
    setRatings({ ...ratings, [skill]: 0 }); // Reset rating for the specific skill
    setCanChangeRating({ ...canChangeRating, [skill]: true }); // Enable rating again
  };
 
  // Function to handle button click
  const handleButtonClick = (fieldName: string, value: string, index: number) => {
    setTechSkills((prevSkills) => {
      const updatedSkills = [...prevSkills];
      updatedSkills[index] = {
        ...updatedSkills[index],
        [fieldName]: value, // Update the specific field
        [`${fieldName}Emoji`]: getEmoji(value), // Get emoji using the getEmoji function
      };
      return updatedSkills;
    });
  };
 
  const handleRemarks = (value: string, index: number) => {
    // Define a regular expression to check for special characters
    
  
    // If no special characters are found, update the state
    setTechSkills((prevSkills) => {
      const updatedSkills = [...prevSkills];
      updatedSkills[index] = {
        ...updatedSkills[index],
        remarks: value,
      };
      return updatedSkills;
    });
  };
  
 
  const handleClickOpen = (type:string) => {
    setOpen(true);
    setSectionType(type);
  };

  const handleClickOpen1 = (type:string) => {
    setOpen1(true);
    setSectionType(type);
  };
  // const handleClickOpen = (type: string) => {
  //   if (type === 'Technical' && (jobData?.primarySkills || jobData?.secondarySkills)) {
  //     toast.info('Skills are automatically loaded from job requirements');
  //     return;
  //   }
  //   setOpen(true);
  //   setSectionType(type);
  // };
 
  const handleClose = () => {
    setOpen(false);
    setOpen1(false);
    setSkillName("");
  };
 
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input=event.target.value;
    setError("");
    const alphabetRegex = /^[a-zA-Z\s]*$/;
    const isDuplicate = techSkills.some(skill => skill?.name?.toLowerCase() === input?.toLowerCase());


    if(input.length <=100 && alphabetRegex.test(input)){
    setSkillName(event.target.value);
    setError("");
    }
    else if (!alphabetRegex.test(input)) {
      setError("Only alphabets are allowed");
    }
    else if (isDuplicate) {
      setError("Skill name already exists");
    } 
    else {
      setError("Maximum 100 characters are allowed");
    }
  };
 
  const validation = () => {
    const hasRatedNonTechnical = Object.keys(skills).some(skill => ratings[skill] > 0);
    const incompleteTechnicalSkill = techSkills?.some(skill => {
        return (
            skill?.experience ||
            skill?.technicalConcepts ||
            ratings[skill.name] === undefined || // Ensure rating is provided
            skill?.logic ||
            skill?.developmentTools
        );
    });

    console.log(techSkills,'dfghjk');
    
    const isTechnicalSkillValid = techSkills?.some(skill => {
        return (
            skill?.experience &&
            skill?.technicalConcepts &&
            ratings?.[skill.name] > 0 &&
            skill?.logic &&
            skill?.developmentTools
        );
    });
    const interviewDate = new Date(selectedInterview?.interviewDate);
    const currentDate = new Date();

    const interviewDateOnly = new Date(interviewDate.setHours(0, 0, 0, 0));
    const currentDateOnly = new Date(currentDate.setHours(0, 0, 0, 0));

     if (selectedInterview?.interviewDate) {    
      
      // Check if the interview date is in the past
      if (currentDateOnly < interviewDateOnly) {
          return 'Interview is not yet started.';
      }
  
      if (
        interviewDateOnly.getTime() === currentDateOnly.getTime() &&
        selectedInterview?.from
    ) {
        // Create a full datetime for the interview start time
        const [hours, minutes] = selectedInterview?.from?.split('T')[1].split(':').map(Number); // Extract hours and minutes
        const interviewStartTime = new Date(selectedInterview?.interviewDate); // Base it on the date
        interviewStartTime.setHours(hours, minutes); // Set the extracted time

        // Calculate 10 minutes after the start time
        const tenMinutesAfterStart = new Date(interviewStartTime.getTime() - 10 * 60 * 1000);
        const currentDateTime = new Date();     
        if (currentDateTime < tenMinutesAfterStart) {
                    
            return "The feedback form will be enabled only 10 minutes before the interview starts.";
        }
    }
  }

    if (!incompleteTechnicalSkill) {
        return 'Fill all the skill fields for technical skills.';
    }

    if (!hasRatedNonTechnical) {
        return 'Please provide a rating for at least one Non-Technical Skill.';
    }

    if(!isTechnicalSkillValid){
      return 'Please Fill all the fields of atleast one technical skills.';
    }
    if (!recommendation) {
        return 'Please select a recommendation.';
    }
    if(!selectedButton){
      return 'Please select Window Violation';
    }

    return ''; // No errors
};


const handleSubmit = () => {
  switch (sectionType) {
    case "non-technical":
      if (skillName) {
        // Create a new skill object
        const newSkill = { [skillName]: { rating: 0 } };

        // Update the state by merging the existing skills with the new skill
        setSkills((prevSkills) => ({
          ...prevSkills,
          ...newSkill,
        }));

        // Reset `skillName` and close the modal/dialog if applicable
        setSkillName("");
        handleClose();
      }
      break;

    case "Technical":
      // Handle multiple technical skills
      setSelectedSkills(prev => [...prev, ...sSkills]);
      if (sSkills && sSkills.length > 0) {
        const newTechnicalSkills = sSkills.map((skillName: any) => ({
          name: skillName,
          rating: 0,
          experience: "",
          technicalConcepts: "",
          developmentTools: "",
          logic: "",
          experienceEmoji: "",
          technicalConceptsEmoji: "",
          developmentToolsEmoji: "",
          logicEmoji: "",
          remarks: "",
        }));

        // Update technical skills
        setTechSkills((prevSkills) => [...prevSkills, ...newTechnicalSkills]);

        // Update ratings and canChangeRating for all new skills
        const newRatings = sSkills.reduce(
          (acc: { [x: string]: number }, skillName: string | number) => {
            acc[skillName] = 0;
            return acc;
          },
          {}
        );

        const newCanChangeRating = sSkills.reduce(
          (acc: { [x: string]: boolean }, skillName: string | number) => {
            acc[skillName] = true;
            return acc;
          },
          {}
        );

        setRatings((prevRatings: any) => ({
          ...prevRatings,
          ...newRatings,
        }));

        setCanChangeRating((prevCanChange: any) => ({
          ...prevCanChange,
          ...newCanChangeRating,
        }));

        // Reset selected skills and close dialog
        setSSkills([]);
        handleClose();
      }
      break;
  }
  
};

 
  const handleRatingChange = (skill: any, newValue: any) => {
    if (canChangeRating[skill]) { // Allow change only if canChangeRating is true for the skill
      setRatings({ ...ratings, [skill]: newValue });
      setCanChangeRating({ ...canChangeRating, [skill]: false }); // Disable further changes
    }
  };
 
  const handleRatingChangeNonTechnical = (skill: any, newValue: any) => {
    setRatings({ ...ratings, [skill]: newValue });
    setCanChangeRating({ ...canChangeRating, [skill]: false }); // Mark as rated
    setSkills((prevSkills) => ({
      ...prevSkills,
      [skill]: { rating: newValue },
    }));
  };
 
  const handleClick = (button:any) => {
    setSelectedButton(button);
  };
 
  const toCamelCase = (str: string) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
        index === 0 ? match.toLowerCase() : match.toUpperCase()
      )
      .replace(/\s+/g, '');
  };
 
  const hanldeFormSubmit = () => {
    // Ensure type safety by asserting the type of skillKey as keyof Skills
    const updatedNonTechSkills = Object.keys(skills).reduce((acc, skillKey) => {
      const key = skillKey as keyof Skills;
   
      const camelCaseKey = toCamelCase(key);
      acc[camelCaseKey] = String(skills[key].rating); 
   
      return acc;
    }, {} as { [key: string]: string });

    const staticSkills: { [key: string]: string } = {};
    const dynamicSkills: { [key: string]: string } = {};

    Object.keys(updatedNonTechSkills).forEach(skill => {
      if (staticSkillNames.includes(skill)) {
        staticSkills[skill] = updatedNonTechSkills[skill]; // Static skills remain as is
      } else {
        dynamicSkills[skill] = updatedNonTechSkills[skill]; // Dynamic skills go into the newSkills section
      }
    });
    
    const updatedTechSkills = techSkills.map(skill => ({
      technologyName:skill.name,
      rating: ratings[skill?.name],
      experience:skill?.experience,
      technicalConcepts:skill?.technicalConcepts,
      logics:skill?.logic,
      developmentTools:skill?.developmentTools,
      remarks:skill?.remarks
    }));
    const nonTechnicalEvaluationList = [
      {
        communicationLevel: staticSkills.communicationLevel || 'string',
        attentionToDetails: staticSkills.attentionToDetails || 'string',
        integrity: staticSkills.integrity || 'string',
        coOperation: staticSkills.cooperation || 'string',
        stressTolerance: staticSkills.stressTolerance || 'string',
        learningAbility: staticSkills.learningAbility || 'string',
        remarks:nonTechnicalRemarks||'',
        newSkills:[dynamicSkills],
      }
    ];
 
    // Prepare Technical Skills List
    const technicalEvalutionList = updatedTechSkills.map(skill => ({
      technologyName: skill.technologyName || 'string',
      rating: skill.rating || 'string',
      experience: skill.experience || 'string',
      technicalConcepts: skill.technicalConcepts || 'string',
      logics: skill.logics || 'string',
      developmentTools: skill.developmentTools || 'string',
      remarks:skill?.remarks||' ',
    }));
 
    const otherSkills = {
      windowViolation: String(selectedButton || 'string'),
      interviewerComments: String(javaApiEvaluation || 'string'),
      interviewScore: String(interviewScore || 'string'),
      recommended: String(recommendation || 'string'),
      remarks:othersRemarks||''
    };
 
   
  const payload={
    technicalEvalutionList,
    nonTechnicalEvaluationList,
    ...otherSkills
  }
 
 
  const errorMessage = validation();
    if (errorMessage) {
        toast.error(errorMessage);
    } 
    
    else {
       if(interviewRound==='interviewDetL1'){
       dispatch(interviewFeedBackL1({interviewId:interviewId,data:payload}));
        
        // if(interviewFeedBackL1.fulfilled.match(response)){
          // const{status}=response.payload||{};
            handleNavigate();
        // }
         
        }
        else if(interviewRound==='interviewDetL2'){
         dispatch(interviewFeedBackL2({interviewId:interviewId,data:payload}));
          // if(interviewFeedBackL2.fulfilled.match(response)){
            // const{status}=response.payload||{};
             handleNavigate();
          // }
        }
        else if(interviewRound==='interviewDetL3'){
        dispatch(interviewFeedbackL3({interviewId:interviewId,data:payload}));
        //  if(interviewFeedbackL3.fulfilled.match(response)){
          // const{status}=response.payload||{};
          // if(status===true){
            handleNavigate();
          // }
        //  }
        }
           
      }
      };
 
 
  const formatTimeRange = (from: string, to: string) => {
    const formatTime = (time: string) => {
      const date = new Date(time);
      const hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const period = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
      return `${formattedHours}:${minutes}${period}`;
    };
 
    return `${formatTime(from)} To ${formatTime(to)}`;
  };
 
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
   
    // Get the day, month, and year
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits for day
    const month = date.toLocaleString('default', { month: 'short' }); // Get abbreviated month
    const year = date.getFullYear();
 
    return `${day} ${month} ${year}`;
  };

  const handleEditClick = (index:number,name:string) => {    
    setEditError('');
    setEditingIndex(index); 
    setEditedName(name);
  };

  const handleEditChange=(editedName:any)=>{
    const alphabetRegex = /^[a-zA-Z\s]*$/;
    const isDuplicate = techSkills.some(skill => skill.name.toLowerCase() === editedName.toLowerCase());

    setEditError('');
    // if(!alphabetRegex.test(editedName)){
    //   setEditError("Only alphabets are allowed");
    // }
    if(editedName.length===0){
      setEditedName(editedName);
      setEditError("Name should not be empty");
    }
    else if(editedName.length>100){
      setEditError("Maximum of 100 characters allowed");
    }
    else if(!alphabetRegex.test(editedName)){
      setEditError("Only alphabets are allowed");
    }
    else if (isDuplicate) {
      setEditedName(editedName);
      setEditError("Skill name already exists");
    } 
    else{
      setEditedName(editedName);
    }
  }
  const handleSaveClick = (editedName:any,skill: any, index: any,rating:any) => {
        // setSkillName(editedName);
        const alphabetRegex = /^[a-zA-Z\s]*$/;
        if(editedName.length===0){
          setEditError("Name should not be empty");
        }
        else if(editedName.length>100){
          setEditError("Maximum of 100 characters allowed");
        }
        else if(!alphabetRegex.test(editedName)){
          setEditError("Only alphabets are allowed");
        }
        else{
       setTechSkills((prevSkills) => {
      const EditedSkills = [...prevSkills];
      if (EditedSkills[index]) {
        EditedSkills[index] = { ...EditedSkills[index], name: editedName, 
        };
        setRatings({ ...ratings, [editedName]:rating });
      }
      return EditedSkills; // Ensure the updated state is returned
    });

  }
    setEditingIndex(null);
  };
 
 
  const handleCancelClick = () => {
    setEditingIndex(null);
  };

   useEffect(()=>{
    dispatch(getCandidateDetails(candidateId));
  },[candidateId,interviewId,dispatch])
 
  
  useEffect(() => {
   
    const selectedInterview = interviewRound && interview ? interview[interviewRound] : null;
    setSelectedInterview(selectedInterview);
  }, [interview, state,interviewRound]);

  useEffect(()=>{
    if(candidateDetails){
      setState(candidateDetails);
      setInterview(candidateDetails?.interview);
    }
  },[candidateDetails])
 
    return (
      <>
        <Box
          sx={{
            backgroundImage: `url(${BackGroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            overflowY: "auto",
            width: "100vw",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              mt: "50px",
            }}
          >
            {!emailPath && (
              <KeyboardBackspaceRoundedIcon
                onClick={handleNavigate}
                sx={{
                  position: "fixed",
                  left: "30px",
                  top: "30px",
                  cursor: "pointer",
                  transition: "transform 0.2s ease-in-out",
                  "&:hover": { transform: "scale(1.1)" },
                }}
              />
            )}
            {/* Header Section */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={InterviewEvaluationFormLogo}
                style={{ width: "40px" }}
                alt="InterviewEvaluationFormLogo"
              />
              <Typography
                sx={{ fontSize: "26px", fontWeight: 700, mt: "30px" }}
              >
                {jobTitle}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#919EAB",
                  fontWeight: 500,
                  mt: "12px",
                }}
              >
                Interview Evaluation Form
              </Typography>
            </Box>

            {/* Candidate & Interview Details Section */}
            <Paper
              sx={{
                width: "840px",
                mt: "40px",
                p: 3,
                mb: 4,
                boxShadow: "none",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ ml: "30px", mr: "60px" }}>
                    <Typography sx={{ color: "#919EAB", mb: "10px" }}>
                      Candidate Name
                    </Typography>
                    <Typography sx={{ color: "#919EAB", mb: "10px" }}>
                      Interviewer Name
                    </Typography>
                    <Typography sx={{ color: "#919EAB", mb: "10px" }}>
                      Job Title
                    </Typography>
                    <Typography sx={{ color: "#919EAB", mb: "10px" }}>
                      Interview Round
                    </Typography>
                  </Box>

                  <Box>
                    <Tooltip
                      title={`${state?.firstName||''} ${state?.lastName||''}`}
                      disableHoverListener={
                        `${state?.firstName||''} ${state?.lastName||''}`.length <= 14
                      }
                    >
                      <Typography sx={{ mb: "10px" }}>
                        {`${state?.firstName||''} ${state?.lastName||''}`.length > 14
                          ? `${state?.firstName||''} ${state?.lastName||''}`.substring(
                              0,
                              14
                            ) + "..."
                          : `${state?.firstName||''} ${state?.lastName||''}`}
                      </Typography>
                    </Tooltip>

                    <Tooltip
                      title={
                        selectedInterview?.interviewer?.userName ||
                        "Not Available"
                      }
                      disableHoverListener={
                        (
                          selectedInterview?.interviewer?.userName ||
                          "Not Available"
                        ).length <= 14
                      }
                    >
                      <Typography sx={{ mb: "10px" }}>
                        {(
                          selectedInterview?.interviewer?.userName ||
                          "Not Available"
                        ).length > 14
                          ? (
                              selectedInterview?.interviewer?.userName ||
                              "Not Available"
                            ).substring(0, 14) + "..."
                          : selectedInterview?.interviewer?.userName ||
                            "Not Available"}
                      </Typography>
                    </Tooltip>

                    <Tooltip
                      title={jobTitle || "N/A"}
                      disableHoverListener={(jobTitle || "N/A").length <= 14}
                    >
                      <Typography sx={{ mb: "10px" }}>
                        {(jobTitle || "N/A").length > 14
                          ? (jobTitle || "N/A").substring(0, 14) + "..."
                          : jobTitle || "N/A"}
                      </Typography>
                    </Tooltip>

                    <Tooltip
                      title={selectedInterview?.interviewRound || "N/A"}
                      disableHoverListener={
                        (selectedInterview?.interviewRound || "N/A").length <=
                        14
                      }
                    >
                      <Typography sx={{ mb: "10px" }}>
                        {(selectedInterview?.interviewRound || "N/A").length >
                        14
                          ? (
                              selectedInterview?.interviewRound || "N/A"
                            ).substring(0, 14) + "..."
                          : selectedInterview?.interviewRound || "N/A"}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ mr: "60px" }}>
                    <Typography sx={{ color: "#919EAB", mb: "10px" }}>
                      Mode of Interview
                    </Typography>
                    <Typography sx={{ color: "#919EAB", mb: "10px" }}>
                      Candidate Email ID
                    </Typography>
                    <Typography sx={{ color: "#919EAB", mb: "10px" }}>
                      Interview Date
                    </Typography>
                    <Typography sx={{ color: "#919EAB", mb: "10px" }}>
                      Interview Time
                    </Typography>
                  </Box>

                  <Box sx={{ mr: "30px" }}>
                    <Tooltip
                      title={selectedInterview?.interviewMode || "N/A"}
                      disableHoverListener={
                        (selectedInterview?.interviewMode || "N/A").length <= 14
                      }
                    >
                      <Typography sx={{ mb: "10px" }}>
                        {(selectedInterview?.interviewMode || "N/A").length > 14
                          ? (
                              selectedInterview?.interviewMode || "N/A"
                            ).substring(0, 14) + "..."
                          : selectedInterview?.interviewMode || "N/A"}
                      </Typography>
                    </Tooltip>

                    <Tooltip
                      title={state?.emailAddress || "Null"}
                      disableHoverListener={
                        (state?.emailAddress || "Null").length <= 14
                      }
                    >
                      <Typography sx={{ mb: "10px" }}>
                        {(state?.emailAddress || "Null").length > 14
                          ? (state?.emailAddress || "Null").substring(0, 14) +
                            "..."
                          : state?.emailAddress || "Null"}
                      </Typography>
                    </Tooltip>

                    <Tooltip
                      title={
                        formatDate(selectedInterview?.interviewDate) || "N/A"
                      }
                      disableHoverListener={
                        (formatDate(selectedInterview?.interviewDate) || "N/A")
                          .length <= 14
                      }
                    >
                      <Typography sx={{ mb: "10px" }}>
                        {(formatDate(selectedInterview?.interviewDate) || "N/A")
                          .length > 14
                          ? (
                              formatDate(selectedInterview?.interviewDate) ||
                              "N/A"
                            ).substring(0, 14) + "..."
                          : formatDate(selectedInterview?.interviewDate) ||
                            "N/A"}
                      </Typography>
                    </Tooltip>

            <Tooltip 
              title={formatTimeRange(selectedInterview?.from, selectedInterview?.to) || 'N/A'} 
              disableHoverListener={(formatTimeRange(selectedInterview?.from, selectedInterview?.to) || 'N/A').length <= 14}
            >
              <Typography sx={{ mb: '10px' }}>
                {(formatTimeRange(selectedInterview?.from, selectedInterview?.to) || 'N/A').length > 14 
                  ? (formatTimeRange(selectedInterview?.from, selectedInterview?.to) || 'N/A').substring(0, 14) + '...' 
                  : formatTimeRange(selectedInterview?.from, selectedInterview?.to) || 'N/A'}
              </Typography>
            </Tooltip>
            </Box>
          </Box>
        </Box>
      </Paper>
 
      {/* Technical Evaluation Section */}
      <Paper sx={{ width: '840px', p: 3, pb:0, boxShadow:'none' }}>
        <Box sx={{display:'flex', flexDirection:"row", justifyContent:'space-between', alignItems:'center', mb:'40px', position:'sticky', top:0, backgroundColor: 'white', zIndex: 1}}>
          <Box>
            <Typography sx={{ fontSize: '18px', fontWeight: 600, mb: '6px' }}>Technical Evaluation (Skills)</Typography>
            <Typography sx={{ fontSize: '14px', color: '#5C6F82'}}>
              Please Evaluate the candidate on the following on a scale of 1 to 5 (1 lowest, 5 highest)
            </Typography>
          </Box>
          <Typography onClick={()=>handleClickOpen('Technical')} sx={{ fontSize: '14px', fontWeight: 600, mb: '6px', color:'#1677FF',cursor:'pointer' }}>+ New Skills</Typography>
        </Box>
        </Paper>
        {/* Skill 1: Java API */}
        {techSkills?.map((skill:any, index:any) => {
            return(
          <Paper sx={{ width: '840px', p: 3, pb:0, boxShadow:'none',mb:'10px' }}>
        <Box sx={{ mb: 4, mt: 3 }}>
        {editingIndex === index ? (
        <TextField
          value={editedName}
          fullWidth
          onChange={(e) => handleEditChange(e.target.value)}
          error={!!editError}
          helperText={editError}
          sx={{ fontSize: '16px', fontWeight: 600, mb: "10px" }}
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <Box sx={{display:'flex', flexDirection:'row'}}>
                <IconButton onClick={()=>handleSaveClick(editedName,skill, index,ratings[skill.name])}>
                  <CheckIcon />
                </IconButton>
                <IconButton onClick={handleCancelClick}>
                  <CloseIcon />
                </IconButton>
              </Box>
            ),
          }}
        />
      ) : (
        <>
        <Box sx={{display:'flex', flexDirection:'row'}}>
        <Tooltip 
  title={skill?.name} 
  disableHoverListener={skill?.name?.length <= 20} 
  arrow
>
  <Typography sx={{ fontSize: '16px', fontWeight: 600, mb: "10px" }}>
    {index + 1}) {truncateText(skill?.name, 20)}
  </Typography>
</Tooltip>
          <IconButton sx={{ p:0, mb:'10px', ml:'10px'}} onClick={()=>handleEditClick(index,skill?.name)}>
            <EditIcon sx={{fontSize:'14px'}} />
          </IconButton>
          </Box>
        </>
      )}
          <Box sx={{ display: 'flex', alignItems: 'center', borderBottom:'1px dashed #DBDBDB', pb:'10px'}}>
          <Typography variant="body2" sx={{ textWrap: 'nowrap'}}>Rating</Typography>
          <Rating
            name={`${skill.name}-rating`}
            value={ratings[skill.name]}
            onChange={(event, newValue) => handleRatingChange(skill.name, newValue)}
            readOnly={!canChangeRating[skill.name]} // Set readOnly based on canChangeRating
            precision={1}
            sx={{ ml: '120px' }}
            icon={<StarIcon />}
          />
          <Typography sx={{ ml: 1,  color:"#DBDBDB" }}><span style={{color:ratings[skill.name]?"#EBD400":''}}>{ratings[skill.name]}</span>/5</Typography>
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleResetSkill(skill.name)}
            disabled={ratings[skill.name] === 0} // Disable button if the rating is 0
            sx={{
              ml: 2,
              borderRadius: '40px',
              fontSize: '10px',
              color: ratings[skill.name] > 0 ? 'black' : '#DBDBDB',
              textTransform: 'none',
              border: '1px solid #DBDBDB',
              ":hover": {
                border: '1px solid #DBDBDB',
                color: 'black',
              },
              ...(ratings[skill.name] === 0 && { 
                cursor: 'not-allowed', 
                color: '#DBDBDB' // Dim color when disabled 
              }),
            }}
            startIcon={<CachedIcon />}
          >
            Reset
          </Button>
        </Box>
 
 
        {/* Criteria Evaluation */}
        <Box sx={{display:'flex', borderBottom:'1px dashed #DBDBDB', pb:'10px', pt:'10px'}}>
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%' }}>    
        <Box sx={{display:'flex', flexDirection:'row', }}>
        <Typography variant="body2" sx={{textWrap:'nowrap', display:'flex', alignItems:'center'}}>Experience</Typography>
        <Box sx={{ display: 'flex', gap: '10px', ml:'94.5px' }}>
          {['Low', 'Fair', 'Average', 'Good', 'Excellent'].map((label) => (
            <Button
              key={label}
              variant="outlined"
              size="small"
              sx={{                      
                color: skill.experience === label ? '#2F54EB' : '#919EAB',
                borderColor: skill.experience === label ? '#2F54EB' : '#919EAB',
                textTransform:'none',
                ":hover":{ borderColor: skill.experience === label ? '#2F54EB' : '#919EAB', color: skill.experience === label ? '#2F54EB' : '#919EAB' }
              }}
              onClick={() => handleButtonClick('experience',label, index)}
            >
              {label}
            </Button>
          ))}
          </Box>
        </Box>
        <Box sx={{mr:'20px', display:'flex', alignItems:'center'}}>
        <Typography sx={{ fontSize: '20px', ml: '20px' }}>{skill.experienceEmoji}</Typography>
        </Box>
        </Box>
      </Box>
 
      {/* Technical Concepts */}
      <Box sx={{display:'flex', borderBottom:'1px dashed #DBDBDB', pb:'10px', pt:'10px'}}>
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
          <Box sx={{display:'flex', flexDirection:'row'}}>
            <Typography variant="body2" sx={{textWrap:'nowrap', display:'flex', alignItems:'center'}}>Technical Concepts</Typography>
            <Box sx={{ display: 'flex', gap: '10px', ml:'39px' }}>
              {['Low', 'Fair', 'Average', 'Good', 'Excellent'].map((label) => (
                <Button
                  key={label}
                  variant="outlined"
                  size="small"
                  sx={{                      
                    color: skill.technicalConcepts=== label ? '#2F54EB' : '#919EAB',
                    borderColor: skill.technicalConcepts === label ? '#2F54EB' : '#919EAB',
                    textTransform:'none',
                    ":hover": { borderColor: skill.technicalConcepts === label ? '#2F54EB' : '#919EAB', color: skill.technicalConcepts === label ? '#2F54EB' : '#919EAB' }
                  }}
                  onClick={() => handleButtonClick('technicalConcepts',label,index)}
                >
                  {label}
                </Button>
              ))}
            </Box>
          </Box>
          <Box sx={{mr:'20px', display:'flex', alignItems:'center'}}>
          <Typography sx={{ fontSize: '20px', ml: '20px' }}>{skill.technicalConceptsEmoji}</Typography>
          </Box>
        </Box>
      </Box>
 
      {/* Logic */}
      <Box sx={{display:'flex', borderBottom:'1px dashed #DBDBDB', pb:'10px', pt:'10px'}}>
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
          <Box sx={{display:'flex', flexDirection:'row'}}>
            <Typography variant="body2" sx={{textWrap:'nowrap', display:'flex', alignItems:'center'}}>Logic</Typography>
            <Box sx={{ display: 'flex', gap: '10px', ml:'129px' }}>
              {['Low', 'Fair', 'Average', 'Good', 'Excellent'].map((label) => (
                <Button
                  key={label}
                  variant="outlined"
                  size="small"
                  sx={{                      
                    color: skill.logic === label ? '#2F54EB' : '#919EAB',
                    borderColor: skill.logic === label ? '#2F54EB' : '#919EAB',
                    textTransform:'none',
                    ":hover": { borderColor: skill.logic === label ? '#2F54EB' : '#919EAB', color: skill.logic === label ? '#2F54EB' : '#919EAB' }
                  }}
                  onClick={() => handleButtonClick('logic',label,index)}
                >
                  {label}
                </Button>
              ))}
            </Box>
          </Box>
          <Box sx={{mr:'20px', display:'flex', alignItems:'center'}}>
          <Typography sx={{ fontSize: '20px', ml: '20px' }}>{skill.logicEmoji}</Typography>
          </Box>
        </Box>
      </Box>
 
      {/* Development Tools */}
      <Box sx={{display:'flex', borderBottom:'1px dashed #DBDBDB', pb:'10px', pt:'10px'}}>
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
          <Box sx={{display:'flex', flexDirection:'row'}}>
            <Typography variant="body2" sx={{textWrap:'nowrap', display:'flex', alignItems:'center'}}>Development Tools</Typography>
            <Box sx={{ display: 'flex', gap: '10px', ml:'42px' }}>
              {['Low', 'Fair', 'Average', 'Good', 'Excellent'].map((label) => (
                <Button
                  key={label}
                  variant="outlined"
                  size="small"
                  sx={{                      
                    color: skill.developmentTools === label ? '#2F54EB' : '#919EAB',
                    borderColor: skill.developmentTools === label ? '#2F54EB' : '#919EAB',
                    textTransform:'none',
                    ":hover": { borderColor: skill.developmentTools === label ? '#2F54EB' : '#919EAB', color: skill.developmentTools === label ? '#2F54EB' : '#919EAB' }
                  }}
                  onClick={() => handleButtonClick('developmentTools',label, index)}
                >
                  {label}
                </Button>
              ))}
            </Box>
          </Box>
          <Box sx={{mr:'20px', display:'flex', alignItems:'center'}}>
          <Typography sx={{ fontSize: '20px', ml: '20px' }}>{skill.developmentToolsEmoji}</Typography>
          </Box>
        </Box>
      </Box>
 
      <Box sx={{display:'flex',flexDirection:'row'}}>
      <Typography variant="body2" sx={{ minWidth:'180px', textWrap: 'nowrap', pt:'10px', pb:'10px',mt:'14px'}}>
            Remarks 
          </Typography> 
      <TextField fullWidth value={skill.remarks} onChange={(e)=>handleRemarks(e.target.value,index)} sx={{mt:'10px'}} />
      </Box>

        </Box>
        </Paper>
        )})}
     
     
     {/* Non-Technical */}
      <Paper sx={{ width: '840px', mt: '40px', p: 3, pb:0, mb: 4, boxShadow:'none' }}>
      <Box sx={{display:'flex', flexDirection:"row", justifyContent:'space-between', alignItems:'center', mb:'40px', position:'sticky', top:0, backgroundColor: 'white', zIndex: 1}}>
          <Box>
            <Typography sx={{ fontSize: '18px', fontWeight: 600, mb: '6px' }}>Non-Technical Evaluation</Typography>
            <Typography sx={{ fontSize: '14px', color: '#5C6F82'}}>
            Please Evaluate the candidate on the following on a scale of 1 to 5 (1 lowest, 5 highest)
            </Typography>
          </Box>
          <Typography onClick={()=>handleClickOpen1('non-technical')} sx={{ fontSize: '14px', fontWeight: 600, mb: '6px', color:'#1677FF',cursor:'pointer' }}>+ New Skills</Typography>
      </Box>
       
      {Object.keys(skills).map((skill: string, index: number) => (
        <Box sx={{ display: 'flex', alignItems: 'center', borderBottom:'1px dashed #DBDBDB', pb:'10px'}} key={index}>
          <Typography variant="body2" sx={{ minWidth:'180px', textWrap: 'nowrap', pt:'10px', pb:'10px'}}>
            {skill} {/* Display the skill name */}
          </Typography>
          <Rating
            name={`${skill}-rating`}
            value={ratings[skill] || 0}
            onChange={(event, newValue) => handleRatingChangeNonTechnical(skill, newValue)}
            readOnly={ratings[skill] > 0 && !canChangeRating[skill]} // Allow rating initially or after reset
            precision={1}
            icon={<StarIcon />}
          />
          <Typography sx={{ ml: 1,  color:"#DBDBDB" }}><span style={{color:ratings[skill]?"#EBD400":''}}>{ratings[skill] || 0}</span>/5</Typography>
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleResetSkill(skill)}
            disabled={!ratings[skill] || ratings[skill] === 0} // Enable only if rating > 0
            sx={{
              ml: 2,
              borderRadius: '40px',
              fontSize: '10px',
              color: ratings[skill] > 0 ? 'black' : '#DBDBDB',
              textTransform: 'none',
              border: '1px solid #DBDBDB',
              ":hover": {
                border: '1px solid #DBDBDB',
                color: 'black',
              },
              ...(ratings[skill] === 0 && {
                cursor: 'not-allowed',
                color: '#DBDBDB',
              }),
            }}
            startIcon={<CachedIcon />}
          >
            Reset
          </Button>
        </Box>
      ))}
      <Box sx={{display:'flex',flexDirection:'row',height:'100px'}}>
      <Typography variant="body2" sx={{ minWidth:'180px', textWrap: 'nowrap', pt:'10px', pb:'10px'}}>
            Remarks 
          </Typography> 
      <TextField fullWidth value={nonTechnicalRemarks} 
      onChange={(e) => {
        const value = e.target.value;
        // Define a regular expression to check for special characters
        const specialCharacterPattern = /[^a-zA-Z0-9\s,./\-()&!?]/;
       
        // If special characters are found, do not update the state
        if (!specialCharacterPattern.test(value)) {
          setNonTechnicalRemarks(value); // Update the state if valid
        } else {
          toast.error('Special characters are not allowed.'); // Optionally show a message
        }
      }}
       sx={{mt:'10px'}}/>
      </Box>
      </Paper>  
 
      {/* Others */}
 
      <Paper sx={{ width: '840px', p: 3, boxShadow:'none' }}>
       
       <Box sx={{display:'flex', flexDirection:"row", justifyContent:'space-between', alignItems:'center', position:'sticky', top:0, backgroundColor: 'white', zIndex: 1}}>
       <Box>
       <Typography sx={{ fontSize: '18px', fontWeight: 600, mb: '6px' }}>Others </Typography>
       <Typography sx={{ fontSize: '14px', color: '#5C6F82'}}>
       Please Evaluate the candidate on the following on a scale of 1 to 5 (1 lowest, 5 highest)
       </Typography>
       </Box>
       {/* <Typography onClick={()=>handleClickOpen('non-technical')} sx={{ fontSize: '14px', fontWeight: 600, mb: '6px', color:'#1677FF',cursor:'pointer' }}>+ New Skills</Typography> */}
       </Box>
 
       <Box sx={{mt:'20px'}}>
       <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
       <Typography sx={{fontSize:'14px'}}>Window Violation</Typography>
       <Box>
       <Button variant='outlined' sx={{ml:'60px', textTransform:'none', height:'30px', color: selectedButton === 'yes' ? '#2F54EB' : '#919EAB', borderColor: selectedButton === 'yes' ? '#2F54EB' : '#919EAB', ":hover":{ color:'#2b67ba', border:'1px solid #2b67ba' }}} onClick={() => handleClick('yes')}>Yes</Button>
       <Button variant='outlined' sx={{ml:'10px', textTransform:'none', height:'30px', color: selectedButton === 'no' ? '#2F54EB' : '#919EAB', borderColor: selectedButton === 'no' ? '#2F54EB' : '#919EAB', ":hover":{ color:'#2b67ba', border:'1px solid #2b67ba' }}} onClick={() => handleClick('no')}>No</Button>
       </Box>
       </Box>
       <Box sx={{display:'flex',flexDirection:'row',mt:'20px'}}>
      <Typography variant="body2" sx={{ minWidth:'180px', textWrap: 'nowrap', pt:'10px', pb:'10px'}}>
            Remarks 
          </Typography> 
      <TextField  fullWidth value={othersRemarks} 
      onChange={(e) => {
        const value = e.target.value;
        const specialCharacterPattern = /[^a-zA-Z0-9\s,./\-()&!?]/;
        if (!specialCharacterPattern.test(value)) {
          setOthersRemarks(value);
        } else {
          toast.error('Special characters are not allowed.'); 
        }
      }}
       sx={{width:'40vw'}} />
      </Box>
 
       <Box>
       <Box sx={{display:'flex', flexDirection:'row', mt:'20px'}}>
       <Typography sx={{fontSize:'14px',fontWeight:'500'}}>Final Recommendation</Typography>
  
       </Box>
       </Box>
 
       <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%', mt:'20px' }}>    
       <Box sx={{display:'flex', flexDirection:'row', }}>
       <Typography variant="body2" sx={{textWrap:'nowrap', display:'flex', alignItems:'center'}}>Interviewer Comments</Typography>
       <Box sx={{ display: 'flex', gap: '10px', ml:'26.5px' }}>
         {['Low', 'Fair', 'Average', 'Good', 'Excellent'].map((label) => (
           <Button
             key={label}
             variant="outlined"
             size="small"
             sx={{                      
               color: javaApiEvaluation === label ? '#2F54EB' : '#919EAB',
               borderColor: javaApiEvaluation === label ? '#2F54EB' : '#919EAB',
               textTransform:'none',
               ":hover":{ borderColor: javaApiEvaluation === label ? '#2F54EB' : '#919EAB', color: javaApiEvaluation === label ? '#2F54EB' : '#919EAB' }
             }}
             onClick={() => handleButtonClickOthers(label, setJavaApiEvaluation, setJavaApiEmoji)}
           >
             {label}
           </Button>
         ))}
         </Box>
       </Box>
       <Box sx={{mr:'20px', display:'flex', alignItems:'center'}}>
       <Typography sx={{ fontSize: '20px', ml: '20px' }}>{javaApiEmoji}</Typography>
       </Box>
       </Box>
       </Box>  
 
       <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', mt:'20px'}}>  
       <Typography sx={{fontSize:'14px'}}>Interview Score</Typography>  
       <Box sx={{ display: 'flex', gap: '10px', ml: '73px' }}>
       {[...Array(10).keys()].map((score) => (
         <Button
           key={score + 1}
           variant="outlined"
           size="small"
           sx={{
             width: '30px',    // Set width to 15px
             height: '30px',   // Set height to 15px
             p: 0,             // Remove padding
             minWidth: '35px', // Ensure the button maintains the width
             fontSize: '10px', // Set font size to fit the number inside the 15px size
             lineHeight: '1',  // Adjust line height to ensure the text is centered
             color: (interviewScore ?? 0) >= score + 1 ? '#059212' : '#919EAB',  // Default to 0 if undefined
             borderColor: (interviewScore ?? 0) >= score + 1 ? '#059212' : '#919EAB',
             bgcolor:  (interviewScore ?? 0) >= score + 1 ? '#D8DBBD' : '#none',              
             textTransform: 'none',
             ":hover": { borderColor: (interviewScore ?? 0) >= score + 1 ? '#059212' : '#919EAB', color: (interviewScore ?? 0) >= score + 1 ? '#059212' : '#919EAB' }
           }}
           onClick={() => setInterviewScore(score + 1)}
         >
           {score + 1}
         </Button>
       ))}
       </Box>
       </Box>
 
     {/* Recommendation */}
       <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '20px' }}>
       <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>Recommended</Typography>
       <Box sx={{ display: 'flex', gap: '10px', ml: '74.5px' }}>
         {['Hire', 'Move to Next Round', 'Keep in Consideration', 'Needs Improvement', 'Do Not Hire'].map((label) => (
           <Button
             key={label}
             variant="outlined"
             size="small"
             sx={{
               color: recommendation === label ? '#2F54EB' : '#919EAB',
               borderColor: recommendation === label ? '#2F54EB' : '#919EAB',
               textTransform: 'none',
               ":hover": { borderColor: recommendation === label ? '#2F54EB' : '#919EAB', color: recommendation === label ? '#2F54EB' : '#919EAB' }
             }}
             onClick={() => setRecommendation(label)}
           >
             {label}
           </Button>
         ))}
       </Box>
       </Box>
       </Paper>
     
 
        <Box sx={{display:'flex', justifyContent:'flex-end', mt:'20px',  width: '890px', pb: 5}}>
        <Button variant='contained' sx={{textTransform:'none'}} onClick={hanldeFormSubmit}>Submit Form</Button>
        </Box>      
        </Box>
        </Box>

        <Dialog open={open}>
          <DialogTitle sx={{ width: "20vw" }}>
            Add New Skill
            <IconButton
              onClick={handleClose}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              "&.MuiDialogContent-root": {
                paddingTop: "7px !important",
              },
            }}
          >
            <Autocomplete<SkillOption, true>
              autoFocus
              multiple
              limitTags={1}
              options={filteredSkillsData}
              getOptionLabel={(option: SkillOption) => option.label}
              onInputChange={(event, value) =>
                handleSecondarySearchChange(value)
              }
              onChange={(event, newValue) => {
                const sData = newValue.map((i) => i.value);
                setSSkills(sData);
              }}
              ListboxProps={{
                onScroll: (event) => {
                  const listboxNode = event.currentTarget;
                  if (
                    listboxNode.scrollTop + listboxNode.clientHeight >=
                      listboxNode.scrollHeight - 50 &&
                    !loadingSecondary &&
                    hasMore
                  ) {
                    const nextPage = secondaryPage + 1;
                    setSecondaryPage(nextPage);
                    fetchSecondarySkills(secondarySearch, nextPage);
                  }
                },
                style: { maxHeight: "200px", overflow: "auto" },
              }}
              sx={{
                "& .MuiInputBase-root": { height: "40px", overflow: "hidden" },
                "& .MuiOutlinedInput-root": { padding: "2px 8px !important" },
                "& .MuiAutocomplete-tag": {
                  margin: "2px",
                  height: "18px",
                  maxWidth: "calc(60% - 4px)",
                },
                "& .MuiChip-root": { height: "20px" },
                maxWidth: 500,
                "& .MuiAutocomplete-option": { fontSize: "0.8rem" },
                "& .MuiAutocomplete-root": { width: "100%" },
                paddingBottom: "10px",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Enter Skills"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      fontSize: "0.8rem",
                      paddingLeft: "2px",
                      paddingRight: "65px",
                      paddingBottom: "9px",
                    },
                    endAdornment: (
                      <>
                        {loadingSecondary && (
                          <CircularProgress color="inherit" size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: "0.8rem",
                      fontWeight: 410,
                      color: "gray",
                    },
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    } else if (e.key === "Escape") {
                      handleClose();
                    }
                  }}
                />
              )}
              disableCloseOnSelect
              renderOption={(props, option: SkillOption, { selected }) => (
                <li
                  {...props}
                  style={{
                    fontSize: "0.8rem",
                    padding: "1px 1px",
                    margin: "0px 0",
                  }}
                >
                  <Checkbox
                    sx={{ transform: "scale(0.8)", marginRight: 2, padding: 0 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
            />
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={open1}>
          <DialogTitle sx={{ width: "20vw" }}>
            Add New Skill
            <IconButton
              onClick={handleClose}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="normal"
              placeholder="Enter Skills Name"
              error={!!error}
              helperText={error}
              fullWidth
              value={skillName}
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                } else if (e.key === "Escape") {
                  handleClose();
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
};
 
export default InterviewEvaluationForm;
 