import { Grid, Typography, Box, Tooltip, CircularProgress, FormControl, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { BaseUrl } from '../../../../../constants/Crudurl';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';

const chartColors: { [key: string]: string } = {
    Open: '#00A76F',
    MovetoJob: '#FFAB00',
    "Create Jd": '#00B8D9',
    "Job Post": '#FF5630',
    "Uploading Resumes": '#05EEFF',
    "Hiring Process": '#912394',
};

function BarChartData() {
    const maxBarValue = 50;
    const data = useSelector((state: RootState) => state.profile.data);
    const userID = data?.data?.userId;
    const [barsData, setBarsData] = useState<{ label: string; value: number; color: string }[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState('Internal');

    useEffect(() => {
        const fetchChartReport = async () => {
            try {
                setLoading(true);
                const response = await axios.post(`${BaseUrl}/dashboard/mrfStageReportByR/${userID}`, {
                    category: [selectedCategory],
                    userId: null
                });
                const responseData = response?.data?.data;
                const desiredOrder = ["Open", "MovetoJob", "CreateJd", "JobPost", "UploadingResumes", "HiringProcess"];
                const updatedBarsData = Object.entries(responseData)
                    .flatMap(([label, value]) => {
                        const commonData = {
                            value: Number(value),
                            color: chartColors[label] || "#000000",
                        };
                        return label === "Open"
                            ? [{ label: "Open", ...commonData }, { label: "Move to Job", ...commonData }] : [{ label, ...commonData }]
                    })
                    .sort((a, b) => desiredOrder.indexOf(a.label) - desiredOrder.indexOf(b.label));
                setBarsData(updatedBarsData);
            } catch (error) {
                setLoading(false);
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        if (userID) {
            fetchChartReport();
        }
    }, [userID, selectedCategory]);

    return (
        <Grid container sx={{ borderRadius: '8px', height: '280px', backgroundColor: '#FFFFFF', padding: '8px', position: 'relative', overflow: 'hidden' }}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography sx={{ fontWeight: '500', fontSize: '12px', padding: '8px' }}>
                        MRF Stage Report
                    </Typography>
                </Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography
                        variant="body2"
                        sx={{
                            color: '#6B7280',
                            backgroundColor: '#FAFAFA',
                            padding: '4px 14px',
                            borderRadius: '20px',
                            fontSize: '10px',
                            marginBottom: '8px',
                        }}
                    >
                        Total Number of MRF:{" "}
                        <span style={{ fontWeight: 'bold' }}>
                            {barsData.reduce((total, bar) => total + bar.value, 0)}
                        </span>
                    </Typography>
                    <FormControl
                        variant="standard"
                        sx={{
                            '& .MuiInput-underline:before': { borderBottom: 'none' },
                            '& .MuiInput-underline:after': { borderBottom: 'none' },
                            '&:hover .MuiInput-underline:before': { borderBottom: 'none !important' }
                        }}
                    >
                        <Select
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                            size="small"
                            IconComponent={(props) => <ArrowDropDownIcon {...props} sx={{ marginTop: '-4px' }} />}
                            sx={{
                                minWidth: 100,
                                maxWidth: 120,
                                fontSize: '12px',
                                height: '30px',
                                padding: '4px',
                            }}
                            renderValue={(value) => ((value as string) === 'Internal' ? 'Internal' : 'External')}
                        >
                            <MenuItem value="Internal">Internal</MenuItem>
                            <MenuItem value="External">External</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {loading ? (
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10 }}>
                    <CircularProgress size={50} />
                </Box>
            ) : (
                <Box sx={{ flexGrow: 1, height: 'auto', width: '100%' }}>
                    <Grid container spacing={2} alignItems="center" sx={{ marginTop: '16px', marginLeft: '24px' }}>
                        <Grid item display="flex" flexDirection="column" alignItems="flex-start" sx={{ marginBottom: '24px', marginRight: '16px' }}>
                            <Typography sx={{ fontSize: '10px', position: 'relative' }}>50
                                <Box sx={{ position: 'absolute', top: '60%', left: '500%', width: 'calc(100% + 800px)', height: '1px', borderTop: '1px dashed #D1D5DB' }} />
                            </Typography>
                            <Box sx={{ position: 'absolute', top: '80%', left: '500%', width: 'calc(100% + 700px)', height: '1px', borderTop: '1px dashed #D1D5DB' }} />
                            <Typography sx={{ fontSize: '10px', marginTop: '12px', position: 'relative' }}>40
                                <Box sx={{ position: 'absolute', top: '60%', left: '500%', width: 'calc(100% + 800px)', height: '1px', borderTop: '1px dashed #D1D5DB' }} />
                            </Typography>
                            <Box sx={{ position: 'absolute', top: '60%', left: '500%', width: 'calc(100% + 650px)', height: '1px', borderTop: '1px dashed #D1D5DB' }} />
                            <Typography sx={{ fontSize: '10px', marginTop: '12px', position: 'relative' }}>30
                                <Box sx={{ position: 'absolute', top: '60%', left: '500%', width: 'calc(100% + 800px)', height: '1px', borderTop: '1px dashed #D1D5DB' }} />
                            </Typography>
                            <Box sx={{ position: 'absolute', top: '60%', left: '500%', width: 'calc(100% + 650px)', height: '1px', borderTop: '1px dashed #D1D5DB' }} />
                            <Typography sx={{ fontSize: '10px', marginTop: '12px', position: 'relative' }}>20
                                <Box sx={{ position: 'absolute', top: '60%', left: '500%', width: 'calc(100% + 800px)', height: '1px', borderTop: '1px dashed #D1D5DB' }} />
                            </Typography>
                            <Box sx={{ position: 'absolute', top: '60%', left: '500%', width: 'calc(100% + 650px)', height: '1px', borderTop: '1px dashed #D1D5DB' }} />
                            <Typography sx={{ fontSize: '10px', marginTop: '12px', position: 'relative' }}>10
                                <Box sx={{ position: 'absolute', top: '60%', left: '500%', width: 'calc(100% + 800px)', height: '1px', borderTop: '1px dashed #D1D5DB' }} />
                            </Typography>
                            <Box sx={{ position: 'absolute', top: '60%', left: '500%', width: 'calc(100% + 650px)', height: '1px', borderTop: '1px dashed #D1D5DB' }} />
                            <Typography sx={{ fontSize: '10px', marginTop: '12px', position: 'relative' }}>00
                                <Box sx={{ position: 'absolute', top: '60%', left: '500%', width: 'calc(100% + 800px)', height: '1px', borderTop: '1px dashed #D1D5DB' }} />
                            </Typography>
                        </Grid>
                        {barsData.map((bar, index) => (
                            <Grid item xs={1.8} key={index} display="flex" flexDirection="column" alignItems="center">
                                <Box sx={{ height: '150px', position: 'relative', alignItems: 'center' }}>
                                    <Tooltip
                                        title={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '4px 8px',
                                                    borderRadius: '50px',
                                                    color: '#FFFFFF',
                                                    fontSize: '8px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '14px',
                                                        height: '14px',
                                                        borderRadius: '50%',
                                                        backgroundColor: bar.color,
                                                        marginRight: '4px',
                                                    }}
                                                />
                                                <Typography sx={{ marginRight: '4px' }}>{bar.label}</Typography>
                                                <Typography>{bar.value}</Typography>
                                            </Box>
                                        }
                                        arrow
                                        placement="top"
                                    >
                                        <Box
                                            sx={{
                                                width: '24px',
                                                height: `${Math.floor((bar.value / maxBarValue) * 140)}px`,
                                                backgroundColor: bar.color,
                                                position: 'absolute',
                                                bottom: 0,
                                                borderRadius: '4px 4px 0 0',
                                                zIndex: 1,
                                                cursor: 'pointer',
                                                transition: 'transform 0.3s',
                                                '&:hover': {
                                                    transform: 'scale(1.1)',
                                                },
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    color: '#000000',
                                                    fontWeight: 'bold',
                                                    position: 'absolute',
                                                    width: '100%',
                                                    right: 2,
                                                    textAlign: 'center',
                                                    transform: 'translateY(-100%)',
                                                }}
                                            >
                                                {bar.value}
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                </Box>
                                <Typography
                                    sx={{
                                        color: '#6B7280',
                                        textAlign: 'center',
                                        fontSize: '7.5px',
                                        marginTop: '24px',
                                        fontWeight: '500',
                                        marginLeft: '20px',
                                    }}
                                >
                                    {bar.label}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
        </Grid>
    );
}

export default BarChartData;