import React from 'react';
import { Box, Typography } from '@mui/material';

interface ResumeHeaderProps {
  positionTitle?: string;
  business?: {
    businessId: string,
    businessName: string
  };
}

const ResumeHeader: React.FC<ResumeHeaderProps> = ({ positionTitle, business }) => {

  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", pt: "5px" }}
      gap={1}
    >
      <Typography
        sx={{ fontWeight: "600", fontSize: "0.75rem", color: "#1F1F1F" }}
      >
        <abbr title={positionTitle} style={{ textDecoration: "none" }}>
          {positionTitle
            ? positionTitle.length > 20
              ? `${positionTitle.slice(0, 20)}...`
              : positionTitle
            : "N/A"}
        </abbr>
      </Typography>
      <Typography
        sx={{
          fontSize: "0.5rem",
          color: "#08979C",
          padding: "2px",
          backgroundColor: `${
            business?.businessName === "HEPL" ? "#D3ADF7" : "#B5F5EC"
          }`,
          borderRadius: "1px",
          border: "1px solid #87E8DE",
        }}
      >
        {business?.businessName && business.businessName.length > 10
          ? `${business.businessName.substring(0, 10)}...`
          : business?.businessName || "N/A"}
      </Typography>
    </Box>
  );
}

export default ResumeHeader;
