import { Box, Paper, Tab, Tabs } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchJobs } from "../../../redux/features/jobcardSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import RTHClosedTable from "../Rth/rthtable/RthClosedTable/intex";
import RTHInProgressTable from "../Rth/rthtable/RthInProgressTable";
// import WFA from "../Rth/rthtable/WFA";
import FilterSidebar from "./FilterSidebar";
import SearchBar from "./SearchBar";
import MyRequest from "../../card/Jobcard/myrequest";
import InProgress from "../../card/Jobcard/inprogress";
import OverallRTH from "../../card/Jobcard/overallrth";
import ToBeAssigned from "../../card/Jobcard/tobeassigned";

import { Job } from "../../card/commoncard/types";
import WaitingToMoveJobCard from "../../WaitingToMoveJobCard";
import ReadyForJdCreation from "../../ReadyForJdCreation";
// import { useAppSelector } from "../../../redux/hooks";
import AssignedRth from "../../card/Jobcard/assignedRth/AssignedRth";
import JobPostCard from "../../JobPostCard";
import ResumeCreation from "../../ResumeCard";
import MrfHub from "../Rth/rthtable/MrfHub";
// import { count } from "console";
import MyRequestTableView from "../Rth/rthtable/MyRequestTableView";
import RTHClosedTablee from "../Rth/rthtable/RthClosedTable";
import RecruiterTable from "../Rth/rthtable/RecruiterTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface CustomJwtPayload {
  permission?: Record<string, boolean>;
  userId?: string;
  role?: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} pt={0}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

// interface RoleTabItem {
//   label: string;
//   component: JSX.Element;
//   data?: any;
// }

// interface RoleTabs {
//   ADMIN: RoleTabItem[];
//   APPROVERHEAD: RoleTabItem[];
//   [key: string]: RoleTabItem[]; // To support other roles if needed
// }

type tabType = {
  tabNamee: string;
};

export const TabsPanelNew: React.FC<tabType> = ({ tabNamee }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [tabName, setTabName] = useState<string>(tabNamee);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [viewType, setViewType] = useState<"grid" | "list">("grid");
  const [userRole, setUserRole] = useState("");
  // const token: any = localStorage.getItem("token");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const { permissionsList, roleName } = (() => {
    try {
      const userItem = localStorage.getItem("user");
      return userItem 
        ? JSON.parse(userItem) 
        : { permissionsList: {}, roleName: '' };
    } catch (error) {
      console.error("Error parsing user data:", error);
      return { permissionsList: {}, roleName: '' };
    }
  })();

  const token = localStorage.getItem("token");
  const { permission, userId } = (() => {
    try {
      if (!token) return { permission: {}, userId: '' };
      const decoded = jwtDecode<CustomJwtPayload>(token);
      return {
        permission: decoded.permission || {},
        userId: decoded.userId || ''
      };
    } catch (error) {
      console.error("Error decoding token:", error);
      return { permission: {}, userId: '' };
    }
  })();
  // const wtMJobStatus = useSelector(
  //   (state: RootState) => state.wtMJob.wtMJobStatus
  // );
  const [wTMJob, setWTMJob] = useState<Job[]>([]);
  const [jdCreation, setJdCreation] = useState<Job[]>([]);
  const [jobPost, setJobPost] = useState<Job[]>([]);

  console.log("roleName", userRole);
  console.log("roleName", roleName);
  console.log("wTMJob", setWTMJob);
  console.log("wTMJob", setJdCreation);
  console.log("wTMJob", setJobPost);
  console.log("wTMJob", wTMJob);
  console.log("jdCreation", jdCreation);
  const [teamHeadFilterDto, setTeamHeadFilterDto] = useState({
    l1StatusPending: false,
    l1StatusDeclined: false,
    l2StatusApproved: false,
    internalOnRole: false,
    internalContract: false,
    externalOneTime: false,
    externalContract: false,
    priorityLow: false,
    priorityMedium: false,
    priorityHigh: false,
    rthStages: null,
    createdBy: null,
    recruiterLead: null,
    PositionTitle: null,
    fromYear: 0,
    fromMonth: 0,
    fromDay: 0,
    toYear: 0,
    toMonth: 0,
    toDay: 0,
    page: 0,
    size: 20,
  });

  const {
    overAllRth,
    tobeAssigned,
    asignedRTh,
    myRequest,
    inProgress,
    closed,
    myRquestCounts,
    JobPost,
    WaitingToMoveToJob,
    Resume,
    ReadyForJDCreation,
  } = useSelector((state: RootState) => state.jobs);

  console.log("JobPost", JobPost);
  console.log("ReadyForJDCreation", ReadyForJDCreation);
  console.log("permission", permission);

  const [lowCount, setLowCount] = useState(0);
  const [mediumCount, setMediumCount] = useState(0);
  const [highCount, setHighCount] = useState(0);
  const [l1StatusPending, setL1StatusPending] = useState(0);
  const [l1StatusDeclined, setL1StatusDeclined] = useState(0);
  const [l2StatusPending, setL2StatusPending] = useState(0);
  const [l2StatusApproved, setL2StatusApproved] = useState(0);
  const [l2StatusDeclined, setL2StatusDeclined] = useState(0);
  

  useEffect(() => {
    try {
      if (token) {
        const decoded = jwtDecode<CustomJwtPayload>(token);
        const role = decoded.role;
        const cleanRole = role?.replace(/\s+/g, "");
        setUserRole(cleanRole || '');
      }
    } catch (error) {
      console.error("Error extracting role:", error);
    }
  }, [token]);

  const handleViewChange = (view: "grid" | "list") => {
    setViewType(view);
  };

  // const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);

  const handleButtonClick = () => {
    navigate("/newrth");
  };

  useEffect(() => {
    setTabName(tabNamee);
  }, [tabNamee]);
  
  useEffect(() => {
    dispatch(fetchJobs({ userId, teamHeadFilterDto }));
  }, [dispatch, userId, teamHeadFilterDto]);

  console.warn("WaitingToMoveToJob", WaitingToMoveToJob);
  const permissionaTab = [
    {
      label: "Overall MRF",
      cardcomponent: (
        <OverallRTH
          drawerOpen={drawerOpen}
          userId={userId}
          teamHeadFilterDto={teamHeadFilterDto}
          setLowCount={setLowCount}
          setMediumCount={setMediumCount}
          setHighCount={setHighCount}
          setL1StatusPending={setL1StatusPending}
          setL1StatusDeclined={setL1StatusDeclined}
          setL2StatusPending={setL2StatusPending}
          setL2StatusApproved={setL2StatusApproved}
          setL2StatusDeclined={setL2StatusDeclined}
        />
      ),
      tablecomponent: (
        <RTHInProgressTable data={inProgress} tabName={tabName} />
      ),
      data: overAllRth,
      permissions: ["approveDeclineMrf", "addInterviewer"],
      queryParam: "overall-mrf",
    },
    {
      label: "To Be Assigned",
      cardcomponent: <ToBeAssigned drawerOpen={drawerOpen}           
      setLowCount={setLowCount}
      setMediumCount={setMediumCount}
      setHighCount={setHighCount} />,
      tablecomponent: <RTHClosedTablee tabName={tabName} />,
      data: tobeAssigned,
      permissions: ["assignAndEditRecruiterLead", "assignAndEditRecruiter"],
      queryParam: "tobe-assigned",
    },    
    {
      label: "Assigned MRF",
      cardcomponent: <AssignedRth drawerOpen={drawerOpen}           setLowCount={setLowCount}
      setMediumCount={setMediumCount}
      setHighCount={setHighCount}/>,
      tablecomponent: <RTHClosedTablee tabName={tabName} />,
      data: asignedRTh,
      permissions: ["assignAndEditRecruiterLead", "assignAndEditRecruiter"],
      queryParam: "assigned-mrf",
    },
    {
      label: "MRF Hub",
      tablecomponent: <MrfHub           
      setLowCount={setLowCount}
      setMediumCount={setMediumCount}
      setHighCount={setHighCount}/>,
      data: asignedRTh,
      permissions: ["recruiterAccess"],
      queryParam: "mrf-hub",
    },
    {
      label: "Waiting to Move to Job",
      cardcomponent: <WaitingToMoveJobCard drawerOpen={drawerOpen}        
      setLowCount={setLowCount}
      setMediumCount={setMediumCount}
      setHighCount={setHighCount}/>,
      tablecomponent: <RecruiterTable tabName={tabName} />,
      data: wTMJob,
      permissions: ["recruiterAccess"],
      queryParam: "waiting-to-move-to-job",
    },
    {
      label: "Ready for JD Creation",
      cardcomponent: <ReadyForJdCreation drawerOpen={drawerOpen} 
      setLowCount={setLowCount}
      setMediumCount={setMediumCount}
      setHighCount={setHighCount}/>,
      tablecomponent: <RecruiterTable tabName={tabName} />,
      data: wTMJob,
      permissions: ["recruiterAccess"],
      queryParam: "ready-for-jd-creation",
    },
    // table
    {
      label: "Job Post",
      cardcomponent: <JobPostCard drawerOpen={drawerOpen}  
      setLowCount={setLowCount}
      setMediumCount={setMediumCount}
      setHighCount={setHighCount}/>,
      tablecomponent: <RecruiterTable tabName={tabName} />,
      data: jobPost,
      permissions: ["recruiterAccess"],
      queryParam: "job-post",
    },
    {
      label: "Resume",
      cardcomponent: <ResumeCreation drawerOpen={drawerOpen} 
      setLowCount={setLowCount}
      setMediumCount={setMediumCount}
      setHighCount={setHighCount}/>,
      tablecomponent: <RecruiterTable tabName={tabName} />,
      data: Resume,
      permissions: ["recruiterAccess"],
      queryParam: "resume",
    },
    {
      label: "My Requests",
      cardcomponent: (
        <MyRequest
          drawerOpen={drawerOpen}
          setLowCount={setLowCount}
          setMediumCount={setMediumCount}
          setHighCount={setHighCount}
          setL1StatusPending={setL1StatusPending}
          setL1StatusDeclined={setL1StatusDeclined}
          setL2StatusPending={setL2StatusPending}
          setL2StatusApproved={setL2StatusApproved}
          setL2StatusDeclined={setL2StatusDeclined}
        />
      ),
      tablecomponent: <MyRequestTableView />,
      data: myRequest,
      permissions: ["mrfUsers"],
      queryParam: "my-requests",
    },
    {
      label: "In Progress",
      cardcomponent: (
        <InProgress
          drawerOpen={drawerOpen}
          setLowCount={setLowCount}
          setMediumCount={setMediumCount}
          setHighCount={setHighCount}
        />
      ),
      tablecomponent: (
        <RTHInProgressTable data={inProgress} tabName={tabName} />
      ),
      data: inProgress,
      permissions: ["mrfUsers"],
      queryParam: "in-progress",
    },
    {
      label: "Closed",
      cardcomponent: (
        <RTHClosedTable
          drawerOpen={drawerOpen}
          setLowCount={setLowCount}
          setMediumCount={setMediumCount}
          setHighCount={setHighCount}
        />
      ),
      tablecomponent: <RTHClosedTablee tabName={tabName} />,
      data: closed,
      permissions: ["mrfUsers", "recruiterAccess", "assignAndEditRecruiter"],
      queryParam: "closed",
    },
  ];

  const hasPermission = (
    requiredPermissions: string[],
    userPermissions: any
  ): boolean => {
    return requiredPermissions.some((perm) => userPermissions[perm] === true);
  };

  const newTabs = permissionaTab.filter((tab) =>
    hasPermission(tab.permissions, permissionsList)
  );

  // const roleTabs: RoleTabs = {
  //   ADMIN: [
  //     { label: 'My Requests', component: <WFA data={myRequest} />, data: myRequest },
  //     { label: 'In Progress', component: <RTHInProgressTable data={inProgress} />, data: inProgress },
  //     { label: 'Closed', component: <RTHClosedTable data={closed} />, data: closed },
  //   ],
  //   APPROVERHEAD: [
  //     { label: 'overall MRF', component: <WFA data={overAllRth} />, data: overAllRth },
  //     { label: 'My Requests', component: <WFA data={myRequest} />, data: myRequest },
  //     { label: 'In Progress', component: <RTHInProgressTable data={inProgress} /> },
  //     { label: 'Closed', component: <RTHClosedTable data={closed} /> },
  //   ],
  //   TALENTACQUISITIONHEAD: [
  //     { label: 'To Be Assigned', component: <WFA data={tobeAssigned} />, data: tobeAssigned },
  //     { label: 'Asigned RTh', component: <WFA data={asignedRTh} />, data: asignedRTh },
  //     { label: 'My Requests', component: <WFA data={myRequest} />, data: myRequest },
  //     { label: 'In Progress', component: <RTHInProgressTable data={inProgress} />, data: inProgress },
  //     { label: 'Closed', component: <RTHClosedTable data={closed} />, data: closed },
  //   ],
  //   RECRUITERLEAD: [
  //     { label: 'To Be Assigned', component: <WFA data={tobeAssigned} />, data: tobeAssigned },
  //     { label: 'Asigned RTh', component: <WFA data={asignedRTh} />, data: asignedRTh },
  //     { label: 'My Requests', component: <WFA data={myRequest} />, data: myRequest },
  //     { label: 'In Progress', component: <RTHInProgressTable data={inProgress} />, data: inProgress },
  //     { label: 'Closed', component: <RTHClosedTable data={closed} />, data: closed },
  //   ],
  //   RECRUITER: [
  //     { label: "Waiting to Move to Job", component: <WFA data={WaitingToMoveToJob} />, data: WaitingToMoveToJob },
  //     { label: "Ready for JD Creation", component: <WFA data={ReadyForJDCreation} />, data: ReadyForJDCreation },
  //     { label: "Job Post", component: <WFA data={JobPost} />, data: JobPost },
  //     { label: "Resume", component: <WFA data={Resume} />, data: Resume },
  //   ]
  // };

  // // Function to get tabs based on the role
  // const getTabsByRole = (role: string): RoleTabItem[] => {
  //   return roleTabs[role] || [];
  // };

  // const tabs = getTabsByRole(userRole);

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
    setTabName(newTabs[newIndex].label);    
    navigate(`?tab=${newTabs[newIndex]?.queryParam}`);
  };  

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");

    if (tab) {
      const index = newTabs.findIndex((t) => t.queryParam === tab);
      if (index !== -1) {
        setTabIndex(index);
        setTabName(newTabs[index].label);
      }
    } else {
      setTabIndex(0);
      setTabName(newTabs[0]?.label || "");
    }
  }, [location.search, newTabs]);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#f0f4ff",
        display: "flex",
        position: "relative",
        overflow: "hidden", 
      }}
    >
      <Box
        sx={{
          flexShrink: 0, 
          transition: "width 0.3s",
          width: drawerOpen ? { xs: "240px", md: "240px" } : "0px",          
        }}
      >
        <FilterSidebar
          onChangeFilter={setDrawerOpen}
          drawerOpen={drawerOpen}
          tabName={tabName}
          tabIndex={tabIndex}
          teamHeadFilterDto={teamHeadFilterDto}
          setTeamHeadFilterDto={setTeamHeadFilterDto}
          myRquestCounts={myRquestCounts}
          lowCount={lowCount}
          mediumCount={mediumCount}
          highCount={highCount}
          l1StatusPending={l1StatusPending}
          l1StatusDeclined={l1StatusDeclined}
          l2StatusPending={l2StatusPending}
          l2StatusApproved={l2StatusApproved}
          l2StatusDeclined={l2StatusDeclined}
        />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          minWidth: 0, 
          transition: "margin-left 0.3s",
          width: drawerOpen ? `calc(100% - 240px)` : "100%",
        }}
      >
        <Paper 
          elevation={0} 
          sx={{ 
            backgroundColor: "transparent",
            overflow: "auto" 
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            aria-label="job tabs"
            sx={{
              ml: { 
                xs: drawerOpen ? "0px" : "20px",
                sm: drawerOpen ? "0px" : "50px",
                md: drawerOpen ? "0px" : "100px" 
              },
              '& .MuiTabs-flexContainer': {
                flexWrap: {
                  xs: 'wrap',
                  sm: 'nowrap'
                }
              }
            }}
            TabIndicatorProps={{
              style: {
                width: "20px",
                marginLeft: `${tabIndex === 0 ? "45px" : "36px"}`,
              },
            }}
          >
            {newTabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                {...a11yProps(index)}
                sx={{ 
                  fontSize: "13px", 
                  textTransform: "none",
                  minWidth: { xs: '120px', sm: 'auto' } 
                }}
              />
            ))}
          </Tabs>
        </Paper>
        
        <SearchBar
          viewType={viewType}
          handleViewChange={handleViewChange}
          handleButtonClick={handleButtonClick}
          tabName={tabName}
        />
        
        {newTabs.map((tab, index) => (
          <CustomTabPanel value={tabIndex} index={index} key={index}>
            <Box sx={{ 
              overflowX: "auto",
              width: "100%"
            }}>
              {viewType === "grid" && tabName !== "MRF Hub"
                ? tab.cardcomponent
                : tab.tablecomponent}
            </Box>
          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
};
