import { Box, Breadcrumbs, Button, Typography, useMediaQuery } from "@mui/material"
import {useNavigate, useParams } from "react-router-dom";
import InterviewEvaluationFormLogo from "../../../../../assets/images/InterviewEvaluationFormLogo.svg";
import backgroundImage from '../../../../../assets/images/feedbackBacgroundImage.svg';
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { getRthDetailsByRthId } from "../../../../../redux/features/resume";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
 
 
export const CandidateJobDetails=()=>{
 
  const dispatch=useAppDispatch();
  const details=useAppSelector((state:any)=>state.resume.rthDetails);
  const{id,recId}=useParams();
  const navigate=useNavigate();
  const handleNavigate=()=>{
      navigate(`/candidateForm/candidate/${recId}/${id}`);
      }
 
  const Md = useMediaQuery("(max-width: 1504px)");
 
 
  useEffect(()=>{
      dispatch(getRthDetailsByRthId(id));
    },[id,dispatch]);
 
    return(
     
      <Box sx={{ backgroundColor: 'white', height: '100vh', width:'100vw',overflowY:'hidden'}}>
      {/* Header */}
      <Box sx={{ background: 'linear-gradient(90deg, #36CFC9 0%, #2F54EB 100%)', p: 2 ,height:'40px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={InterviewEvaluationFormLogo}
            alt="Theron logo"
            style={{ width: '50px', height: '50px', marginRight: '10px' }}
          />
          <Typography sx={{ color: 'white', fontWeight: 500, fontSize: '20px' }}>
            Theron-Cavin Infotech
          </Typography>
        </Box>
      </Box>
 
      {/* Job Position */}
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'contain',
          backgroundPosition: 'right',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'white',
          width: '100%',
          height: '100vh',
        }}
      >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1,mt:'20px',ml:'20px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ml:'50px',fontSize:'22px'}} fontWeight="bold">
          Job Details
        </Typography>
      </Box>
     
      {/* Breadcrumbs */}
      <Box sx={{ml:'50px',mt:'-14px'}}>
      <Breadcrumbs separator="•" aria-label="breadcrumb">
        <Typography>
          Home
        </Typography>
        <Typography>
          Job Listing
        </Typography>
        <Typography color="inherit">
          Job Details
        </Typography>
      </Breadcrumbs>
      </Box>
      <Box
    sx={{
      position: 'sticky',
      top: 0,
      zIndex: 1,
      backgroundColor: 'white', // Ensure it blends well with the design
      padding: '10px 50px',
    }}
  >
    <Typography sx={{ fontSize: '22px', mt: '20px' }} fontWeight="bold">
      {details?.positionTitle}
    </Typography>
  </Box>
 
      <Box sx={{ml:'45px', height:Md?'42vh':'50vh',overflowY:'auto'}}>
       
      {details?.jdTemplateText ? (
        <>
         <Box>
          <Typography sx={{ ml:2, fontSize:'16px', mr:'65px'}}
            dangerouslySetInnerHTML={{ __html: details?.jdTemplateText }}
          />
          </Box>  
          </>
        ) : (
          <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', height:'70vh', width: '85vw'}}>
          <Typography sx={{ mt: 2 }}>No description available.</Typography>
          </Box>
        )}
      </Box>
      <Button
      variant="contained"
      color="primary"
      endIcon={<ArrowForwardIosIcon />}
      sx={{
        width:'156px',
        height:'46px',
        textTransform: 'none', // Disable uppercase transformation
        fontSize: '14px', // Adjust font size
        fontWeight: 'bold', // Make text bold
        padding: '10px 20px', // Adjust padding
        borderRadius: '8px', // Add rounded corners
        boxShadow: 'none', // Remove default shadow
        mt:'20px',
        ml:'45px',
        backgroundColor:'#1677FF',
        '&:hover': {
          backgroundColor: '#005fcc', // Add hover effect
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add hover shadow
        },
      }}
      onClick={handleNavigate}
    >
      Apply Now
    </Button>
    </Box>
 </Box>
 
     </Box>
    )
}
 