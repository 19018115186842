import React, { useEffect, useState } from "react";
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Typography, InputAdornment, InputBase, Button, CircularProgress, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTableCell from "./customTableCell";
import SearchIcon from '@mui/icons-material/Search';
import { getJobOfferApproval } from "../../Crud/rth";
import NoDataAvailable from '../../assets/images/NoDataAvail.svg';
import ApproverDialog from "./approverDialog";

interface CandidateData {
  slNo?: number;
  firstName: string;
  mrfId: string;
  mrfName: string;
  expectedDOJ: string;
  offer: string;
  approverStatus: string;
  l1Remarks?: string;
  l2Remarks?: string;
  l3Remarks?: string;
  candidateId?: string;
  l1Status?: string;
  l2Status?: string;
  l3Status?: string;
}

const getStatusValue = (status: any) => {
  if (typeof status === "object" && status !== null) {
    const key = Object.keys(status)[0];
    return key || "";
  }
  return status;
};

const getStatusText = (l1Status: any, l2Status: any, l3Status: any) => {
  const l1 = getStatusValue(l1Status);
  const l2 = getStatusValue(l2Status);
  const l3 = getStatusValue(l3Status);

  if (l1 === "Pending" || !l1) return "L1 Pending";
  if (l1 === "Rejected") return "L1 Rejected";
  if (l1 === "Approved" && l2 === "Pending") return "L2 Pending";
  if (l2 === "Rejected") return "L2 Rejected";
  if (l1 === "Approved" && l2 === "Approved" && l3 === null) return "Approved";
  if (l1 === "Approved" && l2 === "Approved" && l3 === "Pending") return "L3 Pending";
  if (l1 === "Approved" && l2 === "Approved" && l3 === "Rejected") return "L3 Rejected";
  if (l1 === "Approved" && l2 === "Approved" && l3 === "Approved") return "Approved";

  return "L1 Pending";
};

const getButtonColor = (state: string) => {
  const colors: Record<string, string> = {
    "L1 Pending": "#FFA940",
    "L1 Rejected": "#F04E4E",
    "L2 Rejected": "#F04E4E",
    "L2 Pending": "#FFA940",
    "L3 Pending": "#FFA940",
    "L3 Rejected": "#F04E4E",
    Approved: "#389E0D",
    "Unknown": "#B0BEC5",
  };
  return colors[state] || "#B0BEC5";
};

const TableChart: React.FC<{ filters: string[] }> = ({ filters }) => {
  const [data, setData] = useState<CandidateData[]>([]);
  const [loading, setLoading] = useState(true);
  const userId = localStorage.getItem("userId") ?? "";
  const [searchTerm, setSearchTerm] = useState<string>('');
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState<CandidateData[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<CandidateData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getJobOfferApproval(userId, searchTerm);
        const candidates = response?.data?.data?.map((item: any, index: number) => ({
          slNo: index + 1,
          firstName: item.candidateLogIn.firstName,
          mrfId: item.seqId,
          mrfName: item.positionTitle,
          expectedDOJ: item.prefStartDate,
          offer: ((item.candidateLogIn.proceedToOffer === "Selected To Offer")) ? "Proceed" : "Assigned",
          approverStatus: getStatusText(item.candidateLogIn.l1Status, item.candidateLogIn.l2Status, item.candidateLogIn.l3Status),
          l1Remarks: item.candidateLogIn.l1Comments || "Nil",
          l2Remarks: item.candidateLogIn.l2Comments || "Nil",
          l3Remarks: item.candidateLogIn.l3Comments || "Nil",
          candidateId: item.candidateLogIn.candidateId,
          l1Status: item.candidateLogIn.l1Status,
          l2Status: item.candidateLogIn.l2Status,
          l3Status: item.candidateLogIn.l3Status,
        }));
        setData(candidates || []);
      } catch (err) {
        console.error("Failed to fetch data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, searchTerm]);

  useEffect(() => {
    if (filters.length === 0) {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item) => filters.includes(getStatusText(item.l1Status, item.l2Status, item.l3Status)));
      setFilteredData(filtered);
    }
  }, [filters, data]);

  const handleRowClick = (row: CandidateData) => {
    navigate('/offer-approval/View-approval', {
      state: { candidateId: row.candidateId }
    });
  };

  const truncateText = (text: string, maxLength: number): string => {
    return text.length > maxLength ? `${text.slice(0, maxLength - 3)}...` : text;
  };

  const handleProceedClick = (row: CandidateData) => {
    setSelectedRow(row);
    setOpenDialog(true);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getJobOfferApproval(userId, searchTerm);
      const candidates = response?.data?.data?.map((item: any, index: number) => ({
        slNo: index + 1,
        firstName: item.candidateLogIn.firstName,
        mrfId: item.seqId,
        mrfName: item.positionTitle,
        expectedDOJ: item.prefStartDate,
        offer: (!item.candidateLogIn.l1Approver && !item.candidateLogIn.l2Approver) ? "Proceed" : "Assigned",
        approverStatus: getStatusText(item.candidateLogIn.l1Status, item.candidateLogIn.l2Status, item.candidateLogIn.l3Status),
        l1Remarks: item.candidateLogIn.l1Comments || "Nil",
        l2Remarks: item.candidateLogIn.l2Comments || "Nil",
        l3Remarks: item.candidateLogIn.l3Comments || "Nil",
        candidateId: item.candidateLogIn.candidateId,
        l1Status: item.candidateLogIn.l1Status,
        l2Status: item.candidateLogIn.l2Status,
      }));
      setData(candidates || []);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <InputBase
        placeholder="Search MRF..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
          </InputAdornment>
        }
        sx={{
          border: "1px solid #ccc", borderRadius: 1, padding: 0, backgroundColor: "white", minHeight: "3px", fontSize: 12, width: "150px", marginBottom: "10px",
          ".MuiInputBase-input": {
            padding: "5px 5px",
          },
        }}
      />
      {loading ? (
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 10 }}>
          <CircularProgress size={50} />
        </Box>
      ) : filteredData.length === 0 ? (
        <Box sx={{ position: "relative", textAlign: "center", mt: 4 }}>
          <img src={NoDataAvailable} alt="No Data Available" style={{ maxWidth: "150px" }} />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ boxShadow: "none", overflowX: "auto", width: "100%", height: '100%', mb: 4 }}>
          <Table sx={{ minWidth: "100%", tableLayout: "auto", }}>
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    padding: "6px 10px",
                    fontSize: "0.85rem",
                    whiteSpace: "nowrap",
                    lineHeight: 1.2,
                  },
                }}>
                <CustomTableCell isHeader>Sl.No.</CustomTableCell>
                <CustomTableCell isHeader>Candidate</CustomTableCell>
                <CustomTableCell isHeader>MRF ID</CustomTableCell>
                <CustomTableCell isHeader>MRF Name</CustomTableCell>
                <CustomTableCell isHeader>Expected DOJ</CustomTableCell>
                <CustomTableCell isHeader>Offer</CustomTableCell>
                <CustomTableCell isHeader>Approver Status</CustomTableCell>
                <CustomTableCell isHeader>L1 Remarks</CustomTableCell>
                <CustomTableCell isHeader>L2 Remarks</CustomTableCell>
                <CustomTableCell isHeader>L3 Remarks</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{
              "& td, & th": {
                padding: "6px 10px",
                fontSize: "0.85rem",
                whiteSpace: "nowrap",
                lineHeight: 1.2,
              },
            }}>
              {filteredData.map((row) => (
                <TableRow key={row.slNo} onClick={() => handleRowClick(row)} sx={{ cursor: "pointer" }}>
                  <CustomTableCell>{row.slNo}</CustomTableCell>
                  <CustomTableCell>{truncateText(row.firstName, 15)}</CustomTableCell>
                  <CustomTableCell>{row.mrfId}</CustomTableCell>
                  <CustomTableCell>{truncateText(row.mrfName, 15)}</CustomTableCell>
                  <CustomTableCell>{row.expectedDOJ}</CustomTableCell>
                  <CustomTableCell>
                    {row.offer === "Proceed" ? (
                      <Button variant="text" style={{ fontWeight: "500", fontSize: "0.65rem", color: "#1677FF", textDecoration: 'none' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleProceedClick(row);
                        }}>
                        Proceed
                      </Button>
                    ) : (
                      <Typography style={{ cursor: "pointer", color: "black", fontWeight: "500", fontSize: "0.75rem" }}> {row.offer} </Typography>
                    )}
                  </CustomTableCell>
                  <CustomTableCell>
                    <div style={{ display: "flex", justifyContent: row.approverStatus === "--------------" ? "center" : "flex-start", alignItems: "center" }}>
                      {row.approverStatus === "--------------" ? (
                        <Typography style={{ textAlign: "center", fontWeight: 500 }}>---</Typography>
                      ) : (
                        <Button
                          variant="outlined"
                          style={{
                            color: getButtonColor(row.approverStatus),
                            borderColor: getButtonColor(row.approverStatus),
                            backgroundColor: getButtonColor(row.approverStatus) + "10",
                            fontSize: "0.75rem",
                            height: "24px",
                            textTransform: "none",
                            fontWeight: '500',
                          }}
                        >
                          {row.approverStatus}
                        </Button>
                      )}
                    </div>
                  </CustomTableCell>
                  <CustomTableCell>{truncateText(row.l1Remarks || "Nil", 20)}</CustomTableCell>
                  <CustomTableCell>{truncateText(row.l2Remarks || "Nil", 20)}</CustomTableCell>
                  <CustomTableCell>{truncateText(row.l3Remarks || "Nil", 20)}</CustomTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {openDialog && selectedRow && (
        <ApproverDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          candidateId={selectedRow.candidateId}
          onAddApprover={(approvers) => {
            console.log("Added Approvers:", approvers);
            setOpenDialog(false);
            fetchData();
          }}
        />
      )}
    </>
  );
};

export default TableChart;