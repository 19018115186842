import { Grid, Typography, Box, Tooltip, CircularProgress, Select, FormControl, MenuItem } from '@mui/material';
import { RootState } from '../../../../../redux/store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BaseUrl } from '../../../../../constants/Crudurl';
import { getRecruiter } from '../../../../../Crud/rth';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';

function HorizontalBarChart() {
    const data = useSelector((state: RootState) => state.profile.data);
    const userID = data?.data?.userId;
    const [barsData, setBarsData] = useState<{ label: string; value: number }[]>([]);
    const [maxBarValue, setMaxBarValue] = useState(0);
    const [loading, setLoading] = useState(true);
    const [selectedRecruiters, setSelectedRecruiters] = useState('')
    const [recruiters, setRecruiters] = useState<{ id: string; title: string }[]>([]);
    const [loadingRecruiters, setLoadingRecruiters] = useState(true);

    useEffect(() => {
        const fetchRecruiters = async () => {
            setLoadingRecruiters(true);
            try {
                const response = await getRecruiter();
                const newRecruiters = response.data.map((data: any) => ({
                    id: data?.userId,
                    title: `${data?.firstName} ${data?.lastName}`
                }));
                setRecruiters(prev => {
                    const merged = [...prev, ...newRecruiters];
                    return merged.filter((rec, index, self) =>
                        index === self.findIndex((r) => r.id === rec.id)
                    );
                });
            } catch (error) {
                console.error('Error fetching positions:', error);
            } finally {
                setLoadingRecruiters(false);
            }
        };
        fetchRecruiters();
    }, []);

    useEffect(() => {
        const fetchChartReport = async () => {
            try {
                setLoading(true);
                const response = await axios.post(`${BaseUrl}/dashboard/sourceReportByTa/${userID}`,
                    {
                        category: [selectedRecruiters],
                        userId: null
                    });
                const responseData = response?.data?.data;
                const updatedBarsData = Object.entries(responseData).map(([label, value]) => ({
                    label,
                    value: Number(value),
                }));
                setBarsData(updatedBarsData);
                setMaxBarValue(Math.max(...updatedBarsData.map(bar => bar.value)));
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        if (userID) {
            fetchChartReport();
        }
    }, [userID, selectedRecruiters]);

    return (
        <Grid container sx={{ borderRadius: '8px', height: '280px', backgroundColor: '#FFFFFF', padding: '16px', position: 'relative' }}>
            <Grid item xs={12} container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '30px', mt: -1 }}>
                <Typography sx={{ fontWeight: '500', fontSize: '10px' }}>Source Report</Typography>
                <FormControl
                    variant="standard"
                    sx={{
                        m: 0.5,
                        minWidth: 100,
                        '& .MuiInput-underline:before': { borderBottom: 'none' },
                        '& .MuiInput-underline:after': { borderBottom: 'none' },
                        '&:hover .MuiInput-underline:before': { borderBottom: 'none !important' }
                    }}
                >
                    <Select
                        value={selectedRecruiters}
                        onChange={(e) => setSelectedRecruiters(e.target.value)}
                        size="small"
                        IconComponent={(props) => <ArrowDropDownIcon {...props} />}
                        displayEmpty
                        sx={{
                            minWidth: 100,
                            maxWidth: 120,
                            fontSize: '12px',
                            height: '30px',
                            padding: '4px',
                            '& .MuiSelect-select': { padding: 0 },
                            '&:hover': { backgroundColor: 'transparent' },
                        }}
                        renderValue={(selected) =>
                            selected ? (selected.length > 8 ? `${selected.substring(0, 8)}...` : selected) : "Recruiters"
                        }
                    >
                        {recruiters.map((rec) => (
                            <MenuItem key={rec.id} value={rec.title}>{rec.title}</MenuItem>
                        ))}
                        {loadingRecruiters && (
                            <MenuItem disabled>
                                <CircularProgress size={20} sx={{ margin: 'auto' }} />
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Grid>
            {loading ? (
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10 }}>
                    <CircularProgress size={50} />
                </Box>
            ) : (
                <Box sx={{ flexGrow: 1, width: '100%', marginTop: 0 }}>
                    {barsData.map((bar, index) => (
                        <Grid container key={index} sx={{ alignItems: 'center', marginBottom: '8px' }}>
                            <Grid item xs={2}>
                                <Typography sx={{ fontSize: '8px' }}>{bar.label}</Typography>
                            </Grid>
                            <Grid item xs={10} sx={{ position: 'relative', padding: '4px', }}>
                                <Tooltip title={`${bar.value}`} arrow placement='top'>
                                    <Box
                                        sx={{
                                            height: '8px',
                                            width: `${(bar.value / maxBarValue) * 100 - 5}%`,
                                            backgroundColor: '#1677FF',
                                            borderRadius: '0 4px 4px 0',
                                            transition: 'width 0.3s ease',
                                        }}
                                    />
                                </Tooltip>
                                <Typography
                                    sx={{
                                        fontSize: '10px',
                                        position: 'absolute',
                                        top: 0,
                                        left: `${(bar.value / maxBarValue) * 100 + 2}%`,
                                        transform: 'translateX(-100%)',
                                        color: '#333',
                                    }}
                                >
                                    {bar.value}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            )}
            {!loading && (
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                    <Typography sx={{ fontSize: '8px' }}>Resume Count</Typography>
                </Grid>
            )}
        </Grid>
    );
}

export default HorizontalBarChart;
