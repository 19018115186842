import React, { useEffect, useState } from 'react';
import { Box, Card, Typography, Grid, Button, TextField, Divider, IconButton, CircularProgress, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, FormControl, Select, MenuItem, SelectChangeEvent, } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ApproverDialog, { Approver } from './approverDialog';
import { getCandidateApproval, getOfferApprovalStatus } from '../../Crud/rth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from "@mui/icons-material/Edit";
import { truncateText } from '../../helpers/truncateText';
import { toast } from 'react-toastify';

const StyledLabel = styled(Typography)({
    fontSize: '0.625rem',
    fontWeight: '500',
    color: "#1414144D"
});

const StyledTextField = styled(TextField)({
    width: '160px',
    height: '40px',
    '& .MuiInputBase-root': {
        height: '40px',
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
        borderRadius: '4px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiInputBase-input': {
        fontSize: '0.75rem',
        color: '#000000',
    },
    '& .Mui-disabled .MuiInputBase-input': {
        color: '#000000',
        WebkitTextFillColor: '#000000',
    },
}).withComponent((props?: any) => <TextField {...props} InputProps={{ readOnly: true }} />);

const getButtonColor = (state: string) => {
    const colors: Record<string, string> = {
        "Pending": "#FFA940",
        "Rejected": "#F04E4E",
        "Approved": "#389E0D",
        "Unknown": "#B0BEC5",
    };
    return colors[state] || "#B0BEC5";
};

const JobOfferApproval: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const candidateId = location.state?.candidateId;
    const [candidateData, setCandidateData] = useState<any>({});
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [approversAdded, setApproversAdded] = useState(false);
    const l1Status = candidateData?.l1Status ? Object.keys(candidateData.l1Status)[0] : '-';
    const l2Status = candidateData?.l2Status ? Object.keys(candidateData.l2Status)[0] : '-';
    const l3Status = candidateData?.l3Status ? Object.keys(candidateData.l3Status)[0] : '-';
    const [offer, setOffer] = useState('');

    useEffect(() => {
        if (candidateId) {
            setLoading(true);
            getCandidateApproval(candidateId)
                .then(response => {
                    setCandidateData(response?.data?.data);
                })
                .catch(error => {
                    console.error("Error fetching candidate details:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [candidateId]);

    const handleChange = async (event: SelectChangeEvent) => {
        const selectedStatus = event.target.value;
        setOffer(selectedStatus);

        try {
            await getOfferApprovalStatus(candidateId, selectedStatus);
            console.log(`Offer status updated to: ${selectedStatus}`);
            toast.success("Offer status updated")
        } catch (error) {
            console.error("Error updating offer status:", error);
        }
    };

    useEffect(() => {
        if (candidateData?.interviewStatus) {
            setOffer(candidateData.interviewStatus);
        }
    }, [candidateData]);


    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleProceedClick = () => {
        setDialogOpen(true);
    };

    const handleAddApprover = (approvers: { l1Approver: Approver | null; l2Approver: Approver | null; l3Approver?: Approver | null }) => {
        if (approvers.l1Approver && approvers.l2Approver) {
            setApproversAdded(true);
            if (candidateId) {
                setLoading(true);
                getCandidateApproval(candidateId)
                    .then(response => {
                        setCandidateData(response?.data?.data);
                    })
                    .catch(error => {
                        console.error("Error refreshing candidate details:", error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
        setDialogOpen(false);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ padding: 2, pt: 0, mb: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <Box display="flex" alignItems="center">
                    <IconButton onClick={() => navigate(`/offer-approval`)}>
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <Tooltip title={candidateData?.firstName} disableHoverListener={candidateData?.firstName?.length <= 20} arrow >
                        <Typography sx={{ fontSize: '1.375rem', fontWeight: '500', ml: 1 }}> {truncateText(candidateData?.firstName, 20)} </Typography>
                    </Tooltip>
                    {(approversAdded || candidateData?.approversAssigned === true) && (
                        <FormControl sx={{ ml: 2, minWidth: 180 }} size="small">
                            <Select
                                displayEmpty
                                value={offer}
                                onChange={handleChange}
                                renderValue={(selected) => selected || "Select Offer"}
                                sx={{
                                    width: '90%',
                                    height: '34px',
                                    textTransform: 'none',
                                    fontSize: '12px',
                                    backgroundColor: ["Offer Accepted", "Offer Declined"].includes(offer) ? '#1677FF' : '#1677FF',
                                    color: '#000',
                                    borderRadius: '4px',
                                    padding: 0,
                                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                                    "& .MuiSelect-icon": { color: "#fff", fontSize: "24px" },
                                    "& .MuiSelect-select": {
                                        color: "#FFFFFF",
                                        fontSize: "14px"
                                    },
                                    "&.Mui-disabled .MuiSelect-select": {
                                        WebkitTextFillColor: "#FFFFFF",
                                        color: "#FFFFFF"
                                    }
                                }}
                            >
                                <MenuItem value="Offer Accepted">Offer Accepted</MenuItem>
                                <MenuItem value="Offer Declined">Offer Declined</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                </Box>
                {l2Status !== "Approved" && (
                    <Tooltip title="Edit" placement="left-start">
                        <IconButton onClick={() => navigate(`/offer-approval/edit-approver/${candidateId}`, { state: { candidateData, candidateId } })}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
            <Card variant="outlined" sx={{ padding: 2, maxWidth: 1200, margin: '0 auto' }}>
                <Typography fontSize="0.875rem" fontWeight="600" marginBottom={2}>
                    Candidate Details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={2} md={2}>
                        <StyledLabel>Candidate Name</StyledLabel>
                        <StyledTextField value={candidateData?.firstName || "_"} />
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <StyledLabel>MRF ID</StyledLabel>
                        <StyledTextField value={candidateData?.mrfId || "_"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>MRF Name</StyledLabel>
                        <StyledTextField value={candidateData?.mrfName || "_"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Education</StyledLabel>
                        <StyledTextField value={candidateData?.education || "_"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Work Location</StyledLabel>
                        <StyledTextField value={candidateData?.workLocation || "_"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Work Mode</StyledLabel>
                        <StyledTextField value={candidateData?.workMode || "_"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Total No. of experience</StyledLabel>
                        <StyledTextField value={candidateData?.totYearsOfExp || "_"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Notice Period (in days)</StyledLabel>
                        <StyledTextField value={candidateData?.noticePeriods || "_"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Date of Joining</StyledLabel>
                        <StyledTextField value={candidateData?.dateOfJoining || "_"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Current CTC</StyledLabel>
                        <StyledTextField value={candidateData?.currentCtc || "_"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Expected CTC</StyledLabel>
                        <StyledTextField value={candidateData?.expectedCtc || "_"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Business</StyledLabel>
                        <StyledTextField value={candidateData?.business || "_"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Payroll</StyledLabel>
                        <StyledTextField value={candidateData?.payRoll || "_"} />
                    </Grid>
                </Grid>
                {/* {!(!approversAdded && candidateData?.approversAssigned === false) && ( */}
                {/* {candidateData?.proceedToOffer !== "Selected To Offer" && ( */}
                {candidateData?.l2Approver?.userName && (
                    <>
                        <Divider sx={{ marginY: 4 }} />
                        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                            <Table>
                                <TableHead>
                                    <TableRow className="table-header">
                                        <TableCell className="table-body" sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}> Approvers </TableCell>
                                        <TableCell className="table-body" sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}> Status </TableCell>
                                        <TableCell className="table-body">Remarks</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            {candidateData?.l1Approver?.userName || '-'}
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <Button
                                                variant="outlined"
                                                style={{
                                                    color: getButtonColor(l1Status),
                                                    borderColor: getButtonColor(l1Status),
                                                    backgroundColor: getButtonColor(l1Status) + '10',
                                                    fontSize: '0.75rem',
                                                    height: '24px',
                                                    textTransform: 'none',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                {l1Status}
                                            </Button>
                                        </TableCell>
                                        <TableCell>{candidateData?.l1Remarks || '-'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            {candidateData?.l2Approver?.userName || '-'}
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <Button
                                                variant="outlined"
                                                style={{
                                                    color: l1Status === 'Pending' ? '#FFA940' : l1Status === 'Rejected' ? '#B0BEC5' : getButtonColor(l2Status),
                                                    borderColor: l1Status === 'Pending' ? '#FFA940' : l1Status === 'Rejected' ? '#B0BEC5' : getButtonColor(l2Status),
                                                    backgroundColor: l1Status === 'Pending' ? `${'#FFA940'}10` : l1Status === 'Rejected' ? `${'#B0BEC5'}10` : `${getButtonColor(l2Status)}10`,
                                                    fontSize: '0.75rem',
                                                    height: '24px',
                                                    textTransform: 'none',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                {l1Status === 'Pending' ? 'Pending' : l1Status === 'Rejected' ? 'Inactive' : l2Status}
                                            </Button>
                                        </TableCell>
                                        <TableCell>{l1Status === 'Pending' || l1Status === 'Rejected' ? '-' : candidateData?.l2Remarks || '-'}</TableCell>
                                    </TableRow>
                                    {candidateData?.l3Approver?.userName && (
                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}> {candidateData?.l3Approver?.userName || '-'} </TableCell>
                                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                <Button
                                                    variant="outlined"
                                                    style={{
                                                        color: l1Status === 'Pending' ? '#FFA940' : l2Status === 'Rejected' ? '#B0BEC5' : l3Status === '-' ? '#FFA940' : getButtonColor(l3Status),
                                                        borderColor: l1Status === 'Pending' ? '#FFA940' : l2Status === 'Rejected' ? '#B0BEC5' : l3Status === '-' ? '#FFA940' : getButtonColor(l3Status),
                                                        backgroundColor: l1Status === 'Pending' ? '#FFA94010' : l2Status === 'Rejected' ? '#B0BEC510' : l3Status === '-' ? '#FFA94010' : `${getButtonColor(l3Status)}10`,
                                                        fontSize: '0.75rem',
                                                        height: '24px',
                                                        textTransform: 'none',
                                                        fontWeight: '500',
                                                    }}
                                                >
                                                    {l1Status === 'Pending' ? 'Pending' : l2Status === 'Rejected' ? 'Inactive' : l3Status === '-' ? 'Pending' : l3Status}
                                                </Button>
                                            </TableCell>
                                            <TableCell>{l1Status === 'Pending' || l2Status === 'Rejected' ? '-' : candidateData?.l3Remarks || '-'}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
                <Divider sx={{ marginY: 4 }} />
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <StyledLabel>Consolidated Interview Feedback</StyledLabel>
                        <TextField value="Selected" disabled
                            sx={{
                                width: '250px', height: '40px',
                                '& .MuiInputBase-root': {
                                    height: '40px',
                                    backgroundColor: '#389E0D1A',
                                    borderRadius: '4px',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '& .MuiInputBase-input': {
                                    fontSize: '0.75rem',
                                    color: '#389E0D',
                                },
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#389E0D',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <StyledLabel>Feedback</StyledLabel>
                        <Button startIcon={<VisibilityIcon />} variant="outlined" onClick={() => navigate(`/interviewFeedBackForm/${candidateId}`)}
                            sx={{
                                fontSize: "0.625rem", fontWeight: "500", color: '#00000080', textTransform: 'none', border: 'none', boxShadow: 'none', width: '250px', height: '40px', backgroundColor: '#0000000D',
                                '&:hover': {
                                    backgroundColor: '#0000000D',
                                    boxShadow: 'none',
                                    border: 'none',
                                }
                            }}
                        >
                            View Submitted Feedback
                        </Button>
                    </Grid>
                </Grid>
                {candidateData?.l1ApproverHistory?.[0] && (
                    <>
                        <Divider sx={{ marginY: 4 }} />
                        <Box sx={{pb : 2}}>
                            <Typography fontSize="0.875rem" fontWeight="600" marginBottom={2}>
                                Approvers History
                            </Typography>
                        </Box>
                        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                            <Table>
                                <TableHead>
                                    <TableRow className="table-header">
                                        <TableCell className="table-body" sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}> Approvers </TableCell>
                                        <TableCell className="table-body" sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}> Status </TableCell>
                                        <TableCell className="table-body">Remarks</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            {candidateData?.l1ApproverHistory?.[0]?.approver?.userName || '-'}
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <Button
                                                variant="outlined"
                                                style={{
                                                    color: getButtonColor(Object.keys(candidateData?.l1ApproverHistory?.[0]?.status || {})[0] || 'Unknown'),
                                                    borderColor: getButtonColor(Object.keys(candidateData?.l1ApproverHistory?.[0]?.status || {})[0] || 'Unknown'),
                                                    backgroundColor: `${getButtonColor(Object.keys(candidateData?.l1ApproverHistory?.[0]?.status || {})[0] || 'Unknown')}10`,
                                                    fontSize: '0.75rem',
                                                    height: '24px',
                                                    textTransform: 'none',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                {Object.keys(candidateData?.l1ApproverHistory?.[0]?.status || {})[0] || '-'}
                                            </Button>
                                        </TableCell>
                                        <TableCell>{candidateData?.l1ApproverHistory?.[0]?.comments || '-'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            {candidateData?.l2ApproverHistory?.[0]?.approver?.userName || '-'}
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <Button
                                                variant="outlined"
                                                style={{
                                                    color: getButtonColor(Object.keys(candidateData?.l2ApproverHistory?.[0]?.status || {})[0] || 'Unknown'),
                                                    borderColor: getButtonColor(Object.keys(candidateData?.l2ApproverHistory?.[0]?.status || {})[0] || 'Unknown'),
                                                    backgroundColor: `${getButtonColor(Object.keys(candidateData?.l2ApproverHistory?.[0]?.status || {})[0] || 'Unknown')}10`,
                                                    fontSize: '0.75rem',
                                                    height: '24px',
                                                    textTransform: 'none',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                {Object.keys(candidateData?.l2ApproverHistory?.[0]?.status || {})[0] || '-'}
                                            </Button>
                                        </TableCell>
                                        <TableCell>{candidateData?.l2ApproverHistory?.[0]?.comments || '-'}</TableCell>
                                    </TableRow>
                                    {candidateData?.l3ApproverHistory?.[0]?.approver?.userName && (
                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                {candidateData?.l3ApproverHistory?.[0]?.approver?.userName || '-'}
                                            </TableCell>
                                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                <Button
                                                    variant="outlined"
                                                    style={{
                                                        color: getButtonColor(Object.keys(candidateData?.l3ApproverHistory?.[0]?.status || {})[0] || 'Unknown'),
                                                        borderColor: getButtonColor(Object.keys(candidateData?.l3ApproverHistory?.[0]?.status || {})[0] || 'Unknown'),
                                                        backgroundColor: `${getButtonColor(Object.keys(candidateData?.l3ApproverHistory?.[0]?.status || {})[0] || 'Unknown')}10`,
                                                        fontSize: '0.75rem',
                                                        height: '24px',
                                                        textTransform: 'none',
                                                        fontWeight: '500',
                                                    }}
                                                >
                                                    {Object.keys(candidateData?.l3ApproverHistory?.[0]?.status || {})[0] || '-'}
                                                </Button>
                                            </TableCell>
                                            <TableCell>{candidateData?.l3ApproverHistory?.[0]?.comments || '-'}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </Card>
            {/* <Box mt={2} display="flex" justifyContent="flex-end">
                {!approversAdded && candidateData?.approversAssigned === false &&
                    <Button variant="contained" onClick={handleProceedClick} sx={{ backgroundColor: "#1677FF", fontSize: "0.625rem", fontWeight: "700" }}> Proceed this offer </Button>
                }
            </Box> */}
            <Box mt={2} display="flex" justifyContent="flex-end">
                {candidateData?.proceedToOffer === "Selected To Offer" &&
                    <Button variant="contained" onClick={handleProceedClick} sx={{ backgroundColor: "#1677FF", fontSize: "0.625rem", fontWeight: "700" }}> Proceed this offer </Button>
                }
            </Box>
            <ApproverDialog open={isDialogOpen} onClose={handleDialogClose} onAddApprover={handleAddApprover} candidateId={candidateId} />
        </Box >
    );
};

export default JobOfferApproval;
