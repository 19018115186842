import React from 'react';
import { TextField } from '@mui/material';

interface CommonTextFieldProps {
  label: string;
  value: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  multiline?: boolean;
  rows?: number;
  size?: 'small' | 'medium';
  error?: boolean;
  helperText?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void; // Add this line
}

const CommonTextField: React.FC<CommonTextFieldProps> = ({ label, value, name, onChange, type = 'text', multiline = false, rows = 1, size = 'small', error = false, helperText = '',onClick }) => {
  const today = new Date().toISOString().split('T')[0]; // Get today's date in yyyy-MM-dd format
  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
  const maxDateForDob = eighteenYearsAgo.toISOString().split('T')[0]; // Calculate the max date for dateOfBirth

  return (
    <TextField
      fullWidth
      autoComplete="chrome-off"
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      onClick={onClick}
      type={type}
      multiline={multiline}
      rows={rows}
      size={size}
      variant="outlined"
      error={error} // Ensure error prop is passed correctly
      helperText={helperText} // Ensure helperText prop is passed correctly
      
      InputLabelProps={{
        sx: { 
          fontSize: '0.8rem',
          fontWeight: 410, color: "gray",
          transform: 'translate(14px, 12px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          }
        },
        shrink: type === 'date' || value?.length > 0,
        // shrink: true,
      }}
      InputProps={{
        inputProps: {
          min: name === 'prefStartDate' ? today : undefined,
          max: name === 'dateOfBirth' ? maxDateForDob : undefined, 
        },
        sx: {
          '& input': {
            color: value ? 'inherit' : 'gray', 
            fontWeight: 'normal', 
          },
        },
      }}
      
    />
  );
};


export default CommonTextField;
