export const truncateText = (text: string, maxLength: number): string => {
    return text?.length > maxLength ? text?.slice(0, maxLength) + '...' : text;
  };

export const extractFileName= (url: any) => {
  if (!url) return { fileName: '', fileExt: '' };

  const fullFileName = url.split('/').pop().split('?')[0] || '';

  // Remove the prefix before the first underscore
  const actualFileName = fullFileName.includes('_')
    ? fullFileName.substring(fullFileName.indexOf('_') + 1)
    : fullFileName;

  const [fileNameWithoutExt, fileExt] = actualFileName.split(/\.(?=[^.]+$)/);

  const truncatedFileName = fileNameWithoutExt.length > 6 ? `${fileNameWithoutExt.slice(0, 6)}..` : fileNameWithoutExt;

  return {
    fileName: `${truncatedFileName}.${fileExt}`,
    fullFileName: actualFileName, 
    fileExt: fileExt.toLowerCase(), 
  };
};