import React, { useRef, useState } from 'react';
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import pdfImage from '../../../../assets/images/pdf 1.svg';
import wordImage from '../../../../assets/images/doc 1.svg';
import { useAppDispatch } from '../internal/contracttab';
import { fileBulkUpload } from '../../../../redux/features/resume';
import { toast } from 'react-toastify';
import { getCandidateByRthIdandRecruiterId } from '../../../../redux/features/candidate';
 
const FileUploadDialog = ({ open, onClose, selectedFiles, setSelectedFiles }:any) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const navigate=useNavigate();
  const {jobTitle,id}=useParams();
  const[loading,setLoading]=useState<boolean>(false);
  const dispatch=useAppDispatch();
  const recId=localStorage.getItem('userId');
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;
    if (file) {
      const newFiles = Array.from(file);
      setSelectedFiles((prevFiles:any) => [...prevFiles, ...newFiles]);
    }
  };
 
  const filterPayload = {
    resumesPostedInFrom:  null,
    resumesPostedInTo:  null,
    interviewStages:  null,
    source:  null,
    expectedCtc:  null,
    noticePeriod:  null,
    status:  null,
    page: 0,
    size: 20,
  };

  const handleAddFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
 
  const handleRemoveFile = (index: number) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };
 
  const handleClearAllFiles = () => {
    setSelectedFiles([]);
  };
 
  const getFileIcon = (file: any) => {
    const extension = file.name.split('.').pop()?.toLowerCase();
 
    switch (extension) {
      case 'pdf':
        return <img src={pdfImage} alt={'pdf'}/>;
      case 'doc':
      case 'docx':
        return <img src={wordImage} alt={'wordDocument'}/>;
      case 'jpg':
      case 'jpeg':
      case 'png':
        return <Avatar alt={file.name} src={URL.createObjectURL(file)} sx={{ width: 40, height: 40 }} />; // Image preview
      default:
        return <InsertDriveFileIcon sx={{ fontSize: 40, color: '#888' }} />; // Default generic file icon
    }
  };
  const handleReviewNow = async () => {
    setLoading(true)
    if (selectedFiles.length > 0) {
      const formData = new FormData();
      selectedFiles.forEach((file: File) => {
        formData.append('resumes', file); // Assuming 'files' is the field name expected by the API
      });
 
      // Dispatch the fileBulkUpload thunk with FormData and rthId (id from useParams)
      const resultAction = await dispatch(fileBulkUpload({ data: formData, rthId: id,recId:recId}));
 
      if (fileBulkUpload.fulfilled.match(resultAction)) {
        const{status,message}=resultAction.payload||{};
        if(status===true){
        // toast.success('Resumes Uploaded Successfully');
        setLoading(false);
        onClose();
        navigate(`/newRequestToHire/${jobTitle}/${id}/BulkUpload/Forms`);
        }
        else if(status===false){
          toast.error(message);
        }
      } else {
        console.error('Error uploading files:', resultAction.payload || resultAction.error);
      }
    }
  };

  const handleReviewLater=async()=>{
    setLoading(true)
    if (selectedFiles.length > 0) {
      const formData = new FormData();
      selectedFiles.forEach((file: File) => {
        formData.append('resumes', file); // Assuming 'files' is the field name expected by the API
      });
 
      // Dispatch the fileBulkUpload thunk with FormData and rthId (id from useParams)
      const resultAction = await dispatch(fileBulkUpload({ data: formData, rthId: id,recId:recId}));
 
      if (fileBulkUpload.fulfilled.match(resultAction)) {
        const{status}=resultAction.payload||{};
        setLoading(false);
        if(status===true){
        toast.success('Resumes Uploaded Successfully');
        dispatch(
              getCandidateByRthIdandRecruiterId({
                rthId: id,
                recId: recId,
                data: filterPayload,
                searchData: '',
              })
            );
        setLoading(false);
        onClose();
        }
        else if(status===400||status===false){
          toast.error('Error in uploading the files');
        }
      } else {
        console.error('Error uploading files:', resultAction.payload || resultAction.error);
      }
    }
  }
  
  // const handleCloseDialog=()=>{
  //   onClose();
  //   setSelectedFiles([]);
  // }
 
  return (
    <>
    <Dialog open={open} maxWidth="md" fullWidth>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
     <DialogTitle>Preview</DialogTitle>
      {/* <CloseIcon sx={{ cursor: 'pointer',mr:'10px' }} onClick={handleCloseDialog} /> */}
    </Box>
      <DialogContent>
      <Box sx={{ backgroundColor: '#f4f8ff', borderRadius:'11px'}}>
        {selectedFiles.length > 0 && 
          <Typography variant="body2" sx={{ pt:2, pr:2, cursor: 'pointer', justifyContent:'flex-end', color:'#1677FF', display:'flex' }} onClick={handleClearAllFiles}>
          Clear all files
        </Typography>}        
        <Grid container spacing={2} sx={{ padding: 2, borderRadius: '8px' }}>       
          <Grid item xs={12} sm={6}  md={selectedFiles.length === 0 ? 12 : 4}>
            <Box
              sx={{
                border: '2px dashed #90caf9',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 44,
                cursor: 'pointer',
                borderRadius: '8px',
              }}
              onClick={handleAddFileClick}
            >
              <AddIcon sx={{ fontSize: 30, color: '#90caf9' }} />
             
            </Box>
          </Grid>
          {selectedFiles.map((file:any, index:any) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: 1,
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  border: '1px solid white',
                  cursor:'pointer'
                }}
              >
                <Box sx={{mr:'3px'}}>
                {getFileIcon(file)}
                </Box>
                <Typography sx={{ flexGrow: 1, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  {file.name}
                </Typography>
                <IconButton onClick={() => handleRemoveFile(index)} size="small">
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </Grid>
          ))}
        </Grid>
        </Box>

      </DialogContent>
      <DialogActions>
        <ErrorOutlineIcon sx={{color:'#1677FF',ml:'20px',mb:'70px'}}/>
        <Typography variant="body2" sx={{ flexGrow: 1,mb:'70px' }}>
          Review your uploaded files now, or access them later from your uploaded profiles.
        </Typography>
        <Box sx={{mt:'30px'}}>
        <Button onClick={handleReviewLater} disabled={loading} sx={{ mr:2, textTransform:'none',color:'#848484'}}>
          Review Later
        </Button>
        <Button variant='contained' disabled={loading} sx={{mr:2,textTransform:'none',backgroundColor:'#1677FF',color:'white',borderRadius:'4px',width:'120px'}} onClick={handleReviewNow}>
          Review Now
        </Button>
        </Box>
      </DialogActions>
 
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept=".pdf,.doc,.docx"
      />
    </Dialog>
    </>
  );
};
 
export default FileUploadDialog;