import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Grid, Radio, RadioGroup, FormControlLabel, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {  InterviewLinkUI, SearchableDropdown, SelectUI, TextFieldUI } from './InputsInterviewSchedule';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { editInterview, getCandidateInterviewDetails, getInterviewDetailsByRecruiterId, getL1Approvers, getMrfDetails, sentInterviewMail } from '../../../../../redux/features/interviewSchedule';
import { InterviewMode } from '../../../../../constants/dummyConst';
import { toast } from 'react-toastify';
import {  getRthPositionReportsTo } from '../../../../../Crud/rth';
import { BASE_COPY_URL } from '../../../../../constants/Crudurl';
 
 
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
 
 
 
export default function EditInterview ( {DialogOpen, close,interviewData }:any ) {
 
  const [typeOfRTH, setTypeOfRTH] = React.useState('Internal');
  const hasInitialized = useRef(false);
  const [rounds, setRounds] = React.useState<string[]>([]);  
  const mrfDetailsData=useAppSelector((state:any)=>state.resume.mrfDetails);
  const[currentInterviewDate,setCurrentInterviewDate]=useState<Date | null>(null);
  const [values, setValues]=useState<any>({
     
  });

 const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const dispatch=useAppDispatch();
  // const state=useAppSelector((state)=>state.interviewSchedule);

  const {id,jobTitle} = useParams();
   
  const handleclose = () => {
    hasInitialized.current = false;
    close();
    setRounds([]);
    setErrors({});
  }
 
  // const fetchInterviewers = async (query: string) => {
  //   // Replace with your API logic
  //    await dispatch(getL1Approvers(query));
  //   return l1Approvers.map((item: { userId: any; firstName: string ,lastName:string }) => ({
  //     label: item.firstName + item.lastName,
  //     value: item.userId,
  //   }));
  // };
  
  const handleInputChangeNormal = (event: React.ChangeEvent<HTMLInputElement>) => {   
    const { name, value } = event.target;    
    setValues({
      ...values,
      [name]: value,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the error
    }));
  };


  const handleInputChangeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // Update form data
    setValues((prev:any) => ({
      ...prev,
      [name]: value,
    }));

    // Validation logic
    const parsedTime = new Date(`1970-01-01T${value}:00`);
    const startFromTime = new Date('1970-01-01T08:00:00'); // 08:00 AM
    const endFromTime = new Date('1970-01-01T18:55:00'); // 06:55 PM
    const startToTime = new Date('1970-01-01T08:05:00'); // 08:05 AM
    const endToTime = new Date('1970-01-01T19:00:00'); // 07:00 PM

    let errorMessage = '';

    if (name === 'timeFrom') {
      if (parsedTime < startFromTime || parsedTime > endFromTime) {
        errorMessage = 'Time From must be between 08:00 AM and 06:55 PM';
      }
    } else if (name === 'timeTo') {
      if (parsedTime < startToTime || parsedTime > endToTime) {
        errorMessage = 'Time To must be between 08:05 AM and 07:00 PM';
      }
    }

    // Update error state
    setErrors((prev) => ({
      ...prev,
      [name]: errorMessage,
    }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, interviewRound: string | undefined) => {
    const { name, value } = event.target;
    // Dynamically update state based on interviewRound
    setValues((prevValues:any) => ({
      ...prevValues,
        [name]: value,
    }));
    validateField(name, value);
    // Clear errors for the specific field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };
  
 
 
  const handleSelectChange = (e:any) => {
    const { name, value } = e.target;
     setValues((prevValues:any)=>({
    ...prevValues,
    [name]:value||null,
   }))
   validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', 
    }));
  };
  
  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    let fromTime = values.from;
    let toTime = values.to;
  
    // Extract time from datetime if in the format "YYYY-MM-DDTHH:mm:ss"
    if (fromTime.includes('T')) {
      fromTime = fromTime.split('T')[1].slice(0, 5); // Extract "HH:mm"
    }
  
    if (toTime.includes('T')) {
      toTime = toTime.split('T')[1].slice(0, 5); // Extract "HH:mm"
    }
  
    
    // Add validation for each required field
    if (!values.interviewRound) {
      newErrors.interviewRound = 'Interview round is required.';
    }
    if (!values.interviewMode) {
      newErrors.interviewMode = 'Interview mode is required.';
    }
    // if (!values.interviewLink) {
    //   newErrors.interviewLink = 'Interview link is required.';
    // }
    if (!values.interviewDate) {
      newErrors.interviewDate = 'Interview date is required.';
    }
    if (!values.from) {
      newErrors.from = 'Start time is required.';
    }
    if (!values.to) {
      newErrors.to = 'End time is required.';
    }
    if(fromTime===toTime){
      newErrors.from='From and To Date should not be same';
    }
    if(fromTime > toTime){
      newErrors.from='From Should be Earlier than To Date';
    }
    // if (!values?.interviewer?.userId||!values.interviewerId) {
    //   newErrors.interviewerId = 'Interviewer is required.';
    // }
  
    setErrors(newErrors);
  
    // Return whether the form is valid
    return Object.keys(newErrors).length === 0;
  };

  const validateField = (name: string, value: any) => {
    let errorMsg = '';
  
    // Add validation rules
    if (!value) {
      errorMsg = 'This field is required.';
    }
  
    // Update errors state
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));
  };
  
  const handleSubmit = async() => {
       
    if (validateForm()) {
           
      const formatTimeWithDate = (time: string, date: Date): string => {
              
        if (!time) return "";
      
          if (date === currentInterviewDate && time.includes("T") && !isNaN(new Date(time).getTime())) {
          return time; 
        }
      
        
        if (!date) return "";
      
        
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) return "";
      
        const formattedDate = parsedDate.toISOString().split("T")[0];
      
         const cleanTime = time.split("T")[1] || time;
      
        
        return `${formattedDate}T${cleanTime}:00`;
      };
       
      
      const formattedFrom = formatTimeWithDate(values?.from, values?.interviewDate);
      const formattedTo = formatTimeWithDate(values?.to, values?.interviewDate);
    
      const payload={
        candidateId:values?.candidateId,
        interviewPlatform:values?.interviewPlatform,
        rthId:values?.rthId,
        recruiterId:values?.recruiterId,
        [values?.roundKey]:{
          interviewRound:values?.interviewRound,
          interviewName:values?.interviewName,
          interviewMode:values?.interviewMode,
          interviewLink:values?.interviewLink,
          interviewDate:values?.interviewDate,
          from:formattedFrom,
          to:formattedTo,
          interviewerId:values?.interviewer?.userId||values?.interviewerId||null,
          optionalAttendeesId:values?.optionalAttendees?.userId||values?.optionalAttendeesId||null,
        }
      }
      const filterOptions = {
        "interviewDateFrom": null,
        "interviewDateTo": null,
        "interviewStages": null,
        "source": null,  
        "expectedCtc": null,
          "noticePeriod": null  
      }
      
    const resultAction=await dispatch(editInterview({interviewId:values?.interviewId,data:payload}));
    if(editInterview.fulfilled.match(resultAction)){
      const{status,message,data}=resultAction.payload||{};
      const feedBackFormLink = `${BASE_COPY_URL}/newRequestToHire/${jobTitle}/${id}/${values?.candidateName}/${data?.candidateId}/${values?.round}/${data?.interviewId}/InterviewFeedBack`;
            const interviewLink = data?.[values?.interviewRound]?.interviewLink;
      
                const mailPayload={
                  interviewId:data?.interviewId,
                  level:values?.interviewRound==='interviewDetL1'?'L1Round':values?.interviewRound==='interviewDetL2'?'L2Round':values?.interviewRound==='interviewDetL3'?'L3Round':'L1Round',
                  interviewLink,
                  feedBackFormLink,
                }
      if(status===true){
        toast.success(message);
        dispatch(getInterviewDetailsByRecruiterId({RecruiterId:values?.recruiterId,rthId:values?.rthId,data:filterOptions}));
        dispatch(getCandidateInterviewDetails(values?.candidateId));
        dispatch(sentInterviewMail(mailPayload));
        handleclose();
      }
      else{
        toast.error(message||'Improper Data')
        handleclose();
      }
    }
    } 
  };
   
  useEffect(()=>{
    dispatch(getL1Approvers(''));
  },[rounds,dispatch])

  useEffect(()=>{
  if(id){
    dispatch(getMrfDetails(id));
  }
  },[id,dispatch])

  useEffect(()=>{
    if(mrfDetailsData){
      setTypeOfRTH(mrfDetailsData?.TypeOfRth)
    }
  },[mrfDetailsData]);

  useEffect(()=>{
    if(interviewData)
    setValues(interviewData);
    setCurrentInterviewDate(interviewData?.interviewDate);
setRounds([interviewData?.interviewName]);
  },[interviewData]);
        
  return (
    <React.Fragment>
      <Dialog
      open={DialogOpen}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="schedule-interview-dialog-description"
      maxWidth={false}
      fullWidth
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          handleclose();
        }
      }}
      PaperProps={{
        style: {
          width: '1400px',
          maxWidth: '1400px',  
          borderRadius:'8px'        
        },
      }}
    >
        <Box sx={{border:'1px solid #D9D9D9', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center' }}>
        <DialogTitle sx={{ p:'20px', pl:'28px' }}>Edit Interview for {values?.candidateName}</DialogTitle>
        <Box sx={{pr:'20px'}}>
        <Box sx={{width:'30px', height:'30px', bgcolor:'#ECECEC', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center' }}>
        <CloseRoundedIcon sx={{color:'#989696',  fontSize:'24px', cursor: 'pointer', '&:hover':{color:'#4a4a4a'} }}  onClick={handleclose} />
        </Box>
        </Box>
        </Box>
        <DialogContent>
          <Box component="form" sx={{ mt: 2 }}>
            <Grid container spacing={3}>
             
              {/* Candidate */}
                   {/* Type of MRF */}
              <Grid item xs={12}>
              <Typography>Type of MRF</Typography>
              <RadioGroup row value={typeOfRTH} sx={{cursor:'not-allowed'}}>
                <Box
                  sx={{
                    bgcolor: typeOfRTH === "Internal" ? "#F0F5FF" : "#00000008",
                    mr: "10px",
                    height: "50px",
                    borderRadius: "8px",
                    paddingLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    border: typeOfRTH === "Internal" ? "1px solid #1677FF" : "none",
                    mt: "10px",
                    width: "182px",
                    cursor:'not-allowed'
                  }}
                >
                  <FormControlLabel
                    value="Internal"
                    sx={{ color: typeOfRTH === "Internal" ? "#1677FF" : "#919EAB",cursor:'not-allowed' }}
                    control={<Radio sx={{ color: typeOfRTH === "Internal" ? "#1677FF" : "#919EAB", '&.Mui-checked': { color: '#1677FF' } }} />}
                    label="Internal"
                  />
                </Box>
 
                <Box
                  sx={{
                    bgcolor: typeOfRTH === "External" ? "#F0F5FF" : "#00000008",
                    mr: "10px",
                    height: "50px",
                    borderRadius: "8px",
                    paddingLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    border: typeOfRTH === "External" ? "1px solid #1677FF" : "none",
                    mt: "10px",
                    width: "182px",
                    cursor:'not-allowed'
                  }}
                >
                  <FormControlLabel
                    value="External"
                    sx={{ color: typeOfRTH === "External" ? "#1677FF" : "#919EAB",cursor:'not-allowed' }}
                    control={<Radio sx={{ color: typeOfRTH === "External" ? "#1677FF" : "#919EAB", '&.Mui-checked': { color: '#1677FF' } }} />}
                    label="External"
                  />
                </Box>
              </RadioGroup>
            </Grid>
 
            {/* Interview Platform */}
            <Grid item xs={12}>
              <Typography>Interview Platform</Typography>
              <RadioGroup row value={'External'} name='interviewPlatform' onChange={(e) => handleInputChangeNormal(e)}>
                <Box
                  sx={{
                    bgcolor: values?.interviewPlatform === "Theron Connect" ? "#F0F5FF" : "#00000008",
                    mr: "10px",
                    height: "50px",
                    borderRadius: "8px",
                    paddingLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    border: values?.interviewPlatform === "Theron Connect" ? "1px solid #1677FF" : "none",
                    mt: "10px",
                    width: "182px",
                  }}
                >
                  <FormControlLabel
                    value="Theron Connect"
                    sx={{ color: values?.interviewPlatform === "Theron Connect" ? "#1677FF" : "#919EAB" }}
                    control={<Radio sx={{ color: values?.interviewPlatform === "Theron Connect" ? "#1677FF" : "#919EAB", '&.Mui-checked': { color: '#1677FF' } }} />}
                    label="Theron Connect"
                  />
                </Box>
 
                <Box
                  sx={{
                    bgcolor: values?.interviewPlatform === "External" ? "#F0F5FF" : "#00000008",
                    mr: "10px",
                    height: "50px",
                    borderRadius: "8px",
                    paddingLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    border: values?.interviewPlatform === "External" ? "1px solid #1677FF" : "none",
                    mt: "10px",
                    width: "182px",
                  }}
                >
                  <FormControlLabel
                    value="External"
                    sx={{ color: values?.interviewPlatform === "External" ? "#1677FF" : "#919EAB" }}
                    control={<Radio sx={{ color: values?.interviewPlatform === "External" ? "#1677FF" : "#919EAB", '&.Mui-checked': { color: '#1677FF' } }} />}
                    label="External"
                  />
                </Box>
              </RadioGroup>
            </Grid>
             
              {/* Interview Round - 01 */}
              <Grid item xs={12} sx={{overflowY:'auto', height:'auto'}}>
              {rounds.map((round) => (
                <Box sx={{ bgcolor: '#f7f7f7', height: '100px', p: '20px', borderRadius: '8px', mb: '20px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pb: '16px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{round}</Typography>
                                      </Box>
                  <Box sx={{ display: 'flex', overflowX: 'auto', flexWrap: 'nowrap', gap: 1, height:55 }}>
                    <Grid item xs={12} sm={6} md={3}>
                  <TextFieldUI
                    value={values?.interviewRound || ''}
                    name={"interviewRound"}
                    placeholder={"L1"}
                    handleInputChange={(event:any) => handleInputChange(event, values?.interviewRound)}
                    width={'150px'}
                    error={!!errors.interviewRound}
                    helperText={errors.interviewRound}                  
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <SelectUI value={values?.interviewMode || ''} handleInputChange={handleSelectChange} 
                    name={"interviewMode"} option={InterviewMode} label={'Interview mode'} width={'150px'}
                    error={!!errors.interviewMode}
                    helperText={errors.interviewMode}
                  
                       />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <InterviewLinkUI value={values?.interviewLink || ''} 
                    name={`interviewLink`} 
                    placeholder={"Interview Link"} handleInputChange={handleInputChange}
                     width={'150px'}
                     error={!!errors.interviewLink}
                     helperText={errors.interviewLink}
                   />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                    <TextFieldUI value={values?.interviewDate || ''} name={`interviewDate`} placeholder={"L1"} handleInputChange={handleInputChange} width={'150px'} type={'date'} 
                      error={!!errors.interviewDate}
                      helperText={errors.interviewDate}  
                    />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                    <TextFieldUI value={values?.from?.split('T')[1]?.slice(0, 5) ||values?.from|| ''} name={`from`} placeholder={""} handleInputChange={handleInputChangeTime} width={'150px'} type={'time'} 
                      error={!!errors.from}
                      helperText={errors.from}  
                    />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                    <TextFieldUI value={values?.to?.split('T')[1]?.slice(0, 5) ||values?.to|| ''} name={`to`} placeholder={""} handleInputChange={handleInputChangeTime} width={'150px'} type={'time'}
                    error={!!errors.to}
                    helperText={errors.to}  
                     />
                     </Grid>

                     <Grid item xs={12} sm={6} md={3}>
                    {/*  */}
                    <SearchableDropdown
                      searchAPICall={getRthPositionReportsTo} 
                      label="Choose Interviewer"
                      value={values.interviewer?.userId ? { id: values.interviewer.userId, label: values.interviewer.userName || '' } : null}
                      onOptionSelect={(selectedOption:any) =>
                        setValues((prevValues:any) => ({
                          ...prevValues,
                          interviewer: selectedOption
                            ? {
                                userId: selectedOption.id,
                                userName: selectedOption.label.split(' | ')[0], // Extract name from the label
                                designation: selectedOption.label.split(' | ')[1], // Extract designation from the label
                              }
                            : null, // Reset to null if no option is selected
                        }))
                      }
                      error={!!errors.interviewerId}
                      helperText={errors.interviewerId}
                      name="interviewerId"
                    />

                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <SearchableDropdown
                      searchAPICall={getRthPositionReportsTo} // Same API call as the original component
                      label="Optional Attendees"
                      value={
                        values?.optionalAttendees?.userId
                          ? { id: values?.optionalAttendees?.userId, label: values?.optionalAttendees?.name || '' }
                          : null
                      }
                      onOptionSelect={(selectedOption:any) =>setValues((prevValues:any)=>({
                        ...prevValues,
                        optionalAttendees:selectedOption
                        ?{
                          userId:selectedOption.id,
                          userName:selectedOption.label.split('|')[0],
                          designation:selectedOption.label.split('|')[1],
                        }
                        :null,
                      }))
                      } 
                      error={!!errors.optionalAttendees}
                      helperText={errors.optionalAttendees}
                      name="optionalAttendees"
                    />
                    </Grid>
                    </Box>
                </Box>

                ))}
                 
                                           
              </Grid>
 
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{height:'50px'}}>
          <Box sx={{pb:'20px'}}>
          <Button variant="contained" sx={{ bgcolor:"#3185FC", borderRadius:'8px', mr: 2, textTransform:'none','&:hover':{bgcolor:'#3185FC'}}} disabled={rounds.length <= 0} onClick={handleSubmit}  >
          Update
          </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>    
  );
}

