import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  Button, 
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Slide,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchJobById } from "../../../redux/features/jobcardSlice";
import Headergpt from "./headergpt";
import InternalOnRoleDetails from "./internalonroledetails";
import Indeed from "../../../assets/images/indeed.svg";
import Naukri from "../../../assets/images/naukri.svg";
import LinkedIn from "../../../assets/images/linkedin.svg";
import Pencil from "../../../assets/images/pencilIcon.svg";
import ReactQuill from "react-quill";
import { TransitionProps } from "@mui/material/transitions";
import { getJdEditAPIData, getJobPostedInAPIData } from "../../../Crud/card";
import SwitchWithLabel from "../../common/SwitchWithLabel";
import { toast } from "react-toastify";
import CopyUrlBox from "../../common/CopyUrlBox";
import InterviewRounds from "../../CustomComponents/InterviewRounds";
import OverallDetail from "../../card/Jobcard/overallrth/OverallDetail";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { getMoveToJobAPIData, getWaitingToMoveToJobAPIData } from "../../../Crud/card";
import { wtMJobGetData } from "../../../redux/features/wTMJob";

// import AssignRecruiterView from "./AssignRecruiterView";
 
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
 
const UxDesign: React.FC = () => {
  const interviewRoundCount = useSelector((state:RootState)=>state.common.interviewRounds)
  const location = useLocation();
  const { jobData } = useAppSelector((state) => state.jobs);
  const [switchStates, setSwitchStates] = useState({
    indeed: false,
    naukri: false,
    linkedIn: false,
  });
  const [switchDate, setSwitchDate] = useState({
    indeed: "",
    naukri: "",
    linkedIn: "",
  });
 
  const [loading, setLoading] = useState(true);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const { id, status, label } = location.state || {};
  console.log("label da",label);
  const [openJdModel, setOpenJDModel] = useState(false);
  const [jdValue, setJdValue] = useState("");
  const [value, setValue] = useState("");
  const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
  const wtMJobStatus = useSelector((state: RootState) => state.wtMJob.wtMJobStatus);
  const navigate  = useNavigate();

  const dispatch = useAppDispatch();
  console.log("status", status);
  console.log("switchDate", switchDate);
  console.log("switchDate", jobData?.postedIn);
  console.log("Complete location state:", location.state);

  const userId = localStorage?.getItem("userId")??"";
  


  const handleDataUpdate = () => {
    setRefreshTrigger(prev => prev + 1);
  };
  
  // console.log("jobData===>", jobData);
  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(fetchJobById(id)).finally(() => setLoading(false));
    }
  }, [dispatch, id, refreshTrigger]);
 
 
 
  useEffect(() => {
    if (jobData?.postedIn) {
      setSwitchStates((prevState) => {
        const updatedStates = Object.keys(prevState).reduce((acc, key) => {
          acc[key] = Object.hasOwn(jobData.postedIn, key);
          return acc;
        }, {} as Record<string, boolean>);
 
        return {
          ...prevState,
          ...updatedStates,
        };
      });
     
    }
  }, [jobData?.postedIn]);
 
  const processSteps = [
    "Open",
    "Move to Job",
    "Create JD",
    "Job Post",
    "Uploading Resumes",
    "Hiring Process",
    "Closed",
  ];
  const approvalStatus = { l1: "Approved", l2: "Pending" };
  const modules = {
    toolbar: [
       [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ align: [] }],
    ["blockquote", "code-block"],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    ["link"],
    ["clean"],
    ],
  };
  const formats = [ 'header', 'font', 'size', 'align', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link',  'color', 'background', 'script' ];
  const handleJDEdit = (data: any) => {
    setOpenJDModel(false);
    const datas = {
      jdText: value,
    };
    getJdEditAPIData(data?.rthId, datas);
    setJdValue(value);
    setValue(jdValue);
  };
 
  const jdData = jdValue || jobData?.jdTemplateText;
 
  const handleSwitchChange =
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setSwitchStates((prevStates) => ({
        ...prevStates,
        [key]: event.target.checked,
      }));
      const currentDate = new Date().toISOString().slice(0, 19).replace("T", "T");
      setSwitchDate((prevDates) => ({
        ...prevDates,
        [key]: event.target.checked ? currentDate : "",
      }));
    };
 
    useEffect(() => {
      if (jobData?.postedIn) {
        setSwitchStates((prevStates) => {
          const updatedStates = { ...prevStates };
          Object.keys(updatedStates).forEach((key) => {
            const platformKey = key.charAt(0).toUpperCase() + key.slice(1);
            if (jobData.postedIn[platformKey as keyof typeof jobData.postedIn]) {
              updatedStates[key as keyof typeof updatedStates] = true;
            }
          });
          return updatedStates;
        });
   
        setSwitchDate((prevDate) => {
          const updatedDates = { ...prevDate };
          Object.keys(updatedDates).forEach((key) => {
            const platformKey = key.charAt(0).toUpperCase() + key.slice(1);
            if (jobData.postedIn[platformKey as keyof typeof jobData.postedIn]) {
              updatedDates[key as keyof typeof updatedDates] = jobData.postedIn[platformKey as keyof typeof jobData.postedIn];
            } else {
              updatedDates[key as keyof typeof updatedDates] = "";
            }
          });
   
          return updatedDates;
        });
      }
    }, [jobData?.postedIn,jobData]);
    useEffect(()=>{
      setSwitchDate({
        indeed: jobData?.postedIn?.indeed,
        naukri: jobData?.postedIn?.naukri,
        linkedIn: jobData?.postedIn?.linkedIn,
      })
    },[jobData?.postedIn,setSwitchDate])
   
    const handleSubmit = async () => {
      const filteredPostedIn = Object.fromEntries(
        Object.entries(switchDate).filter(([key, value]) => value !== "")
      );
   
      const reqBody = {
        rthId: jobData?.rthId,
        postedIn: filteredPostedIn,  
        noOfRounds: Number(interviewRoundCount || jobData?.numOfRounds),
      };
   
      try {
        await getJobPostedInAPIData(reqBody);
        toast.success("Posted job sites successfully");
        navigate("/dashboard?tab=job-post")
      } catch (error) {
        toast.error("Posted job sites failed");
      }
    };
   
  console.warn("jobData",jobData);
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
 
  return (
    <Box sx={{ p: 3 }}>
      {/* <Box display={"flex"}>
        <IconButton onClick={handleBackButtonClick}>
          <KeyboardBackspaceIcon />
        </IconButton>
        <Box
          sx={{
            backgroundColor: "white",
            width: "fit-content",
            borderRadius: "5%",
          }}
          p={0.5}
        >
          <img
            src={
              jobData?.business?.businessName === "HEPL"
                ? heplLogo
                : jobData?.business?.businessName === "HEPL"
                ? CITPL
                : heplLogo
            }
            alt=""
          />
        </Box>
      </Box> */}

      <Headergpt
        l1={jobData?.l1Status}
        l2={jobData?.l2Status}
        recruiterAssigned={jobData?.recruiterAssigned}
        recruiterLeadAssigned={jobData?.recruiterLeadAssigned}
        positionTitle={jobData?.positionTitle}
        status={jobData?.status}
        numOfPosition={jobData?.numOfPosition}
        Steps={processSteps}
        data={jobData}
        approvalStatus={approvalStatus}
        numOfOpenPosition={jobData?.numOfOpenPosition}
      />
      {status && (
        <>
          <InterviewRounds />
          <Box
            sx={{
              p: 3,
              backgroundColor: "background.default",
              borderRadius: 2,
              ml: "16px",
              mr: "16px",
              mb: "20px",
              position: "relative",
              overflow: "hidden",
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
          >
            <div
              style={{ paddingRight: "30px" }}
              dangerouslySetInnerHTML={{
                __html: jdData?.replace(/class=/g, "className="),
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 10,
                right: 15,
                cursor: "pointer",
              }}
              onClick={() => {
                setOpenJDModel(true);
                setValue(jdValue || jobData?.jdTemplateText);
              }}
            >
              <img src={Pencil} alt="Pencil icon edit" width={15} />
            </Box>
          </Box>
          <Dialog
            open={openJdModel}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenJDModel(false)}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>Edit Job Description</DialogTitle>
            <DialogContent>
              <ReactQuill
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                  outline: "none",
                  border: "none",
                }}
                value={value}
                onChange={setValue}
                modules={modules}
                formats={formats}
                placeholder="Write something..."
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenJDModel(false)}>Closed</Button>
              <Button onClick={() => handleJDEdit(jobData)}>Update</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      <InternalOnRoleDetails jobData={jobData} />
      {label === "overall MRF" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 1,
            paddingRight: "20px",
          }}
        >
          <OverallDetail job={jobData} onDataUpdate={handleDataUpdate} />
        </Box>
      )}
      {label === "Waiting to Move to Job" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 1,
            paddingRight: "20px",
          }}
        >
          <Button
            sx={{
              textTransform: "none",
              minWidth: "100px",
              padding: "8px 15px",
              fontSize: "16px",
              background: "#1677FF",
              color: "#FFFFFF",
              "&:hover": {
                background: "#1677FF",
                color: "#FFFFFF",
                boxShadow: "none",
              },
            }}
            onClick={async (e) => {
              try {
                e.stopPropagation();
                await getMoveToJobAPIData(id, teamHeadFilter);
                await getWaitingToMoveToJobAPIData(userId, teamHeadFilter);
                dispatch(wtMJobGetData(!wtMJobStatus));
                toast.success("Job successfully moved!");
                navigate("/dashboard?tab=ready-for-jd-creation");
              } catch (error) {
                console.error("Error moving job:", error);
              }
            }}
          >
            Move to Job
          </Button>
        </Box>
      )}
      {(label === "ready-for-jd-creation" && jobData?.rthProcess !=="JobPost") && (
        <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 1,
          paddingRight: "20px",
        }}
      >
       <Button
       sx={{
         textTransform: "none",
         minWidth: "100px",
         padding: "8px 15px",
         fontSize: "16px",
         background: "#1677FF",
         color: "#FFFFFF",
         "&:hover": {
           background: "#1677FF",
           color: "#FFFFFF",
           boxShadow: "none",
         },
       }}
       onClick={(e) => {
         navigate(`/JDCreation/${id}`);
         e.stopPropagation();
       }}
     >
       Create JD
     </Button>
     </Box>
      )}
      {status && (
        <Box
          sx={{
            p: 3,
            backgroundColor: "background.default",
            borderRadius: 2,
            ml: "16px",
            mr: "16px",
            my: "20px",
            position: "relative",
          }}
        >
          <Typography sx={{ color: "#8C8C8C", fontSize: "14px" }}>
            Job Post{" "}
          </Typography>
          <Box my={1} width={"33%"}>
            <CopyUrlBox id={jobData?.rthId} />
          </Box>
          <SwitchWithLabel
            label="Indeed"
            imageSrc={Indeed}
            checked={switchStates.indeed}
            onChange={handleSwitchChange("indeed")}
            date={switchDate.indeed || jobData?.postedIn?.indeed}
          />
          <SwitchWithLabel
            label="Naukri"
            imageSrc={Naukri}
            checked={switchStates.naukri}
            onChange={handleSwitchChange("naukri")}
            date={switchDate.naukri || jobData?.postedIn?.naukri}
          />
          <SwitchWithLabel
            label="LinkedIn"
            imageSrc={LinkedIn}
            checked={switchStates.linkedIn}
            onChange={handleSwitchChange("linkedIn")}
            date={switchDate.linkedIn || jobData?.postedIn?.linkedIn}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "auto", fontSize: "10px", marginTop: "10px" }}
            onClick={handleSubmit}
            // disabled={disabledButton}
          >
            Submit
          </Button>
        </Box>
      )}
    </Box>
  );
};
 
export default UxDesign;