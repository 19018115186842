import { Box, Button, Typography } from "@mui/material";
import React from "react";
import shareIcon from "../../assets/images/shareIcon.svg";
import AjSelectAndView from "./AjSelectAndView";
import citplLogo from "../../assets/images/citplLogo.svg"
import { toast } from "react-toastify";
import { Job } from "../../redux/features/jobcardSlice";
import { BASE_COPY_URL } from "../../constants/Crudurl";

interface props{
  allPostedJobs:Job[];
  setClickedIndex:Function;
  clickedIndex:number;
}

const AjBody: React.FC<props> = ({allPostedJobs,clickedIndex,setClickedIndex}) => {
  
  const recId=localStorage.getItem('userId');
  const handleShareLinkButton=()=>{
    const link=`${BASE_COPY_URL}/candidateForm/${recId}/${allPostedJobs[clickedIndex]?.rthId}`;
    console.log("clickIndex",allPostedJobs[clickedIndex].rthId);
    navigator.clipboard.writeText(link);
    toast.success("Link Copied Sucessfully")
  }

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "white",
          width: "95%",
          margin: "auto",          
          borderTopLeftRadius:"5px",
          borderTopRightRadius:"5px",
          borderBottom:"1px solid #e5e8e8"                    
        }}
      >
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} >
          <Box flexBasis={"25%"} >
            <Box >
            <Typography color={"#384250"} fontSize={15} paddingLeft={"20px"}>
              All Jobs
            </Typography>
            </Box>
          </Box>
          
          <Box
            flexBasis={"75%"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderLeft={"1px solid #e5e8e8"}
            
          >
            <Box display={"flex"} gap={1} alignItems={"center"} p={2}>
              <img src={citplLogo} alt="B Icon" width={"50px"} height={"50px"}/>
              <Typography color={"#384250"}>{allPostedJobs[clickedIndex]?.positionTitle}</Typography>
              <Typography  color={"#6C737F"} fontSize={13}>
                {allPostedJobs[clickedIndex]?.seqId}
              </Typography>
            </Box>
            <Box p={2}>
              <Button
                sx={{
                  minWidth: "auto",
                  padding: "6px 10px",
                  backgroundColor: "#1657FF",
                  paddingRight: "0px",
                }}
                variant="contained"
                startIcon={<img src={shareIcon} alt="no-data"/>}
                onClick={handleShareLinkButton}
              ></Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <AjSelectAndView  setClickedIndex={setClickedIndex} clickedIndex={clickedIndex} allPostedJobs={allPostedJobs}/>
    </Box>
  );
};

export default AjBody;
