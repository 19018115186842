import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Switch, Typography, IconButton, TextField, Collapse, styled, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import { addRole, fetchRoles } from '../../../../redux/features/roleSlice';
import { toast } from 'react-toastify';

const RightDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    justifyContent: 'flex-end',
  },
  '& .MuiDialog-paper': {
    margin: 0,
    maxWidth: '400px',
    width: '100%',
    height: '100%',
    maxHeight: 'none',
    borderRadius: 0,
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 0,
  marginTop: '12px',
  display: 'flex',
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
        ...theme.applyStyles('dark', {
          backgroundColor: '#177ddc',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 8,
  },
  '& .MuiSwitch-track': {
    borderRadius: 12,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)',
    }),
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface CollapsibleSectionProps {
  title: string;
  children: React.ReactNode;
  defaultExpanded?: boolean;
  permissionKeys?: (keyof permissions)[];
  formData: Partial<RolesData>;
  setFormData: React.Dispatch<React.SetStateAction<Partial<RolesData>>>;
}

export const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  children,
  defaultExpanded = false,
  permissionKeys = [],
  formData,
  setFormData,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const isAllEnabled = permissionKeys.every((key) => formData.permissionsList?.[key] ?? false);

  const handleMainSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setFormData((prevState) => {
      const updatedPermissions = {
        ...prevState.permissionsList,
        ...permissionKeys.reduce((acc, key) => {
          acc[key] = isChecked;
          return acc;
        }, {} as permissions),
      };
      return {
        ...prevState,
        permissionsList: updatedPermissions,
      };
    });
  };

  const handleExpandClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleSwitchClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <Box sx={{ borderBottom: '1px solid #f0f0f0' }}>
      <Box onClick={handleExpandClick}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 1,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {isExpanded ? (<ExpandMoreIcon sx={{ fontSize: 20, color: 'text.secondary', mr: 1 }} />) : (<ChevronRightIcon sx={{ fontSize: 20, color: 'text.secondary', mr: 1 }} />)}
          <Typography variant="caption"> {title} </Typography>
        </Box>
        <AntSwitch size="small" color='primary' checked={isAllEnabled} onChange={handleMainSwitchChange} onClick={handleSwitchClick} />
      </Box>
      <Collapse in={isExpanded}>
        <Box sx={{ ml: 4, mb: 2 }}>{children}</Box>
      </Collapse>
    </Box>
  );
};

interface AddRoleModalProps {
  open: boolean;
  onClose: () => void;
}

export interface permissions {
  recruiterAccess?: boolean;
  assignAndEditRecruiter?: boolean;
  assignAndEditRecruiterLead?: boolean;
  mrfUsers?: boolean;
  addInterviewer?: boolean;
  assignMrf?: boolean;
  viewMrfStage?: boolean;
  editMrfStage?: boolean;
  viewAddComments?: boolean;
  viewResumes?: boolean;
  viewInterviewFeedback?: boolean;
  receiveAlerts?: boolean;
  scheduleRemindersForInterviews?: boolean;
  manageUserSettings?: boolean;
  viewAndManageRoles?: boolean;
  assignRolesToUsers?: boolean;
  recruitment?: boolean;
  mrfUsersApprovers?: boolean;
  useFiltersForLeadsApprovers?: boolean;
  overAllReport?: boolean;
  allPersonLogin?: boolean;
  approveDeclineMrf?: boolean;
  interviewCalenderStatus?: boolean;
  offerApprover?: boolean;
  mrfHub?: boolean;
  resumeVault?: boolean;
  interviewSuite?: boolean;
  filters?: boolean;
  notification?: boolean;
  settings?: boolean;
  focusBoard?: boolean;
  reportsAndMetrics?: boolean;
  commonUser?: boolean;
  resumeOwner?: boolean;
  offerApproverAcess?: boolean;
}

export interface RolesData {
  id?: string;
  roleId?: string;
  roleName: string;
  permissionsList: permissions;
}

const AddRoleModal: React.FC<AddRoleModalProps> = ({ open, onClose }) => {
  const dispatch = useDispatch<AppDispatch>();
  const getDefaultPermissions = (): permissions => ({
    recruiterAccess: false,
    assignAndEditRecruiter: false,
    assignAndEditRecruiterLead: false,
    mrfUsers: false,
    addInterviewer: false,
    assignMrf: false,
    viewMrfStage: false,
    editMrfStage: false,
    viewAddComments: false,
    viewResumes: false,
    viewInterviewFeedback: false,
    receiveAlerts: false,
    scheduleRemindersForInterviews: false,
    manageUserSettings: false,
    viewAndManageRoles: false,
    assignRolesToUsers: false,
    recruitment: false,
    mrfUsersApprovers: false,
    useFiltersForLeadsApprovers: false,
    overAllReport: false,
    allPersonLogin: false,
    approveDeclineMrf: false,
    interviewCalenderStatus: false,
  });
  const [formData, setFormData] = useState<Partial<RolesData>>({ roleName: "", permissionsList: getDefaultPermissions() });
  const ALPHABETIC_REGEX = /^[A-Za-z ]$/;

  useEffect(() => {
    if (!open) {
      setFormData({ roleName: "" });
    }
  }, [open]);

  const handlePermissionToggle = (permissionKey: keyof permissions) => {
    setFormData((prevState) => {
      const updatedPermissions = {
        ...prevState.permissionsList,
        [permissionKey]: !(prevState.permissionsList?.[permissionKey] ?? false),
      };
      return {
        ...prevState,
        permissionsList: updatedPermissions,
      };
    });
  };

  const handleSubmit = async () => {
    if (!formData.roleName || !formData.roleName.trim()) {
      toast.error("Please add a role name");
      return;
    }
    const isAnyPermissionSelected = Object.values(formData.permissionsList ?? {}).some((value) => value === true);

    if (!isAnyPermissionSelected) {
      toast.error("Please select at least one permission");
      return;
    }
    try {
      await dispatch(addRole(formData)).unwrap();
      await dispatch(fetchRoles());
      // toast.success("Role added Successfully");
      onClose();
      setFormData({
        roleName: "",
        permissionsList: getDefaultPermissions(),
      });
    } catch (error) {
      toast.error("Role Name already exists");
    } finally {
      // Any cleanup logic can go here if needed
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 20) {
      const sanitizedValue = value.replace(/[^A-Za-z ]/g, "").toUpperCase();
      setFormData((prev) => ({ ...prev, roleName: sanitizedValue }));
    }
  };  

  const handleKeyDown = (event?: any) => {
    const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", "Space"];
    if (!ALPHABETIC_REGEX.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const renderMrfHubSection = () => (
    <CollapsibleSection title="MRF Hub" permissionKeys={['mrfUsers', 'approveDeclineMrf', 'assignMrf', 'viewMrfStage', 'editMrfStage', 'viewAddComments']} formData={formData} setFormData={setFormData}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">MRF User</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.mrfUsers ?? false} onChange={() => handlePermissionToggle('mrfUsers')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Approve/Decline MRF</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.approveDeclineMrf ?? false} onChange={() => handlePermissionToggle('approveDeclineMrf')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Add Interviewer</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.addInterviewer ?? false} onChange={() => handlePermissionToggle('addInterviewer')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box> */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Assign MRF</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.assignMrf ?? false} onChange={() => handlePermissionToggle('assignMrf')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">View MRF Stage</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.viewMrfStage ?? false} onChange={() => handlePermissionToggle('viewMrfStage')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Edit MRF Stage</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.editMrfStage ?? false} onChange={() => handlePermissionToggle('editMrfStage')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">View/Add Comments</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.viewAddComments ?? false} onChange={() => handlePermissionToggle('viewAddComments')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
      </Box>
    </CollapsibleSection>
  );

  const renderResumeSection = () => (
    <CollapsibleSection title="Resume Vault" permissionKeys={['viewResumes']} formData={formData} setFormData={setFormData}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">View Resumes</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.viewResumes ?? false} onChange={() => handlePermissionToggle('viewResumes')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
      </Box>
    </CollapsibleSection>
  );

  const renderInterviewSuiteSection = () => (
    <CollapsibleSection title="Interview Suite" permissionKeys={['interviewCalenderStatus', 'assignAndEditRecruiterLead', 'assignAndEditRecruiter', 'recruiterAccess', 'viewInterviewFeedback']} formData={formData} setFormData={setFormData}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Interview Calendar & Status</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.interviewCalenderStatus ?? false} onChange={() => handlePermissionToggle('interviewCalenderStatus')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Assign & Edit recruiter Lead</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.assignAndEditRecruiterLead ?? false} onChange={() => handlePermissionToggle('assignAndEditRecruiterLead')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Assign & Edit recruiter</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.assignAndEditRecruiter ?? false} onChange={() => handlePermissionToggle('assignAndEditRecruiter')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Recruiter Access</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.recruiterAccess ?? false} onChange={() => handlePermissionToggle('recruiterAccess')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">View Interview Feedback</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.viewInterviewFeedback ?? false} onChange={() => handlePermissionToggle('viewInterviewFeedback')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
      </Box>
    </CollapsibleSection>
  );

  const renderNotificationSection = () => (
    <CollapsibleSection title="Notification" permissionKeys={['receiveAlerts', 'scheduleRemindersForInterviews']} formData={formData} setFormData={setFormData}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption"> Receive Alerts for MRF/Interviews/MRF stages changes/ interview Status changes/ Approvals based on the permissions accessed </Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.receiveAlerts ?? false} onChange={() => handlePermissionToggle('receiveAlerts')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Schedule Reminders for Interviews</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.scheduleRemindersForInterviews ?? false} onChange={() => handlePermissionToggle('scheduleRemindersForInterviews')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
      </Box>
    </CollapsibleSection>
  );

  const renderSettingsSection = () => (
    <CollapsibleSection title="Settings" permissionKeys={['manageUserSettings', 'viewAndManageRoles', 'assignRolesToUsers']} formData={formData} setFormData={setFormData}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Manage User Settings (Profile, Notifications)</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.manageUserSettings ?? false} onChange={() => handlePermissionToggle('manageUserSettings')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">View and Manage Roles</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.viewAndManageRoles ?? false} onChange={() => handlePermissionToggle('viewAndManageRoles')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Assign Roles to Users</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.assignRolesToUsers ?? false} onChange={() => handlePermissionToggle('assignRolesToUsers')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
      </Box>
    </CollapsibleSection>
  );

  const renderFocusBoardSection = () => (
    <CollapsibleSection title="FocusBoard" permissionKeys={['recruitment', 'mrfUsersApprovers', 'useFiltersForLeadsApprovers']} formData={formData} setFormData={setFormData}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Recruitment</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.recruitment ?? false} onChange={() => handlePermissionToggle('recruitment')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">MRF Users Approvers</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.mrfUsersApprovers ?? false} onChange={() => handlePermissionToggle('mrfUsersApprovers')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">User Filters For Leads & Approvers</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.useFiltersForLeadsApprovers ?? false} onChange={() => handlePermissionToggle('useFiltersForLeadsApprovers')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
      </Box>
    </CollapsibleSection>
  );

  const renderReportsAndMetricesSection = () => (
    <CollapsibleSection title="Reports & Metrics" permissionKeys={['overAllReport']} formData={formData} setFormData={setFormData}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Overall Reports</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.overAllReport ?? false} onChange={() => handlePermissionToggle('overAllReport')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
      </Box>
    </CollapsibleSection>
  );

  const renderCommonUserSection = () => (
    <CollapsibleSection title="Common User" permissionKeys={['allPersonLogin']} formData={formData} setFormData={setFormData}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Overall Reports</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.allPersonLogin ?? false} onChange={() => handlePermissionToggle('allPersonLogin')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
      </Box>
    </CollapsibleSection>
  );

  const renderResumeOwnerSection = () => (
    <CollapsibleSection title="Resume Owner" permissionKeys={['resumeOwner']} formData={formData} setFormData={setFormData}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Change candidate status, and schedule & edit the Interview</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.resumeOwner ?? false} onChange={() => handlePermissionToggle('resumeOwner')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
      </Box>
    </CollapsibleSection>
  );

  const renderOfferApproverSection = () => (
    <CollapsibleSection title="Offer Approver" permissionKeys={['offerApprover']} formData={formData} setFormData={setFormData}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Offer Approver</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.offerApprover ?? false} onChange={() => handlePermissionToggle('offerApprover')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
      </Box>
    </CollapsibleSection>
  );

  const renderOfferModuleSection = () => (
    <CollapsibleSection title="Offer Module" permissionKeys={['offerApproverAcess']} formData={formData} setFormData={setFormData}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Offer Module</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.offerApproverAcess ?? false} onChange={() => handlePermissionToggle('offerApproverAcess')} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
      </Box>
    </CollapsibleSection>
  );

  return (
    <RightDialog open={open} onClose={onClose} TransitionComponent={Transition} hideBackdrop={false} >
      <DialogTitle sx={{ p: 2, borderBottom: '1px solid #f0f0f0' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 500 }}>
            Add New Role
          </Typography>
          <IconButton aria-label="close" onClick={onClose} sx={{ position: "absolute", right: 8, top: 8, backgroundColor: "#ECECEC", }}
          >
            <CloseIcon sx={{ fontSize: '12px' }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1, fontSize: '12px' }}> Role Name </Typography>
          <TextField
            placeholder="Enter Role Name"
            fullWidth
            value={formData.roleName || ""}
            size="small"
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            sx={{
              mb: 3,
              "& .MuiInputBase-input": {
                fontSize: "12px",
              },
              "& .MuiInputLabel-root": {
                fontSize: "12px",
              },
            }}
          />
          {renderMrfHubSection()}
          {renderResumeSection()}
          {renderInterviewSuiteSection()}
          {renderNotificationSection()}
          {renderSettingsSection()}
          {renderFocusBoardSection()}
          {renderReportsAndMetricesSection()}
          {renderCommonUserSection()}
          {renderResumeOwnerSection()}
          {renderOfferApproverSection()}
          {renderOfferModuleSection()}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2.5, borderTop: '1px solid #f0f0f0', position: 'sticky', bottom: 0, bgcolor: 'background.paper' }}>
        <Button onClick={handleSubmit}
          disabled={!formData.roleName}
          variant="contained"
          sx={{ textTransform: 'none', fontSize: '12px', borderRadius: '8px', backgroundColor: '#1677FF' }} > Add New Role </Button>
      </DialogActions>
    </RightDialog>
  );
};

export default AddRoleModal;