import React, { useEffect, useState } from "react";
import { Box, Typography, Divider, Collapse, Checkbox } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import NavigateNext from "@mui/icons-material/NavigateNext";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import { styled } from "@mui/system";
import { DateRangePicker } from "rsuite";
// import CustomSmallFormControlLabel2 from "../dashboard/commoncomponent/CustomSmallFormControlLabel2";
import RecruiterDropDown from "../dashboard/commoncomponent/RecruiterDropDown";
import RecruiterLeadDropDown from "../dashboard/commoncomponent/RecruiterLeadDropDown";
import { useAppSelector } from "../../redux/hooks";
import RthStagesDropDown from "../dashboard/commoncomponent/RthStagesDropDown";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
// import CustomReportDropDowns from "../common/CustomReportDropDowns";
// import { CollapsibleSection } from "../dashboard/commoncomponent/CollapseComponenet";

const SmallCheckbox = styled(Checkbox)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    fontSize: 12,
  },
}));

interface prop {
  setFilterData: Function;
  setSideBarOpen: Function;
  selectedTab: string;
  filterCount: any;
  setFilterData2: Function;
  roleName: string;
  jobLabels: string[];
}

const Filter: React.FC<prop> = ({
  setFilterData,
  setSideBarOpen,
  selectedTab,
  filterCount,
  setFilterData2,
  roleName,
  jobLabels,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [activeFilter, setActiveFilter] = useState<"date" | "week" | null>(
    null
  );
  console.log("setDrawerOpen",setDrawerOpen);

  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);
  const [selectedJobLabel, setSelectedJobLabel] = useState<string[]>([]);
console.log('setSelectedJobLabel',setSelectedJobLabel)
  const recruiterLeadState = useAppSelector((state) => state.recruiterLead);
  const recruiterState=useAppSelector((state)=>state.recruiterDd);

  const rthStages=useSelector((state:RootState)=>state.rthStages.rthStagesName);
  console.log("rthStages",rthStages);

  useEffect(()=>{
    setFilterData2((prev:any)=>({
      ...prev,
      recruiterLead:recruiterLeadState.recruiterLeadId
    }))
  },[recruiterLeadState,setFilterData2])

  console.log(recruiterState);

  useEffect(()=>{
    
    setFilterData2((prev:any)=>({
      ...prev,
      recruiter:recruiterState.recruiterId
    }))
  },[recruiterState,setFilterData2])

  useEffect(()=>{
    const formattedRthStages = rthStages.map(
      (stage) =>
        stage
          .replace(/\s(.)/g, (match, group1) => group1.toUpperCase()) 
          .replace(/\s/g, "") 
    );

    setFilterData2((prev:any)=>({
      ...prev,
      rthStages:formattedRthStages
    }))
  },[rthStages,setFilterData2])

  useEffect(() => {
    const formattedSelectedJobLable: string[] = selectedJobLabel.map(
      (words: string) =>
        words
          .split(" ")
          .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
          .join("")
    );
    console.log("formattedSelectedJobLable", formattedSelectedJobLable);
    setFilterData2((prev: any) => ({
      ...prev,
      rthStages: formattedSelectedJobLable,
    }));
  }, [selectedJobLabel,setFilterData2]);

  const CustomSmallFormControlLabel = (props: {
    label: string;
    count: number;
    data: any;
  }) => {
    // const handleChipColor = () => {
    //   if (props.label === "Job Open") {
    //     return "#FFEBC2";
    //   } else if (props.label === "Job On Hold") {
    //     return "#F7F1FF";
    //   } else if (props.label === "Job Reopened") {
    //     return "#CAF1D9";
    //   } else if (props.label === "Job Filled") {
    //     return "#C2EEF6";
    //   } else if (props.label === "Job Closed") {
    //     return "#FFD7CE";
    //   } else {
    //     return "transparent";
    //   }
    // };

    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const handleCheckboxChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      label: string
    ) => {
      if (event.target.checked) {
        setSelectedLabel(label);
        setActiveFilter("week");
        let fromDate: Date | null = null;
        let toDate: Date = new Date();
        if (label === "1 week") {
          fromDate = new Date();
          fromDate.setDate(toDate.getDate() - 7);
        } else if (label === "2 week") {
          fromDate = new Date();
          fromDate.setDate(toDate.getDate() - 14);
        } else if (label === "3 week") {
          fromDate = new Date();
          fromDate.setDate(toDate.getDate() - 21);
        } else if (label === "1 Month") {
          fromDate = new Date();
          fromDate.setMonth(toDate.getMonth() - 1);
        } else if (label === "2 Months") {
          fromDate = new Date();
          fromDate.setMonth(toDate.getMonth() - 2);
        }

        const formattedFromDate = fromDate ? formatDate(fromDate) : null;
        const formattedToDate = formatDate(toDate);

        console.log("From Date:", formattedFromDate);
        console.log("To Date:", formattedToDate);

        setFilterData((prev: any) => ({
          ...prev,
          from: formattedFromDate,
          to: formattedToDate,
        }));
        setFilterData2((prev: any) => ({
          ...prev,
          fromDate: formattedFromDate,
          toDate: formattedToDate,
        }));
      } else {
        setSelectedLabel(null);
        setActiveFilter(null);
        setFilterData((prev: any) => ({
          ...prev,
          from: null,
          to: null,
        }));

        setFilterData2((prev: any) => ({
          ...prev,
          fromDate: null,
          toDate: null,
        }));
      }
    };

    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        borderBottom={"0.2px solid #0000001A"}
      >
        <Box flexBasis={"10%"}>
          <SmallCheckbox
            onChange={(event) => handleCheckboxChange(event, props.data)}
            disabled={activeFilter === "date"}
            checked={selectedLabel === props.label}
          />
        </Box>
        <Box flexBasis={"80%"} display={"flex"} alignItems={"center"} gap={1}>
          {/* <Chip
            sx={{
              borderRadius: "10px",
              display:
                handleChipColor() === "transparent" ? "none" : "inline-block",
              height: "12px",
              width: "12px",
              backgroundColor: handleChipColor,
              border: `1px solid ${handleChipColor}`,
              padding: 0,
            }}
            size="small"
            label=""
          /> */}
          <Typography fontSize={12} sx={{ color: "#434343", fontWeight: 600 }}>
            {props.label}
          </Typography>
        </Box>
        <Box flexBasis={"10%"}>
          {/* <Typography fontSize={12} sx={{ color: "#8C8C8C", fontWeight: 400 }}>
            {props.count}
          </Typography> */}
        </Box>
      </Box>
    );
  };

  // const screeningChildren = [
  //   "Screening in Progress",
  //   "Screening Completed",
  //   "Screening Rejected",
  // ];
  // const l1InterviewChildren = [
  //   "L1 Interview Scheduled",
  //   "L1 Interview Declined",
  //   "L1 Interview Rescheduled",
  //   "L1 Interview Completed",
  //   "L1 Interview Cancelled",
  //   "L1 Interview NoShow",
  //   "L1 Interview FeedbackPending",
  //   "L1 Interview Selected",
  //   "L1 Interview Rejected",
  //   "L1 Interview ProxyDetected",
  // ];
  // const l2InterviewChildren = [
  //   "L2 Interview Scheduled",
  //   "L2 Interview Declined",
  //   "L2 Interview Rescheduled",
  //   "L2 Interview Completed",
  //   "L2 Interview Cancelled",
  //   "L2 Interview NoShow",
  //   "L2 Interview FeedbackPending",
  //   "L2 Interview Selected",
  //   "L2 Interview Rejected",
  //   "L2 Interview ProxyDetected",
  // ];

  // const hrRoundChildren = [
  //   "HR Round Scheduled",
  //   "HR Round Declined",
  //   "HR Round Rescheduled",
  //   "HR Round Completed",
  //   "HR Round Cancelled",
  //   "HR Round NoShow",
  //   "HR Round Feedback Pending",
  //   "HR Round Selected",
  //   "HR Round Rejected",
  // ];

  // const otherStatusChildren = [
  //   "Duplicate Profile",
  //   "OnHold",
  //   "Candidate Withdrawn",
  //   "Selected To Offer",
  //   "Offer Accepted",
  //   "Offer Declined",
  // ];

  const handleSidebarOpen = () => {
    setSideBarOpen(false);
  };

  const handleDateChange = (value: [Date, Date]) => {
    setActiveFilter("date");
    const fromDate = value[0].toISOString().slice(0, 10);
    const toDate = value[1].toISOString().slice(0, 10);
    console.log(fromDate, toDate);
    console.log(fromDate, toDate);

    const adjustToLocal = (date: Date, isStartOfDay: boolean) => {
      const adjustedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      if (isStartOfDay) {
        adjustedDate.setHours(0, 0, 0, 0); // Start of the day
      } else {
        adjustedDate.setHours(23, 59, 59, 999); // End of the day
      }
      return adjustedDate.toISOString();
    };

    const fromDate2 = adjustToLocal(value[0], true); // Start of the day
    const toDate2 = adjustToLocal(value[1], false); // End of the day

    setFilterData2((prev: any) => ({
      ...prev,
      fromDate: fromDate2,
      toDate: toDate2,
    }));

    setFilterData((prev: any) => ({
      ...prev,
      from: fromDate,
      to: toDate,
    }));
  };

  const handleDateClean = () => {
    setFilterData2((prev: any) => ({
      ...prev,
      fromDate: null,
      toDate: null,
    }));

    setFilterData((prev: any) => ({
      ...prev,
      from: null,
      to: null,
    }));
    setActiveFilter(null);
  };

  const weekLabels = ["1 week", "2 week", "3 week", "1 Month", "2 Months"];

  // const collapsibleData = [
  //   { title: "Screening", children: screeningChildren },
  //   { title: "L1 Interview", children: l1InterviewChildren },
  //   { title: "L2 Interview", children: l2InterviewChildren },
  //   { title: "HR Round", children: hrRoundChildren },
  //   { title: "Other Statuses", children: otherStatusChildren },
  // ];

  return (
    <Box
      bgcolor={"white"}
      sx={{
        margin: "20px",
        marginTop: "0px",
        borderRadius: "10px",
        marginRight: "0px",
        zIndex: 1000,
        top: "-10px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          bgcolor: "white",
          padding: "10px",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "2px",
          borderRadius: "10px",
        }}
      >
        <TuneIcon sx={{ fontSize: 20 }} />
        {drawerOpen && (
          <Typography variant="h6" sx={{ fontSize: 14 }}>
            Filter
          </Typography>
        )}
        <Box
          sx={{
            cursor: "pointer",
            padding: "0px",
            backgroundColor: "#F6F6F6",
            marginLeft: 2,
          }}
        >
          {drawerOpen ? (
            <NavigateBefore sx={{ fontSize: 14 }} onClick={handleSidebarOpen} />
          ) : (
            <NavigateNext sx={{ fontSize: 14 }} />
          )}
        </Box>
      </Box>
      {drawerOpen && <Divider />}
      <Collapse in={drawerOpen} timeout="auto" unmountOnExit>
        <Box
          p={1}
          bgcolor={"white"}
          sx={{
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            overflowX: "auto",
            minHeight: "430px",
          }}
          display={"flex"}
          flexDirection={"column"}
          gap={2}
        >
          <Box>
            <Box paddingLeft={0.5}>
              <Typography sx={{ fontWeight: 600 }} fontSize={12}>
                Date
              </Typography>
            </Box>
            {(selectedTab === "Candidate Interview Status" ||
              selectedTab === "Source Report" ||
              selectedTab === "MRF Stage Report" ||
              selectedTab === "Department wise MRF" ||
              selectedTab === "Client wise MRF" ||
              selectedTab === "Selection Rate" ||
              selectedTab === "MRF Category Report" ||
              selectedTab === "Overall report" ||
              selectedTab === "MRF Approval Status" ||
              selectedTab === "Approved MRF Report" ||
              selectedTab === "Overall MRF Report") && (
              <DateRangePicker
                showOneCalendar
                placement="rightEnd"
                onOk={handleDateChange}
                onClean={handleDateClean}
                disabled={activeFilter === "week"}
                style={{ width: "100%", left: "10px", maxWidth: "300px" }}
                menuStyle={{
                  transform: "translateX(14px)",
                  position: "absolute",
                  zIndex: 9999,
                  marginTop: "300px",
                }}
                disabledDate={(date) => {
                  const today = new Date();
                  today.setHours(0, 0, 0, 0);
                  return date > today;
                }}
              />
            )}
          </Box>

          {((selectedTab === "MRF Stage Report" &&
            (roleName === "TEAMHEAD" || roleName === "APPROVERHEAD")) ||
            selectedTab === "MRF Approval Status" ||
            selectedTab === "Approved MRF Report" ||
            selectedTab === "Overall MRF Report" ||
            selectedTab === "Candidate Interview Status" ||
            selectedTab === "Source Report" ||
            selectedTab === "MRF Category Report" ||
            selectedTab === "Overall report") && (
            <Box display={"flex"} flexDirection={"column"} gap={1.5}>
              {weekLabels.map((data) => (
                <CustomSmallFormControlLabel
                  label={data}
                  data={data}
                  count={0}
                />
              ))}
            </Box>
          )}

          {(selectedTab === "Department wise MRF" ||
            (selectedTab === "MRF Stage Report" &&
              (roleName === "RECRUITER" ||
                roleName === "RECRUITERLEAD" ||
                roleName === "TALENTACQUISITIONHEAD")) ||
            selectedTab === "Client wise MRF") && (
            <Box display={"flex"} flexDirection={"column"} gap={1.5}>
              <Typography
                paddingLeft={0.5}
                sx={{ fontWeight: 600 }}
                fontSize={12}
              >
                Stages
              </Typography>
              <RthStagesDropDown/>
              {/* {jobLabels.map((data) => (
                <CustomSmallFormControlLabel2
                  label={data}
                  data={data}
                  count={0}
                  selectedJobLabel={selectedJobLabel}
                  setSelectedJobLabel={setSelectedJobLabel}
                />
              ))} */}
            </Box>
          )}

          {selectedTab === "Selection Rate" && (
            <Box>
              <Box mb={2}>
                <Typography
                  paddingLeft={0.5}
                  sx={{ fontWeight: 600 }}
                  fontSize={12}
                >
                  Recruiter
                </Typography>
                <RecruiterDropDown />
              </Box>
              <Box>
                <Typography
                  paddingLeft={0.5}
                  sx={{ fontWeight: 600 }}
                  fontSize={12}
                >
                  Recruiter Lead
                </Typography>
                <RecruiterLeadDropDown />
              </Box>
            </Box>
          )}

          {/* Custom Report Drop-Downs */}

          {/* <CustomReportDropDowns
  buttonText="Select Position Title"
  selectedItems={["Position 1"]}
  items={[
    { label: "Position 1", value: "1" },
    { label: "Position 2", value: "2" },
  ]}
  onToggle={(label:any, value:any) => console.log("Toggled:", label, value)}
  onSearch={(query:any) => console.log("Search query:", query)}
  loading={false}
  hasMore={true}
  onLoadMore={() => console.log("Load more items...")}
/> */}

          {/* {(selectedTab === "Candidate Interview Status" ||
            selectedTab === "Source Report" ||
            selectedTab === "MRF Stage Report") && (
            <Box>
              {collapsibleData.map((data) => (
                <CollapsibleSection
                  title={data.title}
                  children={data.children}
                  filterCount={filterCount}
                />
              ))}
            </Box>
          )} */}
        </Box>
      </Collapse>
    </Box>
  );
};

export default Filter;
