import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Grid,
  InputBase,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledTableCell } from "./RthClosedTable";
import { getMyRequestsAPIData } from "../../../../Crud/card";
import { useAppSelector } from "../../../../redux/hooks";
import priorityHighSymbol from "../../../../assets/images/priorityHighSymbol.svg";
import priorityMediumSymbol from "../../../../assets/images/priorityMediumSymbol.svg";
import priorityLowSymbol from "../../../../assets/images/priorityLowSymbol.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import pendingIcon from "../../../../assets/images/pendingicon.svg";
import NoDataAvail from "../../../../assets/images/NoDataAvail.svg";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
   
  const MyRequestTableView: React.FC = () => {
    const tableHead = [
      "SI.No.",
      "MRF Name",
      "Type of MRF",
      "Business",
      "Priority",
      "Status",
      "L1 Status",
      "L2 Status",
    ];
   
    const userId = localStorage.getItem("userId") ?? "";
    const [loading, setLoading] = useState<boolean>(false);
    console.log(loading)
    const [tableData, setTableData] = useState<any[]>([]);
    const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const navigate=useNavigate();
console.log('setSearchLoading',setSearchLoading)

      const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        console.log("search qqq", event.target.value);
      };

      const handleRowClick=(rthId:string)=>{
        navigate(`/request-hire`, { state: { id: rthId , queryParam: "my-requests" } });
      }

    
   console.log(setSearchQuery)
    useEffect(() => {
      setLoading(true);
      getMyRequestsAPIData(userId, teamHeadFilter, searchQuery)
        .then((res) => {
          // setLoading(false);
          setTableData(res?.data?.listOfRth);
        })
        .catch((error) => {
          console.error("Error fetching MRF data:", error);
        })
        .finally(() => {
          setLoading(false);
      });
  }, [searchQuery, teamHeadFilter, userId]);

  return (
    <Box sx={{ width: "auto" }}>
      <Grid
        item
        sx={{ marginTop: tableData.length > 0 ? "-60px" : "-58px" }}
        position="absolute"
      >
        <InputBase
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search MRF..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
            </InputAdornment>
          }
          sx={{
            border: "1px solid #ccc",
            borderRadius: 1,
            padding: 0,
            backgroundColor: "white",
            minHeight: "3px",
            fontSize: 14,
            ".MuiInputBase-input": {
              padding: "5px 5px",
            },
          }}
        />
        {searchLoading && (
          <CircularProgress
            size={20}
            sx={{
              position: "absolute",
              right: "10px",
              top: "25%",
              transform: "translateY(-50%)",
              color: "#2F54EB",
            }}
          />
        )}
      </Grid>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <CircularProgress size={100} sx={{ color: "#2F54EB" }} />
        </Box>
      ) : tableData.length > 0 ? (
        <Box sx={{ width: "100%", overflowX: "auto" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="MRF table">
              <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
                <TableRow
                  sx={{
                    "& th": {
                      padding: "4px 8px", // Adjust padding for header cells here
                      fontSize: "14px", // Optional: Adjust font size
                    },
                  }}
                >
                  {tableHead.map((data, index) => (
                    <StyledTableCell>{data}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "& td, & th": {
                    padding: "4px 8px", // Adjust padding here
                  },
                }}
              >
                {tableData.map((row, index) => (
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(row.rthId)}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)", // Example color
                      },
                    }}
                  >
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{row?.positionTitle}</StyledTableCell>
                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          size="small"
                          sx={{
                            backgroundColor: "#F0F0F0",
                            border: "1px solid lightgray",
                            fontSize: "8px",
                            padding: "2px",
                            color: "#8C8C8C",
                            height: "20px",
                            minWidth: "50px",
                          }}
                        >
                          {row.typeOfRth}
                        </Button>
                        <Button
                          size="small"
                          sx={{
                            backgroundColor: "#F0F0F0",
                            border: "1px solid lightgray",
                            fontSize: "8px",
                            padding: "2px 4px",
                            color: "#8C8C8C",
                            height: "20px",
                            minWidth: "50px",
                          }}
                        >
                          {row.modeOfRth}
                        </Button>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        sx={{
                          width: "fit-content",
                          fontSize: "12px",
                          padding: "3%",
                          height: "px",
                          border:
                            "1px solid " +
                            (row.business.businessName === "HEPL"
                              ? "#D3ADF7"
                              : "#87E8DE"),
                          backgroundColor:
                            row.business.businessName === "HEPL"
                              ? "#EFDBFF"
                              : "#B5F5EC",
                          color:
                            row.business.businessName === "HEPL"
                              ? "#722ED1"
                              : "#08979C",
                        }}
                      >
                        {row.business?.businessName &&
                        row.business.businessName.length > 10
                          ? `${row.business.businessName.substring(0, 10)}...`
                          : row.business?.businessName || "N/A"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        gap={1}
                      >
                        {row.priority === "High" ? (
                          <Box>
                            <img src={priorityHighSymbol} alt="" />
                          </Box>
                        ) : row.priority === "Medium" ? (
                          <Box>
                            <img src={priorityMediumSymbol} alt="" />
                          </Box>
                        ) : (
                          <Box>
                            <img src={priorityLowSymbol} alt="" />
                          </Box>
                        )}
                        <Typography
                          sx={{
                            color:
                              row.priority === "High"
                                ? "#F04E4E"
                                : row.priority === "Medium"
                                ? "#FFA940"
                                : "#73D13D",
                            fontSize: "100%",
                          }}
                        >
                          {row.priority}
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Chip
                        label={row.status}
                        size="small"
                        sx={{
                          fontSize: "12px",
                          height: "px",
                          borderRadius: "1px",
                          backgroundColor: row.status === "Declined" ? "#FFEBEE": "#389E0D1A",
                          color: row.status === "Declined" ? "#f44336": "#389E0D",
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.l1Status === "Pending" || row.l1Status === "Declined" ? (
                        <Chip
                          label={
                            row.l1Status === null
                              ? "L1 Pending"
                              : "L1 " + row.l1Status
                          }
                          icon={
                            row.l1Status === "Declined" ? <CancelIcon style={{ color: "#f44336", fontSize: "12px" }}/> :
                            <img
                              src={pendingIcon}
                              alt="pending"
                              style={{ width: "10px", height: "10px" }}
                            />
                          }
                          size="small"
                          sx={{
                            backgroundColor:row.l1Status === "Declined" ?"#FFEBEE" : "#FFF7E6",
                            color:row.l1Status === "Declined" ? "#d32f2f":"#595959",
                            border:row.l1Status === "Declined" ? "1px solid #EF9A9A":"1px solid #FFE7BA",
                          }}
                        />
                      ) : (
                        <Chip
                          label={row.l1Status}
                          icon={
                            <CheckCircleIcon
                              style={{ color: "#4caf50", fontSize: "12px" }}
                            />
                          }
                          size="small"
                          sx={{
                            backgroundColor: "#F6FFED",
                            color: "#595959",
                            border: "1px solid #D9F7BE",
                          }}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.l2Status === "Pending" || row.l2Status === "Declined" || row.l2Status === null ? (
                        <Chip
                          label={
                            row.l2Status === null
                              ? "L2 Pending"
                              : "L2 " + row.l2Status
                          }
                          icon={
                            row.l2Status === "Declined" ? <CancelIcon style={{ color: "#f44336", fontSize: "12px" }}/> :
                            <img
                              src={pendingIcon}
                              alt="pending"
                              style={{ width: "10px", height: "10px" }}
                            />
                          }
                          size="small"
                          sx={{
                            backgroundColor:row.l2Status === "Declined" ?"#FFEBEE": "#FFF7E6",
                            color: row.l2Status === "Declined"?"#d32f2f":"#595959",
                            border: row.l2Status === "Declined" ? "1px solid #EF9A9A":"1px solid #FFE7BA",
                          }}
                        />
                      ) : (
                        <Chip
                          label={row.l2Status}
                          icon={
                            <CheckCircleIcon
                              style={{ color: "#4caf50", fontSize: "12px" }}
                            />
                          }
                          size="small"
                          sx={{
                            backgroundColor: "#F6FFED",
                            color: "#595959",
                            border: "1px solid #D9F7BE",
                          }}
                        />
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
          }}
        >
          <img
            src={NoDataAvail}
            alt="No Data Available"
            style={{ maxWidth: "200px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default MyRequestTableView;
