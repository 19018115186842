import { Box, Typography, FormControlLabel, Select, MenuItem, Checkbox, IconButton, Button, useMediaQuery  } from "@mui/material"
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import copyIconSchedule from '../../../../../assets/images/copyIconSchedule.svg'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export const ChooseTimeLine = ({availability, handleCheckboxChange, handleTimeChange, timeOptions, handleCancel, addTimeSlot, removeTimeSlot }:any) =>{

  const Md = useMediaQuery("(max-width: 1504px)");

return(
<Box pt={3}>
                <Typography sx={{ fontWeight: 700, color: '#44464C', fontSize: '20px' }}>
                  Please Select Interviewer’s Available Time
                </Typography>
                <Typography sx={{ fontWeight: 500, color: '#000', fontSize: '14px', mt: '20px' }}>
                  Weekly hours for the current month (February)
                </Typography>

                {availability.map((item:any, index:any) => (
                  <Box key={item.day} display="flex" alignItems="center" gap={2} my={1}  height='52px'>
                    <FormControlLabel
                      sx={{ width: '50px' }}
                      control={<Checkbox checked={item.checked} onChange={() => handleCheckboxChange(index)} />}
                      label={<Typography sx={{ fontSize: '14px', color: '#00000099' }}>{item.day}</Typography>}
                    />

                    {item.checked ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', width:Md? '90%' : '95%', height:'auto'}}>
                        {/* Fixed-width Scrollable Time Slots */}
                        <Box sx={{ width: 'auto', maxWidth: Md? '90%':'100%', overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex', gap: 2, p: 0.8 }}>
                          {item.times.map((time:any, timeIndex:any) => (
                            <Box display="flex" alignItems="center" gap={1} key={timeIndex}>
                              <Box display="flex" flexDirection="column">
                              <Select
                                sx={{
                                  width: '133px !important',
                                  height: '40px !important',
                                  fontSize: '14px !important',
                                  borderRadius: '8px !important',
                                  bgcolor: 'white',
                                  border: '1px solid #00000099 !important',
                                  '& .MuiSelect-icon': { color: '#00000099 !important' },
                                  '& .MuiOutlinedInput-notchedOutline': { border: 'none !important' },
                                }}
                                IconComponent={KeyboardArrowDownRoundedIcon}
                                value={time.from}
                                onChange={(e) => handleTimeChange(index, timeIndex, 'from', e.target.value)}
                              >
                                {timeOptions.map((option:any) => (
                                  <MenuItem key={option} value={option} sx={{ color: '#00000099 !important', fontSize: '14px !important' }}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </Select>
                              {time.error && <Typography color="red" fontSize="12px">{time.error}</Typography>}
                              </Box>

                              <EastRoundedIcon sx={{ color: '#9A9A9A', width: '16px' }} />

                              <Box display="flex" flexDirection="column">
                              <Select
                                sx={{
                                  width: '133px !important',
                                  height: '40px !important',
                                  fontSize: '14px !important',
                                  borderRadius: '8px !important',
                                  bgcolor: 'white',
                                  border: '1px solid #00000099 !important',
                                  '& .MuiSelect-icon': { color: '#00000099 !important' },
                                  '& .MuiOutlinedInput-notchedOutline': { border: 'none !important' },
                                }}
                                IconComponent={KeyboardArrowDownRoundedIcon}
                                value={time.to}
                                onChange={(e) => handleTimeChange(index, timeIndex, 'to', e.target.value)}
                              >
                                {timeOptions.map((option:any) => (
                                  <MenuItem key={option} value={option} sx={{ color: '#00000099 !important', fontSize: '14px !important' }}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </Select>
                              {time.error && <Typography color="red" fontSize="12px">{time.error}</Typography>}
                              </Box>

                              <IconButton onClick={() => removeTimeSlot(index, timeIndex)}>
                                <CloseRoundedIcon sx={{ color: '#9A9A9A' }} fontSize="small" />
                              </IconButton>
                            </Box>
                          ))}
                        </Box>

                        {/* Fixed Add & Copy Icons (Outside Scrollable Area) */}
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <IconButton onClick={() => addTimeSlot(index)}>
                            <AddRoundedIcon sx={{ color: '#9A9A9A' }} fontSize="small" />
                          </IconButton>

                          {item.times.length > 0 && (
                            <IconButton>
                              <img src={copyIconSchedule} style={{ color: '#9A9A9A', width: '12px' }} alt="image-ctr" />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                    ) : (
                      <Typography ml={'20px'} color="gray">
                        Unavailable
                      </Typography>
                    )}
                  </Box>
                ))}

                <Box sx={{ mt: '30px', position: 'relative' }}>
                  <Typography
                    sx={{
                      width: '20px',
                      position: 'absolute',
                      top: '-10px',
                      left: '2.5%',
                      transform: 'translateX(-50%)',
                      backgroundColor: '#f7f7f7',
                      fontSize: '14px',
                      textAlign: 'center',
                    }}
                  >
                    Or
                  </Typography>
                  <Box sx={{ borderTop: '1px dashed #0000001A', pt: '20px' }} />
                </Box>

                <Button variant="outlined" sx={{ border: '1px solid #1677FF80', bgcolor: '#F0F5FF', color: '#1677FF', borderRadius: '8px', textTransform: 'none', mt: '20px' }}>
                  + Add Specific Date & Time
                </Button>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button onClick={handleCancel} sx={{ color: '#848484', textTransform: 'none' }}>
                    Cancel
                  </Button>
                  <Button sx={{ ml: '10px', borderRadius: '8px', bgcolor: '#1677FF', textTransform: 'none' }} variant="contained">
                    Submit
                  </Button>
                </Box>
                </Box>  
)
}