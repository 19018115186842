import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RoleType{
    selectedRole:string;
}

const initialState:RoleType={
    selectedRole:""
}

const roleSlice=createSlice({
    name:"role",
    initialState,
    reducers:{
        setSelectedRole:(state,action:PayloadAction<string>)=>{
            state.selectedRole=action.payload;
        },
        resetSelectedRole:(state)=>{
            state.selectedRole="";
        }
    }
})

export const {setSelectedRole,resetSelectedRole}=roleSlice.actions;
export default roleSlice.reducer;