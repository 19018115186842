import axios from 'axios';
import { BaseUrl } from '../../constants/Crudurl';


export const getAllTemplate = async (search:any) => {
  try {
    const response = await axios.get(
      `${BaseUrl}/jdTemplate/getAllJd?search=${search}`,
    );    
    return response.data;
  } catch (error: any) {
    console.error('getAllTemplate', error);

    return error.response?.data || { message: 'An unknown error occurred' };
  }
}

export const getAllDraftedJdTemplate = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(
      `${BaseUrl}/jdTemplate/getDraftedJdTemplate`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getAllTemplate', error);

    return error.response?.data || { message: 'An unknown error occurred' };
  }
}


export const SaveAsDraftAndTemplate = async (data:any)=>{
    const token = localStorage.getItem('token');
    try{
        const response = await axios.post(`${BaseUrl}/jdTemplate/addJdTemplate`, data,
            {
                headers: { Authorization: `Bearer ${token}` },                
            },
        )
        return response.data
    } catch (error) {
        console.error("saveAsTemplate", error)
    }
}

export const Submit = async (data:any,rthId:any)=>{
  const token = localStorage.getItem('token');
  try{
      const response = await axios.post(`${BaseUrl}/rth/jdSubmission/${rthId}`, data,
          {
              headers: { Authorization: `Bearer ${token}` },
              
          },
      )
      return response.data
  } catch (error) {
      console.error("saveAsTemplate", error)
  }
}