import React, { useEffect, useState } from "react";
import {
  Drawer,
  IconButton,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { exportReportApiFunction, getCandStatusByRth } from "../../Crud/report";
import { getRthById } from "../../Crud/rth";
// import { ExportData } from "./SearchAndExport";

interface prop {
  openn: boolean;
  setOpenn: Function;
  status: string;
  seqId: string;
  currentReportData: any[];
  selectedTab: string;
  roleName: string;
}

const RightDrawer: React.FC<prop> = ({
  openn,
  setOpenn,
  status,
  seqId,
  currentReportData,
}) => {
  const [open, setOpen] = useState(false);
  const [popOverData, setPopOverData] = useState<any[]>();

  console.log("popOverData",popOverData);
  console.log("openn",openn);
  console.log("status",status);
  console.log("seqId",seqId);
  console.log("currentReportData",currentReportData);

    // const [apiExport, setApiExport] = useState<ExportData>({
    //   fileName: "sample excel",
    //   columns: [],
    //   rows: [],
    // });

  const [mrfTitle, setMrfTitle] = useState<string | null | undefined>(
    undefined
  );
  const [mrfCategory, setMrfCategory] = useState<string | null | undefined>(
    undefined
  );
  const [mrfSubcategory, setMrfSubcategory] = useState<
    string | null | undefined
  >(undefined);
  const [position, setPosition] = useState<string | null | undefined>(
    undefined
  );
  const [recruiter, setRecruiter] = useState<string | null | undefined>(
    undefined
  );
  const [recruiterLead, setRecruiterLead] = useState<string | null | undefined>(
    undefined
  );

  // const [searchQuery, setSearchQuery] = useState<string>("");
  // console.log(searchQuery);

  // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchQuery(event.target.value);
  // };

  const tableData:string[] = [
    "S no",
    "Position",
    "Recruiter",
    "Recruiter Lead",
    "Candidate",
    "Interview Stage",
    "Interview Status",
    "L1 Interviewer Name",
    "L2 Interviewer Name",
    "Interview Details",
  ];

  const toggleDrawer = (isOpen: boolean) => {
    setOpen(isOpen);
    setOpenn(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const foundRthId =
          currentReportData.find((item) => item.rthRequest.seqId === seqId)
            ?.rthRequest.rthId || "";

        setOpen(openn);

        console.log("foundRthId",foundRthId);

        if (foundRthId.length > 0) {
          console.log("id da dei", foundRthId);
          const candStatusResponse = await getCandStatusByRth(
            foundRthId,
            status
          );
          console.log("candidate report",candStatusResponse);
          
          setPopOverData(candStatusResponse?.data);          
          console.log("see this", candStatusResponse.data);

          const rthResponse = await getRthById(foundRthId);
          console.log(rthResponse);
          setMrfTitle(rthResponse.data.positionTitle);
          setMrfCategory(rthResponse.data.typeOfRth);
          setMrfSubcategory(rthResponse.data.modeOfRth);
          setPosition(rthResponse.data.numOfOpenPosition);
          setRecruiterLead(
            rthResponse?.data?.assignedRecruiterLead
              ?.map((recruiter: any) => recruiter?.userName)
              .join(",") || "-"
          );
          setRecruiter(
            rthResponse?.data?.assignedRecruiter
              ?.map((recruiter: any) => recruiter?.userName)
              .join(", ") || "-"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [seqId, openn, currentReportData, status]);

  console.log("popOverData",popOverData);
  
  const handleExportButton=()=>{
    if (!popOverData) return;
    const rows=popOverData?.map((data,index)=>({
      "S no":index+1,
      Position:position || "-",
      Recruiter:recruiter || "-",
      "Recruiter Lead":recruiterLead || "-",
      Candidate:data?.candidateLogIn?.firstName || "-",    
      "Interview Stage":data?.interview?.status ||"-",
      "Interview Status":data?.candidateLogIn?.status||"-",
      "L1 Interviewer Name":data?.interview?.interviewDetL1?.interviewer?.userName||"-",
      "L2 Interviewer Name":data?.interview?.interviewDetL2?.interviewer?.userName || "-",
      "Interview Details":data?.interview?.interviewPlatform ||"-"
    }))

    console.log("broski",rows);

    exportReportApiFunction({fileName:"Nothing",columns:tableData,rows:rows},"Candidate_Interview_Status/Mrf");


  }
  

  return (
    <div>    
      <Drawer anchor="right" open={open} onClose={() => toggleDrawer(false)}>        
        <Box
          sx={{
            width: 1100,
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: 1,
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={{ backgroundColor: "#F0F5FF" }}
              gap={1}
              border={"1px solid #F0F5FF"}
              borderRadius={1}
              p={1}
            >
              <Typography fontSize={15} textTransform={"uppercase"}>
                {mrfTitle}
              </Typography>
              <Typography>-</Typography>
              <Typography p={0.5} fontSize={12} textTransform={"uppercase"}>
                {mrfCategory}
              </Typography>
              <Typography>|</Typography>

              <Typography p={0.5} fontSize={12} textTransform={"uppercase"}>
                {mrfSubcategory}
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                size="small"
                onClick={handleExportButton}
              >
                Export
              </Button>
              <IconButton onClick={() => toggleDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: 1, width: 1100 }}>
          <TableContainer
            component={Paper}
            sx={{ border: "15px solid #F0F5FF" }}
          >
            <Table aria-label="MRF table">
              <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
                <TableRow
                  sx={{
                    "& th": {
                      padding: "4px 8px",
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                    },
                  }}
                >
                  {tableData.map((data) => (
                    <TableCell>{data}</TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  "& td, & th": {
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                  },
                }}
              >
                {popOverData?.map((data, index) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{position}</TableCell>
                    <TableCell>{recruiter}</TableCell>
                    <TableCell>{recruiterLead}</TableCell>
                    <TableCell>{data?.candidateLogIn?.firstName}</TableCell>
                    <TableCell>{data?.interview?.status ||"-"}</TableCell>
                    <TableCell>{data?.candidateLogIn?.status||"-"}</TableCell>
                    <TableCell>{data?.interview?.interviewDetL1?.interviewer?.userName||"-"}</TableCell>
                    <TableCell>{data?.interview?.interviewDetL2?.interviewer?.userName || "-"}</TableCell>
                    <TableCell>{data?.interview?.interviewPlatform ||"-"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Drawer>
    </div>
  );
};

export default RightDrawer;
