import React, { useEffect, useState } from 'react';
import { Typography, Grid, Box, Paper, SelectChangeEvent } from '@mui/material';
// import CommonSelect from '../../../common/CommomSelect';
// import { Citizenship, Nationality } from '../../../../constants/dummyConst';
// import { getCitizenship, getNationality } from '../../../../redux/features/resume';
// import { useAppDispatch } from '../internal/contracttab';
// import { useAppSelector } from '../../../../redux/hooks';
import { useLocation } from 'react-router-dom';
import CommonTextField from '../../../common/CommonTextField';
import {
  GetCountries,
} from "react-country-state-city";
import CommonSelect from '../../../common/CommomSelect';
import { toast } from 'react-toastify';
 
 
const AdditionalInformation = ({formValues,setFormValues,errors,setErrors,isAccess}:any) => {
// const dispatch=useAppDispatch();
// const state=useAppSelector((state)=>state.resume)
const location = useLocation();
const isFormsPath = location.pathname.includes('Forms');
const path=location.pathname.includes("candidateForm");
const[countryOption,setCountryOption]=useState<any[]>([]);
  
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {
    
          if (!isAccess) {
            toast.warn("You don't have access");
            return;
          }
          
    const { name, value } = event.target;
    let validationError = '';  
  
    if (name === 'citizenship') {
      if (!/^[a-zA-Z]*$/.test(value)) {
        validationError = 'Only alphabets are allowed';
      } else if (value.length > 20) {
        validationError = 'Maximum 20 characters allowed';
      }
    }
  
    // Update errors
    setErrors((prevErrors:any) => ({
      ...prevErrors,
      [name]: validationError,
    }));
  
    // Update form values only if there is no validation error
    if (!validationError) {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  
  };
   
    useEffect(() => {
      // Fetch countries
      GetCountries().then((result:any) => {
        setCountryOption(
          result.map((country: any) => ({
            label: country.name,
            value: country.name,
          }))
        );
      });
    }, []);
    
  return (
   
    <Box sx={{ pt: '4px',ml:path?'4px':''}}>
        <Paper elevation={0} sx={{overflowY:'auto', position:'relative'}}>
        {isFormsPath ? (
  <Typography sx={{ fontSize: '20px', fontWeight: '500', mb: '27px' }}>
    Additional Information
  </Typography>
) : path ? (
  <Typography
    sx={{
      fontSize: '20px',
      fontWeight: '500',
      mt: '10px',
      ml:'20px',
      color: '#000',
    }}
  >
    3.Additional Information
  </Typography>
) : (
  <Typography
    sx={{
      fontSize: '14px',
      mb: '17px',
      m: '14px',
      backgroundColor: '#1677FF1A',
      color: '#1677FF',
      width: '159px',
      p: '3px',
      borderRadius: '3px',
      textAlign: 'center',
      fontWeight: '500',
    }}
  >
    Additional Information
  </Typography>
)}
 
 
      <Box sx={{width:'50vw'}}>
      <Grid container spacing={4} sx={isFormsPath ? {} : {m: '1px'}}>
 
      <Grid item xs={12} md={4} >
      <Typography 
          sx={{ fontSize: '14px', color: path ? '#c2c2c2' : '#bababa', mb: '17px' }}
        >
          Nationality
          {path && <span style={{ color: 'red' }}> *</span>}
        </Typography>

      <CommonSelect
                  labelId=""
                  label=""
                  name="nationality"
                  value={formValues.nationality||''}
                  onChange={handleInputChange}
                  options={countryOption}
                  error={!!errors.nationality}
                  helperText={errors.nationality}
                />
          </Grid>
          <Grid item xs={12} md={4}>
              <Typography sx={{fontSize:'14px',color:'#bababa' , mb:'17px'}}>CitizenShip
              {path && <span style={{ color: 'red' }}> *</span>}
              </Typography>
             <CommonTextField
                  label=""
                  name="citizenship"
                  value={formValues.citizenship||''}
                  onChange={handleInputChange}
                  error={!!errors.citizenship}
                  helperText={errors.citizenship}
                />
             </Grid>
        </Grid>
     
      </Box>
      {/* <Box sx={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',gap:'20px',mt:'50px',mr:'10px', position:"absolute", bottom:'10px', right:'10px'}}>
        <Button  sx={{textTransform:'none',border:'InactiveBorder',color:'#848484'}} onClick={handlePreviousPage}>Previous</Button>
      <Button variant='contained'onClick={handleContinue} sx={{textTransform:'none'}}>Continue</Button>
      </Box> */}
      </Paper>
    </Box>
  );
};
 
export default AdditionalInformation;