import { Chip, Grid, Typography, TextField, Box, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CommonTextField from '../../../../common/CommonTextField';
import CommonMultiSelect from '../../../../common/CommonMultiSelect';
import { getSkill } from '../../../../../redux/features/resume';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { toast } from 'react-toastify';

export const Experience = ({ formValues, setFormValues,errors,setErrors,isAccess }: any) => {
 
  const isInitialized = useRef(false);
  const[skillDropDown,setSkillDropDown]=useState(false);
  const[skillOptions,setSkillOptions]=useState<any[]>([]);
  const dispatch=useAppDispatch();
  const[searchState,setSearchState]=useState(false);
  const State=useAppSelector((state)=>state.resume);
  const {skills}=State;
  const handleInputChangeExp=(event:React.ChangeEvent<HTMLInputElement>)=>{
    
    if (!isAccess) {
      toast.warn("You don't have access");
      return;
    }
    const{value}=event.target;
    setFormValues({
      ...formValues,
      totYearsOfExp:value
    })
  }
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index?: number) => {
    
    if (!isAccess) {
      toast.warn("You don't have access");
      return;
    }
    
    const { name, value } = event.target;

    // Ensure errors.experience is initialized as an array if it is undefined
    const updatedErrors = errors.experience || [];

    
    if (index === undefined) return;
    const currentDate = new Date().toISOString().split('T')[0]; 

    // Validation check for 'from' and 'to' fields
    if (formValues.experience[index]) {
      const updatedExperience = [...formValues.experience];
      const updatedEntry = { ...updatedExperience[index] };
      updatedEntry[name] = value;

      // From date validation - cannot be in the future or today
      if (name === 'from') {
        if (updatedEntry.from && (updatedEntry.from > currentDate)) {
          updatedErrors[index] = {
            ...updatedErrors[index],
            from: 'From date cannot be in the future or today',
          };

          
          setErrors({
            ...errors,
            experience: updatedErrors,
          });
          return; 
        } else {
          updatedErrors[index] = {
            ...updatedErrors[index],
            from: '',
          };
        }
      }

      // // To date validation - cannot be in the future
      if (name === 'to') {
        if (updatedEntry.to && (updatedEntry.to > currentDate)) {
          updatedErrors[index] = {
            ...updatedErrors[index],
            to: 'To date cannot be in the future',
          };

          
          setErrors({
            ...errors,
            experience: updatedErrors,
          });
          return; 
        } else if (
          updatedEntry.from &&
          updatedEntry.to &&
          new Date(updatedEntry.to) <= new Date(updatedEntry.from)
        ) {
          updatedErrors[index] = {
            ...updatedErrors[index],
            to: 'To date must be greater than From date',
          };
          setErrors({
            ...errors,
            experience: updatedErrors,
          });
          return;
  
        } else {
          updatedErrors[index] = {
            ...updatedErrors[index],
            to: '',
          };
        }

        // To date must be greater than from date
        if (updatedEntry.from && updatedEntry.to && updatedEntry.to <= updatedEntry.from) {
          updatedErrors[index] = {
            ...updatedErrors[index],
            to: 'To date must be greater than From date',
          };

          
          setErrors({
            ...errors,
            experience: updatedErrors,
          });
          return; 
        }
      }

      // // Check if 'from' and 'to' are the same
      if (updatedEntry.from && updatedEntry.to && updatedEntry.from === updatedEntry.to) {
        updatedErrors[index] = {
          ...updatedErrors[index],
          [name]: 'From and To dates cannot be the same',
        };

        // Update errors and return early
        setErrors({
          ...errors,
          experience: updatedErrors,
        });
        return; // Exit the function without updating the state
      }

      // If validation passes, clear the error and update the experience state
      updatedExperience[index] = updatedEntry;     
      setFormValues({
        ...formValues,
        experience: updatedExperience,
      });

      // Clear errors if validation passes
      setErrors({
        ...errors,
        experience: updatedErrors,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleInputChangeSkills=(event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>)=>{
    
    if (!isAccess) {
      toast.warn("You don't have access");
      return;
    }
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const transformCityData = (data: any[]) => {
    return data?.map(data => ({
      label: data?.skillName,      // Use cityName as the label
      value: data?.skillName, // Use cityId as value, fallback to cityName if cityId is null
    }));
  };
  const fetchMoreSkillOptions = async (searchTerm: string, page: number) => {
      if(searchTerm.length>0){
        setSearchState(true);
      }
      try {
        const result = await dispatch(getSkill({ search: searchTerm, page, size: 10 })).unwrap();
        const newOptions = transformCityData(result?.skills || []);
    
        // Append new options to existing skillOptions
        setSkillOptions((prevOptions) => {
          console.log(prevOptions,'ibghjgh');
          
          const mergedOptions = [...prevOptions, ...newOptions];
          
          // Remove duplicates by value (optional, if skill names might repeat)
          const uniqueOptions = Array.from(
            new Map(mergedOptions.map((item) => [item.value, item])).values()
          );
    
          return uniqueOptions;
        });
      } catch (error) {
        console.error("Error fetching more skill options:", error);
      }
    };
    
  useEffect(()=>{
    if (formValues?.experience?.length ===0||formValues?.experience===null) {
       if(isInitialized.current){
            setFormValues((prevFormData:any) => ({
      ...prevFormData,
      experience: [{
        jobTitle: '',
        company: '',
        from: '',
        to: '',
        expLabel: 'Latest Experience', 
            }]
    }));
  }
  if(formValues?.skills===null){
    setSkillDropDown(true);
  }
  // isInitialized.current = true;
}
  },[formValues,setFormValues])
  
  useEffect(()=>{
    // if(!isInitialized.current){
      if(formValues?.skills===null){
        setSkillDropDown(true);
      }
    // }
    isInitialized.current = true;
  },[formValues])

  console.log(skillDropDown,'skillDropDown');
  
    useEffect(()=>{
     dispatch(getSkill({search :'', page :0, size :10}));
     
    },[dispatch])
  
    useEffect(()=>{
      const skillData=transformCityData(skills); 
      if(!searchState)
      setSkillOptions((prevOptions) => [...prevOptions, ...skillData]);
      else if(searchState){
        setSkillOptions(skillData);
      }
    },[skills,searchState])
  

  return (
    <>
    <Box sx={{m:'10px'}}>
      <Typography
        sx={{
          fontSize: '14px',
          mb: '40px',
          backgroundColor: '#1677FF1A',
          color: '#1677FF',
          width: '100px',
          p: '3px',
          borderRadius: '3px',
          textAlign: 'center',
          fontWeight: '500',
        }}
      >
        Experience
      </Typography>

      <Typography variant="body2" sx={{ mb: '20px', ml:'14px', color:'#c2c2c2' }}>
        Skills
      </Typography>
      <Box sx={{ display:'flex', flexDirection:'row', justifyContent:'space-between', ml:'14px' }}>
          <Box>
        {!skillDropDown&&
        formValues?.skills?.map((skill: string, index: number) => (
         
            <Chip
              label={skill || "Skill not Selected"}
              // onDelete={() => handleDeleteSkill(skill)}
              sx={{ margin: '4px' }}
            />
        
        ))}
        {skillDropDown&&
        <Box sx={{width:'18vw'}}>
        <CommonMultiSelect
        labelId=""
        label=""
        name="skills"
        value={formValues.skills || []}
        onChange={handleInputChangeSkills}
        options={skillOptions}
        error={!!errors.skills} 
        pageType='skillsSearch'
        helperText={errors.skills || ""}    
        fetchMoreOptions={(searchTerm:any, page:any) => fetchMoreSkillOptions(searchTerm, page)}      
      />
      </Box>
        }
      </Box>
        <Box sx={{mr:'120px'}}>
          <Typography sx={{ mb: '10px',fontSize:'14px', color:'#c2c2c2' }}>Total Years of Experience</Typography>
        <TextField
            variant="outlined"
            size="small"
            value={formValues?.totYearsOfExp || ''}
            onChange={handleInputChangeExp}
          />
          </Box>
        </Box>

      {formValues?.experience?.map((entry: any, index: number) => (
        <Box key={index} sx={{ml:'14px',mt:'10px'}}>
          <Typography sx={{mb:'10px',fontWeight:'bold'}}>{entry?.expLabel}</Typography>
          <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
        
         <Grid item xs={4}>
         <Typography sx={{mb:'18px',color:'#adadad',fontSize:'14px'}}>Job Title</Typography>
         <CommonTextField
                  label=""
                  name="jobTitle"
                  value={entry?.jobTitle}
                  onChange={(event) => handleInputChange(event, index)}
                />
         </Grid>
         <Grid item xs={8}>
         <Typography sx={{mb:'18px',color:'#c2c2c2',fontSize:'14px'}}>Company</Typography>
         <CommonTextField
                  label=""
                  name="company"
                  value={entry?.company}
                  onChange={(event) => handleInputChange(event, index)}
                />
         </Grid>
         <Box sx={{width:'140px'}}>
         <Grid item>
         <Typography sx={{mb:'18px',color:'#c2c2c2',fontSize:'14px'}}>From</Typography>
         <CommonTextField
                  label=""
                  name="from"
                  type="date"
                  value={entry?.from}
                  onChange={(event) => handleInputChange(event, index)}
                  error={!!errors.experience?.[index]?.from} // Check if there's an error for this field
                  helperText={errors.experience?.[index]?.from || ''} // Display the error message
              
                />
         </Grid>
         </Box>
         <Box sx={{width:'140px'}}>
         <Grid item >
         <Typography sx={{mb:'18px',color:'#c2c2c2',fontSize:'14px'}}>To</Typography>
         <CommonTextField
                  label=""
                  type='date'
                  name="to"
                  value={entry?.to}
                  onChange={(event) => handleInputChange(event, index)}
                  error={!!errors.experience?.[index]?.to} // Check if there's an error for this field
                  helperText={errors.experience?.[index]?.to || ''} // Display the error message
              
                />
         </Grid>
         </Box>
        </Box>
        </Box>
        ))}
</Box>
    </>
  );
};
