import { Box, Paper, Tabs, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import NavigateNext from "@mui/icons-material/NavigateNext";
// import NavigateBefore from "@mui/icons-material/NavigateBefore";

interface prop {
  setSelectedTab: Function;
  tabsData: string[];
  setSideBarOpen: Function;
  sideBarOpen: boolean;
  setSearchQuery:Function;
  roleName:string;
}

const ReportTabs: React.FC<prop> = ({
  setSelectedTab,
  tabsData,
  setSideBarOpen,
  sideBarOpen,
  setSearchQuery,
  roleName
}) => {  
  const [activeTab, setActiveTab] = useState<number>(0);  

  useEffect(()=>{
    setActiveTab(0);
  },[roleName])
  

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    setSelectedTab(tabsData[newValue]);
    setSearchQuery("");
  };

  const handleSidebarOpen=()=>{
    setSideBarOpen(true);
  }

  return (
    <Box display={"flex"} alignItems={"center"}>
      {!sideBarOpen && (
        <Box
          sx={{
            backgroundColor: "white",
            margin: "2px",
            padding: "10px",
            borderRadius: "10px",
            display: "flex",
            gap: 3,
          }}
        >
          <TuneIcon sx={{ fontSize: 20 }} />
          <Box
            sx={{
              cursor: "pointer",
              padding: "0px",
              backgroundColor: "#F6F6F6",
            }}                        
          >
            <NavigateNext sx={{ fontSize: 14 }} onClick={handleSidebarOpen}/>
          </Box>
        </Box>
      )}
      <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          aria-label="report tabs"
          variant="scrollable"
          TabIndicatorProps={{
            style: {
              width: "20px", 
              // new
              marginLeft: `${"45px"}`,
              // old
              // marginLeft: `${0 === 0 ? '45px' : '36px'}`,
            },
          }}
        >
          {tabsData?.map((tab, index) => (
            <Tab
              key={index}
              label={tab}
              sx={{
                fontSize: "13px", 
                textTransform: "none", 
              }}
            />
          ))}
        </Tabs>
      </Paper>
    </Box>
  );
};

export default ReportTabs;
