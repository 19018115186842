import { Avatar, Box } from "@mui/material";
import React from "react";
import Select from "react-select";

export interface optionType {
  value: string;
  label: string;
  imgUrl: string;
  designation: string;
  candidateId?:string;
}

interface prop {
  options: optionType[] | undefined;
  value: optionType | null | undefined;
  selectedOption: Function;
  candidateId:string;
  isAccess:boolean;
}

const CustomRecruiterDropDown: React.FC<prop> = ({ options, value,selectedOption,candidateId,isAccess }) => {
  return (
    <Box>
      <Select
        isDisabled={isAccess ? false : true}
        options={options}
        formatOptionLabel={(data) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {data.imgUrl ? (
              <img
                src={data.imgUrl}
                alt={data.label}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                }}
              />
            ) : (
              <Avatar alt={data.label} src={data.imgUrl || data.label} />
            )}
            <div style={{ marginLeft: 8 }}>
              <div style={{ fontWeight: "bold" }}>{data.label}</div>
              <div style={{ fontSize: "0.75rem", color: "#555" }}>
                {data.designation}
              </div>
            </div>
          </div>
        )}
        value={value}
        onChange={(selected)=>selectedOption({
          value: selected?.value,
          label: selected?.label,
          imgUrl: selected?.imgUrl,
          designation: selected?.designation,
          candidateId:candidateId
        })}
      ></Select>
    </Box>
  );
};

export default CustomRecruiterDropDown;
