import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReportTableView from "./ReportTableView";
import ReportTabs from "./ReportTabs";
import { useNavigate } from "react-router-dom";
import {
  getCandidateReportR,
  getCandidateReportRl,
  getCandidateReportTa,
  getMrfApprovalStatusAh,
  getMrfApprovalStatusTh,
  getMrfApprovedReportAh,
  getMrfApprovedReportTh,
  getMrfReportTa,
  getMrfStageReportAh,
  getMrfStageReportR,
  getMrfStageReportRl,
  getMrfStageReportTh,
  getOverallMrfReportAh,
  getOverallMrfReportTa,
  getOverallMrfReportTh,
  getOverAllReportR,
  getOverAllReportRl,
  getSourceReportR,
  getSourceReportRl,
  getSourceReportTa,
} from "../../Crud/report";
import SearchAndExport from "./SearchAndExport";
// import ReportSidebar from "./ReportSidebar";
import RightDrawer from "./TableDrawer";
import Filter from "./ReportSidebar";
// import RoleReportDropdown from "../dashboard/commoncomponent/RoleReportDropdown";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const ReportMain: React.FC = () => {
  const [tabsData, setTabsData] = useState<string[]>([]);

  const [filterData, setFilterData] = useState({
    from: "",
    to: "",
    positions: [],
    interviewStatus: [],
    page: 0,
    size: 1000,
  });
  console.log("setFilterData", setFilterData);
  const [filterData2, setFilterData2] = useState({
    l1StatusPending: false,
    l1StatusDeclined: false,
    l2StatusPending: false,
    l2StatusApproved: false,
    internalOnRole: false,
    internalContract: false,
    externalOneTime: false,
    externalContract: false,
    priorityLow: false,
    priorityMedium: false,
    priorityHigh: false,
    rthStages: [],
    postedIn: [],
    createdBy: [],
    recruiterLead: [],
    positionTitle: [],
    business: [],
    location: [],
    recruiter: [],
    fromDate: null,
    toDate: null,
    page: 0,
    size: 1000,
  });
  console.log("setFilterData2", setFilterData2);

  const [currentReportData, setCurrentReportData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(tabsData[0]);
  const userId = localStorage.getItem("userId") ?? "";
  const [roleName, setRoleName] = useState<string>("");
  const selectedRole = useSelector(
    (state: RootState) => state.reportRole.selectedRole
  );
  
  const jobLabels=["Open","Move To Job","Job Post","Closed"];

  console.log("selecteddTab", selectedTab);

  const [rthId, setRthId] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [openn, setOpenn] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true);
  const [filterCount, setFilterCount] = useState<any>(null);
  console.log("filterCount", filterCount);

  const [exportHeaderTitle, setExportHeaderTitle] = useState<string[]>([]);
  const [exportRows, setExportRows] = useState<
    { [key: string]: string | number | null | undefined }[]
  >([]);

  console.log("whath", jobLabels);

  useEffect(() => {
    if (selectedRole === "TEAMHEAD") {
      setRoleName("TEAMHEAD");
    } else if (selectedRole === "APPROVERHEAD") {
      setRoleName("APPROVERHEAD");
    } else if (selectedRole === "TALENTACQUISITIONHEAD") {
      setRoleName("TALENTACQUISITIONHEAD");
    } else if (selectedRole === "RECRUITERLEAD") {
      setRoleName("RECRUITERLEAD");
    } else if (selectedRole === "RECRUITER") {
      setRoleName("RECRUITER");
    }
  }, [selectedRole]);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  useEffect(() => {
    const fetchTabsData = async () => {
      if (roleName === "TEAMHEAD" || roleName === "APPROVERHEAD") {
        await setTabsData([
          "MRF Approval Status",
          "MRF Stage Report",
          "Approved MRF Report",
          "Overall MRF Report",
        ]);
        await setSelectedTab("MRF Approval Status");
      } else if (
        roleName === "TALENTACQUISITIONHEAD" ||
        roleName === "RECRUITERLEAD" ||
        roleName === "RECRUITER"
      ) {
        await setTabsData([
          "Candidate Interview Status",
          "Source Report",
          "MRF Stage Report",
          "Department wise MRF",
          "Client wise MRF",
          "Selection Rate",
          "MRF Category Report",
          "Overall report",
        ]);
        await setSelectedTab("Candidate Interview Status");
      }
    };
    fetchTabsData();
  }, [roleName]);

  useEffect(() => {
    setCurrentReportData([]);
    const fetchReportData = async () => {
      setIsLoading(true);
      try {
        if (selectedTab === "MRF Approval Status" && roleName === "TEAMHEAD") {
          await getMrfApprovalStatusTh(
            userId,
            filterData,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res.data.mrfApprovalStatus);
            console.log("respone of api", res.data);
            console.log("filterDataa", filterData);
          });
        } else if (
          selectedTab === "MRF Stage Report" &&
          roleName === "TEAMHEAD"
        ) {
          await getMrfStageReportTh(
            userId,
            filterData,
            debouncedSearchQuery
          ).then((res) => {
            console.log("MRF Stage Report th", res);
            setCurrentReportData(res?.data.MrfReport);
          });
        } else if (
          selectedTab === "Approved MRF Report" &&
          roleName === "TEAMHEAD"
        ) {
          await getMrfApprovedReportTh(
            userId,
            filterData,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res?.data.MrfApprovedReport);
            console.log(res);
          });
        } else if (
          selectedTab === "Overall MRF Report" &&
          roleName === "TEAMHEAD"
        ) {
          await getOverallMrfReportTh(
            userId,
            filterData,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res?.data.OverAllRth);
            console.log("res", res);
          });
        } else if (
          selectedTab === "MRF Approval Status" &&
          roleName === "APPROVERHEAD"
        ) {
          await getMrfApprovalStatusAh(
            userId,
            filterData,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res.data.mrfApprovalStatus);
            console.log("respone of api", res);
          });
        } else if (
          selectedTab === "MRF Stage Report" &&
          roleName === "APPROVERHEAD"
        ) {
          await getMrfStageReportAh(
            userId,
            filterData,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res?.data.MrfReport);
            console.log(res);
          });
        } else if (
          selectedTab === "Approved MRF Report" &&
          roleName === "APPROVERHEAD"
        ) {
          await getMrfApprovedReportAh(
            userId,
            filterData,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res?.data.MrfApprovedReport);
            console.log(res);
          });
        } else if (
          selectedTab === "Overall MRF Report" &&
          roleName === "APPROVERHEAD"
        ) {
          await getOverallMrfReportAh(
            userId,
            filterData,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res?.data.Candidate_Interview_Status);
            console.log("res", res);
          });
        } else if (
          selectedTab === "Source Report" &&
          roleName === "TALENTACQUISITIONHEAD"
        ) {
          await getSourceReportTa(
            userId,
            filterData,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res.source_Report);
            setFilterCount(res.interviewStatusCount);
          });
        } else if (
          selectedTab === "Source Report" &&
          roleName === "RECRUITERLEAD"
        ) {
          await getSourceReportRl(
            userId,
            filterData,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res.source_Report);
            console.log("res", res);
          });
        } else if (
          selectedTab === "Source Report" &&
          roleName === "RECRUITER"
        ) {
          await getSourceReportR(userId, filterData, debouncedSearchQuery).then(
            (res) => {
              setCurrentReportData(res.source_Report);
              console.log("res", res);
            }
          );
        } else if (
          (selectedTab === "MRF Stage Report" ||
            selectedTab === "Department wise MRF" ||
            selectedTab === "Client wise MRF" ||
            selectedTab === "Selection Rate" ||
            selectedTab === "MRF Category Report") &&
          roleName === "TALENTACQUISITIONHEAD"
        ) {
          await getMrfReportTa(userId, filterData2, debouncedSearchQuery).then(
            (res) => {
              setCurrentReportData(res?.data?.MrfReport);
              console.log("hey see filterData2", filterData2);
              console.log("MRF Stage Report res", res?.data);
            }
          );
        } else if (
          (selectedTab === "MRF Stage Report" ||
            selectedTab === "Department wise MRF" ||
            selectedTab === "Client wise MRF" ||
            selectedTab === "Selection Rate" ||
            selectedTab === "MRF Category Report") &&
          roleName === "RECRUITERLEAD"
        ) {
          await getMrfStageReportRl(
            userId,
            filterData2,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res?.data?.MrfReport);
            console.log("res RECRUITERLEAD", res);
          });
        } else if (
          (selectedTab === "MRF Stage Report" ||
            selectedTab === "Department wise MRF" ||
            selectedTab === "Client wise MRF" ||
            selectedTab === "Selection Rate" ||
            selectedTab === "MRF Category Report") &&
          roleName === "RECRUITER"
        ) {
          await getMrfStageReportR(
            userId,
            filterData2,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res?.data?.MrfReport);
            console.log("res", res);
          });
        } else if (
          selectedTab === "Overall report" &&
          roleName === "TALENTACQUISITIONHEAD"
        ) {
          await getOverallMrfReportTa(
            userId,
            filterData,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res?.data?.Candidate_Interview_Status);
            console.log("res", res);
          });
        } else if (
          selectedTab === "Overall report" &&
          roleName === "RECRUITERLEAD"
        ) {
          await getOverAllReportRl(
            userId,
            filterData,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res?.data?.Candidate_Interview_Status);
            console.log("res", res);
          });
        } else if (
          selectedTab === "Overall report" &&
          roleName === "RECRUITER"
        ) {
          await getOverAllReportR(
            userId,
            filterData,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res?.data?.Candidate_Interview_Status);
            console.log("res", res);
          });
        } else if (
          selectedTab === "Candidate Interview Status" &&
          roleName === "TALENTACQUISITIONHEAD"
        ) {
          await getCandidateReportTa(
            userId,
            filterData,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res);
            setFilterCount(null);
            console.log("TALENTACQUISITIONHEAD res", res);
          });
        } else if (
          selectedTab === "Candidate Interview Status" &&
          roleName === "RECRUITERLEAD"
        ) {
          await getCandidateReportRl(
            userId,
            filterData,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res);
            console.log("ress", res);
          });
        } else if (
          selectedTab === "Candidate Interview Status" &&
          roleName === "RECRUITER"
        ) {
          await getCandidateReportR(
            userId,
            filterData,
            debouncedSearchQuery
          ).then((res) => {
            setCurrentReportData(res);
            console.log("res", res);
          });
        }
      } catch (error) {
        console.error("Error fetching report data", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchReportData();
  }, [
    selectedTab,
    userId,
    roleName,
    filterData,
    debouncedSearchQuery,
    filterData2,
  ]);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", marginBottom: "30px" }}
      gap={4}
    >
      {sideBarOpen && (
        <Box sx={{ flexBasis: "20%", width: "100%" }}>
          <Filter
            setFilterData={setFilterData}
            setSideBarOpen={setSideBarOpen}
            selectedTab={selectedTab}
            filterCount={filterCount}
            setFilterData2={setFilterData2}
            roleName={roleName}
            jobLabels={jobLabels}
          />
        </Box>
      )}
      <Box
        sx={{
          maxWidth: "100%",
          flexBasis: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "scroll",
        }}
        pl={1}
        pr={1}
      >
        <Box mb={1}>
          <ReportTabs
            setSelectedTab={setSelectedTab}
            tabsData={tabsData}
            sideBarOpen={sideBarOpen}
            roleName={roleName}
            setSearchQuery={setSearchQuery}
            setSideBarOpen={setSideBarOpen}
          />
        </Box>
        <Box mb={1}>
          <SearchAndExport
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            exportHeaderTitle={exportHeaderTitle}
            exportRows={exportRows}
            selectedTab={selectedTab}
          />
        </Box>
        <Box>
          <RightDrawer
            roleName={roleName}
            selectedTab={selectedTab}
            openn={openn}
            setOpenn={setOpenn}
            seqId={rthId}
            status={status}
            currentReportData={currentReportData}
          />
          <ReportTableView
            currentReportData={currentReportData}
            selectedTab={selectedTab}
            isLoading={isLoading}
            roleName={roleName}
            setRthId={setRthId}
            setStatus={setStatus}
            setOpenn={setOpenn}
            setExportHeaderTitle={setExportHeaderTitle}
            setExportRows={setExportRows}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ReportMain;
