import { useEffect, useState } from 'react';
import { InputAdornment, InputBase, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Button, Dialog, DialogTitle, Box, Typography, IconButton, Divider, DialogContent, TextField, DialogActions, CircularProgress } from '@mui/material';
import CustomTableCell from '../customTableCell';
import SearchIcon from '@mui/icons-material/Search';
import { getApproveAndDecline, getL3Approval } from '../../../Crud/rth';
import { GridCloseIcon } from "@mui/x-data-grid";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import NoDataAvailable from '../../../assets/images/NoDataAvail.svg';

interface ApproverData {
    rthName: string;
    mrfId: string;
    prefStartedDate: string;
    candidateLogIn: {
        l1Approver: { userName: string | null } | null;
        l2Approver: { userName: string | null } | null;
        l3Approver: { userName: string | null } | null;
        interviewStatus: string;
        candidateId: string;
        firstName: string;
        lastName: string | null;
        l3Status: {
            Approved?: string;
            Rejected?: string;
        } | null;
    };
}

const L3approver = () => {
    const userId = localStorage.getItem('userId') ?? '';
    const location = useLocation();
    const candidateId = location.state?.candidateId;
    const [approvalData, setApprovalData] = useState<ApproverData[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [actionType, setActionType] = useState<'Approved' | 'Rejected' | null>(null);
    const [selectedCandidateId, setSelectedCandidateId] = useState<string | null>(null);
    const [comments, setComments] = useState('');
    const [error, setError] = useState('');
    const [page, setPage] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setPage(0);
            try {
                const response = await getL3Approval(userId, searchTerm, page);
                setApprovalData(response?.data?.data || []);
            } catch (error) {
                console.error('Error fetching approval data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (userId) {
            fetchData();
        }
    }, [userId, searchTerm, page]);

    const handleDialogOpen = (candidateId: string, action: 'Approved' | 'Rejected') => {
        setOpenDialog(true);
        setActionType(action);
        setSelectedCandidateId(candidateId);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setComments('');
        setError('');
        setActionType(null);
        setSelectedCandidateId(null);
    };

    const handleConfirmAction = async () => {
        if (!selectedCandidateId) {
            setError('Candidate ID is missing.');
            return;
        }

        if (!actionType) {
            setError('Action type is missing.');
            return;
        }

        if (!comments.trim()) {
            setError('Please enter remarks.');
            return;
        }

        if (comments.length > 200) {
            setError('Remarks cannot exceed 200 characters.');
            toast.error("Remarks cannot exceed 200 characters.")
            return;
        }

        try {
            setIsLoading(true);
            await getApproveAndDecline({
                candidateId: selectedCandidateId,
                level: 'L3Status',
                status: actionType,
                comments,
            });

            const response = await getL3Approval(userId, searchTerm, page);
            setApprovalData(response?.data?.data || []);
            toast.success("Status updated successfully");
            handleDialogClose();
        } catch (error) {
            console.error('Error updating status:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRowClick = (row: ApproverData) => {
        navigate('/offer-approval/View-approval-table', {
            state: { candidateId: row.candidateLogIn?.candidateId , level: 'L3Status'}
        });
    };

    const filteredData = candidateId ? approvalData.filter((item) => item.candidateLogIn?.candidateId === candidateId) : approvalData;

    return (
        <>
            <InputBase
                placeholder="Search MRF...."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon sx={{ fontSize: 18, marginLeft: '10px' }} />
                    </InputAdornment>
                }
                sx={{ border: '1px solid #ccc', borderRadius: 1, padding: 0, backgroundColor: 'white', minHeight: '3px', fontSize: 12, width: '150px', marginBottom: '10px',
                    '.MuiInputBase-input': {
                        padding: '5px 5px',
                    },
                }}
            />
            {isLoading ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 10,
                    }}
                >
                    <CircularProgress size={50} />
                </Box>
            ) : filteredData.length === 0 ? (
                <Box sx={{ position: 'relative', textAlign: 'center', mt: 4 }}>
                    <img src={NoDataAvailable} alt="No Data Available" style={{ maxWidth: '150px' }}/>
                </Box>
            ) : (
                <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                    <Table>
                        <TableHead>
                            <TableRow
                                sx={{
                                    '& th': {
                                        padding: '8px 12px',
                                        whiteSpace: 'nowrap',
                                    },
                                }}
                            >
                                <CustomTableCell isHeader>Sl.No.</CustomTableCell>
                                <CustomTableCell isHeader>Candidate</CustomTableCell>
                                <CustomTableCell isHeader>MRF ID</CustomTableCell>
                                <CustomTableCell isHeader>MRF Name</CustomTableCell>
                                <CustomTableCell isHeader>Expected DOJ</CustomTableCell>
                                <CustomTableCell isHeader>L1 Approver</CustomTableCell>
                                <CustomTableCell isHeader>L2 Approver</CustomTableCell>
                                <CustomTableCell isHeader>L3 Approver</CustomTableCell>
                                <CustomTableCell isHeader hasBorder={false}>Offer Approval</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody
                            sx={{
                                "& td, & th": {
                                    padding: "8px 12px",
                                    whiteSpace: "nowrap",
                                },
                            }}
                        >
                            {filteredData.map((item, index) => {
                                return (
                                    <TableRow
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => handleRowClick(item)}
                                        key={item.candidateLogIn?.candidateId || index}
                                    >
                                        <CustomTableCell>{index + 1}</CustomTableCell>
                                        <CustomTableCell>{`${item.candidateLogIn?.firstName} ${item.candidateLogIn?.lastName || ''}`}</CustomTableCell>
                                        <CustomTableCell>{item.mrfId}</CustomTableCell>
                                        <CustomTableCell>{item.rthName}</CustomTableCell>
                                        <CustomTableCell>{item.prefStartedDate}</CustomTableCell>
                                        <CustomTableCell>{item.candidateLogIn?.l1Approver?.userName || 'N/A'}</CustomTableCell>
                                        <CustomTableCell>{item.candidateLogIn?.l2Approver?.userName || 'N/A'}</CustomTableCell>
                                        <CustomTableCell>{item.candidateLogIn?.l3Approver?.userName || 'N/A'}</CustomTableCell>
                                        <CustomTableCell hasBorder={false}>
                                            {item.candidateLogIn?.l3Status?.Approved ? (
                                                <Typography sx={{ color: '#00AB55', fontSize: '0.875rem' }}>
                                                    Approved on {new Date(item.candidateLogIn.l3Status.Approved).toLocaleDateString()}
                                                </Typography>
                                            ) : item.candidateLogIn?.l3Status?.Rejected ? (
                                                <Typography sx={{ color: '#E8505B', fontSize: '0.875rem' }}>
                                                    Rejected on {new Date(item.candidateLogIn.l3Status.Rejected).toLocaleDateString()}
                                                </Typography>
                                            ) : (
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDialogOpen(item.candidateLogIn?.candidateId, 'Rejected');
                                                        }}
                                                        sx={{
                                                            marginRight: 1,
                                                            backgroundColor: '#FEF2F3',
                                                            textTransform: 'none',
                                                            color: '#E8505B',
                                                            boxShadow: 'none',
                                                            fontSize: '0.75rem',
                                                            width: '40%',
                                                            '&:hover': {
                                                                backgroundColor: '#FEF2F3',
                                                                boxShadow: 'none',
                                                            },
                                                            '&:active': {
                                                                boxShadow: 'none',
                                                            },
                                                        }}
                                                    >
                                                        Reject
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDialogOpen(item.candidateLogIn?.candidateId, 'Approved');
                                                        }}
                                                        sx={{
                                                            marginRight: 1,
                                                            backgroundColor: '#00AB55',
                                                            textTransform: 'none',
                                                            boxShadow: 'none',
                                                            fontSize: '0.75rem',
                                                            width: '40%',
                                                            '&:hover': {
                                                                backgroundColor: '#00AB55',
                                                                boxShadow: 'none',
                                                            },
                                                            '&:active': {
                                                                boxShadow: 'none',
                                                            },
                                                        }}
                                                    >
                                                        Approve
                                                    </Button>
                                                </>
                                            )}
                                        </CustomTableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {openDialog && actionType && (
                <Dialog fullWidth sx={{ maxWidth: "500px", margin: "auto" }}
                    open={openDialog}
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            handleDialogClose();
                        }
                    }}
                    slotProps={{ backdrop: { onClick: (e) => e.stopPropagation() } }}
                >
                    <DialogTitle sx={{ p: 2, borderBottom: "1px solid #f0f0f0" }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6" sx={{ fontSize: "1rem", fontWeight: 500 }}> {actionType === 'Approved' ? 'Approve Remarks' : 'Rejection Remarks'} </Typography>
                            <IconButton aria-label="close"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleDialogClose();
                                }}
                                sx={{ position: "absolute", right: 8, top: 8, backgroundColor: "#ECECEC" }}
                            >
                                <GridCloseIcon sx={{ fontSize: "1rem" }} />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        {actionType === 'Rejected' && (
                            <Typography variant="body2" sx={{ mb: 1, fontSize: "0.75rem", color: "#757575" }}> Please provide a reason for declining </Typography>
                        )}
                        <TextField placeholder={`Enter ${actionType === 'Approved' ? 'remarks' : 'your reason here'}`}
                            fullWidth
                            multiline
                            rows={3}
                            value={comments}
                            onChange={(e) => {
                                e.stopPropagation();
                                const input = e.target.value;
                                if (input.length > 200) {
                                    setError("Remarks cannot exceed 200 characters.");
                                } else {
                                    setError("");
                                }
                                setComments(input);
                            }}
                            sx={{
                                "& .MuiInputBase-input": {
                                    fontSize: "12px",
                                },
                            }}
                        />
                        <Box sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between" }}>
                            <Typography variant="body2" sx={{ mt: 1, fontSize: "10px", color: "#8C8C8C" }} > Max 200 characters.</Typography>
                            {error && (<Typography variant="body2" sx={{ mt: 1, fontSize: "12px", color: "red", textAlign: "start" }}>{error} </Typography>)}
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ p: 2.5, pt: 0, bottom: 0, bgcolor: "background.paper" }}>
                        <Button onClick={handleDialogClose} variant="text"
                            sx={{
                                textTransform: "none", fontSize: "14px", color: "#757575", boxShadow: 'none',
                                '&:hover': {
                                    backgroundColor: 'none',
                                    boxShadow: 'none',
                                },
                                '&:active': {
                                    boxShadow: 'none',
                                },
                            }}
                        >
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmAction} sx={{
                            textTransform: "none", fontSize: "14px", backgroundColor: actionType === 'Approved' ? '#00AB55' : '#E8505B', width: '20%', color: "#FFFFFF", boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: actionType === 'Approved' ? '#00AB55' : '#E8505B',
                                boxShadow: 'none',
                            },
                            '&:active': {
                                boxShadow: 'none',
                            },
                        }}
                        >
                            {actionType === 'Approved' ? 'Approve' : 'Reject'}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default L3approver;
