import React, { useCallback } from 'react'
import { Box, Button, Card, FormControl, Grid, InputLabel, MenuItem, Paper, Select, StepLabel, Stepper, Tooltip, Typography } from "@mui/material";
import Img from "../../../../../assets/images/interviewDetailsImg.svg";
import { useEffect, useState } from "react";
import ScheduleInterviewDialog from "./ScheduleInterviewDialog";
import StepIcon from "../../../../../assets/images/stepIcon.svg";
import CurrentStepIcon from "../../../../../assets/images/currentStepIcon.svg";
import StepperConnectorIcon from "../../../../../assets/images/stepperConnectorIcon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { getCandidateInterviewDetails, setFeedBackStatus } from "../../../../../redux/features/interviewSchedule";
import MSTeamIcon from "../../../../../assets/images/Teams-icon.svg";
import MeetIcon from '../../../../../assets/images/google-meet.svg';
import Zoom from '../../../../../assets/images/zoom-icon.svg';
import dateAndTimeIcon from "../../../../../assets/images/dateAndTimeIcon.svg";
import FillFeedbackIcon from "../../../../../assets/images/fillFeedbackFormIcon.svg";
import FeedBackCompleted from '../../../../../assets/images/FeedBack-SubmitIcon.svg';
import InterviewIcon from "../../../../../assets/images/interviewerIcon.svg";
import { setStatus } from "../../../../../redux/features/interviewScheduleSlice";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useMediaQuery } from "@mui/material";
import { toast } from 'react-toastify';
import EditIcon from '../../../../../assets/images/editIcon.svg';
import EditInterview from './EditInterview';

 
export const InterviewDetails = ({interviewPermission,recruiterId}:any) => {
  const [open, setOpen] = useState(false);  
  const navigate = useNavigate();
  const { jobTitle, id, candidateName, candidateId } = useParams();
  const [steps, setSteps] = useState<any>([]);
  const [selectedStep, setSelectedStep] = useState(0); // Track selected step for highlight
    const[interviewNextRound,setInterviewNextRound]=useState('');
    const [interviewDate, setInterviewDate] = useState<Date | null>(null);
    const[interviewFrom,setInterviewFrom]=useState<Date|null>(null);
  const[interviewId,setInterviewId]=useState<any[]>([]);
  const [candidateIdNew, setCandidateId] = useState(candidateId);
  const [localStatuses, setLocalStatuses] = useState<Record<string, string>>({});
  const [editingIndex, setEditingIndex] = useState<number | null>(null); 
  const [editOpen,setEditOpen]=useState(false); 
  const location=useLocation();
  const resumeRepoPath=location.pathname.includes('resumeRepo');
  const [isAccess,setIsAccess]=useState<boolean>(true);
  const[editInterviewData,setEditInterviewData]=useState<any[]>([]);
   const handleClickOpen = () =>{ 
    if (!recruiterId || recruiterId === "null" || recruiterId === "undefined") {
      toast.error("Recruiter not assigned");
      return;
    }
    if(interviewPermission)
    setOpen(true);
  if(!interviewPermission)
    toast.error("Fill all the mandatory fields of the candidate to schedule the interview");
   };
   const [activeTooltipIndex, setActiveTooltipIndex] = useState(null);


  // const handleClickScheduleNextRound=(roundName:any)=>{
  //   setOpen(true);
  //   setInterviewNextRound(roundName);
  // }

  const handleClickScheduleNextRound = useCallback((roundName: any) => {
    setOpen(true);
    setInterviewNextRound(roundName);
  }, [setOpen, setInterviewNextRound]); 
  
  const [expandedStep, setExpandedStep] = useState<number | null>(null); // Expanded step state
  const state=useAppSelector((state:any)=>state.interviewSchedule.candidateInterviewDetails)
  const dispatch=useAppDispatch();
  const handleClose = () => {
    setOpen(false);
    
  }
  // const interviewDetails = [state.interviewDetL1, state.interviewDetL2, state.interviewDetL3];
  // const validInterviewDetails = interviewDetails.filter((detail) => detail !== null);
//  const statusFromSlice=useAppSelector((state)=>state.interviewSchedule.status);
const handleNavigate = useCallback(
  ({ interviewRound, interviewId }: { interviewRound: any; interviewId: any }) => {
    if(isAccess)
    navigate(`/newRequestToHire/${jobTitle}/${id}/${candidateName}/${candidateId}/${interviewRound}/${interviewId}/InterviewEvaluationForm`);
  else
   toast.warn("You don't have access")
  },
  [navigate, jobTitle, id, candidateName, candidateId,isAccess]
);
 
const handleReviewNavigate=useCallback((interviewId:any)=>{
   navigate(`/interviewReviewForm/${candidateId}/${interviewId?.interviewId}/${jobTitle}`)
},[candidateId,jobTitle,navigate]);

const handleButtonClick = useCallback((detail:any,index:any) => {
  const interviewDate = new Date(detail?.interviewDate);
  const currentDate = new Date();
  const interviewTime = new Date(`${detail?.from}`);

  const timeDifference = interviewTime.getTime() - currentDate.getTime();
  const tenMinutesInMillis = 10 * 60 * 1000;

  
  if (currentDate >= interviewDate && currentDate > interviewTime) {
    if (!detail?.feedbackForm ) {
      handleNavigate({ interviewRound: detail?.round, interviewId: state?.interviewId });
    } else {
      handleReviewNavigate({ interviewId: state?.interviewId });
    }
  } else if (timeDifference <= tenMinutesInMillis && timeDifference > 0) {
    // Allow navigation if within 10 minutes of interview time
    if (!detail?.feedbackForm ) {
      handleNavigate({ interviewRound: detail?.round, interviewId: state?.interviewId });
    } else {
      handleReviewNavigate({ interviewId: state?.interviewId });
    }
  } 
  else {
    setActiveTooltipIndex((prevIndex) => (prevIndex === index ? null : index));
    setTimeout(() => {
      setActiveTooltipIndex(null);
    }, 3000);
  }
},[state?.interviewId,handleReviewNavigate,handleNavigate]);


const handleChange = useCallback(
  (index: number, value: string, round: string, interviewId: any) => {
    const updateStatus = async () => {
      setEditingIndex(null);
      let level = '';
      switch (round) {
        case 'interviewDetL1':
          level = 'L1Feedback';
          break;
        case 'interviewDetL2':
          level = 'L2Feedback';
          break;
        case 'interviewDetL3':
          level = 'L3Feedback';
          break;
        default:
          break;
      }

      try {
        const resultAction = await dispatch(setFeedBackStatus({ interviewId, level, status: value }));
        console.log(setFeedBackStatus.fulfilled.match(resultAction), '987654');

        if (setFeedBackStatus.fulfilled.match(resultAction)) {
          const { status, message } = resultAction.payload || {};
          if (status === true) {
            toast.success(message);
            dispatch(getCandidateInterviewDetails(candidateId));
          } else if (status === 400) {
            toast.error(message);
          }
        } else if (setFeedBackStatus.rejected.match(resultAction)) {
          toast.error("Failed to update feedback status");
        }
      } catch (error) {
        console.error("Error while updating status:", error);
        toast.error("An unexpected error occurred");
      } finally {
        setLocalStatuses((prev) => ({
          ...prev,
          [index]: value, // Update the status for the specific index
        }));
        dispatch(setStatus(value));
      }
    };

    updateStatus();
  },
  [dispatch,candidateId,]
);


 
  const handleStepClick = useCallback((index: any) => {
    setExpandedStep(index); // Toggle expanded step
    setCandidateId(candidateId);
  },[candidateId])
 
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
   
    // Get the day, month, and year
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits for day
    const month = date.toLocaleString('default', { month: 'short' }); // Get abbreviated month
    const year = date.getFullYear();
 
    return `${day} ${month} ${year}`;
  };
 
  const formatTimeRange = (from: string) => {
    const formatTime = (time: string) => {
      const date = new Date(time);
      const hours = date.getHours().toString().padStart(2, '0'); // Ensure 2 digits for hours
      const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure 2 digits for minutes
      return `${hours}:${minutes}`; // 24-hour format
    };
 
    return `${formatTime(from)}`;
  };
 
const meetingIcon=(meetName:any)=>{
  switch(meetName){
    case 'Teams':
      return <img src={MSTeamIcon} style={{width:'20px',height:'20px'}} alt='Teams Icon'/>
    case 'Google Meet':
      return <img src={MeetIcon} style={{width:'20px',height:'20px'}} alt='Meet Icon'/>
    case 'Zoom':
      return <img src={Zoom} style={{width:'20px',height:'20px'}} alt='Zoom Icon'/>
  }
}

 const Md = useMediaQuery("(max-width: 1504px)");

const handleEditClick=useCallback((detail:any)=>{
  console.log(detail,'detail123454');
  setEditOpen(true);
  setEditInterviewData(detail);  
},[]);

  useEffect(() => {
    const cardStyles = {
      width: '240px',
      display: 'flex',
      alignItems: 'center',
      p: 1,
      bgcolor: '#00000005',
      boxShadow: 'none',
      border: 'none',
      borderRadius: '12px',
    };
 
    // Create an array of valid interview details for L1, L2, and L3 rounds, filtering out null or empty objects
    const interviewDetails = [
      state?.interviewDetL1 ? { ...state?.interviewDetL1, round: "interviewDetL1" ,roundKey:'interviewDetL1',candidateId:candidateId,candidateName:candidateName,rthId:id,interviewId:state?.interviewId,interviewPlatform:state?.interviewPlatform} : null,
      state?.interviewDetL2 ? { ...state?.interviewDetL2, round: "interviewDetL2" ,roundKey:'interviewDetL2',candidateId:candidateId,candidateName:candidateName,rthId:id,interviewId:state?.interviewId,interviewPlatform:state?.interviewPlatform} : null,
      state?.interviewDetL3 ? { ...state?.interviewDetL3, round: "interviewDetL3" ,roundKey:'interviewDetL3',candidateId:candidateId,candidateName:candidateName,rthId:id,interviewId:state?.interviewId,interviewPlatform:state?.interviewPlatform} : null,
    ]
    .filter(detail =>
      detail &&
      Object.values(detail).some(value => value !== null && value !== undefined)
    );
 
    const isValidInterview = (detail:any) => {
      if (!detail) return false;
   
      const keysToCheck = [
        "interviewRound", "interviewName", "interviewMode", "interviewLink", "interviewDate",
        "from", "to", "interviewer", "optionalAttendees", "feedbackForm", "status"
      ];
   
      // Check if any of the key fields is not null or undefined
      return keysToCheck.some(key => detail[key] !== null && detail[key] !== undefined);
    };
   
    // Filter out rounds with all null or undefined values
    const filteredDetails = interviewDetails.filter(isValidInterview);
   
 
    // If no valid interview details, clear steps and return early
    if (filteredDetails?.length === 0) {
      setSteps([]);
      return;
    }
 


 
    // Map over interview details to initialize steps with content
    const initializedSteps = filteredDetails.map((detail, index) => ({
      label: (
        <Box
          sx={{ display: 'flex', flexDirection: 'column' }}
          onClick={() => handleStepClick(index)}
        >
          <Typography
            variant="h6"
            sx={{
              color: expandedStep === index ? '#1677FF' : '#161C24',
              marginRight: '16px',
            }}
          >
            {`${detail?.interviewRound} Interview `} 
            {(expandedStep === index && isAccess && !detail?.feedbackForm) && (
        <img src={EditIcon} alt='EditIcon'onClick={(e) => {
          e.stopPropagation(); // Prevents triggering step click when clicking the edit icon
          handleEditClick(detail);
        }} />
      )}
          </Typography>
          
          <Typography sx={{ color: '#C4CDD5', fontSize: '16px' }}>
            {formatDate(detail?.interviewDate) || 'No Date Scheduled'}
          </Typography>
        </Box>
      ),
      content: (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 2, ml: '20px', mt: '30px', overflowX:"scroll", height:'60px', mr:3, pt:'20px' }}>
          {/* Interview Mode Card */}
          <Grid item xs={12} sm={6} md={3} sx={{height:'50px'}}>          
          <Card sx={{ ...cardStyles }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: '12px' }}>
              <Typography variant="body2" sx={{ color: '#919EAB', fontSize: '10px' }}>
                Interview Mode
              </Typography>
              <Box sx={{display:'flex',flexDirection:'row',gap:'10px'}}>
              {meetingIcon(detail?.interviewMode)}
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {detail?.interviewMode || 'Not Specified'}
              </Typography>
              </Box>
            </Box>
          </Card>
          </Grid>
 
          {/* Date & Time Card */}
          <Grid item xs={12} sm={6} md={3} sx={{height:'50px'}}>
          <Card sx={{ ...cardStyles }}>
            <img src={dateAndTimeIcon} alt="Date and Time" />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: '12px' }}>
              <Typography variant="body2" sx={{ color: '#919EAB', fontSize: '10px' }}>
                Date & Time
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {detail?.from
                  ? `${formatDate(detail?.interviewDate)} | ${formatTimeRange(detail?.from)} - ${formatTimeRange(detail?.to)}`
                  : 'Not Specified'}
              </Typography>
            </Box>
          </Card>
          </Grid>
 
          {/* Interviewer Card */}
          <Grid item xs={12} sm={6} md={3} sx={{height:'50px'}}>
          <Card sx={{ ...cardStyles }}>
            <img src={InterviewIcon} alt="Interviewer" />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: '12px' }}>
              <Typography variant="body2" sx={{ color: '#919EAB', fontSize: '10px' }}>
                Interviewer
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {detail?.interviewer?.userName?.length > 15
                  ? `${detail?.interviewer?.userName.slice(0, 15)}...`
                  : detail?.interviewer?.userName || 'Not Specified'}
              </Typography>
            </Box>
          </Card>
          </Grid>
 
          {/* Feedback Button */}
          <Grid item xs={12} sm={6} md={3} sx={{height:'50px'}}>
          <Tooltip
        title="Interview is not yet started"
        open={activeTooltipIndex === index}
        onClose={() => setActiveTooltipIndex(null)}
        disableHoverListener
        disableFocusListener
        disableTouchListener
      >
          <Button
            variant="outlined"
            sx={{
              minWidth:'230px',
              textTransform: 'none',
              bgcolor: '#F0F5FF',
              color: detail?.feedbackForm ? '#389E0D' : '#1677FF',
              border: detail?.feedbackForm ? '1px solid #389E0D' : '1px solid #1677FF',
              borderRadius: '8px',
              ':hover': { border: detail?.feedbackForm ? '1px solid #389E0D' : '1px solid #1677FF' },
              height:'48px'
            }}
            startIcon={detail?.feedbackForm ? <img src={FeedBackCompleted} alt="Feedback completed" />
              : <img src={FillFeedbackIcon} alt="Fill Feedback Form Icon" />}
              endIcon={detail?.feedbackForm ? <VisibilityOutlinedIcon sx={{color:'#389E0D'}} /> : null}
              onClick={()=>handleButtonClick(detail,index)}
          >
            {detail?.feedbackForm ? 'Feedback Submitted' : 'Fill Feedback Form'}
            {!detail?.feedbackForm &&
              <span style={{ color: 'red', marginLeft: '10px', marginRight: '14px' }}>*</span>
            }
          </Button>
          </Tooltip>
          </Grid>
 
          {/* Status TextField */}
          <Grid item xs={12} sm={6} md={3} sx={{height:'50px'}}>
          {(detail?.feedbackForm && detail?.status !== 'Pending' && detail?.status !== null) ? (
          <Select
            value={detail?.status || ''}
            // IconComponent={detail?.status !== 'On Hold' ? () => <div /> :KeyboardArrowDownRoundedIcon}  
            // inputProps={{
            //   readOnly: detail?.status !== 'On Hold',
            // }}
            onChange={(e) => handleChange(index, e.target.value, detail?.round, state?.interviewId)}
            disabled={!isAccess}
            sx={{
              width: '200px',
              height:'48px',
              borderRadius: '8px',
              color: detail?.status === 'Selected' ? '#389E0D' :
                detail?.status === 'Rejected' ? '#ff8f8f' :
                  detail?.status === 'On Hold' ? '#ffa769' : undefined,
              backgroundColor: detail?.status === 'Rejected' ? '#ffe8e8' :
                detail?.status === 'Selected' ? '#ebf5e6' :
                  detail?.status === 'On Hold' ? '#fff7f2' : undefined,
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: detail?.status === 'Selected' ? '#389E0D' :
                  detail?.status === 'Rejected' ? '#ffc9c9' :
                    detail?.status === 'On Hold' ? '#ffaa6e' : undefined,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: detail?.status === 'Selected' ? '#389E0D' :
                  detail?.status === 'Rejected' ? '#ffc9c9' :
                    detail?.status === 'On Hold' ? '#ffaa6e' : undefined,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: detail?.status === 'Selected' ? '#389E0D' :
                  detail?.status === 'Rejected' ? '#ffc9c9' :
                    detail?.status === 'On Hold' ? '#ffaa6e' : undefined,
              },
              '& .MuiSelect-select': {
                color: detail?.status === 'Selected' ? '#389E0D' :
                  detail?.status === 'Rejected' ? '#ff8f8f' :
                    detail?.status === 'On Hold' ? '#ffa769' : undefined,
              },
            }}
          >
            <MenuItem value="On Hold">On Hold</MenuItem>
            <MenuItem value="Selected">Selected</MenuItem>
            <MenuItem value="Rejected">Rejected</MenuItem>
          </Select>
          ) : (
            <FormControl
            variant="outlined"
            sx={{ borderColor: "divider" }}
          >
            <InputLabel sx={{ fontSize: "0.8rem", fontWeight: 410, color: "gray"}}>Choose Status</InputLabel>
            <Select
              value={localStatuses[index] || ''} 
              onChange={(e) => handleChange(index, e.target.value, detail?.round, state?.interviewId)}
              disabled={!detail?.feedbackForm||!isAccess}
              label={"Choose Status"}
              IconComponent={KeyboardArrowDownRoundedIcon}
                sx={{
                width: '200px',
                height:'48px',
                borderRadius: '8px',                
              }}
            >
              <MenuItem value="On Hold">On Hold</MenuItem>
              <MenuItem value="Selected">Selected</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
            </FormControl>
          )}
          </Grid>
        </Box> 
      ),
    }));
 
    // Add "Schedule L2 Interview" step if there are fewer than 3 rounds
    if (filteredDetails.length < 3) {
      const nextRound = filteredDetails?.length === 1 ? 'L2' : 'L3';
      const InterviewNextRound=`interviewDet${nextRound}`
      const lastInterviewDate = new Date(filteredDetails[filteredDetails.length - 1]?.interviewDate);
      const lastInterviewFrom=new Date(filteredDetails[filteredDetails.length-1]?.from);
      setInterviewFrom(lastInterviewFrom);
      setInterviewDate(lastInterviewDate);
       const AfterLastInterview = new Date(lastInterviewDate);
      AfterLastInterview.setDate(lastInterviewDate.getDate() + 1);
            
      // Check if the current date is after 10 days from the last interview date
      initializedSteps?.push({
        label: <span style={{ display: 'none' }} />,
        content: (
          <Button
            variant="outlined"
            sx={{ color: '#0D6EFD', borderColor: '#0D6EFD', textTransform: 'none' }}
            onClick={() => {
              if (isAccess) {
                handleClickScheduleNextRound(InterviewNextRound);
              } else {
                toast.warn("You don't have access");
              }
            }}
            disabled={filteredDetails[filteredDetails.length - 1].status !== 'Selected'||!filteredDetails[filteredDetails.length-1].feedbackForm||!isAccess}
          >
            + Schedule {nextRound} Interview
          </Button>
        ),
      });
    }
 
    // Set initialized steps to state
    setSteps(initializedSteps);
  }, [expandedStep, state, activeTooltipIndex,handleEditClick,candidateName,id,isAccess,interviewNextRound,
    handleChange, handleNavigate,handleStepClick,editingIndex,localStatuses,handleReviewNavigate,handleButtonClick,candidateId,candidateIdNew,handleClickScheduleNextRound]);
 
 
  useEffect(()=>{
    setSteps([]);
    setInterviewDate(null);
    setInterviewFrom(null);
    dispatch(getCandidateInterviewDetails(candidateId));
    setExpandedStep(0);
  },[candidateId,dispatch,setSteps,candidateIdNew])
 
  useEffect(()=>{
    if(state){
      setInterviewId(state?.interviewId);
    }
  },[state])

  useEffect(()=>{
    if(steps.length===0){
      setInterviewDate(null);
      setInterviewFrom(null);
    }
  },[candidateId,steps])
  
 useEffect(()=>{
  if(resumeRepoPath){
    setIsAccess(false);
  }
  else{
    setIsAccess(true);
  }
 },[resumeRepoPath])
   
  return (
    <>  
      <Box sx={{ width: '90vw', height: '80px', ml: '70px', mt: '10px' }}>
      <Paper sx={{height: Md? "62vh" : "70vh",  width: Md? "87vw" : "90vw", display: 'flex', flexDirection: 'column', borderRadius: '8px', overflow: 'hidden', boxShadow: 'none', pb: '10px', overflowY:'scroll', '&::-webkit-scrollbar': { width: '0px', height: '0px' } }}>     
         {steps?.length > 0 ? (
        <Stepper orientation="vertical" activeStep={0} sx={{ ml: '30px' }}>
          {steps?.map((step:any, index:any) => (
            <Box
              key={index}
              sx={{
                mb: 1,
                cursor: 'pointer',
                transform: expandedStep === index ? 'scale(1.02)' : 'scale(1)',
                transition: 'transform 0.2s ease, background-color 0.2s ease',
                backgroundColor: expandedStep === index ? 'transparent' : 'transparent',
                p: expandedStep === index ? 2 : 0,
                borderRadius: expandedStep === index ? '8px' : 0,
              }}
              onClick={() => handleStepClick(index)}
            >
              <StepLabel
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                icon={
                  <img
                    src={expandedStep === index ? CurrentStepIcon : StepIcon}
                    alt={expandedStep === index ? "current step" : "step icon"}
                    width="10px"
                  />
                }
              >
                <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#161C24' }}>
                  {step?.label}
                </Typography>
              </StepLabel>
             
              <Box sx={{ mt: 1 }}>{step?.content}</Box>
             
              {index < steps?.length - 1 && (
                  <img src={StepperConnectorIcon} alt="step connector" style={{ width: '8px', height: '50px' }} />
              )}
            </Box>
          ))}
        </Stepper>
        ):(
        <Box sx={{ height: Md? "62vh" : "70vh", display:'flex', justifyContent:'center', alignItems:'center', borderRadius:'8px', overflow:'hidden', boxShadow:'none'}}>
        <Box>
        <Box sx={{display:'flex', justifyContent:'center'}}>
        <img src={Img} style={{width:'300px'}} alt="Interview Details imageshow" />
        </Box>
        <Box sx={{mt:'30px', display:'flex', justifyContent:'center', flexDirection:'column'}}>
        <Typography sx={{color:'#212B36', fontSize:'18px', fontWeight:600, width:'350px', textAlign:'center'}}>No Interview Scheduled Yet</Typography>
        <Typography sx={{color:'#919EAB', fontSize:'14px', width:'350px', textAlign:'center', pt:'14px'}}>It looks like you haven't schedule any Interview yet.</Typography>
        <Typography sx={{color:'#919EAB', fontSize:'14px', width:'350px', textAlign:'center'}}>Get started by clicking "Add" below.</Typography>
        <Typography  onClick={handleClickOpen} sx={{color:'#1677FF', fontWeight:600, width:'350px', textAlign:'center', pt:'14px', cursor: 'pointer', '&:hover':{color:'#1f69d1'}}}>+ Schedule Interview</Typography>
        </Box>        
        </Box>
        </Box>
        )}
 
        </Paper>
      </Box>
      <ScheduleInterviewDialog DialogOpen={open} close={handleClose} setSteps={setSteps} setSelectedStep={setSelectedStep} selectedStep={selectedStep} handleNavigate={handleNavigate} interviewNextRound={interviewNextRound} 
      interviewId={interviewId} prevInterviewDate={interviewDate} prevInterviewFrom={interviewFrom}/>
      <EditInterview DialogOpen={editOpen} close={()=>setEditOpen(false)} interviewData={editInterviewData}/>
    </>
  );
};
 