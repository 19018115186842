import { Grid, Typography, Box, FormControl, Select, MenuItem, CircularProgress } from '@mui/material';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { BaseUrl } from '../../../../../constants/Crudurl';
import { getRecruiter } from '../../../../../Crud/rth';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';

function GaugeChart() {
    const data = useSelector((state: RootState) => state.profile.data);
    const userID = data?.data?.userId;
    const [selectedValue, setSelectedValue] = useState(0);
    const [unselectedValue, setUnselectedValue] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [selectedRecruiters, setSelectedRecruiters] = useState('')
    const [recruiters, setRecruiters] = useState<{ id: string; title: string }[]>([]);
    const [loadingRecruiters, setLoadingRecruiters] = useState(true);

    useEffect(() => {
        const fetchRecruiters = async () => {
            setLoadingRecruiters(true);
            try {
                const response = await getRecruiter();
                const newRecruiters = response.data.map((data: any) => ({
                    id: data?.userId,
                    title: `${data?.firstName} ${data?.lastName}`
                }));
                setRecruiters(prev => {
                    const merged = [...prev, ...newRecruiters];
                    return merged.filter((rec, index, self) =>
                        index === self.findIndex((r) => r.id === rec.id)
                    );
                });
            } catch (error) {
                console.error('Error fetching positions:', error);
            } finally {
                setLoadingRecruiters(false);
            }
        };
        fetchRecruiters();
    }, []);

    useEffect(() => {
        const fetchStatusReport = async () => {
            try {
                const response = await axios.post(`${BaseUrl}/dashboard/selectionRateByRl/${userID}`,
                    {
                        mrf: [selectedRecruiters],
                        userId: null
                    });
                const responseData = response?.data?.data;
                const selected = responseData?.Selected || 0;
                const totalCandidates = responseData?.["Total Candidates"] || 0;
                const unselected = totalCandidates - selected;
                setSelectedValue(selected);
                setUnselectedValue(unselected);
                setTotalValue(totalCandidates);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (userID) {
            fetchStatusReport();
        }
    }, [userID, selectedRecruiters]);

    const statusReport = [
        { label: 'Selected', value: selectedValue, color: '#1677FF' },
        { label: 'Unselected', value: unselectedValue, color: '#D9D9D9' },
    ];

    return (
        <Grid container sx={{ borderRadius: '8px', height: '260px', backgroundColor: '#FFFFFF', padding: '8px', overflow: 'hidden', position: 'relative' }}>
            <Grid item xs={12} container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '30px', mt: -1 }}>
                <Typography sx={{ fontWeight: '500', fontSize: '10px' }}>Selection Rate</Typography>
                <FormControl
                    variant="standard"
                    sx={{
                        m: 0.5,
                        minWidth: 100,
                        '& .MuiInput-underline:before': { borderBottom: 'none' },
                        '& .MuiInput-underline:after': { borderBottom: 'none' },
                        '&:hover .MuiInput-underline:before': { borderBottom: 'none !important' }
                    }}
                >
                    <Select
                        value={selectedRecruiters}
                        onChange={(e) => setSelectedRecruiters(e.target.value)}
                        size="small"
                        IconComponent={(props) => <ArrowDropDownIcon {...props} />}
                        displayEmpty
                        sx={{
                            minWidth: 100,
                            maxWidth: 120,
                            fontSize: '12px',
                            height: '30px',
                            padding: '4px',
                            '& .MuiSelect-select': { padding: 0 },
                            '&:hover': { backgroundColor: 'transparent' },
                        }}
                        renderValue={(selected) =>
                            selected ? (selected.length > 8 ? `${selected.substring(0, 8)}...` : selected) : "Recruiters"
                        }
                    >
                        {recruiters.map((rec) => (
                            <MenuItem key={rec.id} value={rec.title}>{rec.title}</MenuItem>
                        ))}
                        {loadingRecruiters && (
                            <MenuItem disabled>
                                <CircularProgress size={20} sx={{ margin: 'auto' }} />
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '170px', position: 'relative' }}>
                <Gauge
                    value={selectedValue}
                    startAngle={-90}
                    endAngle={90}
                    innerRadius="60%"
                    outerRadius="100%"
                    sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 20,
                            transform: 'translate(2px, -8px)',
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                            fill: '#1677FF',
                        },
                    }}
                    text={({ value }) => `${value}% `}
                    valueMax={totalValue}
                />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                {statusReport.map((item, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', margin: '4px', backgroundColor: '#FAFAFA', borderRadius: '8px', padding: '2px 4px' }}>
                        <Box sx={{ width: 6, height: 6, borderRadius: '1px', backgroundColor: item.color, marginRight: '8px' }}></Box>
                        <Typography sx={{ color: '#6B7280', fontSize: '10px', padding: '2px' }}>{item.label}</Typography>
                        <Typography sx={{ color: '#6B7280', fontSize: '10px', fontWeight: 'bold' }}>{item.value}</Typography>
                    </Box>
                ))}
            </Grid>
        </Grid>
    );
}

export default GaugeChart;
