import React, { useEffect, useState } from 'react';
import { Box, Card, Typography, Grid, Button, TextField, Divider, IconButton, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { getApproveAndDecline, getCandidateApproval } from '../../../Crud/rth';
import { toast } from 'react-toastify';
import { GridCloseIcon } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';

const StyledLabel = styled(Typography)({
    fontSize: '0.625rem',
    fontWeight: '500',
    color: "#1414144D"
});

const StyledTextField = styled(TextField)({
    width: '160px',
    height: '40px',
    '& .MuiInputBase-root': {
        height: '40px',
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
        borderRadius: '4px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiInputBase-input': {
        fontSize: '0.75rem',
        color: '#000000',
    },
    '& .Mui-disabled .MuiInputBase-input': {
        color: '#000000',
        WebkitTextFillColor: '#000000',
    },
});

const getButtonColor = (state: string) => {
    const colors: Record<string, string> = {
        "Pending": "#FFA940",
        "Rejected": "#F04E4E",
        "Approved": "#389E0D",
        "Unknown": "#B0BEC5",
    };
    return colors[state] || "#B0BEC5";
};

const JobOfferApproval1: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const candidateId = location.state?.candidateId;
    const [candidateData, setCandidateData] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [actionType, setActionType] = useState<'Approved' | 'Rejected' | null>(null);
    const [comments, setComments] = useState('');
    const [error, setError] = useState('');
    const level = location.state?.level;
    const l1Status = candidateData?.l1Status ? Object.keys(candidateData.l1Status)[0] : '-';
    const l2Status = candidateData?.l2Status ? Object.keys(candidateData.l2Status)[0] : '-';
    const l3Status = candidateData?.l3Status ? Object.keys(candidateData.l3Status)[0] : '-';

    useEffect(() => {
        if (candidateId) {
            setLoading(true);
            getCandidateApproval(candidateId)
                .then(response => {
                    setCandidateData(response?.data?.data);
                })
                .catch(error => {
                    console.error("Error fetching candidate details:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [candidateId]);

    const handleBackButtonClick = () => {
        const currentLevel = location.state?.level;
        let targetPath = "/job-offer-tableview?tab=l1-approver";
        if (currentLevel === "L2Status") {
            targetPath = "/job-offer-tableview?tab=l2-approver";
        } else if (currentLevel === "L3Status") {
            targetPath = "/job-offer-tableview?tab=l3-approver";
        }
        navigate(targetPath);
    };

    const handleViewFeedbackClick = () => {
        navigate(`/interviewFeedBackForm/${candidateId}`);
    };

    const handleDialogOpen = (action: 'Approved' | 'Rejected') => {
        console.log('Dialog Opened with Action:', action);
        setOpenDialog(true);
        setActionType(action);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setComments('');
        setError('');
        setActionType(null);
    };

    const handleConfirmAction = async () => {
        if (!candidateId) {
            setError('Candidate ID is missing.');
            return;
        }

        if (!actionType) {
            setError('Action type is missing.');
            return;
        }

        if (!comments.trim()) {
            setError('Please enter remarks.');
            return;
        }

        if (comments.length > 200) {
            setError('Remarks cannot exceed 200 characters.');
            toast.error("Remarks cannot exceed 200 characters.")
            return;
        }

        try {
            await getApproveAndDecline({
                candidateId,
                level,
                status: actionType,
                comments,
            });
            toast.success(`${actionType} successfully`);
            handleDialogClose();
            const response = await getCandidateApproval(candidateId);
            setCandidateData(response?.data?.data);
        } catch (error) {
            console.error('Error updating status:', error);
            toast.error('Failed to update status');
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ padding: 2, pt: 0, mb: 2 }}>
            <IconButton onClick={handleBackButtonClick}>
                <KeyboardBackspaceIcon />
            </IconButton>
            <Card variant="outlined" sx={{ padding: 2, maxWidth: 1200, margin: '0 auto' }}>
                <Typography fontSize="0.875rem" fontWeight="600" marginBottom={2}>
                    Candidate Details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={2} md={2}>
                        <StyledLabel>Candidate Name</StyledLabel>
                        <StyledTextField value={candidateData?.firstName || "-"} />
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <StyledLabel>MRF ID</StyledLabel>
                        <StyledTextField value={candidateData?.mrfId || "-"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>MRF Name</StyledLabel>
                        <StyledTextField value={candidateData?.mrfName || "-"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Education</StyledLabel>
                        <StyledTextField value={candidateData?.education || "-"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Work Location</StyledLabel>
                        <StyledTextField value={candidateData?.workLocation || "-"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Work Mode</StyledLabel>
                        <StyledTextField value={candidateData?.workMode || "-"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Total No. of experience</StyledLabel>
                        <StyledTextField value={candidateData?.totYearsOfExp || "-"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Notice Period (in days)</StyledLabel>
                        <StyledTextField value={candidateData?.noticePeriods || "-"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Date of Joining</StyledLabel>
                        <StyledTextField value={candidateData?.dateOfJoining || "-"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Current CTC</StyledLabel>
                        <StyledTextField value={candidateData?.currentCtc || "-"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Expected CTC</StyledLabel>
                        <StyledTextField value={candidateData?.expectedCtc || "-"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Business</StyledLabel>
                        <StyledTextField value={candidateData?.business || "-"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Payroll</StyledLabel>
                        <StyledTextField value={candidateData?.payRoll || "-"} />
                    </Grid>
                </Grid>
                <Divider sx={{ marginY: 4 }} />
                <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                    <Table>
                        <TableHead>
                            <TableRow className="table-header">
                                <TableCell className="table-body" sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}> Approvers </TableCell>
                                <TableCell className="table-body" sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}> Status </TableCell>
                                <TableCell className="table-body">Remarks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}> {candidateData?.l1Approver?.userName || '-'} </TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <Button
                                        variant="outlined"
                                        style={{
                                            color: getButtonColor(l1Status),
                                            borderColor: getButtonColor(l1Status),
                                            backgroundColor: getButtonColor(l1Status) + '10',
                                            fontSize: '0.75rem',
                                            height: '24px',
                                            textTransform: 'none',
                                            fontWeight: '500',
                                        }}
                                    >
                                        {l1Status}
                                    </Button>
                                </TableCell>
                                <TableCell>{candidateData?.l1Remarks || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}> {candidateData?.l2Approver?.userName || '-'} </TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <Button
                                        variant="outlined"
                                        style={{
                                            color: l1Status === 'Pending' ? '#FFA940' : l1Status === 'Rejected' ? '#B0BEC5' : getButtonColor(l2Status),
                                            borderColor: l1Status === 'Pending' ? '#FFA940' : l1Status === 'Rejected' ? '#B0BEC5' : getButtonColor(l2Status),
                                            backgroundColor: l1Status === 'Pending' ? `${'#FFA940'}10` : l1Status === 'Rejected' ? `${'#B0BEC5'}10` : `${getButtonColor(l2Status)}10`,
                                            fontSize: '0.75rem',
                                            height: '24px',
                                            textTransform: 'none',
                                            fontWeight: '500',
                                        }}
                                    >
                                        {l1Status === 'Pending' ? 'Pending' : l1Status === 'Rejected' ? 'Inactive' : l2Status}
                                    </Button>
                                </TableCell>
                                <TableCell>{l1Status === 'Pending' || l1Status === 'Rejected' ? '-' : candidateData?.l2Remarks || '-'}</TableCell>
                            </TableRow>
                            {candidateData?.l3Approver?.userName && (
                                <TableRow>
                                    <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}> {candidateData?.l3Approver?.userName || '-'} </TableCell>
                                    <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                        <Button
                                            variant="outlined"
                                            style={{
                                                color: l1Status === 'Pending' ? '#FFA940' : l2Status === 'Rejected' ? '#B0BEC5' : l3Status === '-' ? '#FFA940' : getButtonColor(l3Status),
                                                borderColor: l1Status === 'Pending' ? '#FFA940' : l2Status === 'Rejected' ? '#B0BEC5' : l3Status === '-' ? '#FFA940' : getButtonColor(l3Status),
                                                backgroundColor: l1Status === 'Pending' ? '#FFA94010' : l2Status === 'Rejected' ? '#B0BEC510' : l3Status === '-' ? '#FFA94010' : `${getButtonColor(l3Status)}10`,
                                                fontSize: '0.75rem',
                                                height: '24px',
                                                textTransform: 'none',
                                                fontWeight: '500',
                                            }}
                                        >
                                            {l1Status === 'Pending' ? 'Pending' : l2Status === 'Rejected' ? 'Inactive' : l3Status === '-' ? 'Pending' : l3Status}
                                        </Button>
                                    </TableCell>
                                    <TableCell>{l1Status === 'Pending' || l2Status === 'Rejected' ? '-' : candidateData?.l3Remarks || '-'}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Divider sx={{ marginY: 4 }} />
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <StyledLabel>Consolidated Interview Feedback</StyledLabel>
                        <TextField value="Selected" disabled
                            sx={{
                                width: '250px', height: '40px',
                                '& .MuiInputBase-root': {
                                    height: '40px',
                                    backgroundColor: '#389E0D1A',
                                    borderRadius: '4px',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '& .MuiInputBase-input': {
                                    fontSize: '0.75rem',
                                    color: '#389E0D'
                                },
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#389E0D',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <StyledLabel>Feedback</StyledLabel>
                        <Button startIcon={<VisibilityIcon />} variant="outlined" onClick={handleViewFeedbackClick}
                            sx={{
                                fontSize: "0.625rem", fontWeight: "500", color: '#00000080', textTransform: 'none', border: 'none', boxShadow: 'none', width: '250px', height: '40px', backgroundColor: '#0000000D',
                                '&:hover': {
                                    backgroundColor: '#0000000D',
                                    boxShadow: 'none',
                                    border: 'none',
                                }
                            }}
                        >
                            View Submitted Feedback
                        </Button>
                    </Grid>
                </Grid>
                {candidateData?.l1ApproverHistory?.[0] && (
                    <>
                        <Divider sx={{ marginY: 4 }} />
                        <Box sx={{ pb: 2 }}>
                            <Typography fontSize="0.875rem" fontWeight="600" marginBottom={2}>
                                Approvers History
                            </Typography>
                        </Box>
                        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                            <Table>
                                <TableHead>
                                    <TableRow className="table-header">
                                        <TableCell className="table-body" sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}> Approvers </TableCell>
                                        <TableCell className="table-body" sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}> Status </TableCell>
                                        <TableCell className="table-body">Remarks</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            {candidateData?.l1ApproverHistory?.[0]?.approver?.userName || '-'}
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <Button
                                                variant="outlined"
                                                style={{
                                                    color: getButtonColor(Object.keys(candidateData?.l1ApproverHistory?.[0]?.status || {})[0] || 'Unknown'),
                                                    borderColor: getButtonColor(Object.keys(candidateData?.l1ApproverHistory?.[0]?.status || {})[0] || 'Unknown'),
                                                    backgroundColor: `${getButtonColor(Object.keys(candidateData?.l1ApproverHistory?.[0]?.status || {})[0] || 'Unknown')}10`,
                                                    fontSize: '0.75rem',
                                                    height: '24px',
                                                    textTransform: 'none',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                {Object.keys(candidateData?.l1ApproverHistory?.[0]?.status || {})[0] || '-'}
                                            </Button>
                                        </TableCell>
                                        <TableCell>{candidateData?.l1ApproverHistory?.[0]?.comments || '-'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            {candidateData?.l2ApproverHistory?.[0]?.approver?.userName || '-'}
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <Button
                                                variant="outlined"
                                                style={{
                                                    color: getButtonColor(Object.keys(candidateData?.l2ApproverHistory?.[0]?.status || {})[0] || 'Unknown'),
                                                    borderColor: getButtonColor(Object.keys(candidateData?.l2ApproverHistory?.[0]?.status || {})[0] || 'Unknown'),
                                                    backgroundColor: `${getButtonColor(Object.keys(candidateData?.l2ApproverHistory?.[0]?.status || {})[0] || 'Unknown')}10`,
                                                    fontSize: '0.75rem',
                                                    height: '24px',
                                                    textTransform: 'none',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                {Object.keys(candidateData?.l2ApproverHistory?.[0]?.status || {})[0] || '-'}
                                            </Button>
                                        </TableCell>
                                        <TableCell>{candidateData?.l2ApproverHistory?.[0]?.comments || '-'}</TableCell>
                                    </TableRow>
                                    {candidateData?.l3ApproverHistory?.[0]?.approver?.userName && (
                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                {candidateData?.l3ApproverHistory?.[0]?.approver?.userName || '-'}
                                            </TableCell>
                                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                <Button
                                                    variant="outlined"
                                                    style={{
                                                        color: getButtonColor(Object.keys(candidateData?.l3ApproverHistory?.[0]?.status || {})[0] || 'Unknown'),
                                                        borderColor: getButtonColor(Object.keys(candidateData?.l3ApproverHistory?.[0]?.status || {})[0] || 'Unknown'),
                                                        backgroundColor: `${getButtonColor(Object.keys(candidateData?.l3ApproverHistory?.[0]?.status || {})[0] || 'Unknown')}10`,
                                                        fontSize: '0.75rem',
                                                        height: '24px',
                                                        textTransform: 'none',
                                                        fontWeight: '500',
                                                    }}
                                                >
                                                    {Object.keys(candidateData?.l3ApproverHistory?.[0]?.status || {})[0] || '-'}
                                                </Button>
                                            </TableCell>
                                            <TableCell>{candidateData?.l3ApproverHistory?.[0]?.comments || '-'}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </Card>
            {level === "L1Status" && candidateData?.l1Status?.Pending && (
                <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button variant="contained" size="small" onClick={() => handleDialogOpen("Rejected")}
                        sx={{
                            marginRight: 1, backgroundColor: "#FEF2F3", textTransform: "none", color: "#E8505B", boxShadow: "none", fontSize: "0.75rem",
                            "&:hover": {
                                backgroundColor: "#FEF2F3",
                                boxShadow: "none",
                            },
                            "&:active": {
                                boxShadow: "none",
                            },
                        }}
                    >
                        Reject
                    </Button>
                    <Button variant="contained" size="small" onClick={() => handleDialogOpen("Approved")}
                        sx={{
                            marginRight: 1, backgroundColor: "#00AB55", textTransform: "none", boxShadow: "none", fontSize: "0.75rem",
                            "&:hover": {
                                backgroundColor: "#00AB55",
                                boxShadow: "none",
                            },
                            "&:active": {
                                boxShadow: "none",
                            },
                        }}
                    >
                        Approve
                    </Button>
                </Box>
            )}

            {level === "L2Status" && candidateData?.l1Status?.Approved && candidateData?.l2Status?.Pending && (
                <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleDialogOpen("Rejected")}
                        sx={{
                            marginRight: 1,
                            backgroundColor: "#FEF2F3",
                            textTransform: "none",
                            color: "#E8505B",
                            boxShadow: "none",
                            fontSize: "0.75rem",
                            "&:hover": {
                                backgroundColor: "#FEF2F3",
                                boxShadow: "none",
                            },
                            "&:active": {
                                boxShadow: "none",
                            },
                        }}
                    >
                        Reject
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleDialogOpen("Approved")}
                        sx={{
                            marginRight: 1,
                            backgroundColor: "#00AB55",
                            textTransform: "none",
                            boxShadow: "none",
                            fontSize: "0.75rem",
                            "&:hover": {
                                backgroundColor: "#00AB55",
                                boxShadow: "none",
                            },
                            "&:active": {
                                boxShadow: "none",
                            },
                        }}
                    >
                        Approve
                    </Button>
                </Box>
            )}

            {level === "L3Status" && candidateData?.l2Status?.Approved && candidateData?.l3Status?.Pending && (
                <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleDialogOpen("Rejected")}
                        sx={{
                            marginRight: 1,
                            backgroundColor: "#FEF2F3",
                            textTransform: "none",
                            color: "#E8505B",
                            boxShadow: "none",
                            fontSize: "0.75rem",
                            "&:hover": {
                                backgroundColor: "#FEF2F3",
                                boxShadow: "none",
                            },
                            "&:active": {
                                boxShadow: "none",
                            },
                        }}
                    >
                        Reject
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleDialogOpen("Approved")}
                        sx={{
                            marginRight: 1,
                            backgroundColor: "#00AB55",
                            textTransform: "none",
                            boxShadow: "none",
                            fontSize: "0.75rem",
                            "&:hover": {
                                backgroundColor: "#00AB55",
                                boxShadow: "none",
                            },
                            "&:active": {
                                boxShadow: "none",
                            },
                        }}
                    >
                        Approve
                    </Button>
                </Box>
            )}

            {openDialog && actionType && (
                <Dialog
                    open={openDialog}
                    fullWidth
                    sx={{ maxWidth: "500px", margin: "auto" }}
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            handleDialogClose();
                        }
                    }}
                    slotProps={{ backdrop: { onClick: (e) => e.stopPropagation() } }}
                >
                    <DialogTitle sx={{ p: 2, borderBottom: "1px solid #f0f0f0" }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6" sx={{ fontSize: "1rem", fontWeight: 500 }}>
                                {actionType === 'Approved' ? 'Approve Remarks' : 'Rejection Remarks'}
                            </Typography>
                            <IconButton aria-label="close"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleDialogClose();
                                }}
                                sx={{ position: "absolute", right: 8, top: 8, backgroundColor: "#ECECEC" }}
                            >
                                <GridCloseIcon sx={{ fontSize: "1rem" }} />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        {actionType === 'Rejected' && (<Typography variant="body2" sx={{ mb: 1, fontSize: "0.75rem", color: "#757575" }}> Please provide a reason for declining </Typography>)}
                        <TextField fullWidth multiline rows={3}
                            placeholder={`Enter ${actionType === 'Approved' ? 'remarks' : 'your reason here'
                                }`}
                            value={comments}
                            onChange={(e) => {
                                e.stopPropagation();
                                const input = e.target.value;
                                if (input.length > 200) {
                                    setError("Remarks cannot exceed 200 characters.");
                                } else {
                                    setError("");
                                }
                                setComments(input);
                            }}
                            sx={{
                                "& .MuiInputBase-input": {
                                    fontSize: "12px",
                                },
                            }}
                        />
                        <Box sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between" }}>
                            <Typography variant="body2" sx={{ mt: 1, fontSize: "10px", color: "#8C8C8C" }}> Max 200 characters. </Typography>
                            {error && (<Typography variant="body2" sx={{ mt: 1, fontSize: "12px", color: "red", textAlign: "start" }}> {error} </Typography>)}
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ p: 2.5, pt: 0, bottom: 0, bgcolor: "background.paper" }}>
                        <Button onClick={handleDialogClose} variant="text"
                            sx={{
                                textTransform: "none", fontSize: "14px", color: "#757575", boxShadow: "none",
                                "&:hover": {
                                    backgroundColor: "none",
                                    boxShadow: "none",
                                },
                                "&:active": {
                                    boxShadow: "none",
                                },
                            }}
                        >
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmAction}
                            sx={{
                                textTransform: "none", fontSize: "14px", backgroundColor: actionType === 'Approved' ? "#00AB55" : "#E8505B", width: "20%", color: "#FFFFFF", boxShadow: "none",
                                "&:hover": {
                                    backgroundColor: actionType === 'Approved' ? "#00AB55" : "#E8505B",
                                    boxShadow: "none",
                                },
                                "&:active": {
                                    boxShadow: "none",
                                },
                            }}
                        >
                            {actionType === 'Approved' ? 'Approve' : 'Reject'}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Box >
    );
};

export default JobOfferApproval1;
