import { Typography, Radio,Grid, Box, RadioGroup, FormControlLabel, Tooltip, Chip } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import pdfIcon from '../../../../assets/images/pdf 1.svg';
import docIcon from '../../../../assets/images/doc 1.svg';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/hooks';
 
const Review = ({formValues,selectedFile,handlePreviousPage,setSelectedFile,setFormValues}:any) => {
 
  const location=useLocation();
  const path=location.pathname.includes("candidateForm");
  const formPath=location.pathname.includes("BulkUpload");
  const stateValue=useAppSelector((State)=>State.resume.selectedState);
  const countryValue=useAppSelector((State)=>State.resume.selectedCountry);
  const cityValue=useAppSelector((State)=>State.resume.selectedCity);
 
  const truncateText = (text: string, maxLength: number = 23) => {
    if (text && text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  const extractFileName = (url: any) => {
    if (!url) return { fileName: '', fileExt: '' };
  
    const fullFileName = url.split('/').pop().split('?')[0] || '';
  
    // Remove the prefix before the first underscore
    const actualFileName = fullFileName.includes('_')
      ? fullFileName.substring(fullFileName.indexOf('_') + 1)
      : fullFileName;
  
    const [fileNameWithoutExt, fileExt] = actualFileName.split(/\.(?=[^.]+$)/);
  
    const truncatedFileName = fileNameWithoutExt.length > 6 ? `${fileNameWithoutExt.slice(0, 6)}..` : fileNameWithoutExt;
  
    return {
      fileName: `${truncatedFileName}.${fileExt}`,
      fullFileName: actualFileName, 
      fileExt: fileExt.toLowerCase(), 
    };
  };
  
  const { fileName, fullFileName, fileExt } = extractFileName(selectedFile?.resumeUrl || '');
  
 
const getFileIcon = (ext: string) => {
  if (ext === 'pdf') {
    return pdfIcon;
  } else if (ext === 'doc' || ext === 'docx') {
    return docIcon;
  }
  return null; 
};
 
const fileIcon = getFileIcon(fileExt);
 
const handleRemoveFile=()=>{
  setSelectedFile(null);
}
 
const handleDeleteSkill = (indexToDelete:any) => {
  setFormValues((prevValues:any) => ({
    ...prevValues,
    skills: prevValues.skills.filter((_:any, index:any) => index !== indexToDelete),
  }));
};

  return (
   
    <Box sx={{ pt: '4px',ml:path?'30px':''}}>
         {/* <Paper elevation={0} sx={{height:'90vh',overflowY:'auto',pt:'1  0px'}}> */}
          <Box sx={{m:'14px', mt:0}}>
      <Typography sx={{fontSize:'20px',fontWeight:'500'}}>
      {path ? '7.Review' : 'Review'}
        </Typography>
      <Box sx={{ mt: '16px', display: 'flex', flexDirection: 'column' }}>
  <Box>
    <Typography sx={{ color: '#bababa',mb:'10px' }}>
      Resume<span style={{ color: 'red' }}> *</span>
    </Typography>
  </Box>
 
  {selectedFile.resumeUrl ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '48px',
                width: path ?'154px':'254px',
                justifyContent: 'space-between',
                bgcolor: '#1677FF1A',
                border: '1px dashed #E3E8F2',
                borderRadius: '4px',
                mb:'30px'
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {fileIcon && (
                  <img
                    src={fileIcon}
                    alt="file-icon"
                    style={{ width: '20px', marginRight: '8px', marginLeft: '20px' }}
                  />
                )}
                {/* Tooltip on file name */}
                <Tooltip title={fullFileName} disableHoverListener={fullFileName === fileName}>
                  <Typography sx={{ color: '#637381', mr: '4px', cursor: 'pointer' }}>{fileName}</Typography>
                </Tooltip>
                {!path && formPath &&
                <CloseRoundedIcon
                  sx={{ color: '#a6a6a6', fontSize: '14px', mt: '6px', cursor: 'pointer' }}
                  onClick={handleRemoveFile}
                />
                }
              </Box>
              {!path && formPath &&
              <Typography
                sx={{ color: '#1677FF', mr: '10px', cursor: 'pointer' }}
                onClick={handlePreviousPage}
              >
                Change File
              </Typography>
             }
            </Box>
          ) : (
            <Typography sx={{ color: '#637381', mt: '8px' }}>Select resume</Typography>
          )}
</Box>
</Box>
 
      <Box sx={{width:'70vw',overFlowX:'hidden'}}>
      <Typography sx={{fontSize:'14px', mb:'17px', m:'14px',backgroundColor:'#1677FF1A',color:'#1677FF',width:'159px',p:'3px',borderRadius:'3px', textAlign:'center'}}>Personal Information</Typography>
      <Box sx={{borderBottom:'1px solid #bababa', pb:'14px'}}>
      <Box sx={{ m:'14px',display:'flex', flexDirection:'row'}}>
          <Box>
          <Typography sx={{color:'#bababa',mb:'18px', fontSize:'14px'}}>How did you heard about us<span style={{color:'red'}}> *</span></Typography>
          <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>{formValues.howDidYouHeardAbtUs}</Typography>          
       </Box>
 
          <Box sx={{pl:'30px'}}>
        <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '24px'}}>
          Have you previously been employed by HEPL?<span style={{ color: 'red' }}> *</span>
        </Typography>
     
        <RadioGroup
          row
          aria-label="employed"
          name="preEmployed"
          value={formValues.preEmployed}
        >
          <FormControlLabel
            value={true}
            control={<Radio />}
            label="Yes"
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="No"
          />
        </RadioGroup>
        </Box>
      </Box>
       
        <Grid container spacing={2} sx={{ m:'4px',mt:'0px'}}>
      <Grid item >
      <Typography sx={{color:'#bababa',mb:'14px'}}>FirstName<span style={{color:'red'}}>  *</span></Typography>
      <Tooltip title={formValues.firstName} disableHoverListener={formValues?.firstName?.length <= 23}>
      <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'186px', p:'0 10px', borderRadius:'8px'}}>
      {truncateText(formValues?.firstName)}
        </Typography>
        </Tooltip>
          </Grid>
          {/* <Grid item >
      <Typography sx={{color:'#bababa',mb:'14px'}}>Middle Name<span style={{color:'red'}}>  *</span></Typography>
      <Tooltip title={formValues.firstName} disableHoverListener={formValues?.middleName?.length <= 23}>
      <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'186px', p:'0 10px', borderRadius:'8px'}}>
        {truncateText(formValues.middleName)}
        </Typography>
        </Tooltip>
          </Grid> */}
          <Grid item >
      <Typography sx={{color:'#bababa',mb:'14px'}}>LastName<span style={{color:'red'}}>  *</span></Typography>
      <Tooltip title={formValues.firstName} disableHoverListener={formValues?.lastName?.length <= 23}>
      <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'186px', p:'0 10px', borderRadius:'8px'}}>
        {truncateText(formValues.lastName)}
        </Typography>
        </Tooltip>
          </Grid>
          <Grid item >
      <Typography sx={{color:'#bababa',mb:'14px'}}>Email Address<span style={{color:'red'}}>  *</span></Typography>
      <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'186px', p:'0 10px', borderRadius:'8px'}}>{formValues.emailAddress}</Typography>
          </Grid>
          <Grid item >
      <Typography sx={{color:'#bababa',mb:'14px'}}>Phone Number<span style={{color:'red'}}>  *</span></Typography>
      <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'186px', p:'0 10px', borderRadius:'8px'}}>{formValues.phoneNumber}</Typography>
          </Grid>
          <Grid container spacing={2} sx={{mt:'10px',ml:'4px'}}>
            <Grid item >
            <Typography sx={{color:'#bababa',mb:'14px'}}>Date of Birth
            </Typography>
            <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>{formValues.dateOfBirth}</Typography>
            </Grid>
            <Grid item >
            <Typography sx={{color:'#bababa',mb:'14px'}}>Gender
            {path && <span style={{ color: 'red' }}> *</span>}
            </Typography>
            <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>{formValues.gender}</Typography>
            </Grid>
            <Grid item >
            <Typography sx={{color:'#bababa',mb:'14px'}}>Current CTC<span style={{color:'red'}}>  *</span>
            </Typography>
            <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>{formValues.currentCtc}</Typography>
            </Grid>
            <Grid item >
            <Typography sx={{color:'#bababa',mb:'14px'}}>Expected CTC<span style={{color:'red'}}>  *</span>
            </Typography>
            <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>{formValues.expectedCtc}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{mt:'10px',ml:'4px'}} >
          <Grid item>
            <Typography sx={{color:'#bababa',mb:'14px'}}>Notice Periods(In days)<span style={{ color: 'red' }}> *</span>
            </Typography>
            <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>{formValues.noticePeriods}</Typography>
            </Grid>
            <Grid item>
            <Typography sx={{color:'#bababa',mb:'14px'}}>Aadhar Number
            </Typography>
            <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>{formValues.aadharNumber}</Typography>
            </Grid>
            <Grid item>
            <Typography sx={{color:'#bababa',mb:'14px'}}>UAN Number
            </Typography>
            <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>{formValues.uanNumber}</Typography>
            </Grid>
          </Grid>
      </Grid>
    </Box>
 
      <Grid item sx={{borderBottom:'1px solid #bababa'}} >
      <Typography sx={{fontSize:'14px', mb:'17px',m:'14px',backgroundColor:'#1677FF1A',color:'#1677FF',width:'159px',p:'3px',borderRadius:'3px', textAlign:'center'}}>Additional Information</Typography>  
      <Box sx={{m:'14px',display:'flex', flexDirection:'row', gap:'20px'}}>    
 
      <Box>
      <Typography sx={{color:'#bababa',mb:'18px'}}>Nationality
      {path && <span style={{ color: 'red' }}> *</span>}
      </Typography>
      <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>{formValues.nationality}</Typography>
      </Box>
      <Box>
      <Typography sx={{color:'#bababa',mb:'18px'}}>Citizenship
      {path && <span style={{ color: 'red' }}> *</span>}
      </Typography>
      <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>{formValues.citizenship}</Typography>
      </Box>
      </Box>
      </Grid>        
 
      <Box>
        <Typography sx={{fontSize:'14px', mb:'17px',m:'14px',backgroundColor:'#1677FF1A',color:'#1677FF',width:'80px',p:'3px',borderRadius:'3px', textAlign:'center'}}>Address</Typography>
      <Box sx={{m:'14px',display:'flex', flexDirection:'row', gap:'20px'}}>
        <Box>
      <Typography sx={{color:'#bababa', mb:'18px'}}>Country
      {path && <span style={{ color: 'red' }}> *</span>}
      </Typography>
      <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>{countryValue||formValues?.address?.country}</Typography>
        </Box>
        <Box>
      <Typography sx={{color:'#bababa', mb:'18px'}}>Address Line 1
      {path && <span style={{ color: 'red' }}> *</span>}
      </Typography>
      <Tooltip title={formValues?.address?.addressLine1} disableHoverListener={formValues?.address?.addressLine1?.length <= 23}>
      <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>
        {truncateText(formValues?.address?.addressLine1)}
        </Typography>
        </Tooltip>
        </Box>
        <Box>
      <Typography sx={{color:'#bababa', mb:'18px'}}>Address Line 2
      {path && <span style={{ color: 'red' }}> *</span>}
      </Typography>
      <Tooltip title={formValues?.address?.addressLine2} disableHoverListener={formValues?.address?.addressLine2?.length <= 23}>
      <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>
        {truncateText(formValues?.address?.addressLine2)}
        </Typography>
        </Tooltip>
        </Box>
       </Box>
      </Box>
 
        <Box sx={{m:'14px',borderBottom:'1px solid #bababa', display:'flex', flexDirection:'row', gap:'20px'}}>
          <Box>
          <Typography sx={{color:'#bababa',mb:'18px'}}>Postal Code
          {path && <span style={{ color: 'red' }}> *</span>}
          </Typography>
          <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px',mb:'18px'}}>{formValues?.address?.postalCode}</Typography>
          </Box>
          <Box>
          <Typography sx={{color:'#bababa',mb:'18px'}}>State
          {path && <span style={{ color: 'red' }}> *</span>}
          </Typography>
          <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>{stateValue||formValues?.address?.state}</Typography>
          </Box>
          <Box>
          <Typography sx={{color:'#bababa',mb:'18px'}}>City
          {path && <span style={{ color: 'red' }}> *</span>}
          </Typography>
          <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>{cityValue||formValues?.address?.city}</Typography>
          </Box>
        </Box>
      <Box sx={{borderBottom:'1px solid #bababa'}}>
      <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Typography sx={{fontSize:'14px', mb:'17px',m:'14px',backgroundColor:'#1677FF1A',color:'#1677FF',width:'159px',p:'3px',borderRadius:'3px', textAlign:'center'}}>Experience</Typography>
      <Typography sx={{color:'#bababa',mb:'18px',m:'14px'}}>Skills</Typography>
      <Grid container spacing={1} sx={{ bgcolor: '#fafafa', width: '254px', p: '10px', borderRadius: '8px', m: '14px' }}>
        {!path&&(
    formValues?.skills && formValues?.skills?.length > 0 ? (
      formValues?.skills?.map((skill:any, index:any) => (
        <Grid item key={index}>
          <Chip
            label={skill}
            onDelete={() => handleDeleteSkill(index)}
            sx={{ fontWeight: '500' }}
          />
        </Grid>
      ))
    ) : (
      <Typography sx={{ fontWeight: '500' }}></Typography>
    )
  )}
{path && (
  formValues?.skills &&
  formValues?.skills.length > 0 ? (
    // Split the single string into individual skills
    formValues?.skills[0]
      .split(',') // Split the string by commas
      .map((skill: string, index: number) => (
        <Grid item key={index}>
          <Chip
            label={skill?.trim()} // Trim any extra spaces
            sx={{ fontWeight: '500' }}
          />
        </Grid>
      ))
  ) : (
    <Typography sx={{ fontWeight: '500' }}>No skills added</Typography>
  ))}
  </Grid>
 
 
          </Grid>          
        </Grid>
        {/* <Grid container spacing={2} sx={{mt:'10px',m:'6px'}}> */}
        <Box sx={{gap:'20px',display:'flex',flexDirection:'row',mt:'10px',m:'6px'}}>
      <Grid item xs={12} md={4}>
      <Typography sx={{color:'#bababa',mb:'14px'}}>Do You have  Work Experience?
      {path && <span style={{ color: 'red' }}> *</span>}
      </Typography>
      <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>
      {formValues.workExperience||'-'}
        </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
      <Typography sx={{color:'#bababa',mb:'14px'}}>Total Years in Experience
      {path && <span style={{ color: 'red' }}> *</span>}
      </Typography>
      <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>{formValues.totYearsOfExp||'-'}</Typography>
          </Grid>
          </Box>
        {/* </Grid> */}
        <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        {formValues?.experience?.map((edu: any, index: number) => (
      <Box key={index}>
      </Box>
        ))}
          </Grid>          
        </Grid>
       
         {formValues?.experience?.map((edu: any, index: number) => (
        <Box key={index} sx={{m:'14px'}}>
          <Typography sx={{mb:'18px',fontWeight:'bold'}}>{edu?.label}</Typography>
          <Box sx={{gap:'20px',display:'flex',flexDirection:'row'}}>
         <Grid item xs={12} md={3}>
         <Typography sx={{mb:'14px',color:'#bababa'}}>Job Title
         {path && <span style={{ color: 'red' }}> *</span>}
         </Typography>
         <Tooltip title={edu?.jobTitle} disableHoverListener={edu?.jobTitle?.length <= 23}>
         <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>
          {truncateText(edu?.jobTitle)||'-'}
          </Typography>
          </Tooltip>
         </Grid>
         <Grid item xs={12} md={3}>
         <Typography sx={{mb:'14px',color:'#bababa'}}>Company
         {path && <span style={{ color: 'red' }}> *</span>}
         </Typography>
         <Tooltip title={edu?.company} disableHoverListener={edu?.company?.length <= 23}>
         <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>
          {truncateText(edu.company)||'-'}
          </Typography>
          </Tooltip>
         </Grid>
         </Box>
         <Box sx={{gap:'20px',display:'flex',flexDirection:'row'}}>
         <Grid item xs={12} md={3}>
         <Typography sx={{mb:'14px',color:'#bababa'}}>From
         {path && <span style={{ color: 'red' }}> *</span>}
         </Typography>
         <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>{edu.from||'-'}</Typography>
         </Grid>
         <Grid item xs={12} md={3}>
         <Typography sx={{mb:'14px',color:'#bababa'}}>To
         {path && <span style={{ color: 'red' }}> *</span>}
         </Typography>
         <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'180px', p:'0 10px', borderRadius:'8px'}}>{edu.to||'-'}</Typography>
         </Grid>
        </Box>
        </Box>
        ))}
        </Box>
          <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Typography sx={{fontSize:'14px', mb:'17px',m:'14px',backgroundColor:'#1677FF1A',color:'#1677FF',width:'159px',p:'3px',borderRadius:'3px', textAlign:'center'}}>Education</Typography>
        {/* {formValues?.education?.map((edu: any, index: number) => {
           console.log(edu,'eduhu');
           
          return(
      <Box key={index}>
      </Box>
          )
})} */}
          </Grid>          
        </Grid>
        {formValues?.educations && formValues?.educations?.length > 0 ? (
 
         formValues?.educations?.map((edu: any, index: number) => (
        <Box sx={{gap:'20px',m:'14px'}}>
          <Typography sx={{mb:'18px',fontWeight:'bold'}}>{edu?.label}</Typography>
        <Box sx={{gap:'20px',display:'flex',flexDirection:'row'}}>
         <Grid item xs={12} md={3}>
         <Typography sx={{mb:'14px',color:'#bababa'}}>University
         {path && <span style={{ color: 'red' }}> *</span>}
         </Typography>
         <Tooltip title={edu?.university} disableHoverListener={edu?.university?.length <= 23}>
         <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'254px', p:'0 10px', borderRadius:'8px'}}>
          {truncateText(edu.university)||'-'}
          </Typography>
          </Tooltip>
         </Grid>
         <Grid item xs={12} md={3}>
         <Typography sx={{mb:'14px',color:'#bababa'}}>Degree
         {path && <span style={{ color: 'red' }}> *</span>}
         </Typography>
         <Tooltip title={edu?.degree} disableHoverListener={edu?.degree?.length <= 23}>
         <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'254px', p:'0 10px', borderRadius:'8px'}}>
          {truncateText(edu.degree)||'-'}
          </Typography>
          </Tooltip>
         </Grid>
         <Grid item xs={12} md={3}>
         <Typography sx={{mb:'14px',color:'#bababa'}}>Field of Study
         {path && <span style={{ color: 'red' }}> *</span>}
         </Typography>
         <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'254px', p:'0 10px', borderRadius:'8px'}}>{edu.fieldOfStudy||'-'}</Typography>
         </Grid>
        </Box>
        <Box sx={{gap:'20px',display:'flex',flexDirection:'row'}}>
         <Grid item xs={12} md={3}>
         <Typography sx={{mb:'14px',color:'#bababa'}}>From
         {path && <span style={{ color: 'red' }}> *</span>}
         </Typography>
         <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'254px', p:'0 10px', borderRadius:'8px'}}>{edu.from||'-'}</Typography>
         </Grid>
         <Grid item xs={12} md={3}>
         <Typography sx={{mb:'14px',color:'#bababa'}}>To
         {path && <span style={{ color: 'red' }}> *</span>}
         </Typography>
         <Typography sx={{display:'flex',alignItems:'center', height:'52px', fontWeight:'500', bgcolor:'#fafafa', width:'254px', p:'0 10px', borderRadius:'8px'}}>{edu.to||'-'}</Typography>
         </Grid>
        </Box>
        </Box>
        ))
      ):(
        <Typography sx={{ color: '#bababa' }}>No education data available.</Typography>
      )}
 
      </Box>
      {/* </Paper>  */}
    </Box>
  );
};
 
export default Review;
 
