import axios from 'axios';
import { BaseUrl, FILE_API_URL, RTH_API_URL } from '../constants/Crudurl';


const authwithoutToken=axios.create();
//GET Business 
export const getRthBusinessInputData = async () => {

  try {
    const response = await axios.get(
      `${RTH_API_URL}/getBusinessList`,
    );
    console.log("raasaaa",response.data)
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);
    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

// GET Band
export const getRthBandData = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(
      `${RTH_API_URL}/getGrade`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);

    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

// GET Department
export const getRthDepartmentData = async () => {
  const token = localStorage.getItem('token');
  // console.log('token', token);

  try {
    const response = await axios.get(
      `${RTH_API_URL}/getDepartmentList`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);

    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

// GET WorkMode
export const getRthWorkModeData = async () => {
  const token = localStorage.getItem('token');
  //console.log('token', token);

  try {
    const response = await axios.get(
      `${RTH_API_URL}/getWorkModeList`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);

    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

// GET Primary Skill

export const getRthPrimarySkillsData = async (search: string = '', page: number = 0, size: number = 20) => {

  try {
    const response = await axios.get(
      `${RTH_API_URL}/getSkillList?search=${search}&page=${page}&size=${size}`,
    );
    // console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);

    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

// GET Secondary Skill

export const getRthSecondarySkillsData = async (search: string = '', page: number = 0, size: number = 20) => {

  try {
    const response = await authwithoutToken.get(
      `${RTH_API_URL}/getSkillList?search=${search}&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: "",
        },
      }
    );
    // console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);
    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

// GET Position Reports to

export const getRthPositionReportsTo = async (search: string = '', page: number = 0, size: number = 10) => {
  try {
    const response = await axios.get(
      `${RTH_API_URL}/getPositionReportsTo?search=${search}&page=${page}&size=${size}`
    );
    return response.data;
  } catch (error: any) {
    console.error('getRthPositionReportsTo Error', error);
    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

// GET  l1 approver

export const getRthL1ApproverData = async (search: string = '', page: number = 0, size: number = 10) => {
  try {
    const response = await axios.get(
      `${RTH_API_URL}/getL1Approvers?search=${search}&page=${page}&size=${size}`
    );
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);
    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

// GET  l2 approver

export const getRthL2ApproverData = async (search: string = '', page: number = 0, size: number = 10) => {
  try {
    const response = await axios.get(
      `${RTH_API_URL}/getL2Approvers?search=${search}&page=${page}&size=${size}`
    );
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);
    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

// GET  Priority

export const getRthPriorityData = async () => {
  const token = localStorage.getItem('token');
  //console.log('token', token);

  try {
    const response = await axios.get(
      `${RTH_API_URL}/getPriorityList`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);

    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

// GET  Qualification

export const getRthQualificationData = async () => {
  const token = localStorage.getItem('token');
  // console.log('token', token);

  try {
    const response = await axios.get(
      `${RTH_API_URL}/getQualificationList`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);

    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

// GET Location

export const getRthLocationData = async () => {
  const token = localStorage.getItem('token');
  // console.log('token', token);

  try {
    const response = await axios.get(
      `${RTH_API_URL}/getLocationList`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log('vidamuyarchi', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);

    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

// GET Reason of Hire

export const getRthReasonForHire = async () => {
  const token = localStorage.getItem('token');
  // console.log('token', token);

  try {
    const response = await axios.get(
      `${RTH_API_URL}/getReasonOfHireList`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);

    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

// GET Role
export const getRoleList = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(
      `${RTH_API_URL}/getActiveRole`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

//Get Position Title
export const getPositionTitle = async (search?: string, page: number = 0, size: number = 10) => {
  try {
    const response = await axios.get(`${RTH_API_URL}/getPositionTitle`, {
      params: { search, page, size }
    });
    return response.data.data;
  } catch (error: any) {
    console.error('getRthData', error);
    return error.response?.data || { message: 'An unknown error occurred' };
  }
}

// GET Designation

export const getDesignationList = async () => {
  const token = localStorage.getItem('token');
  // console.log('token', token);

  try {
    const response = await axios.get(
      `${RTH_API_URL}/getDesignationList`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);
    return error.response?.data || { message: 'An unknown error occurred' };
  }
};


// GET Designation

export const getOrganization = async () => {
  const token = localStorage.getItem('token');
  // console.log('token', token);

  try {
    const response = await axios.get(
      `${RTH_API_URL}/getOrganization`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);
    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

// GET getSupervisors

export const getSupervisors = async () => {
  const token = localStorage.getItem('token');
  // console.log('token', token);

  try {
    const response = await axios.get(
      `${RTH_API_URL}/getSupervisors`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);
    return error.response?.data || { message: 'An unknown error occurred' };
  }
};


// GET getRecruiterLead

export const getRecruiterLead = async (search?: string, page: number = 0, size: number = 100) => {
  try {
    const response = await axios.get(`${RTH_API_URL}/getRecruiterLead`, {
      params: {
        search, // Query parameter for search
        page,   // Query parameter for pagination page
        size,   // Query parameter for pagination size
      },
    });
    // console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getRecruiterLead', error);
    return error.response?.data || { message: 'An unknown error occurred' };
  }
};




// GET getRecruiter

export const getRecruiter = async (search?: string, page: number = 0 , size: number = 100) => {
  try {
    const response = await axios.get(
      `${RTH_API_URL}/getRecruiter`,
      {
        params:{
          search,
          page,
          size
        }
      }
    );
    // console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);
    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

export const editRecruiter = async (rthId: "string", data: any) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.put(`${FILE_API_URL}/editRecruiter/${rthId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  }
  catch (error: any) {
    return error.response?.data || { message: 'An unknown error occurred' };
  }

}

export const deleteRecruiter=async(mrfId:any)=>{
  try{
    const response=await axios.delete(`${FILE_API_URL}/deleteRecruiter`,{
      data:{mrfId}
    }
    )
    return response.data;
  }
  catch(error:any){
    return error.response?.data || { message: 'An unknown error occurred' };
  }
}

export const getMrfByRthId = async (rthId: any) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${BaseUrl}/mrf/getMrfByRthId/${rthId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    console.log("hey man",response);
    return response.data.data;
  }
  catch (error: any) {
    return error.response?.data || { message: 'An unknown error occurred' };
  }
}

// Get Approvers
export const getApprovers = async (search: string = '', page: number = 0, size: number = 10) => {
  try {
    const response = await axios.get(
      `${RTH_API_URL}/getPositionReportsTo?search=${search}&page=${page}&size=${size}`
    );
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: 'An unknown error occurred' };
  }
};


export const getRthById = async (id?:string) => {
  console.log("idea",id);
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(
      `${BaseUrl}/rth/getRthById/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

// Job offer approval

export const getJobOfferApproval = async (userId?: string, search?: string,) => {
  const requestBody = {
    l1Pending: false,
    l1Rejected: false,
    l2Pending: false,
    l2Rejected: false,
    L3Pending: false,
    L3Rejected: false,
    bothLevelsApproved: false,
    page: 0,
    size: 100,
  };
  return await axios.post(
    `${BaseUrl}/offerApproval/getList/${userId}${search ?`?search=${search}`:''}`,
    requestBody
  );
};

export const getCandidateApproval = async (candidateId?: string) => {
  return await axios.get(
    `${BaseUrl}/offerApproval/candidateDetailPage/${candidateId}`,
    {
      headers: {
        Authorization: "",
      },
    }
  )
}

export const getPostApprovals = async (candidateId: string, l1Approver: string, l2Approver: string, l3Approver?: string) => {
  try {
    let url = `${BaseUrl}/offerApproval/assignApprovers/${candidateId}?l1Approver=${l1Approver}&l2Approver=${l2Approver}`;
    if (l3Approver) {
      url += `&l3Approver=${l3Approver}`;
    }
    const response = await axios.post(url);
    return response.data;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

// L1 Approver
export const getL1Approval = async (approverId?: string, search?: string, page: number = 0, size: number = 100) => {
  return await axios.get(
    `${BaseUrl}/offerApproval/getL1Pending/${approverId}?search=${search}&page=${page}&size=${size}`,
  )}

// L2 Approver
export const getL2Approval = async (approverId?: string, search?: string, page: number = 0, size: number = 100) => {
  return await axios.get(
    `${BaseUrl}/offerApproval/getL2Pending/${approverId}?search=${search}&page=${page}&size=${size}`,
  )}

// L3 Approver
export const getL3Approval = async (approverId?: string, search?: string, page: number = 0, size: number = 100) => {
  return await axios.get(
    `${BaseUrl}/offerApproval/getL3Pending/${approverId}?search=${search}&page=${page}&size=${size}`,
  )}

// Approve and Decline
export const getApproveAndDecline = async (data: { candidateId: string; level?: string; status: "Approved" | "Rejected"; comments: string }) => {
  return await axios.put(`${BaseUrl}/offerApproval/setStatus`, data,
    {
      headers: {
        Authorization: " ",
      },
    }
  );
};

// View approve edit
export const editApproveview = async (candidateId: string, requestBody: any) => {
  return await axios.put(`${BaseUrl}/candidate/editCandidate/${candidateId}`, requestBody)
}

//offerApprovalViewStatus
export const getOfferApprovalStatus = async (candidateId: string, status: string) => {
  return await axios.post(`${BaseUrl}/offerApproval/setInterviewStatus/${candidateId}/${status}`);
}

//UnreadNotification
export const getUnreadNotification = async (userId?: string, page: number = 0, size: number = 10) => {
  return await axios.get(`${BaseUrl}/notification/getUnRead/${userId}?page=${page}&size=${size}`);
};

//readNotification
export const getReadNotification = async (userId?: string, page: number = 0, size: number = 10) => {
  return await axios.get(`${BaseUrl}/notification/getRead/${userId}?page=${page}&size=${size}`);
}

//getNotification
export const getNotification = async (notificationId?: string) => {
  return await axios.get(`${BaseUrl}/notification/getNotification/${notificationId}`);
}

//markAllAsReadNotification
export const markAllAsReadNotification = async (userId?: string) => {
  return await axios.put(`${BaseUrl}/notification/setStatusAllRead/${userId}`);
}














