import { Box, Button, InputAdornment, InputBase } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
// import { toast } from "react-toastify";
import { exportReportApiFunction } from "../../Crud/report";

interface prop {
  setSearchQuery: Function;
  searchQuery: string;
  exportHeaderTitle: string[];
  exportRows: { [key: string]: string | number | null | undefined }[];
  selectedTab: string;
}

export interface ExportData {
  fileName: string;
  columns: string[];
  rows: { [key: string]: any }[];
}

const SearchAndExport: React.FC<prop> = ({
  setSearchQuery,
  searchQuery,
  exportHeaderTitle,
  exportRows,
  selectedTab,
}) => {
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const [apiExport, setApiExport] = useState<ExportData>({
    fileName: "sample excel",
    columns: [],
    rows: [],
  });

  const userString = localStorage.getItem("user");
  const userData = userString ? JSON.parse(userString) : null;
  const roleName = userData?.roleName || null;

  useEffect(() => {
    let keyMap: { [key: string]: string };

    if (selectedTab === "MRF Approval Status") {
      keyMap = {
        id: "S no",
        seqId: "MRF ID",
        positionTitle: "MRF Title",
        typeOfRth: "MRF Category",
        modeOfRth: "MRF Subcategory",
        status: "MRF Status",
        l1Approver: "L1 Approver",
        l2Approver: "L2 Approver",
      };
    } else if (
      selectedTab === "MRF Stage Report" &&
      (roleName === "TEAMHEAD" || roleName === "APPROVERHEAD")
    ) {
      keyMap = {
        s_no: "S No",
        mrfId: "MRF ID",
        mrfTitle: "MRF Title",
        mrfCategory: "MRF Category",
        mrfSubcategory: "MRF Subcategory",
        mrfStatus: "MRF Status",
        l1Approver: "L1 Approver",
        l2Approver: "L2 Approver",
        mrfStage: "MRF Stage",
        mrfCreationDate: "MRF Creation Date",
        ageingInDays: "Ageing (In days)",
        taHead: "TA Head",
        recruiterLead: "Recruiter Lead",
      };
    } else if (
      selectedTab === "MRF Stage Report" &&
      (roleName === "TALENTACQUISITIONHEAD" ||
        roleName === "RECRUITERLEAD" ||
        roleName === "RECRUITER")      
    ) {
      keyMap = {
        sNo: "S no",
        mrfId: "MRF ID",
        mrfTitle: "MRF Title",
        mrfCategory: "MRF Category",
        mrfSubcategory: "MRF Subcategory",
        mrfStage: "MRF Stage",
        mrfCreationDate: "MRF creation Date",
        ageingInDays: "Ageing(in days)",
        recruiterLead: "Recruiter Lead",
        recruiter: "Recruiters",
      };
    } else if (selectedTab === "Approved MRF Report") {
      keyMap = {
        sNo: "S no",
        mrfId: "MRF ID",
        mrfTitle: "MRF Title",
        mrfCategory: "MRF Category",
        mrfSubcategory: "MRF Subcategory",
        department: "Department",
        createdBy: "Created by",
        mrfCreationDate: "MRF creation Date",
        position: "Position",
        recruiterLead: "Recruiter Lead",
        recruiter: "Recruiter",
        candidate: "Candidate",
        mrfStage: "MRF Stage",
        interviewStage: "Interview Stage",
        interviewStatus: "Interview Status",
        l1InterviewerName: "L1 Interviewer Name",
        l2InterviewerName: "L2 Interviewer Name",
        ageingHiringCycleInDays: "Ageing / Hiring Cycle(in days)",
        closedDate: "Closed date",
      };
    } else if (selectedTab === "Overall MRF Report") {
      keyMap = {
        sNo: "S no",
        mrfId: "MRF ID",
        mrfTitle: "MRF Title",
        mrfCategory: "MRF Category",
        mrfSubcategory: "MRF Subcategory",
        mrfCreationDate: "MRF creation Date",
        position: "Position",
        recruiterLead: "Recruiter Lead",
        recruiter: "Recruiter",
        candidate: "Candidate",
        mrfStage: "MRF Stage",
        interviewStage: "Interview Stage",
        interviewStatus: "Interview Status",
        l1InterviewerName: "L1 Interviewer Name",
        l2InterviewerName: "L2 Interviewer Name",
        ageingHiringCycleInDays: "Ageing / Hiring Cycle(in days)",
        closedDate: "Closed date",
      };
    } else if (selectedTab === "Source Report") {
      keyMap = {
        sNo: "S. No",
        mrfId: "MRF ID",
        mrfTitle: "MRF Title",
        mrfCategory: "MRF Category",
        mrfSubcategory: "MRF Subcategory",
        resumeId: "Resume ID",
        resumeName: "Resume Name",
        recruiter: "Recruiter",
        source: "Source",
      };
    } else if (selectedTab === "Department wise MRF") {
      keyMap = {
        sNo: "S no",
        mrfId: "MRF ID",
        mrfTitle: "MRF Title",
        mrfCategory: "MRF Category",
        mrfSubcategory: "MRF Subcategory",
        mrfStage: "MRF Stage",
        departmant: "Department",
        mrfCreationDate: "MRF creation Date",
        ageingInDays: "Ageing(in days)",
        recruiterLead: "Recruiter Lead",
        recruiter: "Recruiters",
      };
    } else if (selectedTab === "Client wise MRF") {
      keyMap = {
        sNo: "S no",
        mrfId: "MRF ID",
        mrfTitle: "MRF Title",
        mrfCategory: "MRF Category",
        mrfSubcategory: "MRF Subcategory",
        mrfStage: "MRF Stage",
        clientName: "Client",
        mrfCreationDate: "MRF creation Date",
        ageingInDays: "Ageing(in days)",
        recruiterLead: "Recruiter Lead",
        recruiter: "Recruiters",
      };
    } else if (selectedTab === "Selection Rate") {
      keyMap = {
        sNo: "S. No",
        mrfId: "MRF ID",
        mrfTitle: "MRF Title",
        mrfCategory: "MRF Category",
        mrfSubcategory: "MRF Subcategory",
        position: "Position",
        resumesSubmitted: "Resumes Submitted",
        closedPosition: "Closed Position",
        selectionPercentage: "Selection %",
      };
    } else if (selectedTab === "Candidate Interview Status") {
      keyMap = {
        sNo: "S no",
        mrfId: "MRF ID",
        mrfTitle: "MRF Title",
        mrfCategory: "MRF Category",
        mrfSubtype: "MRF Subtype",
        position: "Position",
        screening: "Screening",
        l1Interview: "L1 Interview",
        l2Interview: "L2 Interview",
        hr: "HR Round",
        other: "Other Status",
      };
    } else if (selectedTab === "Overall report") {
      keyMap = {
        sNo: "S no",
        mrfId: "MRF ID",
        mrfTitle: "MRF Title",
        mrfCategory: "MRF Category",
        mrfSubcategory: "MRF Subcategory",
        mrfCreationDate: "MRF creation Date",
        position: "Position",
        recruiterLead: "Recruiter Lead",
        recruiter: "Recruiter",
        candidate: "Candidate",
        mrfStage: "MRF Stage",
        interviewStage: "Interview Stage",
        interviewStatus: "Interview Status",
        l1InterviewerName: "L1 Interviewer Name",
        l2InterviewerName: "L2 Interviewer Name",
        ageingHiringCycleInDays: "Ageing / Hiring Cycle(in days)",
        closedDate: "Closed date",
      };
    } else if (selectedTab === "MRF Category Report") {
      keyMap = {
        sNo: "S no",
        mrfId: "MRF ID",
        mrfTitle: "MRF Title",
        mrfCategory: "MRF Category",
        mrfSubcategory: "MRF Subtype",
        position: "Position",
        recruiter: "Recruiter",
        recruiterLead: "Recruiter Lead",
      };
    } else {
      keyMap = {};
    }

    const transformedRows = exportRows.map((row) => {
      const transformedRow: { [key: string]: any } = {};
      for (const [key, value] of Object.entries(row)) {
        const newKey = keyMap[key];
        if (newKey) {
          transformedRow[newKey] = value;
        }
      }
      return transformedRow;
    });

    setApiExport({
      fileName: apiExport.fileName,
      columns: exportHeaderTitle,
      rows: transformedRows,
    });
  }, [exportHeaderTitle, exportRows, selectedTab, apiExport.fileName,roleName]);

  const handleExportButton = () => {
    console.log(apiExport);
    exportReportApiFunction(apiExport, selectedTab);
  };

  console.log("Boss",apiExport);
  
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      flexDirection={"row"}
      alignItems={"center"}
      pl={1}
      pr={1}
    >
      <Box>
        <InputBase
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search MRF..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
            </InputAdornment>
          }
          sx={{
            border: "1px solid #ccc",
            borderRadius: 1,
            padding: 0,
            backgroundColor: "white",
            minHeight: "3px",
            fontSize: 14,
            ".MuiInputBase-input": {
              padding: "5px 5px",
            },
          }}
        />
      </Box>
      <Box>
        <Button variant="contained" size="small" onClick={handleExportButton}>
          Export
        </Button>
      </Box>
    </Box>
  );
};

export default SearchAndExport;
