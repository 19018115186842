import styled from '@emotion/styled';
import { Box, Button, Card, Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { editApproveview, getCandidateApproval } from '../../../Crud/rth';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const StyledLabel = styled(Typography)({
    fontSize: '0.625rem',
    fontWeight: '500',
    color: "#1414144D"
});

const StyledTextFields = styled(TextField)({
    width: '160px',
    height: '40px',
    '& .MuiInputBase-root': {
        height: '40px',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiInputBase-input': {
        fontSize: '0.75rem',
        color: '#000000',
    },
    '& .Mui-disabled .MuiInputBase-input': {
        color: '#000000',
        WebkitTextFillColor: '#000000',
    },
}).withComponent((props?: any) => <TextField {...props} InputProps={{ readOnly: true }} />);

const StyledTextField = styled(TextField)({
    width: '160px',
    height: '40px',
    '& .MuiInputBase-root': {
        height: '40px',
        backgroundColor: 'FFFFFF',
        borderRadius: '4px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #1677FF',
    },
    '& .MuiInputBase-input': {
        fontSize: '0.75rem',
        color: '#000000',
    },
    '& .Mui-disabled .MuiInputBase-input': {
        color: '#000000',
        WebkitTextFillColor: '#000000',
    },
});

const EditApproverPage: React.FC = () => {
    const location = useLocation();
    const candidateData = location.state?.candidateData;
    const navigate = useNavigate();
    const { candidateId } = useParams();
    const [loading, setLoading] = useState(false);
    console.log(loading);
    const [editedData, setEditedData] = useState<any>(candidateData);

    useEffect(() => {
        if (candidateId) {
            setLoading(true);
            getCandidateApproval(candidateId)
                .then(response => {
                    setEditedData(response?.data?.data);
                })
                .catch(error => {
                    console.error("Error fetching candidate details:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [candidateId]);

    const handleBackButtonClick = () => {
        navigate(-1);
    };

    const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditedData({
            ...editedData,
            [field]: event.target.value,
        });
    };

    const handleSave = () => {
        if (!candidateId) {
            console.error("Candidate ID is missing!");
            return;
        }
        const requestBody = { ...editedData };
        editApproveview(candidateId, requestBody)
            .then(response => {
                console.log("Candidate data saved successfully:", response);
                toast.success("Candidate date updated Successfully")
                navigate(-1);
            })
            .catch(error => {
                console.error("Error saving candidate data:", error);
            });
    };

    return (
        <Box sx={{ padding: 2, pt: 0, mb: 2 }}>
            <IconButton onClick={handleBackButtonClick}>
                <KeyboardBackspaceIcon />
            </IconButton>
            <Card variant="outlined" sx={{ padding: 2, maxWidth: 1200, margin: '0 auto' }}>
                <Typography fontSize="0.875rem" fontWeight="600" marginBottom={2}>
                    Candidate Details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={2} md={2}>
                        <StyledLabel>Candidate Name</StyledLabel>
                        <StyledTextField value={editedData?.firstName} onChange={handleChange('firstName')} />
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <StyledLabel>MRF ID</StyledLabel>
                        <StyledTextFields value={editedData?.mrfId || "_"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>MRF Name</StyledLabel>
                        <StyledTextFields value={editedData?.mrfName || "_"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Education</StyledLabel>
                        <StyledTextFields value={editedData?.education} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Work Location</StyledLabel>
                        <StyledTextField value={editedData?.workLocation} onChange={handleChange('workLocation')} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Work Mode</StyledLabel>
                        <StyledTextFields value={editedData?.workMode || "_"} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Total No. of experience</StyledLabel>
                        <StyledTextField value={editedData?.totYearsOfExp} onChange={handleChange('totYearsOfExp')} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Notice Period (in days)</StyledLabel>
                        <StyledTextField value={editedData?.noticePeriods} onChange={handleChange('noticePeriods')} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Date of Joining</StyledLabel>
                        <StyledTextField type="date" value={editedData?.dateOfJoining} onChange={handleChange('dateOfJoining')} inputProps={{  min: new Date().toISOString().split("T")[0], pattern: "\\d{4}-\\d{2}-\\d{2}" }} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Current CTC</StyledLabel>
                        <StyledTextField type="number" value={editedData?.currentCtc} onChange={handleChange('currentCtc')} inputProps={{ min: 0 }} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Expected CTC</StyledLabel>
                        <StyledTextField type="number" value={editedData?.expectedCtc} onChange={handleChange('expectedCtc')} inputProps={{ min: 0 }} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Business</StyledLabel>
                        <StyledTextField value={editedData?.business} onChange={handleChange('business')} />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabel>Payroll</StyledLabel>
                        <StyledTextField value={editedData?.payRoll} onChange={handleChange('payRoll')} />
                    </Grid>
                </Grid>
                <Divider sx={{ marginY: 4 }} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button variant="contained" onClick={handleSave} sx={{ backgroundColor: "#1677FF", fontSize: "0.625rem", fontWeight: "700" }}>
                        Submit
                    </Button>
                </Box>
            </Card>
        </Box>
    );
};

export default EditApproverPage;
