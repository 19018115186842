import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material'
import React from 'react'
import CommonTextField from '../../../../common/CommonTextField'
import CommonSelect from '../../../../common/CommomSelect';
import { gender, knownOptions } from '../../../../../constants/dummyConst';
import { toast } from 'react-toastify';

export const PersonalInformation = ({formValues,setFormValues,errors,setErrors,isAccess}:any) => {
  

  const validateField = (name: string, value: string) => {
    const maxLength = 10;
    // const maxAadharLength=12;
    const maxNoticePeriodLength=3;
    let error = '';
    
    if (name === 'currentCtc' || name === 'expectedCtc') {
      // Check if the value is a valid number
      if (!/^\d*$/.test(value)) {
        error = 'Only numbers are allowed';
      } else if (value.length > maxLength) {
        error = `Maximum of ${maxLength} digits allowed`;
      }
    }
    else if (name === 'noticePeriods') {
      // Check if the notice period is a valid number and within the max length
      if (!/^\d*$/.test(value)) {
        error = 'Only numbers are allowed';
      } else if (value.length > maxNoticePeriodLength) {
        error = `Maximum of ${maxNoticePeriodLength} digits allowed`;
      }
    }
  

    else if(name==='uanNumber'){
      if (!/^\d*$/.test(value)) {
        error='UAN Number should contain only numbers'
      }
      if(value.length>13){
        error='UAN Number should be 12 digits'
      }
    }

    else if(name==='aadharNumber'){

      if(value.length>13){
        error='Aadhar Number should be 12 digits'
      }
    }

    return error;
  };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {

      if (!isAccess) {
        toast.warn("You don't have access");
        return;
      }
      
        const { name, value } = event.target;
        const error = validateField(name, value);

        // Set errors if any
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          [name]: error
        }));

     if (!error) {
      setFormValues({
        ...formValues,
        [name]: value
      });
    }
      };
  return (
    <><Typography sx={{ fontSize: '14px', mb: '17px', m: '14px', backgroundColor: '#1677FF1A', color: '#1677FF', width: '159px', p: '3px', borderRadius: '3px', textAlign: 'center', fontWeight: '500' }}>Personal Information</Typography>
    {/* <Grid container spacing={2} sx={{ m: '10px' }}> */}
      <Box sx={{display:'flex',flexDirection:'row',m:'14px',mt:'40px',justifyContent:'space-between'}}>
          <Grid item xs={2}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>How did you heard about us?<span style={{ color: 'red' }}>  *</span></Typography>
                <Box sx={{width:'13vw'}}>
                <CommonSelect
                labelId=""
                label=""
                name="howDidYouHeardAbtUs"
                value={formValues.howDidYouHeardAbtUs || ''}
                onChange={handleInputChange}
                error={!!errors.howDidYouHeardAbtUs}
                helperText={errors.howDidYouHeardAbtUs}
                options={knownOptions} // Your options array
              />
              </Box>
          </Grid>
          <Grid item xs={2} >
            <Box>
              <Typography sx={{ color: '#c2c2c2', mb: '8px',fontSize:'11px', textWrap:'nowrap' }}>Have you previously been employed by HEPL?<span style={{ color: 'red' }}>  *</span></Typography>
              </Box>
              <Box sx={{width:'14vw'}}>
              <CommonTextField
                  label=""
                  name="preEmployed"
                  value={formValues.preEmployed ? 'Yes' : 'No'} 
                  onChange={handleInputChange}
                  error={!!errors.preEmployed}
                  helperText={errors.preEmployed}
                />
                </Box>
          </Grid>
          <Grid item xs={2}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>FirstName<span style={{ color: 'red' }}> *</span></Typography>
              <CommonTextField
                  label=""
                  name="firstName"
                  value={formValues.firstName === 'Not Identified' ? '' : formValues.firstName || ''}
                  onChange={handleInputChange}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                />
          </Grid>
          <Grid item xs={2}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>LastName<span style={{ color: 'red' }}>  *</span></Typography>
              <CommonTextField
                  label=""
                  name="lastName"
                  value={formValues.lastName === 'Not Identified' ? '' : formValues.lastName || ''}
                  onChange={handleInputChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
          </Grid>
          </Box>
          <Box sx={{display:'flex',flexDirection:'row',m:'14px',mt:'20px',justifyContent:'space-between'}}>
          <Grid item xs={2}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>Email Address<span style={{ color: 'red' }}>  *</span></Typography>
              <Box sx={{width:'13vw'}}>
              <CommonTextField
                  label=""
                  name="emailAddress"
                  value={formValues.emailAddress === 'Not Identified' ? '' : formValues.emailAddress || ''}
                  onChange={handleInputChange}
                  error={!!errors.emailAddress}
                  helperText={errors.emailAddress}
                />
                </Box>
          </Grid>
          <Grid item xs={2}>
          <Box sx={{width:'14vw',ml:'10px'}}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px' }}>Phone Number<span style={{ color: 'red' }}>  *</span></Typography>
              
              <CommonTextField
                  label=""
                  name="phoneNumber"
                  value={formValues.phoneNumber === 'Not Identified' ? '' : formValues.phoneNumber || ''}
                  onChange={handleInputChange}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber}
                />
                </Box>
          </Grid>
          <Grid item xs={3}>
          <Box sx={{width:'14vw',ml:'50px'}}>
          <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>Gender<span style={{ color: 'red' }}> *</span></Typography>
         
          <CommonSelect
                  labelId="gender"
                  label=""
                  name="gender"
                  value={formValues.gender || ''}
                  onChange={handleInputChange}
                  error={!!errors.gender}
                  helperText={errors.gender}
                  options={gender}
                />
             </Box>   
           </Grid>
           <Box sx={{ width: '14vw' }}>
                <Typography sx={{ fontSize: '12px', color: '#c2c2c2', mb: '6px' }}>Date Of Birth</Typography>
                <CommonTextField
                  label=""
                  type="date"
                  name="dateOfBirth"
                  value={formValues.dateOfBirth}
                  onChange={handleInputChange}
                  error={!!errors.dateOfBirth}
                  helperText={errors.dateOfBirth}
                />
              </Box>
        
         
          </Box>
          <Box sx={{display:'flex',flexDirection:'row',gap:'40px',m:'14px',mt:'20px'}}>
          <Grid item xs={2}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>Current CTC<span style={{ color: 'red' }}> *</span></Typography>
              <CommonTextField
                  label=""
                  name="currentCtc"
                  value={formValues.currentCtc||''}
                  onChange={handleInputChange}
                  error={!!errors.currentCtc}
                  helperText={errors.currentCtc}
                />
          </Grid>
          <Grid item xs={2} sx={{ml:'10px',width:'14vw'}}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>Expected CTC<span style={{ color: 'red' }}>  *</span></Typography>
              <CommonTextField
                  label=""
                  name="expectedCtc"
                  value={formValues.expectedCtc||''}
                  onChange={handleInputChange}
                  error={!!errors.expectedCtc}
                  helperText={errors.expectedCtc}
                />
          </Grid>
         <Grid item xs={2} sx={{ml:'50px'}}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>Notice Period(in days)<span style={{ color: 'red' }}>  *</span></Typography>
              <CommonTextField
                  label=""
                  name="noticePeriods"
                  value={formValues.noticePeriods||''}
                  onChange={handleInputChange}
                  error={!!errors.noticePeriods}
                  helperText={errors.noticePeriods}
                />
          </Grid>
          <Grid item xs={2} sx={{ml:'50px'}}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>UAN Number</Typography>
              <CommonTextField
                  label=""
                  name="uanNumber"
                  value={formValues.uanNumber||''}
                  onChange={handleInputChange}
                  error={!!errors.uanNumber}
                  helperText={errors.uanNumber}
                />
          </Grid>
          </Box>
          <Box sx={{display:'flex',flexDirection:'row',gap:'40px',m:'14px',mt:'20px'}}>
          <Grid item xs={2}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>Aadhar Number </Typography>
              <CommonTextField
                  label=""
                  name="aadharNumber"
                  value={formValues.aadharNumber||''}
                  onChange={handleInputChange}
                  error={!!errors.aadharNumber}
                  helperText={errors.aadharNumber}
                />
          </Grid>
          </Box>
      </> 
  )
}
