import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Typography, Button, Alert, CircularProgress } from "@mui/material";
import { useDispatch } from 'react-redux';
import { getRthBusinessInputData, submitRthForm } from '../../../../redux/features/rthSlice';
import CommonSelect from "../../../common/CommomSelect";
import { SelectChangeEvent } from "@mui/material";
import CommonTextField from "../../../common/CommonTextField";
import type { AppDispatch } from '../../../../redux/store';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';
import { uploadFile } from "../../../../Crud/choosefile";
import mammoth from 'mammoth';
import { getDocument } from 'pdfjs-dist';
// import * as PDFJS from 'pdfjs-dist';
import {
  getRthBandData,
  getRthDepartmentData,
  getRthLocationData,
  getRthL1ApproverData,
  getRthL2ApproverData,
  getRthPositionReportsTo,
  getRthPrimarySkillsData,
  // getRthPriorityData, 
  getRthQualificationData,
  getRthSecondarySkillsData,
} from "../../../../Crud/rth";
import { getRthL1L2GetAPIData } from "../../../../Crud/newRth";
import { useNavigate } from "react-router-dom";
import SearchableDropdown from "../../../CustomComponents/SearchableDropdown";
import { GetState, GetCity, GetCountries } from 'react-country-state-city';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,        // Extra-small: <600px
      sm: 600,      // Small: 600px to 960px
      md: 960,      // Medium: 960px to 1280px
      lg: 1380,     // Large: 1280px to 1920px
      xl: 1920,     // Extra-large: >1920px
    },
  },
});

interface SkillOption {
  value: string;
  label: string;
}

export const useAppDispatch: () => AppDispatch = useDispatch;

interface FormData {
  userId: string;
  contractDur: string;
  contractType: string;
  businessId: string;
  positionTitle: string;
  l1ApprovalId: string;
  l2ApprovalId: string;
  l2ApprovalLabel?: string;
  l1ApprovalLabel?: string;
  departmentId: string;
  band: string;
  qualNeeded: string;
  minExperience: string;
  maxExperience: string;
  primarySkills?: any;
  secondarySkills?: any;
  workMode: string;
  priority: string;
  numOfPosition: string;
  posReportsToId: string;
  reasonForHire: string;
  state: string;
  location: string;
  ctc: string;
  prefStartDate: string;
  comments: string;
  typeOfRth: string,
  modeOfRth: string,
  jdAttachmentFileUrl: string;
  otherFileUrl: String;
  posReportsToLabel?:string;
  jdAttachmentText: string;
}

const arr: any = [];
const secondarySkills: string[] = [];
const ContractTab: React.FC = () => {

  const [chooseFile, setChooseFile] = useState()
  const [chooseFile1, setChooseFile1] = useState()
  const [LocationData, setLocationData] = useState([])
  const [posReportsTo, setposReportsTo] = useState([])
  console.log("posReportsTo",posReportsTo)
  console.log("LocationData",LocationData)
  const [QualificationData, setQualificationData] = useState([])
  console.log("QualificationData",QualificationData)
  // const [PriorityData,setPriorityData] = useState([])
  const [L2ApproverData, setL2ApproverData] = useState([])
  const [L1ApproverData, setL1ApproverData] = useState([])
  console.log("L2ApproverData",L2ApproverData)
  console.log("L1ApproverData",L1ApproverData)

  const [secondarySkillsData, setSecondarySkillsData] = useState([])
  const [primarySkillsData, setPrimarySkillsData] = useState([])
  const [departmentData, setDepartmentData] = useState([])
  const [bandData, setBandData] = useState([])
  const [businessData, setBusinessData] = useState([])
  const [pSkills, setPSkills] = useState<any>([]);
  const [sSkills, setSSkills] = useState<any>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [submitted, setSubmitted] = useState(false);
  const[cityOption,setCityOption]=useState<any[]>([]);
  const[countryOption,setCountryOption]=useState<any[]>([]);
  console.log("countryOption",countryOption)
  const[stateOption,setStateOption]=useState<any[]>([]);
  const[selectedState,setSelectedState]=useState<any>([]);
  const[selectedCountry,setSelectedCountry]=useState<any>([]);
  const[selectedCity,setSelectedCity]=useState<any>([]);
  const [primarySearch, setPrimarySearch] = useState("");
  const [primaryPage, setPrimaryPage] = useState(0);
  const [loadingPrimary, setLoadingPrimary] = useState(false);
  const [secondarySearch, setSecondarySearch] = useState("");
  const [secondaryPage, setSecondaryPage] = useState(0);
  const [loadingSecondary, setLoadingSecondary] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [fileContent, setFileContent] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const PAGE_SIZE = 20;
  // const selectedCountry = 'India';
  const navigate = useNavigate();
  const token: any = localStorage.getItem("token")
  const decoded: any = jwtDecode(token);
  const userId = decoded.userId;

  

  const [formData, setFormData] = useState<FormData>({
    userId: userId || "",
    contractDur: "",
    contractType: "",
    businessId: "",
    positionTitle: "",
    l1ApprovalId: "",
    l2ApprovalId: "",
    departmentId: "",
    band: "",
    qualNeeded: "",
    minExperience: "",
    maxExperience: "",
    primarySkills: "",
    secondarySkills: "",
    workMode: "",
    priority: "",
    numOfPosition: "",
    posReportsToId: "",
    reasonForHire: "",
    location: "",
    state:"",
    ctc: "",
    prefStartDate: "",
    comments: "",
    typeOfRth: "Internal",
    modeOfRth: "Contract",
    jdAttachmentFileUrl: "",
    otherFileUrl: "",
    l1ApprovalLabel: "",
    l2ApprovalLabel: "",
    posReportsToLabel:"",
    jdAttachmentText: "",
  });

  useEffect(() => {
    getRthBusinessInputData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.businessId, label: i.businessName } })
      setBusinessData(data)
    }).catch((err) => console.error(err));

    getRthBandData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.gradeName, label: i.gradeName } })
      setBandData(data)
    }).catch((err) => console.error(err));

    getRthDepartmentData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.departmentId, label: i.departmentName } })
      setDepartmentData(data)
    }).catch((err) => console.error(err));

    // getRthWorkModeData().then(res => {
    //   const data = res.data?.map((i: any) => { return { value: i.workId, label: i.workName } })
    //   setWorkModeData(data)
    //  // console.log('data', data)
    // }).catch((err) => console.error(err));

    getRthPrimarySkillsData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.skillName, label: i.skillName } })
      setPrimarySkillsData(data)
    }).catch((err) => console.error(err));

    getRthSecondarySkillsData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.skillName, label: i.skillName } })
      setSecondarySkillsData(data)
    }).catch((err) => console.error(err));

    getRthL1ApproverData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.userId, label: i.firstName } })
      setL1ApproverData(data)
    }).catch((err) => console.error(err));

    getRthL2ApproverData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.userId, label: i.firstName } })
      setL2ApproverData(data)
    }).catch((err) => console.error(err));

    // getRthPriorityData().then(res => {
    //   const data = res.data?.map((i: any) => { return { value: i.priorityId, label: i.priorityName } })
    //   setPriorityData(data)
    //   //console.log('data', data)
    // }).catch((err) => console.error(err));

    getRthQualificationData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.degree, label: i.degree } })
      setQualificationData(data)
    }).catch((err) => console.error(err));

    getRthPositionReportsTo().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.userId, label: i.firstName } })
      console.log(data);
      setposReportsTo(data)
    }).catch((err) => console.error(err));

    getRthLocationData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.locationName, label: i.locationName } })
      setLocationData(data)
    }).catch((err) => console.error(err));

    // getRthReasonForHire().then(res => {
    //   const data = res.data?.map((i: any) => { return { value: i.reasonOfHireId, label: i.reasonOfHireName } })
    //   setReasonForHire(data)
    //   //console.log('data', data)
    // }).catch((err) => console.error(err));
  }, []);


  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timer: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
 
  const fetchPrimarySkills = useCallback(
      async (search: string, page: number) => {
        if (loadingPrimary || (!hasMore && search !== "")) return;
  
        // setLoadingPrimary(true);
        try {
          const res = await getRthPrimarySkillsData(search, page, PAGE_SIZE);
          const newData = res.data?.map((i: any) => ({
            value: i.skillName,
            label: i.skillName,
          }));
  
          if (newData?.length < PAGE_SIZE) {
            setHasMore(false);
          }
  
          setPrimarySkillsData((prevData) =>
            page === 0 ? newData : [...prevData, ...newData],
          );
        } catch (error) {
          console.error('Error fetching primary skills:', error);
        } finally {
          setLoadingPrimary(false);
        }
      },
      [hasMore, loadingPrimary],
    );
  
    const fetchSecondarySkills = useCallback(
      async (search: string, page: number) => {
        if (loadingSecondary || (!hasMore && search !== "")) return;
  
        // setLoadingSecondary(true);
        try {
          const res = await getRthSecondarySkillsData(search, page, PAGE_SIZE);
          const newData = res.data?.map((i: any) => ({
            value: i.skillName,
            label: i.skillName,
          }));
  
          if (newData?.length < PAGE_SIZE) {
            setHasMore(false);
          }
  
          setSecondarySkillsData((prevData) =>
            page === 0 ? newData : [...prevData, ...newData],
          );
        } catch (error) {
          console.error('Error fetching secondary skills:', error);
        } finally {
          setLoadingSecondary(false);
        }
      },
      [hasMore, loadingSecondary],
    );
  
    useEffect(() => {
      const resetAndFetch = () => {
        setPrimaryPage(0);
        setSecondaryPage(0);
        setHasMore(true);
  
        if (primarySearch === "") {
          setPrimarySkillsData([]); 
          fetchPrimarySkills("", 0); 
        } else {
          fetchPrimarySkills(primarySearch, 0); 
        }
  
        if (secondarySearch === "") {
          setSecondarySkillsData([]); 
          fetchSecondarySkills("", 0); 
        } else {
          fetchSecondarySkills(secondarySearch, 0); 
        }
      };
  
      resetAndFetch();
    }, [primarySearch, secondarySearch, fetchPrimarySkills,  fetchSecondarySkills]);
  
    const handlePrimarySearchChange = debounce((value: string) => {
      setPrimarySearch(value.trim());
    }, 300);
  
    const handleSecondarySearchChange = debounce((value: string) => {
      setSecondarySearch(value.trim());
    }, 300);

  const reasonForHireOptions = [
    { value: 'replacement', label: 'Replacement' },
    { value: 'newPosition', label: 'New Position' },
    { value: 'expansion', label: 'Expansion' },
  ];

  const workModeOptions = [
    { value: 'onsite', label: 'Onsite' },
    { value: 'remote', label: 'Remote' },
    { value: 'hybrid', label: 'Hybrid' },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {
    const { name, value } = event.target;

    if (name === "ctc") {
      // Clean the input to allow only numbers and one decimal point
      const cleanValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').substring(0, 16);
      const numValue = parseFloat(cleanValue) || 0;
  
      // Only update if empty or value is >= 100000
      if (!cleanValue || numValue >= 100000) {
        setFormData(prevData => ({
          ...prevData,
          [name]: cleanValue,
        }));
      }
    }

    if (name === "secondarySkills") {
      secondarySkills.push(value);
    }

    if (name === "primarySkills") {
      arr.push(value);
      setFormData({
        ...formData,
        primarySkills: [arr],
      });
    }

    if (name === "minExperience") {
      // When min experience changes, reset max experience if it's less than new min
      const minYear = parseInt(value.split(' ')[0]);
      setFormData(prevData => {
        const maxYear = parseInt(prevData.maxExperience?.split(' ')[0] || '0');
        return {
          ...prevData,
          [name]: value,
          // Reset maxExperience if it's less than or equal to new minExperience
          maxExperience: maxYear <= minYear ? '' : prevData.maxExperience
        };
      });
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const minExperienceOptions = Array.from({ length: 21 }, (_, i) => ({
    value: `${i} year${i + 1 > 1 ? 's' : ''}`,
    label: `${i} year${i + 1 > 1 ? 's' : ''}`,
  }));

  // Generate options for max experience based on selected min experience
  const maxExperienceOptions = React.useMemo(() => {
    const minYear = parseInt(formData.minExperience?.split(' ')[0] || '0');
    const startYear = minYear > 0 ? minYear + 1 : 1;
    
    return Array.from({ length: 20 - startYear + 1 }, (_, i) => ({
      value: `${startYear + i} year${startYear + i > 1 ? 's' : ''}`,
      label: `${startYear + i} year${startYear + i > 1 ? 's' : ''}`,
    }));
  }, [formData.minExperience]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    console.log("con11111", fileContent)
  
    const file = event.target.files[0];
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
  
    if (!allowedTypes.includes(file.type)) {
      toast.error("Invalid file type. Only PDF, DOC, and DOCX are allowed.");
      event.target.value = "";
      return;
    }
  
    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      toast.error("File size exceeds 5 MB. Please upload a smaller file.");
      event.target.value = "";
      return;
    }
  
    const formDataObj = new FormData();
    formDataObj.append("file", file);
  
    try {
      // Upload file first
      const uploadResponse = await uploadFile(formDataObj);
      setChooseFile(uploadResponse.data.data);
      
      // Then handle content extraction
      if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        const arrayBuffer = await file.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer });
        const content = result.value;
        setFileContent(content);
        // Update form data directly
        setFormData(prev => ({
          ...prev,
          jdAttachmentText: content
        }));
      } else if (file.type === "application/pdf") {
        const arrayBuffer = await file.arrayBuffer();
        const typedArray = new Uint8Array(arrayBuffer);
        const pdf = await getDocument(typedArray).promise;
        
        let textContent = "";
        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const text = await page.getTextContent();
          const pageText = text.items.map((item: any) => item.str).join(" ");
          textContent += pageText + " ";
        }
        
        setFileContent(textContent);
        // Update form data directly
        setFormData(prev => ({
          ...prev,
          jdAttachmentText: textContent
        }));
      }
      
      // toast.success("File processed successfully!");
    } catch (error) {
      console.error("Error processing file:", error);
      // toast.error("Failed to process file.");
    }
  };

  const handleL1Change = (item: any) => {
    setFormData({
      ...formData,
      l1ApprovalId: item?.id,
      l1ApprovalLabel: item?.label
    });
  }

  const handleL2Change = (item: any) => {
    setFormData({
      ...formData,
      l2ApprovalId: item?.id,
      l2ApprovalLabel: item?.label
    });
  }

  const handlePReportChange = (item:any)=>{
    setFormData({
      ...formData,
      posReportsToId: item?.id,
      posReportsToLabel: item?.label,
    });
  }

  const handleSelectStateChange = (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    const { value } = event.target;
    const selectedStateLabel = stateOption.find((option) => option.value === value)?.label || '';
    setSelectedState(value);
  
    // // Clear city selection when state changes
    // setSelectedCity('');
    
    setFormData((prevValues: any) => ({
      ...prevValues,
      state: selectedStateLabel,
      // location: '', 
    }));
  
    setErrors((prevErrors) => ({
      ...prevErrors,
      state: '',
    }));
  };

  const handleSelectCityChange = (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    const { value } = event.target;
    const selectedCityLabel = cityOption.find((option) => option.value === value)?.label || '';
    setSelectedCity(value);
  
    setFormData((prevValues: any) => ({
      ...prevValues,
      location: selectedCityLabel,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      location: '',
    }));
  };

  useEffect(() => {
    GetCountries().then((result) => {
      const countries = result.map((country: any) => ({
        label: country.name,
        value: country.id,
      }));
      setCountryOption(countries);
  
      // Set default country to India
      const india = countries.find((country) => country.label === 'India');
      if (india) {
        setSelectedCountry(india.value); // Automatically set India as default
      }
    });
  }, []);

 
  useEffect(() => {
    // Fetch states based on the default country
    GetState(selectedCountry).then((result) => {
      setStateOption(
        result.map((state: any) => ({
          label: state.name,
          value: state.id,
        }))
      );
    });
  }, [selectedCountry]);

  useEffect(() => {
    // Fetch cities based on selected state
    if (selectedState) {
      GetCity(selectedCountry, selectedState).then((result) => {
        setCityOption(
          result.map((city: any) => ({
            label: city.name,
            value: city.id,
          }))
        );
      });
    }
  }, [selectedState,selectedCountry]);

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    Object.keys(formData).forEach((key) => {
      if (key === 'jdAttachmentFileUrl' && formData.jdAttachmentFileUrl === '') {
        return;
      }
      if (key === "jdAttachmentText" && formData.jdAttachmentText === "") {
        return;
      }
      if (key === 'otherFileUrl' && formData.otherFileUrl === '') {
        return;
      }
      if (
        key === "secondarySkills" &&
        formData.secondarySkills === ""
      ) {
        return;
      }
      if (
        key === "band" &&
        formData.band === ""
      ) {
        return;
      }
      if (
        key === "comments" &&
        formData.comments === ""
      ) {
        return;
      }
      if (
        key === "qualNeeded" &&
        formData.qualNeeded === ""
      ) {
        return;
      }
      if (!formData[key as keyof typeof formData]) {
        newErrors[key] = key;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFileChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
  
      
      if (!allowedTypes.includes(file.type)) {
        toast.error("Invalid file type. Only PDF, DOC, and DOCX are allowed.");
        event.target.value = ""; 
        return;
      }

      const maxSize = 5 * 1024 * 1024;
      if (file.size > maxSize) {
        toast.error("File size exceeds 5 MB. Please upload a smaller file.");
        event.target.value = "";
        return;
      }
      const reader = new FileReader();
      const formData = new FormData();
      formData.append("file", file);
      reader.onload = (e) => {
        uploadFile(formData).then((res) => setChooseFile1(res.data.data)).catch(err => console.error(err));
      };
      reader.readAsDataURL(file);
    }
  }

  // const validate = () => {
  //   const newErrors: { [key: string]: string } = {};
  //   Object.keys(formData).forEach((key) => {
  //     if (!formData[key as keyof typeof formData]) {
  //       newErrors[key] = key+" field is required";
  //     }
  //   });
  //   setErrors(newErrors);
  //   return Object.keys(newErrors).length === 0;
  // };

  // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   const newErrors = validate();
  //   if (Object.keys(newErrors).length > 0) {
  //     setErrors(newErrors);
  //     return;
  //   }
  //   setSubmitted(true);
  //   // Form is valid, proceed with submission
  // };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (loading) return; 
    setLoading(true); 
    setSubmitted(true);
    if (formData.numOfPosition === "0" ||
      (formData.numOfPosition?.length || 0) > 4) {
        setLoading(false);
      return;
    }

    if ((formData.positionTitle?.length || 0) > 32) {
      setLoading(false);
      return;
    }

    if ((formData.ctc?.length || 0) > 15) {
      setLoading(false);
      return;
    }

    if ((formData.comments?.length || 0) > 500) {
      setLoading(false);
      return;
    }

    delete formData.l1ApprovalLabel
    delete formData.l2ApprovalLabel
    let data = {
      ...formData
    }
    if (pSkills.length >= 1) {
      data = {
        ...data,
        primarySkills: pSkills
      }
    }
    if (setSSkills.length >= 1) {
      data = {
        ...data,
        secondarySkills: sSkills
      }
    }
    if (chooseFile) {
      data = {
        ...data,
        jdAttachmentFileUrl: chooseFile
      }
    }
    if (chooseFile1) {
      data = {
        ...data,
        otherFileUrl: chooseFile1
      }
    }
try{
  if (validate()) {
    await submitRthForm(data);
    toast.success("Form Submitted Sucessfully")
    navigate('/dashboard?tab=my-requests')
    setFormData({
      userId: userId || "",
      contractDur: "",
      contractType: "",
      businessId: "",
      positionTitle: "",
      l1ApprovalId: "",
      l2ApprovalId: "",
      departmentId: "",
      band: "",
      qualNeeded: "",
      minExperience: "",
      maxExperience: "",
      primarySkills: "",
      secondarySkills: "",
      workMode: "",
      priority: "",
      numOfPosition: "",
      posReportsToId: "",
      reasonForHire: "",
      location: "",
      state: "",
      ctc: "",
      prefStartDate: "",
      comments: "",
      typeOfRth: "Internal",
      modeOfRth: "Contract",
      jdAttachmentFileUrl: "",
      otherFileUrl: "",
      l1ApprovalLabel: "",
      l2ApprovalLabel: "",
      jdAttachmentText: "",
    });
    setErrors({});
    setSubmitted(false);
  }
} catch (error) {
      toast.error("Error submitting form");
  } finally {
      setLoading(false);
  }
    
    // const newErrors = validate();
    // setErrors(newErrors);
    // if (Object.keys(newErrors).length === 0) {
    //   setSubmitted(true);
    // } else {
    //   setSubmitted(false);
    // }
  };

  useEffect(() => {
    if (formData?.departmentId) {
      console.log("Fetching L1 and L2 approvers for department:", formData.departmentId);
  
      getRthL1L2GetAPIData(formData.departmentId)
        .then((res) => {
          if (res.data?.data) {
            const { l1Approver, l2Approver } = res.data.data;
            const updatedFormData = {
              l1ApprovalId: l1Approver?.userId || '',
              l2ApprovalId: l2Approver?.userId || '',
              l1ApprovalLabel: `${l1Approver?.firstName || ''} ${l1Approver?.lastName || ''}`,
              l2ApprovalLabel: `${l2Approver?.firstName || ''} ${l2Approver?.lastName || ''}`,
            };
  
            setFormData((prevFormData) => {
              const isUpdated =
                prevFormData.l1ApprovalId !== updatedFormData.l1ApprovalId ||
                prevFormData.l2ApprovalId !== updatedFormData.l2ApprovalId ||
                prevFormData.l1ApprovalLabel !== updatedFormData.l1ApprovalLabel ||
                prevFormData.l2ApprovalLabel !== updatedFormData.l2ApprovalLabel;
  
              return isUpdated ? { ...prevFormData, ...updatedFormData } : prevFormData;
            });
  
            setErrors((prevErrors) => ({
              ...prevErrors,
              l1ApprovalId: "",
              l2ApprovalId: "",
              l1ApprovalLabel: "",
              l2ApprovalLabel: "",
            }));
          }
        })
        .catch((error) => console.error("Error fetching approvers:", error));
    }
  }, [formData?.departmentId]); 
  

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            marginBottom: "50px",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              p: 3,
              backgroundColor: "white",
              marginTop: "10px",
            }}
          >
            <Typography color="gray" sx={{ fontSize: "12px" }}>
              On-Role Details
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  md={3}
                  lg={2}
                  sx={{ paddingTop: "33px", paddingLeft: "30px" }}
                >
                  <CommonSelect
                    labelId="business-label"
                    label="Business"
                    name="businessId"
                    value={formData.businessId}
                    onChange={handleChange}
                    options={businessData}
                    error={!!errors.businessId}
                    helperText={
                      errors.businessId === "businessId" ? "Business" : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <CommonSelect
                    labelId="Department"
                    label="Department"
                    name="departmentId"
                    value={formData.departmentId}
                    onChange={handleChange}
                    options={departmentData}
                    error={!!errors.departmentId}
                    helperText={
                      errors.departmentId === "departmentId" ? "Department" : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <CommonTextField
                    label="Number of Positions"
                    name="numOfPosition"
                    value={formData.numOfPosition
                      ?.replace(/[^0-9]/g, "")
                      .substring(0, 5)}
                    onChange={handleChange}
                    error={
                      !!errors.numOfPosition ||
                      formData.numOfPosition === "0" ||
                      (formData.numOfPosition?.length || 0) > 4
                    }
                    helperText={
                      formData.numOfPosition === "0"
                        ? "Number of Positions cannot be 0"
                        : (formData.numOfPosition?.length || 0) > 4
                        ? "Number of Positions cannot exceed 4 Letters"
                        : errors.numOfPosition === "numOfPosition"
                        ? "Number of Positions field is required"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <SearchableDropdown
                    searchAPICall={getRthPositionReportsTo}
                    label="Position Reports To"
                    value={
                      formData.posReportsToId && formData.posReportsToLabel
                        ? {
                            id: formData.posReportsToId,
                            label: formData.posReportsToLabel,
                          }
                        : null
                    }
                    onOptionSelect={handlePReportChange}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <SearchableDropdown
                    searchAPICall={getRthL1ApproverData}
                    l2IdApproval={formData.l2ApprovalId}
                    label="L1 approval"
                    value={
                      formData.l1ApprovalId && formData.l1ApprovalLabel
                        ? {
                            id: formData.l1ApprovalId,
                            label: formData.l1ApprovalLabel,
                          }
                        : null
                    }
                    onOptionSelect={handleL1Change}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <SearchableDropdown
                    searchAPICall={getRthL2ApproverData}
                    label="L2 approval"
                    l2IdApproval={formData.l1ApprovalId}
                    value={
                      formData.l2ApprovalId && formData.l2ApprovalLabel
                        ? {
                            id: formData.l2ApprovalId,
                            label: formData.l2ApprovalLabel,
                          }
                        : null
                    }
                    onOptionSelect={handleL2Change}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <CommonSelect
                    labelId="reason-for-hire"
                    label="Reason for Hire"
                    name="reasonForHire"
                    value={formData.reasonForHire}
                    onChange={handleChange}
                    error={!!errors.reasonForHire}
                    helperText={
                      errors.reasonForHire === "reasonForHire"
                        ? "Reason for Hire"
                        : ""
                    }
                    options={reasonForHireOptions}
                  />
                </Grid>
                {/* <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="location"
                  label="Location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  error={!!errors.location}
                  helperText={errors.location === "location" ? "Location " : ""}
                  options={LocationData}
                />
              </Grid> */}
                <Grid item xs={12} md={3} lg={2}>
                  <CommonSelect
                    labelId=""
                    label="State"
                    name="state"
                    value={selectedState || ""}
                    onChange={handleSelectStateChange}
                    error={!!errors.state}
                    helperText={errors.state === "state" ? "State" : ""}
                    options={stateOption}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <CommonSelect
                    labelId="location"
                    label="Location"
                    name="city"
                    value={selectedCity || ""}
                    onChange={handleSelectCityChange}
                    error={!!errors.location}
                    helperText={
                      errors.location === "location" ? "Location " : ""
                    }
                    options={cityOption}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <CommonTextField
                    label="Position Title"
                    name="positionTitle"
                    value={formData.positionTitle || ""}
                    onChange={(e) => {
                      const alphabetOnly = e.target.value.replace(
                        /[^a-zA-Z\s]/g,
                        ""
                      );

                      const truncated = alphabetOnly.substring(0, 32);

                      handleChange({
                        ...e,
                        target: {
                          ...e.target,
                          value: truncated,
                          name: "positionTitle",
                        },
                      });
                    }}
                    error={
                      !!errors.positionTitle ||
                      (formData.positionTitle?.length || 0) > 32
                    }
                    helperText={
                      (formData.positionTitle?.length || 0) > 32
                        ? "Position Title cannot exceed 32 characters"
                        : errors.positionTitle === "positionTitle"
                        ? "Position Title field is required"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <CommonSelect
                    labelId="Band"
                    label="Band"
                    name="band"
                    value={formData.band}
                    onChange={handleChange}
                    options={bandData}
                    // error={!!errors.band}
                    // helperText={errors.band === "band" ? "Band " : ""}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <CommonTextField
                    label="Qualification"
                    name="qualNeeded"
                    value={formData.qualNeeded}
                    onChange={(e) => {
                      const input = e.target.value;
                      const lettersOnly = input.replace(/[^a-zA-Z\s]/g, "");

                      handleChange({
                        ...e,
                        target: {
                          ...e.target,
                          name: "qualNeeded",
                          value: lettersOnly,
                        },
                      });
                    }}
                    error={(formData.qualNeeded?.length || 0) > 64}
                    helperText={
                      (formData.qualNeeded?.length || 0) > 64
                        ? "Qualification cannot exceed 64 characters"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <CommonSelect
                    labelId="Min Experience"
                    label="Min Experience"
                    name="minExperience"
                    value={formData.minExperience}
                    onChange={handleChange}
                    options={minExperienceOptions}
                    error={!!errors.minExperience}
                    helperText={
                      errors.minExperience === "minExperience"
                        ? "Min Experience"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <CommonSelect
                    labelId="Max Experience"
                    label="Max Experience"
                    name="maxExperience"
                    value={formData.maxExperience}
                    onChange={handleChange}
                    options={maxExperienceOptions}
                    error={!!errors.maxExperience}
                    helperText={
                      errors.maxExperience === "maxExperience"
                        ? "Max Experience"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <CommonTextField
                    label="CTC"
                    name="ctc"
                    value={formData.ctc
                      ?.replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g, "$1")
                      .substring(0, 16)}
                    onChange={handleChange}
                    error={
                      !!errors.ctc ||
                      (formData.ctc && parseFloat(formData.ctc) < 100000) ||
                      (formData.ctc?.split(".")[0].length || 0) > 15
                    }
                    helperText={
                      formData.ctc && parseFloat(formData.ctc) < 100000
                        ? "Minimum CTC should be Lakh"
                        : (formData.ctc?.split(".")[0].length || 0) > 15
                        ? "CTC cannot exceed 15 Numbers"
                        : errors.ctc === "ctc"
                        ? "CTC field is required"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <Autocomplete
                    size="small"
                    multiple
                    limitTags={1}
                    options={primarySkillsData || []}
                    getOptionLabel={(option: SkillOption) => option.label}
                    onInputChange={(event, value) =>
                      handlePrimarySearchChange(value)
                    }
                    onChange={(event, newValue) => {
                      console.warn("newValue", newValue);
                      const pData = newValue.map((i) => i.value);
                      setPSkills(pData);
                      const sSkills = secondarySkillsData.filter(
                        (i: any) => !pData.includes(i.value)
                      );
                      if (newValue.length >= 1) {
                        setSecondarySkillsData(sSkills);
                      } else {
                        setSecondarySkillsData(primarySkillsData);
                      }
                      setFormData((prevData) => ({
                        ...prevData,
                        primarySkills: pData,
                      }));
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        primarySkills: "",
                      }));
                    }}
                    ListboxProps={{
                      onScroll: (event) => {
                        const listboxNode = event.currentTarget;
                        if (
                          listboxNode.scrollTop + listboxNode.clientHeight >=
                            listboxNode.scrollHeight - 50 &&
                          !loadingPrimary &&
                          hasMore
                        ) {
                          const nextPage = primaryPage + 1;
                          setPrimaryPage(nextPage);
                          fetchPrimarySkills(primarySearch, nextPage);
                        }
                      },
                      style: { maxHeight: "200px", overflow: "auto" },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "40px", // Fixed height for input box
                        overflow: "hidden",
                      },
                      // "& .MuiAutocomplete-tag": {
                      //   margin: "2px",
                      //   maxWidth: "calc(78% - 4px)",
                      //   // height: "18px", // Apply your required max-width calculation
                      // },
                      "& .MuiOutlinedInput-root": {
                        padding: "2px 8px !important",
                      },
                      "& .MuiAutocomplete-tag": {
                        margin: "2px",
                        height: "18px",
                        maxWidth: "calc(60% - 4px)",
                      },
                      "& .MuiChip-root": {
                        height: "20px",
                      },
                      maxWidth: 500,
                      "& .MuiAutocomplete-option": {
                        fontSize: "0.8rem",
                      },
                      "& .MuiAutocomplete-root": {
                        width: "100%",
                      },
                      paddingBottom: "10px",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Primary Skills"
                        error={!!errors.primarySkills}
                        helperText={
                          errors.primarySkills === "primarySkills"
                            ? "Primary skills field is required"
                            : ""
                        }
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            fontSize: "0.8rem",
                            paddingLeft: "2px",
                            paddingRight: "65px",
                            paddingBottom: "9px",
                          },
                          endAdornment: (
                            <>
                              {loadingPrimary && (
                                <CircularProgress color="inherit" size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: "0.8rem",
                            fontWeight: 410,
                            color: "gray",
                          },
                        }}
                      />
                    )}
                    disableCloseOnSelect
                    renderOption={(
                      props,
                      option: SkillOption,
                      { selected }
                    ) => (
                      <li
                        {...props}
                        style={{
                          fontSize: "0.8rem",
                          padding: "1px 1px",
                          margin: "0px 0",
                        }}
                      >
                        <Checkbox
                          sx={{
                            transform: "scale(0.8)",
                            marginRight: 2,
                            padding: 0,
                          }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={3} lg={2}>
                  <Autocomplete
                    size="small"
                    multiple
                    limitTags={1}
                    options={secondarySkillsData || []}
                    getOptionLabel={(option: SkillOption) => option.label}
                    onInputChange={(event, value) =>
                      handleSecondarySearchChange(value)
                    }
                    onChange={(event, newValue) => {
                      const sData = newValue.map((i) => i.value);
                      setSSkills(sData);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        secondarySkills: "",
                      }));
                      setFormData((prevData) => ({
                        ...prevData,
                        secondarySkills: sData,
                      }));
                    }}
                    ListboxProps={{
                      onScroll: (event) => {
                        const listboxNode = event.currentTarget;
                        if (
                          listboxNode.scrollTop + listboxNode.clientHeight >=
                            listboxNode.scrollHeight - 50 &&
                          !loadingSecondary &&
                          hasMore
                        ) {
                          const nextPage = secondaryPage + 1;
                          setSecondaryPage(nextPage);
                          fetchSecondarySkills(secondarySearch, nextPage);
                        }
                      },
                      style: { maxHeight: "200px", overflow: "auto" },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "40px", // Fixed height for input box
                        overflow: "hidden",
                      },
                      // "& .MuiAutocomplete-tag": {
                      //   margin: "2px",
                      //   maxWidth: "calc(78% - 4px)",
                      //   // height: "18px", // Apply your required max-width calculation
                      // },
                      "& .MuiOutlinedInput-root": {
                        padding: "2px 8px !important",
                      },
                      "& .MuiAutocomplete-tag": {
                        margin: "2px",
                        height: "18px",
                        maxWidth: "calc(60% - 4px)",
                      },
                      "& .MuiChip-root": {
                        height: "20px",
                      },
                      maxWidth: 500,
                      "& .MuiAutocomplete-option": {
                        fontSize: "0.8rem",
                      },
                      "& .MuiAutocomplete-root": {
                        width: "100%",
                      },
                      paddingBottom: "10px",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Secondary Skills"
                        // error={!!errors.secondarySkills}
                        // helperText={
                        //   errors.secondarySkills === "secondarySkills"
                        //     ? "Secondary skills field is requierd"
                        //     : ""
                        // }
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            fontSize: "0.8rem",
                            paddingLeft: "2px",
                            paddingRight: "65px",
                            paddingBottom: "9px",
                          },
                          endAdornment: (
                            <>
                              {loadingSecondary && (
                                <CircularProgress color="inherit" size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: "0.8rem",
                            fontWeight: 410,
                            color: "gray",
                          },
                        }}
                      />
                    )}
                    disableCloseOnSelect
                    renderOption={(
                      props,
                      option: SkillOption,
                      { selected }
                    ) => (
                      <li
                        {...props}
                        style={{
                          fontSize: "0.8rem",
                          padding: "1px 1px",
                          margin: "0px 0",
                        }}
                      >
                        <Checkbox
                          sx={{
                            transform: "scale(0.8)",
                            marginRight: 2,
                            padding: 0,
                          }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={3} lg={2}>
                  <CommonTextField
                    label="Preferred Start Date"
                    type="date"
                    name="prefStartDate"
                    value={formData.prefStartDate}
                    onChange={handleChange}
                    error={!!errors.prefStartDate}
                    helperText={
                      errors.prefStartDate === "prefStartDate"
                        ? "Preferred Start Date"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <CommonSelect
                    labelId="work-mode-label"
                    label="Work Mode"
                    name="workMode"
                    value={formData.workMode}
                    onChange={handleChange}
                    options={workModeOptions}
                    error={!!errors.workMode}
                    helperText={
                      errors.workMode === "workMode" ? "Work Mode" : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <CommonSelect
                    labelId="priority-label"
                    label="Priority"
                    name="priority"
                    value={formData.priority}
                    onChange={handleChange}
                    options={[
                      { value: "High", label: "High" },
                      { value: "Medium", label: "Medium" },
                      { value: "Low", label: "Low" },
                    ]}
                    error={!!errors.priority}
                    helperText={
                      errors.priority === "priority" ? "Priority" : ""
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3} lg={2}>
                  <input
                    accept=".pdf,.doc,.docx"
                    id="file-input"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <label htmlFor="file-input">
                    <Button
                      variant="outlined"
                      component="span"
                      sx={{
                        width: "100%",
                        fontSize: "0.75rem",
                        padding: "2px 4px",
                        minHeight: "40px",
                        border: "1px solid",
                        borderColor: "darkgray",
                        borderRadius: "4px",
                        marginTop: "1px",
                        backgroundColor: "transparent",
                      }}
                    >
                      {!formData.jdAttachmentFileUrl && !chooseFile
                        ? "JD Upload"
                        : formData.jdAttachmentFileUrl || "JD Uploaded"}
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <input
                    accept=".pdf,.doc,.docx"
                    id="file-input1"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange1}
                  />
                  <label htmlFor="file-input1">
                    <Button
                      variant="outlined"
                      component="span"
                      sx={{
                        width: "100%",
                        fontSize: "0.75rem",
                        padding: "2px 4px",
                        minHeight: "40px",
                        border: "1px solid",
                        borderColor: "darkgray",
                        borderRadius: "4px",
                        marginTop: "1px",
                        backgroundColor: "transparent",
                      }}
                    >
                      {!formData.otherFileUrl && !chooseFile1
                        ? "File Upload"
                        : formData.otherFileUrl || "File Uploaded"}
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <CommonTextField
                    label="Comments"
                    name="comments"
                    value={formData.comments.substring(0, 501)}
                    onChange={handleChange}
                    error={(formData.comments?.length || 0) > 500}
                    helperText={
                      (formData.comments?.length || 0) > 500
                        ? "comments cannot exceed 500 characters"
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              p: 3,
              backgroundColor: "white",
              marginTop: "10px",
            }}
          >
            <Typography color="gray" sx={{ fontSize: "12px" }}>
              Contract Details
            </Typography>
            <Box component="form" sx={{ mt: 1 }}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={3} lg={2}>
                  <CommonSelect
                    labelId="Contract Duration"
                    label="Duration in Months"
                    name="contractDur"
                    value={formData.contractDur}
                    onChange={handleChange}
                    options={Array.from({ length: 12 }, (_, i) => ({
                      value: (i + 1).toString(),
                      label: (i + 1).toString(),
                    }))}
                    error={!!errors.contractDur}
                    helperText={
                      errors.contractDur === "contractDur"
                        ? "Duration in Months"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <CommonSelect
                    labelId="Contract Type"
                    label="Contract Type"
                    name="contractType"
                    value={formData.contractType}
                    onChange={handleChange}
                    options={[
                      { value: "Full-Time", label: "Full-Time" },
                      { value: "Part-Time", label: "Part-Time" },
                      { value: "Fixed-Term", label: "Fixed-Term" },
                    ]}
                    error={!!errors.contractType}
                    helperText={
                      errors.contractType === "contractType"
                        ? "Contract Type"
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              sx={{
                mr: 2,
                color: "black",
                backgroundColor: "transparent",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              }}
              onClick={() => {
                setFormData({
                  userId: userId || "",
                  contractDur: "",
                  contractType: "",
                  businessId: "",
                  positionTitle: "",
                  l1ApprovalId: "",
                  l2ApprovalId: "",
                  departmentId: "",
                  band: "",
                  qualNeeded: "",
                  minExperience: "",
                  maxExperience: "",
                  primarySkills: [],
                  secondarySkills: [],
                  workMode: "",
                  priority: "",
                  numOfPosition: "",
                  posReportsToId: "",
                  reasonForHire: "",
                  location: "",
                  state: "",
                  ctc: "",
                  prefStartDate: "",
                  comments: "",
                  typeOfRth: "Internal",
                  modeOfRth: "Contract",
                  jdAttachmentFileUrl: "",
                  otherFileUrl: "",
                  jdAttachmentText: "",
                });
                setErrors({});
                setSubmitted(false);
                navigate(-1);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ color: "#F0F5FF" }}
              type="submit"
              disabled={loading} // Disable button when loading
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: "#F0F5FF" }} />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
          {submitted && Object.keys(errors).length > 0 && (
            <Alert severity="error" sx={{ mt: 2 }}>
              Please correct the errors in the form before submitting.
            </Alert>
          )}
        </Box>
      </ThemeProvider>
    </>
  );
};

export default ContractTab;
