import { Box } from "@mui/material"
import CustomTabs from "../common/tabs"
import { useEffect, useState } from "react";
import FilterSidebar from "../dashboard/commoncomponent/FilterSidebar";
import { InterviewCalendar } from "./interviewCalendar";
import { InterviewStatusTable } from "./interviewStatusTable";
import { useAppSelector } from "../../redux/hooks";

export const InterviewModuleTab = () => {
  const [tabIndex, setTabIndex] =  useState<number>(() => {
     const candidateTabIndex = localStorage.getItem('candidateTabIndex');
    return candidateTabIndex !== null ? parseInt(candidateTabIndex, 10) : 0;
  });
    const [drawerOpen, setDrawerOpen] = useState(false);
    const[tabName,setTabName]=useState('interviewModuleCalendar');
    const filterData=useAppSelector((state)=>state.interviewFilter);
    const dateFilter=useAppSelector((state)=>state.teamHeadFilter);
    const{interviewMode,interviewName,interviewStages,source,positions,interviewStatus}=filterData;
    const{fromDate,toDate}=dateFilter;

    const tabsData = [
        { label: 'Interview Calendar' }, 
        { label: 'Interview Status' },
      ];

      const normalizePayloadValue = (value:any) => {
        if (Array.isArray(value)) {
          return value.length > 0 ? value : null;
        }
        return value || null;
      };

      const addOneDay = (value: any) => {
        if (!value) return null;
        
        const date = new Date(value);
        if (isNaN(date.getTime())) return null; // Check for invalid date
      
        date.setUTCDate(date.getUTCDate() + 1);
        return date.toISOString();
      };
      
      
      console.log(fromDate,toDate,'dfghjkl');
      
      const filterPayloadCalendar={
        "interviewDateFrom":addOneDay(fromDate),
        "interviewDateTo":normalizePayloadValue(toDate),
        "interviewStages":normalizePayloadValue(interviewStages),
        "interviewName":normalizePayloadValue(interviewName),
        "source":normalizePayloadValue(source),
        "expectedCtc":null,
        "noticePeriod":null,
        "interviewMode":normalizePayloadValue(interviewMode),
        "positions":normalizePayloadValue(positions),
        "interviewStatus":normalizePayloadValue(interviewStatus)
      }

    const renderTabContent = () => {
    switch (tabIndex) {
        case 0:
        return <InterviewCalendar drawerOpen={drawerOpen} filterPayload={filterPayloadCalendar} />;
        case 1:
        return <InterviewStatusTable filterPayload={filterPayloadCalendar}/>
    }};

    useEffect(()=>{
      switch(tabIndex){
        case 0:
          setTabName('interviewModuleCalendar')
          break;
        case 1:
          setTabName('interviewModuleTable')
          break;
      }
    },[tabIndex])

  return (
    <Box sx={{ width: '100%', backgroundColor: '#f0f4ff', display: 'flex', position: 'relative', flexDirection:'row' }}>    
    <Box sx={{height:'80vh'}}>    
    <FilterSidebar onChangeFilter={setDrawerOpen} drawerOpen={drawerOpen} tabIndex={tabIndex} tabName={tabName} />
    </Box>
    <Box sx={{ width: '100%', transition: 'margin-left 0.3s' }}>
    <CustomTabs
      tabsData={tabsData}
      drawerOpen={drawerOpen} 
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
    />
    {renderTabContent()}
    </Box>      
    </Box>
  )
}
