import { Avatar, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getRecruiterLead, getRthById } from "../../../Crud/rth";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { assignRecruiterLead } from "../../../redux/features/recruiterSlice";
import { AppDispatch } from "../../../redux/store";
import { toast } from "react-toastify";

interface prop {
  numOfPosition?: string;
  numOfOpenPosition?: any;
}

const AssignRecruiterLead: React.FC<prop> = ({
  numOfOpenPosition,
  numOfPosition,
}) => {
  console.log("numOfOpenPosition", numOfOpenPosition);
  console.log("numOfPosition", numOfPosition);
  

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const location = useLocation();
  const [status,setStatus]=useState<string>();
  const { id } = location.state || {};
  const [isRecruiterLeadAssigned, setIsRecruiterLeadAssigned] =
    useState<boolean>(false);
  const [recruiterLeadData, setRecruiterLeadData] = useState<any[]>([]);
  console.log("recruiterLeadData", recruiterLeadData);
  const [disable, setIsDisable] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  console.log('edit',edit)

  const [rows, setRows] = useState<any[]>([]);
  const [assignedDate, setAssignedDate] = useState<string>("");    
  const dispatch=useDispatch<AppDispatch>();
  const [reset,setReset]=useState<number>(0);

  const getFormattedDate = () => {
    const now = new Date();
    return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(now.getDate()).padStart(2, "0")}`;
  };

  const handleButtonClick = () => {
    setIsOpen(false);
  };

  const handleEditButton = () => {
    setIsDisable(false);
    setEdit(true);
  };

  const handleCancelButton=()=>{
    setIsOpen(true);
    setReset((prev)=>prev+1)
  }

  const handleSubmitButton = async () => {
    const userId = localStorage.getItem("userId") || "";
  
    const recruiterLeadIds = rows.map((recruiter) => recruiter.userId);
  
    console.log("recruiterLeadIds", recruiterLeadIds.toString());
  
    if (rows.length > 0) {
      try {
        // Wait for the async operation to complete
        await dispatch(
          assignRecruiterLead({
            rthId: id,
            recruiterLeadId: recruiterLeadIds.toString(),
            assignedDate: assignedDate,
            taId: userId,
          })
        ).unwrap(); // Use .unwrap() to handle errors properly (if using Redux Toolkit)
  
        // After successful assignment
        setReset((prev) => prev + 1);
        setRows([]); // Clear the selected recruiter leads
        toast.success("Recruiter Lead Assigned Successfully");
      } catch (error) {
        // Handle any error from the async operation
        console.error("Error assigning recruiter lead:", error);
        toast.error("Failed to assign recruiter lead. Please try again.");
      }
    } else {
      toast.warning("Select at least 1 Recruiter Lead");
    }
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRthById(id);
        const assigned=response.data.recruiterLeadAssigned;        
        setIsRecruiterLeadAssigned(assigned);
        setStatus(response.data.status);

        if (assigned) {
          console.log("assigned",assigned);
          console.log("machii",response);
          setIsOpen(false);
          setIsDisable(true);
          setRows(response.data.assignedRecruiterLead);
          console.log("response.data", response.data.recruiterLeadAssignedDate);
          setAssignedDate(response.data.recruiterLeadAssignedDate);
        } else {
          setRows([]);
          setAssignedDate(getFormattedDate());
          setIsDisable(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    getRecruiterLead().then((res) => {
      console.log("recruiter Lead", res);
      setRecruiterLeadData(res.data);
    });
  }, [reset,id]);

  console.log("rowww", rows);

  return (
    <Box>
      {isOpen ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexDirection={"row"}
        >
          <Box display={"flex"} flexDirection={"column"}>
            <Box>
              <Typography
                variant="h6"
                sx={{ fontSize: "12px", color: "#919EAB" }}
              >
                Click "Assign Recruiter Lead" to allocate the{" "}
                {numOfOpenPosition} available positions.
              </Typography>
            </Box>
          </Box>

          <Button
            variant="outlined"
            size="small"
            sx={{ border: "1.5px solid" }}
            onClick={handleButtonClick}
          >
            Assign Recruiter Lead
          </Button>
        </Box>
      ) : (
        <Box>
          <Box marginBottom={2} display={"flex"} justifyContent={"flex-end"}>
            {isRecruiterLeadAssigned && (
              <Button disabled={status === "Closed"?true:false} onClick={handleEditButton}>Edit</Button>
            )}
          </Box>

          <Box
            display={"flex"}
            gap={2}
            p={1}
            sx={{ backgroundColor: "#F9F9F9" }}
          >
            <Typography flexBasis={"20%"} fontSize={15}>
              No. of Open Position
            </Typography>
            <Typography flexBasis={"50%"} fontSize={15}>
              Assign Recruiter Lead
            </Typography>
            <Box
              display={"flex"}
              flexBasis={"30%"}
              alignItems={"center"}
              gap={2}
            >
              <Typography fontSize={15}>Assigned Date</Typography>
              <Typography fontSize={12}>
                assigned date can’t be edited
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            gap={2}
            pl={1}
            pr={1}
            pt={0.5}
            pb={0.5}
            mb={0.8}
          >
            <Box flexBasis={"20%"}>
              <Typography>{numOfOpenPosition}</Typography>
            </Box>

            <Box
              flexBasis={"50%"}
              display={"flex"}
              flexDirection={"column"}
              gap={1}
            >              
                <Select
                  options={recruiterLeadData.map((data) => ({
                    value: data.userId,
                    label: `${data.firstName} ${data.lastName}`,
                    imageUrl: data.imageUrl,
                    designation: data.designation,                    
                  }))}
                  formatOptionLabel={(data) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {data.imageUrl ? (
                        <img
                          src={data.imageUrl}
                          alt={data.label}
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <Avatar
                          alt={data.label}
                          src={data.imageUrl || data.label}
                        />
                      )}
                      <div style={{ marginLeft: 8 }}>
                        <div style={{ fontWeight: "bold" }}>{data.label}</div>
                        <div style={{ fontSize: "0.75rem", color: "#555" }}>
                          {data.designation}
                        </div>
                      </div>
                    </div>
                  )}
                  isMulti 
                  isDisabled={disable}  
                  onChange={(selected) =>
                    setRows(
                      selected.map((option) => ({
                        userId: option.value,
                        userName: option.label,
                        imageUrl: option.imageUrl,
                        designation: option.designation,
                      }))
                    )
                  }     
                           
                  value={
                    rows.length > 0
                      ? rows.map((row)=>({
                        value:row.userId,
                        label:`${row.userName}`,
                        imageUrl: row.imageUrl,
                        designation: row.designation
                      }))
                      : null
                  }
                ></Select>              
            </Box>

            <Box
              flexBasis={"30%"}
              display={"flex"}
              alignItems={"center"}
              gap={7}
            >
              <Typography sx={{ color: disable ? "grey" : "black" }}>
                {assignedDate}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {
        (!disable && !isOpen) && (
          <Box display={"flex"} justifyContent={"end"}  gap={3}>
          <Button variant="outlined" onClick={handleCancelButton}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmitButton}>Submit</Button>
        </Box>
        )
      }
    </Box>
  );
};

export default AssignRecruiterLead;
