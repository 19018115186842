import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import CustomTabs from '../../../common/tabs';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { EmployeInformation } from './EmployeInformation';
import { InterviewDetails } from './resumeCardTabFiles/InterviewDetails';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getCandidateByCandidateId, getInterviewStatus, setInterviewStatus } from '../../../../redux/features/resume';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { toast } from 'react-toastify';
import { truncateText } from '../../../../helpers/truncateText';
import { getCandidateInterviewDetails } from '../../../../redux/features/interviewSchedule';


export const CandidateInfoTab = () => {
    const drawerOpen = true;
    const [tabIndex, setTabIndex] =  useState<number>(() => {
        // Retrieve the saved tab index from localStorage or default to 1 (Candidate tab)
        const candidateTabIndex = localStorage.getItem('candidateTabIndex');
        return candidateTabIndex !== null ? parseInt(candidateTabIndex, 10) : 0;
      });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [categories, setCategories] = useState<Record<string, string[]>>({});
    const[buttonName,setButtonName]=useState<any>([]);
    const[interviewPermission,setInterviewPermission]=useState('');
    const {candidateName='',candidateId}=useParams();
    const recruiterId=localStorage.getItem('userId');
    const state=useAppSelector((state)=>state.resume.interviewStatus);
    const candidateDetails=useAppSelector((state:any)=>state.resume.candidate)
    const navigate=useNavigate();
    const dispatch=useAppDispatch();
    const location=useLocation();
    const resumeRepoPath=location.pathname.includes('resumeRepo');
    const tabsData = [
        { label: 'Employee Information' },
        { label: 'Interview Details' },
      ];
      const [isAccess,setIsAccess]=useState<boolean>(false);
      const candidateInterviewDetails=useAppSelector((state:any)=>state.interviewSchedule.candidateInterviewDetails);
      const[interviewDetailAccess,setInterviewDetailAccess]=useState<boolean>(false);

        const renderTabContent = () => {
        switch (tabIndex) {
          case 0:
            return <EmployeInformation isAccess={isAccess} />;
          case 1:
            return <InterviewDetails interviewPermission={interviewPermission} recruiterId={candidateDetails?.recruiterId}/>;
          default:
            return null;
        }
      };

      const categorizeData = (data: any[]) => {
        return data.reduce(
          (acc: Record<string, string[]>, item: any) => {
            const statusName=item?.statusName
            if (typeof statusName === 'string') {
              const lowerCaseStatus = statusName.toLowerCase();

              // Exclude 'Offer Accepted' and 'Offer Declined'
              if (lowerCaseStatus.includes("offer accepted") || lowerCaseStatus.includes("offer declined")) {
                return acc; // Skip adding these statuses
              }
              if (statusName.toLowerCase().includes('screening')) {
                acc['Screening'] = [...(acc['Screening'] || []), statusName];
              } else if (statusName.toLowerCase().includes('l1')) {
                acc['L1 Interview'] = [...(acc['L1 Interview'] || []), statusName];
              } else if (statusName.toLowerCase().includes('l2')) {
                acc['L2 Interview'] = [...(acc['L2 Interview'] || []), statusName];
              } else if (statusName.toLowerCase().includes('l3')) {
                acc['L3 Interview'] = [...(acc['L3 Interview'] || []), statusName];
              } 
              else if (statusName.toLowerCase().includes('hr')) {
                acc['HR Round'] = [...(acc['HR Round'] || []), statusName];
              } 
              else {
                acc['Other'] = [...(acc['Other'] || []), statusName];
              }
            } else {
              // Optional: Handle non-string statusNames
              acc['Other'] = [...(acc['Other'] || []), JSON.stringify(statusName)];
            }
            return acc;
          },
          {}
        );
      };
      
    
      const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      };
       
      const handleClose = () => {
        setAnchorEl(null);
      };

    const handleButtonName=async(label:string)=>{
           
      if (
        label === 'Selected To Offer' &&
        !['L1 Interview Scheduled','L1 Interview Completed','L1 Interview Selected','L1 Interview Rejected','L2 Interview Scheduled','L2 Interview Completed','L2 Interview Selected','L2 Interview Rejected',
          'L3 Interview Scheduled','L3 Interview Completed','L3 Interview Selected','L3 Interview Rejected','HR Round Scheduled','HR Round Completed','HR Round Selected','HR Round Rejected'
        ].includes(candidateDetails?.interviewStatus || '')
      ) {        
        toast.error("Oops! Candidate not selected.");
        return;
      }
      
      const resultAction=await dispatch(setInterviewStatus({candidateId:candidateId,recruiterId:recruiterId,status:label}));

      if(setInterviewStatus.fulfilled.match(resultAction)){
        const { status }=resultAction.payload || {};
        if(status===true){
          setButtonName(label);
          toast.success('Interview Status Updated Successfully');
          dispatch(getCandidateByCandidateId(candidateId));
        }
        else if(status===400){
          toast.error('Interview Status not updated');
        }
      
    }
      dispatch(getCandidateByCandidateId(candidateId));
      handleClose();
    }
    
const handleBack=()=>{
  //  navigate(`/dashboard/MRH/${jobTitle}/${id}`);
  navigate(-1)
  localStorage.setItem('activeTabIndex', '1');
  localStorage.setItem('candidateTabIndex','0');
};

useEffect(()=>{
  if(candidateDetails)
  setButtonName(candidateDetails?.interviewStatus)
  setInterviewPermission(candidateDetails?.interviewPermission);
},[candidateDetails])

useEffect(()=>{
   dispatch(getInterviewStatus());
   dispatch(getCandidateByCandidateId(candidateId));
},[candidateId,dispatch]);

useEffect(() => {
  if (state) {
    const categorizedData = categorizeData(state);
    setCategories(categorizedData);
  }
}, [state]);

useEffect(() => {
  const checkAccess = async () => {
    if(resumeRepoPath){
      setIsAccess(false);
    }
    else{
      setIsAccess(true);
    }
  };
  checkAccess();
}, [resumeRepoPath]);

useEffect(()=>{
  dispatch(getCandidateInterviewDetails(candidateId))
},[candidateId,dispatch])

useEffect(() => {
  if (candidateInterviewDetails?.interviewDetL1) {
    const { interviewRound, interviewName, interviewMode, interviewDate, from, to, interviewer } =
      candidateInterviewDetails.interviewDetL1;

    const hasValidData =
      interviewRound || interviewName || interviewMode || interviewDate || from || to || interviewer;

    setInterviewDetailAccess(!!hasValidData);
  } else {
    setInterviewDetailAccess(false);
  }
}, [candidateInterviewDetails]);


console.log(isAccess,'isAccess1234');

  return (
    <><Box>
      <Box sx={{display:'flex', flexDirection:'row', ml:'20px'}}>
      <ArrowBackIcon onClick={handleBack} sx={{ color: '#948F8F', cursor: 'pointer', mt:'10px' }} />
      <CustomTabs
          tabsData={tabsData}
          drawerOpen={drawerOpen}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex} />
          </Box>
          <Box sx={{mt:'20px',ml:'70px',display:'flex',flexDirection:'row',gap:'4px'}}>
        <Tooltip
              title={candidateName} 
              disableHoverListener={candidateName?.length <= 20} 
              arrow>
        <Typography sx={{ fontSize: '22px', fontWeight: '500' }}>{truncateText(candidateName,20)}</Typography>
        </Tooltip>
        {tabIndex===0 && (
          <>
          <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}} >
            <Box>
            <Button variant='contained' sx={{ width: '180px', height: '34px', textTransform: 'none', fontSize: '12px', backgroundColor: '#1677FF', ml: '14px', p: 0, borderRadius: '4px' }} endIcon={ (candidateDetails?.interviewPermission && isAccess && interviewDetailAccess) ?<KeyboardArrowDownIcon sx={{ fontSize: '14px' }} />:null} 
               onClick={(candidateDetails?.interviewPermission && isAccess && interviewDetailAccess) ? handleClick : undefined}>
             {buttonName}
             </Button>
             </Box>
          </Box>
          </>
        )}
      </Box>

      <Box sx={{height:'auto', mt:'30px' }}>
      {renderTabContent()}
      </Box>
      
    </Box>
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
        style: {
          padding: '16px', 
          borderRadius: '8px', 
        },
      }}
    >
       <Box
    sx={{
      maxHeight: 300, // Limit the height
      overflowY: 'auto', // Enable vertical scrolling
    }}
  >
          {Object.entries(categories).map(([category, items]) => (
          <div key={category}>
            <Typography variant="subtitle2" color="textSecondary" style={{ marginBottom: '8px' }}>
              {category}:
            </Typography>
            {items.map((item, index) => (
              <MenuItem key={index} onClick={() =>handleButtonName(item)}>
                {item}
              </MenuItem>
            ))}
          </div>
        ))}
        </Box>
      </Menu>

      </>
  )
}
