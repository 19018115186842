import React, { useEffect, useState } from 'react';
import {  Typography, Grid, Box, SelectChangeEvent} from '@mui/material';
import CommonTextField from '../../../common/CommonTextField';
import CommonSelect from '../../../common/CommomSelect';
import { useAppDispatch } from '../external/ExternalDetails';
import { getCity, getCountry, getState } from '../../../../redux/features/resume';
import { useLocation } from 'react-router-dom';
import {
  GetCountries,
  GetState,
  GetCity,
} from "react-country-state-city";
import { setCity, setCountry, setState } from '../../../../redux/features/resumeSlice';
import { toast } from 'react-toastify';
 
const Address = ({ formValues, setFormValues ,errors,setErrors,selectedState,setSelectedState,selectedCountry,setSelectedCountry,selectedCity,setSelectedCity,isAccess }: any) => {
const location=useLocation();
const isFormsPath = location.pathname.includes('Forms');
const path=location.pathname.includes("candidateForm");
const dispatch=useAppDispatch();
const[cityOption,setCityOption]=useState<any[]>([]);
const[countryOption,setCountryOption]=useState<any[]>([]);
const[stateOption,setStateOption]=useState<any[]>([]);
 
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    
    if (!isAccess) {
      toast.warn("You don't have access");
      return;
    }

    const { name, value } = event.target;

    if(name==='country'){
      setErrors((prevErrors:any)=>({
        ...prevErrors,
        country:'',
      }))
    }

    if(name==='state'){
      setErrors((prevErrors:any)=>({
        ...prevErrors,
        state:'',
      }))
    }

    if(name==='city'){
      setErrors((prevErrors:any)=>({
        ...prevErrors,
        city:'',
      }))
    }
    
    if (name === 'postalCode') {
      // Allow only numbers and limit to 6 digits
      if (!/^\d{0,6}$/.test(value)) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          postalCode: 'Postal code must be a number and no more than 6 digits',
        }));
      } else {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          postalCode: '',
        }));
      }
    }

    if (name === 'addressLine1') {
      if (!value) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          addressLine1: 'AddressLine 1 is required',
        }));
      } else if (value.length > 128) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          addressLine1: 'AddressLine 1 cannot exceed 128 characters',
        }));
      } else {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          addressLine1: '',
        }));
      }
    }
  
    // For addressLine2 validation
    if (name === 'addressLine2') {
      if (value.length > 128) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          addressLine2: 'AddressLine 2 cannot exceed 128 characters',
        }));
      } else {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          addressLine2: '',
        }));
      }
    }
  
    setFormValues((prevValues: any) => ({
      ...prevValues,
      address: {
        ...prevValues.address,
        [name]: value,
      },
    }));
  };

  const handleSelectStateChange=(event:React.ChangeEvent<HTMLInputElement>|SelectChangeEvent<string>)=>{
    
    if (!isAccess) {
      toast.warn("You don't have access");
      return;
    }

    const{value}=event.target;
    const stateValue=String(value);
      const selectedStateLabel = stateOption.find((option) => option.value === value)?.label || '';
    setSelectedState(value);
   dispatch(setState(selectedStateLabel));
    setFormValues((prevValues:any)=>({
      ...prevValues,
      address:{
        ...prevValues.address,
        state:stateValue,
      }
    }));  
      setErrors((prevErrors:any)=>({
        ...prevErrors,
        state:'',
      }))
    

  }
  
  const handleSelectCountryChange=(event:React.ChangeEvent<HTMLInputElement>|SelectChangeEvent<string>)=>{
    
    if (!isAccess) {
      toast.warn("You don't have access");
      return;
    }

    const{value}=event.target;
    const countryValue=String(value);
    const selectedCountryLabel = countryOption.find((option) => option.value === value)?.label || '';
    setSelectedCountry(value);
    dispatch(setCountry(selectedCountryLabel));

    setFormValues((prevValues:any)=>({
      ...prevValues,
      address:{
        ...prevValues.address,
        country:countryValue,
      }
    }));
    
      setErrors((prevErrors:any)=>({
        ...prevErrors,
        country:'',
      }))
    
  }
  const handleSelectCityChange=(event:React.ChangeEvent<HTMLInputElement>|SelectChangeEvent<string>)=>{
    
    if (!isAccess) {
      toast.warn("You don't have access");
      return;
    }

    const{value}=event.target;
    const cityValue=String(value);
    const selectedCityLabel = cityOption.find((option) => option.value === value)?.label || '';
    setSelectedCity(value);
    dispatch(setCity(selectedCityLabel));

    setErrors((prevErrors:any)=>({
      ...prevErrors,
      city:'',
    }))
     
    setFormValues((prevValues:any)=>({
      ...prevValues,
      address:{
        ...prevValues.address,
        city:cityValue,
      }
    }));
  }

  useEffect(()=>{
    dispatch(getCountry());
    dispatch(getCity());
    dispatch(getState());
  },[dispatch]);

  useEffect(() => {
    // Fetch countries
    GetCountries().then((result:any) => {
      setCountryOption(
        result.map((country: any) => ({
          label: country.name,
          value: country.id,
        }))
      );
    });
  }, []);

  useEffect(() => {
    // Fetch states based on selected country
    if (selectedCountry) {
      GetState(selectedCountry).then((result:any) => {
        setStateOption(
          result.map((state: any) => ({
            label: state.name,
            value: state.id,
          }))
        );
      });
    }
  }, [selectedCountry]);

  useEffect(() => {
    // Fetch cities based on selected state
    if (selectedState) {
      GetCity(selectedCountry,selectedState).then((result:any) => {
        setCityOption(
          result.map((city: any) => ({
            label: city.name,
            value: city.id,
          }))
        );
      });
    }
  }, [selectedState,selectedCountry]);
  
  return (
   
    <Box sx={{ pt: '4px',ml:path?'10px':'' , pb:'10px'}}>
        {/* <Paper elevation={0} sx={{overflowY:'auto',pt:'10px',position:'relative'}}> */}
        {isFormsPath ? (
      <Typography sx={{ fontSize: '22px', fontWeight: '500', mb: '27px' }}>
        Address
      </Typography>
    ) : path ?(
      <Typography
    sx={{
      fontSize: '20px',
      fontWeight: '500',
      ml:'20px',
      color: '#000',
    }}
  >
    4.Address
  </Typography>
    ) :(
      <Typography
        sx={{
          fontSize: '14px',
          mb: '17px',
          m: '14px',
          backgroundColor: '#1677FF1A',
          color: '#1677FF',
          width: '69px',
          p: '3px',
          borderRadius: '3px',
          textAlign: 'center',
          fontWeight: '500',
        }}
      >
        Address
      </Typography>
    )}
      <Box sx={{width:'50vw'}}>
      <Grid container spacing={2} sx={isFormsPath ? {} : {m: '10px'}} >
 
      <Grid item xs={12} md={4}>
        <Typography sx={{fontSize:'14px',color:'#bababa' , mb:'17px'}}>Country
        {path && <span style={{ color: 'red' }}> *</span>}
        </Typography>
        <CommonSelect
                  labelId=""
                  label=""
                  name="country"
                  value={selectedCountry||''}
                  onChange={handleSelectCountryChange}
                  error={!!errors?.country}
                  helperText={errors?.country}
                  options={countryOption}
                />
          </Grid>
          <Grid item xs={12} md={4}>
             <Typography sx={{fontSize:'14px',color:'#bababa' , mb:'17px'}}>Address Line 1
             {path && <span style={{ color: 'red' }}> *</span>}
             </Typography>
             <CommonTextField
                  label=""
                  name="addressLine1"
                  value={formValues.address?.addressLine1||''}
                  onChange={handleInputChange}
                  error={!!errors?.addressLine1}
                  helperText={errors?.addressLine1}
                />
             </Grid>
             <Grid item xs={12} md={4}>
             <Typography sx={{fontSize:'14px',color:'#bababa' , mb:'17px'}}>Address Line 2
             {path && <span style={{ color: 'red' }}> *</span>}
             </Typography>
             <CommonTextField
                  label=""
                  name="addressLine2"
                  value={formValues?.address?.addressLine2||''}
                  onChange={handleInputChange}
                  error={!!errors?.addressLine2}
                  helperText={errors?.addressLine2}
                />
             </Grid>
             <Grid item xs={12} md={4}>
        <Typography sx={{fontSize:'14px',color:'#bababa' , mb:'17px'}}>Postal Code
        {path && <span style={{ color: 'red' }}> *</span>}
        </Typography>
        <CommonTextField
                  label=""
                  name="postalCode"
                  value={formValues?.address?.postalCode||''}
                  onChange={handleInputChange}
                  error={!!errors?.postalCode}
                  helperText={errors?.postalCode}
                />
              </Grid>
          <Grid item xs={12} md={4}>
             <Typography sx={{fontSize:'14px',color:'#bababa' , mb:'17px'}}>State
             {path && <span style={{ color: 'red' }}> *</span>}
             </Typography>
             <CommonSelect
                  labelId=""
                  label=""
                  name="state"
                  value={selectedState||''}
                  onChange={handleSelectStateChange}
                  error={!!errors?.state}
                  helperText={errors?.state}
                  options={stateOption.length > 0 ? stateOption : [{ label: "No options", value: "" }]}
                />
             </Grid>
             <Grid item xs={12} md={4}>
             <Typography sx={{fontSize:'14px',color:'#bababa' , mb:'17px'}}>City
             {path && <span style={{ color: 'red' }}> *</span>}
             </Typography>
             <CommonSelect
                  labelId=""
                  label=""
                  name="city"
                  value={selectedCity||''}
                  onChange={handleSelectCityChange}
                  options={cityOption.length > 0 ? cityOption : [{ label: "No options", value: "" }]}
                  error={!!errors?.city}
                  helperText={errors?.city}
                />
             </Grid>
        </Grid>
     
      </Box>
      {/* <Box sx={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',gap:'20px',mt:'50px',mr:'10px', position:"absolute", bottom:'10px', right:'10px'}}>
        <Button  sx={{textTransform:'none',border:'InactiveBorder',color:'#848484'}} onClick={handlePreviousPage}>Previous</Button>
      <Button variant='contained' sx={{textTransform:'none'}} onClick={handleContinue}>Continue</Button>
      </Box> */}
      {/* </Paper> */}
    </Box>
  );
};
 
export default Address;
 
