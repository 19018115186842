import React from "react";
import { Box, Grid, Typography, Avatar, Chip } from "@mui/material";
import priorityHighSymbol from "../../../assets/images/priorityHighSymbol.svg";
import priorityMediumSymbol from "../../../assets/images/priorityMediumSymbol.svg";
import priorityLowSymbol from "../../../assets/images/priorityLowSymbol.svg";
import { stringAvatar } from "../../../helpers/stringAvatar";
import pdf from "../../../assets/images/pdf 1.svg"

// interface jobData {
//   business?:{
//     businessId:string,
//     businessName:string
//   };
//   positionTitle?: string;
//   numOfPosition?: string;
//   department?: {
//     departmentId: string,
//     departmentName: string
//   }
//   location?: string;
//   qualNeeded?: {
//     qualificationId: string,
//     degree: string
//   };
//   workMode?: string;
//   maxExperience?: string;
//   band?: {
//     bandId: string;
//     bandName: string;
//   };
//   ctc?: string;
//   priority?: string;
//   reportsTo?: any;
//   l1Approval?: {
//     id:string
//     firstName:string
//     profilePicture:string
//     role:string
//   }
//   l2Approval?:{
//     id:string
//     firstName:string
//     profilePicture:string
//     role:string
//   };
//   raisedBy?: string;
//   position?: string;
//   posReportsTo?:{
//     id:string
//     firstName:string
//     profilePicture:any
//     role:string
//   } ;
//   userId?:{
//     id:string
//     firstName:string
//     profilePicture:any
//     role:string
//   }
//   typeOfRth?:string;
//   modeOfRth?:string
// }

// interface InternalOnRoleDetailsProps {
//   jobData?: jobData;
// }
const formatCurrency = (value: number | undefined): string => {
  if (value === undefined) return "";
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  }).format(value);
};

const 
InternalOnRoleDetails = ({ jobData }: any) => {
  return (
    <>
      <Box
        sx={{
          p: 3,
          backgroundColor: "background.default",
          borderRadius: 2,
          ml: "16px",
          mr: "16px",
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          {jobData?.typeOfRth} - {jobData?.modeOfRth} Details
        </Typography>
        <Grid container spacing={2}>
          {/* Existing fields */}
          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                Business
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: "14px" }}
                title={jobData?.business?.businessName || ""}
              >
                {jobData?.business?.businessName
                  ? jobData.business.businessName.length > 10
                    ? `${jobData.business.businessName.slice(0, 10)}...`
                    : jobData.business.businessName
                  : ""}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                Position Title
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "14px" }}>
                {jobData?.positionTitle}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                Number Of Positions
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "14px" }}>
                {jobData?.numOfPosition}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                Department
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "14px" }}>
                {jobData?.department?.departmentName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                Location
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "14px" }}>
                {jobData?.location}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                Qualification Needed
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "14px" }}>
                {jobData?.qualNeeded}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt="10px">
          {/* Additional fields */}
          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                Work Mode
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "14px" }}>
                {jobData?.workMode
                  ? jobData.workMode.charAt(0).toUpperCase() +
                    jobData.workMode.slice(1)
                  : ""}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                Min Experience
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "14px" }}>
                {jobData?.minExperience}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                Max Experience
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "14px" }}>
                {jobData?.maxExperience}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                Band
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "14px" }}>
                {jobData?.band}
              </Typography>
            </Box>
          </Grid>
          {jobData?.ctc && (
            <Grid item xs={2}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", color: "gray" }}
                >
                  CTC
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "14px" }}>
                  {formatCurrency(jobData?.ctc)}
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                Priority
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                gap={1}
              >
                {jobData?.priority === "High" ? (
                  <Box>
                    <img src={priorityHighSymbol} alt="" />
                  </Box>
                ) : jobData?.priority === "Medium" ? (
                  <Box>
                    <img src={priorityMediumSymbol} alt="" />
                  </Box>
                ) : (
                  <Box>
                    <img src={priorityLowSymbol} alt="" />
                  </Box>
                )}
                <Typography
                  sx={{
                    color:
                      jobData?.priority === "High"
                        ? "#F04E4E"
                        : jobData?.priority === "Medium"
                        ? "#FFA940"
                        : "#73D13D",
                    fontSize: "100%",
                  }}
                >
                  {jobData?.priority}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt="10px">
          {jobData?.reasonForHire && (
            <Grid item xs={2}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", color: "gray" }}
                >
                  Reason For Hire
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "14px" }}>
                  {jobData.reasonForHire.charAt(0).toUpperCase() +
                    jobData.reasonForHire.slice(1)}
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                Preffered Start Date
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "14px" }}>
                {jobData?.prefStartDate}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                Job Attachment
              </Typography>
              <Box
              // sx={{
              //   // p: 2,
              //   // backgroundColor: "background.default",
              //   // marginBottom: "1%",
              //   // borderRadius: 0,
              // }}
              >
                {jobData?.jdAttachmentFileUrl ? (
                  <Chip
                    icon={
                      <img
                        src={pdf}
                        alt="PDF Icon"
                        style={{ width: 20, height: 20 }}
                      />
                    }
                    label="Job description.pdf"
                    onClick={() =>
                      window.open(jobData?.jdAttachmentFileUrl, "_blank")
                    }
                    sx={{
                      cursor: "pointer",
                      borderRadius: 1,
                      backgroundColor: "white",
                    }}
                  />
                ) : (
                  "---"
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                File Attachment
              </Typography>
              <Box
              // sx={{
              //   // p: 2,
              //   // backgroundColor: "background.default",
              //   // marginBottom: "1%",
              //   // borderRadius: 0,
              // }}
              >
                {jobData?.otherFileUrl ? (
                  <Chip
                    icon={
                      <img
                        src={pdf}
                        alt="PDF Icon"
                        style={{ width: 20, height: 20 }}
                      />
                    }
                    label="Other_File.pdf"
                    onClick={() => window.open(jobData?.otherFileUrl, "_blank")}
                    sx={{
                      cursor: "pointer",
                      borderRadius: 1,
                      backgroundColor: "white",
                    }}
                  />
                ) : (
                  "---"
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                Comments
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "14px" }}>
                {jobData?.comments ? jobData?.comments : "---"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt="10px">
          {/* New fields for Position Reports to, L1 Approval, L2 Approval, and Who Raised */}
          {jobData.typeOfRth === "Internal" && (
            <Grid item xs={2}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", color: "gray" }}
                >
                  Position Reports To
                </Typography>
                <Box display="flex" alignItems="center" flexDirection={"row"}>
                  <Box>
                    <Avatar
                      {...stringAvatar(
                        `${jobData?.posReportsTo?.userName}`,
                        "headerProfile"
                      )}
                      alt={jobData?.posReportsTo?.imageUrl}
                      src={jobData?.posReportsTo?.imageUrl}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "13px", ml: 1, mt: 1 }}
                    >
                      {jobData?.posReportsTo?.userName}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "11px", ml: 1, color: "gray" }}
                    >
                      {jobData?.posReportsTo?.designation}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                L1 Approval
              </Typography>
              <Box display="flex" alignItems="center" flexDirection={"row"}>
                <Box>
                  <Avatar
                    alt={jobData?.l1Approval?.imageUrl}
                    {...stringAvatar(
                      `${jobData?.l1Approval?.userName}`,
                      "headerProfile"
                    )}
                    src={jobData?.l1Approval?.imageUrl}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "13px", ml: 1, mt: 1 }}
                  >
                    {jobData?.l1Approval?.userName}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "11px", ml: 1, color: "gray" }}
                  >
                    {jobData?.l1Approval?.designation}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                L2 Approval
              </Typography>
              <Box display="flex" alignItems="center" flexDirection={"row"}>
                <Box>
                  <Avatar
                    alt={jobData?.l2Approval?.imageUrl}
                    {...stringAvatar(
                      `${jobData?.l2Approval?.userName}`,
                      "headerProfile"
                    )}
                    src={jobData?.l2Approval?.imageUrl}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "13px", ml: 1, mt: 1 }}
                  >
                    {jobData?.l2Approval?.userName}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "11px", ml: 1, color: "gray" }}
                  >
                    {jobData?.l2Approval?.designation}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                Created by
              </Typography>
              <Box display="flex" alignItems="center" flexDirection={"row"}>
                <Box>
                  <Avatar
                    alt={jobData?.user?.imageUrl}
                    {...stringAvatar(
                      `${jobData?.user?.userName}`,
                      "headerProfile"
                    )}
                    src={jobData?.user?.imageUrl}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "13px", ml: 1, mt: 1 }}
                  >
                    {jobData?.user?.userName}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "11px", ml: 1, color: "gray" }}
                  >
                    {jobData?.user?.designation}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          p: 3,
          backgroundColor: "background.default",
          borderRadius: 2,
          ml: "16px",
          mr: "16px",
          mt: "16px",
        }}
      >
        <Grid container spacing={2}>
          {/* Additional fields */}

          <Grid item xs={12}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray", marginBottom: "8px" }}
              >
                Primary Skills
              </Typography>
              <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                {jobData?.primarySkills?.map((skill: any, index: any) => (
                  <Chip
                    key={index}
                    label={skill}
                    sx={{
                      fontSize: "12px",
                      backgroundColor: "#f5f5f5",
                      color: "black",
                      borderRadius: "8px",
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt="10px">
          {/* Additional fields */}

          <Grid item xs={12}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", color: "gray", marginBottom: "8px" }}
              >
                Secondary Skills
              </Typography>
              <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                {jobData?.secondarySkills?.map((skill: any, index: any) => (
                  <Chip
                    key={index}
                    label={skill}
                    sx={{
                      fontSize: "12px",
                      backgroundColor: "#f5f5f5",
                      color: "black",
                      borderRadius: "8px",
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {((jobData?.typeOfRth === "Internal" &&
        jobData?.modeOfRth === "Contract") ||
        (jobData?.typeOfRth === "External" &&
          jobData?.modeOfRth === "Contract")) && (
        <Box
          sx={{
            p: 3,
            backgroundColor: "background.default",
            borderRadius: 2,
            ml: "16px",
            mr: "16px",
            mt: "16px",
          }}
        >
          <Grid container spacing={2}>
            {/* Additional fields */}

            <Grid item xs={2}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", color: "gray" }}
                >
                  Contract Duration
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "14px" }}>
                  {jobData?.contractDur}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", color: "gray" }}
                >
                  Contract Type
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "14px" }}>
                  {jobData?.contractType}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      {jobData?.typeOfRth === "External" && (
        <Box
          sx={{
            p: 3,
            backgroundColor: "background.default",
            borderRadius: 2,
            ml: "16px",
            mr: "16px",
            mt: "16px",
          }}
        >
          <Grid container spacing={2}>
            {/* Additional fields */}

            <Grid item xs={2}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", color: "gray" }}
                >
                  Client Name
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "14px" }}>
                  {jobData?.clientName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", color: "gray" }}
                >
                  Client SPOC
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "14px" }}>
                  {jobData?.clientPoc}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", color: "gray" }}
                >
                  Client Rate Card
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "14px" }}>
                  {jobData?.clientRateCard}
                </Typography>
              </Box>
            </Grid>
            {jobData?.clientMarginInAmount && (
            <Grid item xs={2}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", color: "gray" }}
                >
                  Client Margin In Amount
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "14px" }}>
                  {jobData?.clientMarginInAmount}
                </Typography>
              </Box>
            </Grid>
          )}
          {jobData?.oneTimeAmount && (
            <Grid item xs={2}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", color: "gray" }}
                >
                  One TIme Amount
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "14px" }}>
                  {jobData?.oneTimeAmount}
                </Typography>
              </Box>
            </Grid>
          )}
          {jobData?.clientMarginInPer && (
            <Grid item xs={2}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", color: "gray" }}
                >
                  Client Margin In Percentage
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "14px" }}>
                  {jobData?.clientMarginInPer}
                </Typography>
              </Box>
            </Grid>)}
            {jobData?.oneTimeCharge && (
            <Grid item xs={2}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", color: "gray" }}
                >
                  One Time Charge
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "14px" }}>
                  {jobData?.oneTimeCharge}
                </Typography>
              </Box>
            </Grid>)}
            <Grid item xs={2}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", color: "gray" }}
                >
                  Approval Attachment
                </Typography>
                <Box
                // sx={{
                //   // p: 2,
                //   // backgroundColor: "background.default",
                //   // marginBottom: "1%",
                //   // borderRadius: 0,
                // }}
                >
                  {jobData?.approvalAttachment ? (
                    <Chip
                      icon={
                        <img
                          src={pdf}
                          alt="PDF Icon"
                          style={{ width: 20, height: 20 }}
                        />
                      }
                      label="Approval Attachment.pdf"
                      onClick={() =>
                        window.open(jobData?.approvalAttachment, "_blank")
                      }
                      sx={{
                        cursor: "pointer",
                        borderRadius: 1,
                        backgroundColor: "white",
                      }}
                    />
                  ) : (
                    "---"
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default InternalOnRoleDetails;
